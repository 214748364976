import React, { useContext, useEffect, useState } from 'react';
import { IsOnlineContext } from '../../../utils/context/DetectInternetConnectionContainer';
import ConnectionLostPopup from '../no-internet-modals/ConnectionLostModal';
import ConnectionLostMoreInfoModal from '../no-internet-modals/more-info-modal/ConnectionLostMoreInfoModal';

const ConnectionLostPopupAndModal = () => {
  const isOnline = useContext(IsOnlineContext);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState<boolean>(false);

  const handleLearnMoreClick = () => setIsMoreInfoModalOpen(true);
  const handleFinishClick = () => setIsMoreInfoModalOpen(false);

  useEffect(() => {
    if (isOnline) {
      setIsPopupOpen(false);
      setIsMoreInfoModalOpen(false);
      return;
    }
    setIsPopupOpen(true);
    setIsMoreInfoModalOpen(false);
  }, [isOnline]);

  return (
    <>
      <ConnectionLostPopup
        isOpen={isPopupOpen}
        onLearnMoreClick={handleLearnMoreClick}
      />
      <ConnectionLostMoreInfoModal
        isOpen={isMoreInfoModalOpen}
        onFinishClick={handleFinishClick}
      />
    </>
  );
};

export default ConnectionLostPopupAndModal;
