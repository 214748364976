import React, { Dispatch, SetStateAction } from 'react';
import SaveTemplateModalButtons from './SaveTemplateModalButtons';
import HeaderComponent from './templateModalHeader';

interface Props {
  title: string
  setTitle: Dispatch<SetStateAction<string>>
  handleSave: () => void
  handleModalClose: () => void
  isButtonDisabled: boolean
  submitLoading: boolean
  isSaved: boolean,
  openTemplates: () => void
}

const SaveTemplateModalContent = ({
  title,
  setTitle,
  handleSave,
  handleModalClose,
  isButtonDisabled,
  submitLoading,
  isSaved,
  openTemplates,
}:Props) => (
  <>
    <HeaderComponent isSaved={isSaved} title={title} setTitle={setTitle} handleSave={handleSave} />
    <SaveTemplateModalButtons
      isButtonDisabled={isButtonDisabled}
      handleSave={handleSave}
      loading={submitLoading}
      handleModalClose={handleModalClose}
      isSaved={isSaved}
      openTemplates={openTemplates}
    />
  </>
);

export default SaveTemplateModalContent;
