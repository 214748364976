import React, {
  Dispatch, SetStateAction,
} from 'react';
import { TrelloData } from '../../../../../shared/types/types';
import WorkspaceAndBoardSetting from './WorkspaceAndBoardSetting';
import ListSettings from './ListSettings';
import AutoSyncSettings from './AutoSyncSettings';

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}
export default function TrelloSettings({ setTrelloData, trelloData }:Props) {
  return (
    <>
      <WorkspaceAndBoardSetting
        setTrelloData={setTrelloData}
        trelloData={trelloData}
      />
      <ListSettings
        setTrelloData={setTrelloData}
        trelloData={trelloData}
      />
      <AutoSyncSettings
        setTrelloData={setTrelloData}
        trelloData={trelloData}
      />
    </>
  );
}
