const log = (text: string, ...rest: any) => {
  // eslint-disable-next-line no-console
  console.log(`%c ${text}`, 'background: #222; color: #bada55', ...rest);
};

export const logError = (text: string, ...rest: any) => {
  // eslint-disable-next-line no-console
  console.error(`%c ${text}`, 'background: #222; color: #bada55', ...rest);
};

export default log;
