import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import MeetingDetailsHeaderOrg from '../../shared/components/MeetingDetailsHeader/MeetingDetailsHeaderOrg';
import {
  AuthState,
  MeetingAnalyticsData,
  MeetingData, MeetingSections, SecretChat, Shortcut, TaskItem, Version,
} from '../../shared/types/types';

import MeetingAnalyticsDataContext from './context/MeetingAnalyticsDataContext';

import {
  darkBlue1,
} from '../../shared/colours';
import TabsMenu from './tab/TabsMenu';
import TabBody from './tab/TabBody';
import FireworkConfetti from '../../shared/confetti/FireworkConfetti';

const HorizontalLine = styled.hr`
  margin: 0;
  border: 1px solid ${darkBlue1};
`;

const PageContainer = styled.div`
  background-color: ${darkBlue1};
  overflow-y: hidden;
`;

type Props = {
  meetingData: MeetingData,
  meetingAnalyticsData: MeetingAnalyticsData,
  attendeesLoading: boolean,
  calendarError: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line react/no-unused-prop-types
  closeTemplatesView: () => void
  unviewedTasksAssignedToMe: number,
  meetingDataVersion: Version,
  authState: AuthState,
  meetingId: string,
  setNotesContent: Dispatch<SetStateAction<string>>
  setTabContentSynchronized: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line no-unused-vars
  handleShortcutTrigger: (shortcut: Shortcut) => void
  membersInvited: boolean,
  taskItems: TaskItem[],
  meetingTab: MeetingSections,
  setMeetingTab: Dispatch<SetStateAction<MeetingSections>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setSecretChatOpen?: Dispatch<SetStateAction<boolean>>,
  secretNotes: SecretChat[],
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
  secretChatId: string,
}

export default function MeetingPageTemplate({
  meetingData,
  meetingAnalyticsData,
  attendeesLoading,
  calendarError,
  setMembersInvited,
  closeTemplatesView,
  unviewedTasksAssignedToMe,
  meetingDataVersion,
  authState,
  meetingId,
  setNotesContent,
  setTabContentSynchronized,
  handleShortcutTrigger,
  membersInvited,
  taskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  setProseMirrorEditorView,
  setSecretNotes,
  setSecretChatOpen,
  handleSecretNotesSelected,
  secretChatId,
  secretNotes,
}: Props) {
  return (
    <MeetingAnalyticsDataContext.Provider value={meetingAnalyticsData}>
      <PageContainer>
        <MeetingDetailsHeaderOrg
          meetingData={meetingData}
          isRecurringMeeting={isRecurringMeeting}
          attendeesLoading={attendeesLoading}
          calendarError={calendarError}
          setMembersInvited={setMembersInvited}
        />
        <HorizontalLine />
        <TabsMenu
          meetingData={meetingData}
          meetingTab={meetingTab}
          setMeetingTab={setMeetingTab}
          closeTemplatesView={closeTemplatesView}
          unviewedTasks={unviewedTasksAssignedToMe}
          meetingDataVersion={meetingDataVersion}
          setSecretNotes={setSecretNotes}
          handleSecretNotesSelected={handleSecretNotesSelected}
          setSecretChatOpen={setSecretChatOpen}
          secretNotes={secretNotes}
          secretChatId={secretChatId}
        />
        <HorizontalLine />
        <TabBody
          tab={meetingTab}
          userId={authState.userId}
          meetingId={meetingId}
          setNotesContent={setNotesContent}
          setIsSynchronized={setTabContentSynchronized}
          taskItems={taskItems}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isTemplatesOpen={isTemplatesOpen}
          setIsTemplatesOpen={setIsTemplatesOpen}
          onShortcutTrigger={handleShortcutTrigger}
          setProseMirrorEditorView={setProseMirrorEditorView}
          secretChatId={secretChatId}
        />
        {/* <ManageNotesBar
            meetingTab={meetingTab}
            isCreateTaskModalOpen={isCreateTaskModalOpen}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            isTemplatesOpen={isTemplatesOpen}
            setIsTemplatesOpen={setIsTemplatesOpen}
          /> */}
        {/* setIsRecurringMeeting={setIsRecurringMeeting}
          /> */}
        <FireworkConfetti shouldTrigger={membersInvited} />
      </PageContainer>
    </MeetingAnalyticsDataContext.Provider>
  );
}

MeetingPageTemplate.defaultProps = {
  setSecretChatOpen: null,
};
