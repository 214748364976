import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../shared/colours';
import PreviewIcon from '../../../shared/icons/PreviewIcon';
import { uiTextMedium } from '../../../shared/typography';

const PreviewButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const Button = styled.button`
  ${uiTextMedium};
  color: ${blue6};
  border: none;
  background: none;
  cursor: pointer;
`;

interface Props {
  onClick: () => void,
}

const PreviewButton = ({ onClick }: Props) => (
  <PreviewButtonContainer onClick={onClick}>
    <PreviewIcon />
    <Button>Preview before sending</Button>
  </PreviewButtonContainer>
);

export default PreviewButton;
