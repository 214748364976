import React, { useState } from 'react';
import ReactTooltip from '../../../../shared/components/tooltip/ReactTooltip';
import ActiveTickIcon from '../../../../shared/icons/integrations/ActiveTickIcon';
import ThreeDotsIcon from '../../../../shared/icons/integrations/ThreeDotsIcon';
import {
  ActiveText, AppContainer, IntegratedContainer, MenuActiveContainer, MenuContainer, NameText,
} from '../integrations/Integrated';
import OptionsDropdown, { OptionsDropdownItem } from '../integrations/OptionsDropdown';
import NotionWorkspaceModal from './NotionWorkspaceModal';
import { red6, surface } from '../../../../shared/colours';
import { notionAuthUrl } from '../../../../utils/notion/notionUtils';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { notionAPIRemoveNotionIntegration } from '../../../../database/firebaseNotionAPI';
import { NotionData } from '../../../../shared/types/types';
import { NotionIcon } from '../../../../shared/icons/integration-icons';

interface Props {
  enable: boolean
  dropDownRef: React.MutableRefObject<null>,
  userId: string,
  notionWorkspace: NotionData[]
}

export default function NotionIntegrated({
  enable, dropDownRef, userId, notionWorkspace,
}: Props) {
  const [isNotionDropdownActive,
    setIsNotionDropdownActive] = useDetectOutsideClick(dropDownRef, false);
  const [notionWorkspaceModal, setNotionWorkspaceModal] = useState(false);

  const handleDisableNotion = () => {
    notionAPIRemoveNotionIntegration(userId);
    setIsNotionDropdownActive(false);
  };

  const closeNotionWorkspaceModal = () => {
    setNotionWorkspaceModal(false);
  };

  const handleTreeDotsClick = () => {
    setIsNotionDropdownActive(!isNotionDropdownActive);
  };

  const notionWorkspaceToolTip = () => (
    <>
      {notionWorkspace.map(
        (workspace) => (
          <div key={workspace.workspaceId}>
            {`${workspace.workspaceName}`}
            <br />
          </div>
        ),
      )}
    </>
  );

  const notionDropdownOptions: OptionsDropdownItem[] = [
    {
      id: 1, value: 'Add Notion workspace', color: surface, onClick: () => window.open(notionAuthUrl),
    },
    {
      id: 2, value: 'Remove a Workspace', color: surface, onClick: () => setNotionWorkspaceModal(!notionWorkspaceModal),
    },
    {
      id: 3, value: 'Disable', color: red6, onClick: () => handleDisableNotion(),
    },
  ];
  if (!enable) return <></>;
  return (
    <>
      <IntegratedContainer>
        <AppContainer>
          <NotionIcon width="28" />
          <NameText>Notion (Beta)</NameText>
        </AppContainer>
        <MenuActiveContainer>
          <ReactTooltip place="top" multiline tip={notionWorkspaceToolTip()}>
            <ActiveTickIcon />
            <ActiveText>
              {`${notionWorkspace.length} `}
              Active Workspace
            </ActiveText>
          </ReactTooltip>
          <MenuContainer>
            <ThreeDotsIcon onClick={() => handleTreeDotsClick()} />
            <OptionsDropdown
              optionsRef={dropDownRef}
              isOpen={isNotionDropdownActive}
              options={notionDropdownOptions}
            />
          </MenuContainer>
        </MenuActiveContainer>
      </IntegratedContainer>
      <NotionWorkspaceModal
        isOpen={notionWorkspaceModal}
        setModalClosed={closeNotionWorkspaceModal}
      />
    </>
  );
}
