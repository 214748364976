import { Dispatch } from 'react';
import { EmailRecipient } from '../../pages/meeting/modal/ShareNotesModal';
import { meetingPath } from '../../routes';
import { MeetingData, MeetingSections, Note } from '../../shared/types/types';
import { currentTimestamp } from '../currentDateNicelyFormatted';
import { createHeadlessFirepad } from '../firebase';
import {
  AGENDA, SHARED_NOTES, PRIVATE_NOTES, TASK,
} from '../enums';

export const getClosestMeeting = (meetings: MeetingData[]) => {
  let closestMeeting = meetings[0];
  const currentTs = currentTimestamp();
  meetings.forEach((candidateMeeting) => {
    if (timeFromNow(candidateMeeting, currentTs) === timeFromNow(closestMeeting, currentTs)) {
      if (timeFromNowSinceLastUpdate(candidateMeeting, currentTs)
        <= timeFromNowSinceLastUpdate(closestMeeting, currentTs)) {
        closestMeeting = candidateMeeting;
      }
    }
    if (timeFromNow(candidateMeeting, currentTs) < timeFromNow(closestMeeting, currentTs)) {
      closestMeeting = candidateMeeting;
    }
  });
  return closestMeeting;
};

export const makeMeetingUrl = (meetingId: string) => `${meetingPath}/${meetingId}`;

// 1630049400    -> 1630049400000
// 1622061799661 -> 1622061799661
export const getTsWithMilliseconds = (ts: number) => {
  if (!ts) return 0; // handle if ts is undefined
  return (ts.toString().length === 10 ? ts * 1000 : ts);
};

const timeFromNow = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.start?.timestamp) {
    console.log('Meeting doesnt have start timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingStartTimestamp = getTsWithMilliseconds(meeting.date.start.timestamp);
  return Math.abs(meetingStartTimestamp - currentTs);
};

const timeFromNowSinceLastUpdate = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.lastUpdated?.timestamp) {
    console.log('Meeting doesnt have lastUpdated timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingUpdateTimestamp = getTsWithMilliseconds(meeting.date.lastUpdated.timestamp);
  return Math.abs(meetingUpdateTimestamp - currentTs);
};

export const setNoteContent = (
  setNote: Dispatch<Note>,
  noteTab: MeetingSections,
  meetingId: string,
  userId: string,
) => {
  const headlessFirepad = createHeadlessFirepad(noteTab, meetingId, userId);
  console.log(headlessFirepad, noteTab, 'headleass pad');
  headlessFirepad.getHtml((html: string) => setNote(html));
};

export const mapAttendeesToEmailRecipients = (attendees: any[]) => {
  if (attendees.length === 0) return [];
  const list = attendees?.map((attendee, index) => {
    const val: EmailRecipient = {
      index,
      displayValue: attendee.email,
    };
    return val;
  }) ?? [];
  return list;
};

export const mapMeetingSectionToText = (meetingSection: MeetingSections) => {
  if (meetingSection === AGENDA) {
    return 'Agenda';
  }
  if (meetingSection === SHARED_NOTES) {
    return 'Shared Notes';
  }
  if (meetingSection === PRIVATE_NOTES) {
    return 'Private Notes';
  }
  if (meetingSection === TASK) {
    return 'Tasks';
  }
  return '';
};
