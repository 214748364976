import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface GifProps {
  fill?: string;
  width?: number;
  height?: number;
}

const GifIcon = ({
  fill,
  width,
  height,
}: GifProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 4H6C4.9 4 4 4.9 4 6V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4M10 10.5H7.5V13.5H8.5V12H10V13.67C10 14.33 9.47 15 8.67 15H7.33C6.53 15 6 14.33 6 13.67V10.33C6 9.67 6.53 9 7.33 9H8.67C9.47 9 10 9.67 10 10.33V10.5M13 15H11.5V9H13V15M17.5 10.5H16V11.5H17.5V13H16V15H14.5V9H17.5V10.5Z"
      fill={fill}
    />
  </svg>
);

GifIcon.defaultProps = {
  fill: darkBlue4,
  width: 18,
  height: 18,
};

export default GifIcon;
