import React from 'react';
import styled from 'styled-components';
import EditPenIcon from '../../icons/editPenIcon';
import { ReporterV2 } from '../../types/types';
import CreatorTag from './CreatorTag';

const CreatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface Props {
  reporter: ReporterV2,
  createdAt: string
}

const CreatorComponent = ({
  reporter, createdAt,
}: Props) => (
  <CreatorContainer>
    <EditPenIcon />
    <CreatorTag
      reporter={reporter}
      createdAt={createdAt}
    />
  </CreatorContainer>
);

export default CreatorComponent;
