import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface DeleteRowProps {
  fill?: string;
  width?: number;
  height?: number;
}

const DeleteRowIcon = ({
  fill,
  width,
  height,
}: DeleteRowProps) => (
  <svg width={width} height={height} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 6.3359375,9.25e-4 c -0.1919425,0 -0.3828519,0.0723 -0.5292969,0.21875 -0.29289,0.29289 -0.29289,0.7696 0,1.0625 L 6.6894531,2.1649875 5.8046875,3.0497531 c -0.2929,0.2929 -0.2929,0.7676569 0,1.0605469 0.29289,0.2929 0.7696,0.2929 1.0625,0 L 7.75,3.2255344 8.6347657,4.1103 c 0.29285,0.2929 0.767646,0.29289 1.060546,0 0.2929011,-0.2929 0.2929001,-0.7676469 0,-1.0605469 L 8.8105467,2.1649875 9.6953117,1.2802219 c 0.2929011,-0.2929 0.2929011,-0.7676569 0,-1.0605469 -0.2929,-0.2929 -0.767676,-0.2929 -1.060546,0 L 7.75,1.1044406 6.8671875,0.219675 C 6.7207425,0.073225 6.52788,9.25e-4 6.3359375,9.25e-4 Z M 2.75,1.6649875 c -1.51878,0 -2.75,1.23122 -2.75,2.75 v 1 c 0,1.5188002 1.23122,2.7500002 2.75,2.7500002 h 10 c 1.5188,0 2.75,-1.2312 2.75,-2.7500002 v -1 c 0,-1.51878 -1.2312,-2.75 -2.75,-2.75 h -0.5 c -0.4142,0 -0.75,0.33579 -0.75,0.75 0,0.41421 0.3358,0.75 0.75,0.75 h 0.5 c 0.6904,0 1.25,0.55964 1.25,1.25 v 1 c 0,0.6904 -0.5596,1.25 -1.25,1.25 h -10 c -0.69036,0 -1.25,-0.5596 -1.25,-1.25 v -1 c 0,-0.69036 0.55964,-1.25 1.25,-1.25 h 0.4375 c 0.41421,0 0.75,-0.33579 0.75,-0.75 0,-0.41421 -0.33579,-0.75 -0.75,-0.75 z"
      fill={fill}
    />
  </svg>
);

DeleteRowIcon.defaultProps = {
  fill: darkBlue4,
  width: 16,
  height: 8,
};

export default DeleteRowIcon;
