import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../App';
import InfoCircle from './infoCircle';
import { noAccessGoogleCalendarWarning } from '../../utils/constants';
import ReactTooltip from '../components/tooltip/ReactTooltip';

const Container = styled.div`
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
  .tooltip-container {
    width: 400px;
    height: fit-content;
  }
`;

export interface Props {
  error: boolean
}

const WarningIcon = ({ error }: Props) => {
  if (!error) return null;
  const { email } = useContext(AuthContext);

  return (
    <Container>
      <ReactTooltip tip={noAccessGoogleCalendarWarning(email)} place="top">
        <InfoCircle fill="red" />
      </ReactTooltip>
    </Container>
  );
};

export default WarningIcon;
