import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase';
import {
  Assignee, SecretChatAttendeeV2, SecretChat, SecretChatData,
} from '../shared/types/types';
import { firestore } from '../utils/firebase';
import { mapDatabaseDataToSecretNotes, mapDatabaseDataToSecretNotesData } from '../pages/meeting/notes/secret-chat/secretChatUtils';

export const dbSaveSecretNotes = (
  chat: SecretChat,
  meetingId: string,
  // eslint-disable-next-line no-unused-vars
  saveChatPath: (chatId: string) => void,
) => {
  firestore()
    .collection('meetings')
    .doc(meetingId)
    .collection('secretNotes')
    .add(chat)
    .then((docRef) => {
      console.log('secret chat added successfully');
      saveChatPath(docRef.id);
      return docRef.id;
    })
    .catch((error) => {
      console.log('something went wrong');
      console.log(error);
      return '';
    });
};

export const dbListenToSecretNotes = (
  meetingId: string,
  userId: string,
  userEmail: string,
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
) => firestore()
  .collection('meetings')
  .doc(meetingId)
  .collection('secretNotes')
  .onSnapshot((snapshot) => {
    if (snapshot.empty) {
      setSecretNotes([]);
    }
    const chats = mapDatabaseDataToSecretNotes(snapshot.docs, userId, userEmail);
    setSecretNotes(chats);
  });

export const dbListenToSecretNotesData = (
  meetingId: string,
  userId: string,
  userEmail: string,
  setSecretNotesData: Dispatch<SetStateAction<SecretChatData[]>>,
) => firestore()
  .collection('meetings')
  .doc(meetingId)
  .collection('secretNotes')
  .onSnapshot((snapshot) => {
    if (snapshot.empty) {
      setSecretNotesData([]);
    }
    const chats = mapDatabaseDataToSecretNotesData(snapshot.docs, userId, userEmail);
    setSecretNotesData(chats);
  });

export const dbGetSecretChat = (
  meetingId: string,
) => firestore()
  .collection('meetings')
  .doc(meetingId)
  .collection('secretNotes')
  .get()
  .then((result) => {
    console.log('successfully got secret chat');
    result.docs.forEach((doc) => {
      console.log(doc.data());
    });
  })
  .catch((error) => {
    console.log('something went wrong', error);
    Sentry.captureException(error);
  });

export const dbSaveMembersRealtime = (members: Assignee[], path: string) => {
  const db = firebase.database();
  db.ref(path).update({
    members,
  })
    .then(() => {
      console.log('updated members successfully');
    })
    .catch((error) => {
      console.log(error);
      console.log('an error occured when updating secret chat members');
      Sentry.captureException(error);
    });
};

export const dbUpdateSecretChatPath = (
  meetingId: string,
  chatId: string,
  path: string,
) => {
  const updateData = {
    chatPathInRealtimeDb: path,
  };
  firestore()
    .collection('meetings')
    .doc(meetingId)
    .collection('secretNotes')
    .doc(chatId)
    .update(updateData)
    .then(() => {
      console.log('successfully saved secret chat path');
    })
    .catch((error) => {
      console.log(error, 'error');
      Sentry.captureException(error);
    });
};

export const dbDeleteSecretChat = (
  meetingId: string,
  secretChatId: string,
) => firestore()
  .collection('meetings')
  .doc(meetingId)
  .collection('secretNotes')
  .doc(secretChatId)
  .delete()
  .then(() => {
    console.log('chat deleted successfully');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbUpdateSecretChatMembers = (
  updatedTitle: string,
  meetingId: string,
  chatId: string,
  updatedMembers: SecretChatAttendeeV2[],
) => {
  const dbData = {
    title: updatedTitle,
    members: updatedMembers,
  };
  firestore()
    .collection('meetings')
    .doc(meetingId)
    .collection('secretNotes')
    .doc(chatId)
    .update(dbData)
    .then(() => {
      console.log('successfully updated secret notes members');
    })
    .catch((error) => {
      console.log(error, ' an error occured');
      Sentry.captureException(error);
    });
};
