import React from 'react';
import styled from 'styled-components';
import { red6 } from '../../../../../shared/colours';
import { uiTextMedium } from '../../../../../shared/typography';

const DeleteText = styled.span`
  ${uiTextMedium};
  color: ${red6};
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
`;

interface Props {
  onClick: () => void;
}
const DeleteButtonAtom = ({ onClick }: Props) => (
  <DeleteText onClick={onClick}>
    Delete
  </DeleteText>
);

export default DeleteButtonAtom;
