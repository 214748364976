/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../App';
import cfSearchPublicUserDataV2ByEmail, { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { toastInfo } from '../../utils/notifications';
import { stopwatchPrintDiff, stopwatchStart } from '../../utils/stopwatch/stopwatch';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const HaraldTestingPage = () => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const emails = [
    'haraldlons@gmail.com',
    'jamesgotz@gmail.com',
    'jamie@meetshepherd.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'jamesgotz@gmail.com',
    'jamie@meetshepherd.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'jamesgotz@gmail.com',
    'jamie@meetshepherd.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'jamesgotz@gmail.com',
    'jamie@meetshepherd.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'haraldlons@gmail.com',
    'tulleepost@gmail.com',
  ];

  useEffect(() => {
    console.log('User Data');
    console.log(userData);
    return () => { };
  }, [authData]);

  const handleClick1 = async () => {
    toastInfo('Clicked', 'Button 1 clicked');

    const data = await cfSearchPublicUserDataV2ByEmail('haras@gmail.com');
    console.log(data);
  };

  const handleClick2 = async () => {
    toastInfo('Clicked', 'Button 2 clicked');
  };
  const handleClick3 = async () => {
    toastInfo('Clicked', 'Button 3 clicked');

    const startTime = stopwatchStart();
    const result = await cfSearchPublicUserDataV2ByEmailsV2(emails);
    console.log(result);
    stopwatchPrintDiff(startTime, 'click3');
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
    handleClick2();
    handleClick3();
  };

  return (
    <Container>
      <TitleAtom>Harald Testing Page</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      <ButtonSmall text="Click 4" onClick={handleClick4} />
    </Container>
  );
};

export default HaraldTestingPage;
