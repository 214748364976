const Signature = (firstName: string, lastName: string) => `
  <div>Kind regards,</div>
  <div>${firstName} ${lastName}</div>
  <br>
  <div>
    <a href="https://u21807731.ct.sendgrid.net/ls/click?upn=U-2BFIzkXyRzVxZrX-2Flhskl7jCDSLy7PQWyuG8i1-2By4ScycYklW4xYgy612VWCA0R2GdTC-2FahRA9lNQBLqAlyf5nrAbyM-2Fik9xlJZitgkrcJLVT5b8lkVuXaSmHDirSMTLuUJY_-2FRGQkG6tqO37Jd2X3w7YiHkHYR0x5dabnIX4QHaY6miReCIg-2BYJTUqpN07HnCEWPbuJ4-2FOG2Hff116C10GOsUzkfmRtoBjGx8y-2F0d6u5615cu3ZZKAOC3glov-2B9FNv-2FByJj-2FypLG6ZYVDk-2BSWOqmivGj5h0zByhlNMACf4j2oCmWCuEVI9An0VbUmfbXMo0xkhDY-2F3nWOzWFhGNnkEyM3Um30NUILvtQfdBBtKIhGmq9nLjPCmblY2fXvCQWsHmBsqWSU6dc-2B4SI1arA5ies3OL3Q9-2FhEzK0twZcbcED5Apu70eZPKmrj7rJvKYCEbBSSGQHwdk6hJ0fMQl2ebgi6h0hJJaCFUxLA3-2B3cF7-2FioSE4PR7l-2FiiEmUA1P6k-2Bj-2FkPoYyXfE70Jq7RJKvCC6-2F2w-3D-3D" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://u21807731.ct.sendgrid.net/ls/click?upn%3DU-2BFIzkXyRzVxZrX-2Flhskl7jCDSLy7PQWyuG8i1-2By4ScycYklW4xYgy612VWCA0R2GdTC-2FahRA9lNQBLqAlyf5nrAbyM-2Fik9xlJZitgkrcJLVT5b8lkVuXaSmHDirSMTLuUJY_-2FRGQkG6tqO37Jd2X3w7YiHkHYR0x5dabnIX4QHaY6miReCIg-2BYJTUqpN07HnCEWPbuJ4-2FOG2Hff116C10GOsUzkfmRtoBjGx8y-2F0d6u5615cu3ZZKAOC3glov-2B9FNv-2FByJj-2FypLG6ZYVDk-2BSWOqmivGj5h0zByhlNMACf4j2oCmWCuEVI9An0VbUmfbXMo0xkhDY-2F3nWOzWFhGNnkEyM3Um30NUILvtQfdBBtKIhGmq9nLjPCmblY2fXvCQWsHmBsqWSU6dc-2B4SI1arA5ies3OL3Q9-2FhEzK0twZcbcED5Apu70eZPKmrj7rJvKYCEbBSSGQHwdk6hJ0fMQl2ebgi6h0hJJaCFUxLA3-2B3cF7-2FioSE4PR7l-2FiiEmUA1P6k-2Bj-2FkPoYyXfE70Jq7RJKvCC6-2F2w-3D-3D&amp;source=gmail&amp;ust=1626947880276000&amp;usg=AFQjCNHwpiFhEz3FX2M7hj63SOfmGcSeug">
      Click here
    </a>
    to also supercharge your meetings with Shepherd
  </div>
  <br>
  <table>
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="left">
          <a href="https://www.meetshepherd.com/"><img class="max-width" border="0"
              style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:30% !important; width:30%; height:auto !important;"
              width="360" alt="" data-proportionally-constrained="true" data-responsive="true"
              src="http://cdn.mcauto-images-production.sendgrid.net/f18be71b57616d7c/686475b9-4e12-4b43-a539-5cc7e4bfedef/1162x228.png">
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div style="padding:16px;">
    <p style="font-size:12px;line-height:20px;text-align:center;margin:0;"><a>Unsubscribe</a> - <a>Unsubscribe Preferences</a></p>
  </div>
`;

export default Signature;
