import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import TurndownService from 'turndown';
import {
  AuthState,
  SlackChannel, SlackMessageContent, SlackUser, TaskItem,
} from '../../shared/types/types';
import { functions } from '../firebase';
import { toastDanger } from '../notifications';
import {
  Block,
  returnProperDueDate,
  Notification,
  sortSlackChannelsAlphabetically,
} from './slackUtils';

import {
  mapAPIUsersToTypeUsers, isValidBlock,
  createSlackMessageContent, mapSlackAPIChannelToSlackChannel,
} from './SlackAPIUtils';

import mdToSlackBlocks from './SlackFormatting';
import { dbUdpateSlackNotificationSent } from '../../database/firebaseTasksAPI';

const turndownService = new TurndownService();

const slackCoreAPISendMessage = (
  messageContent: SlackMessageContent,
  accessToken: string,
) => functions()
  .httpsCallable('sendMessageToSlackv2')({ accessToken, messageContent })
  .then((response) => response.data)
  .catch((error) => {
    console.log('Got NO response from slackAPISendMessage');
    console.log(error);
    throw error;
  });

export default slackCoreAPISendMessage;

export const slackCoreSendMessages = (
  channels: SlackChannel[],
  accessToken: string,
  notes: string,
) => {
  const promises = channels.map((channel) => {
    const convertedBlocks = coreConvertToSlackBlocks(notes);
    const messageContent = createSlackMessageContent(channel, convertedBlocks);
    return slackCoreAPISendMessage(messageContent, accessToken);
  });
  return Promise.all(promises).then((responses) => console.log(responses));
};

// TODO: Add Core in name
export const coreConvertToSlackBlocks = (notes: string) => {
  const mdNotes = turndownService.turndown(notes);
  const slackBlocks = mdToSlackBlocks(mdNotes);
  return slackBlocks;
};

// TODO Ivan: Where do you check the users slack notification settings?
export const slackCoreAPISendNotificationForTaskUpdate = (
  task: TaskItem,
  context: AuthState,
  updatedSection: string,
  newDueDate?: string,
) => {
  // Example of how I would structure this function
  // 1. Should we send at all?
  // 2. Create object to send
  // 3. Send it
  // 4. Handle respons
  if (task.assignee.data.email === context.email) return;
  const taskItemName = task.data.title;
  const updatedTaskDescription = task.data.description;
  const taskCreatorNames = task.data.reporter.name;
  const oldDate = task.date.dueDate.date.date;
  const dueDate = returnProperDueDate(oldDate, newDueDate);
  const updaterNames = `${context.firstName} ${context.lastName}`;
  const meeting = task.meeting.name;
  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${updaterNames}* updated the ${updatedSection} of a task assigned to you \t ${newDueDate!?.length ? `:date: Due: ${dueDate.toDateString()}` : ''} \n*Task:* ${taskItemName} ${updatedTaskDescription.length ? `\n*Description* ${updatedTaskDescription}` : ''} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${meeting.length ? `\t :virtual-meeting: *Meeting*: ${meeting}` : ''} `,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const dataProps = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(dataProps)
    .then(() => {
      console.log('slack notification sent successfully');
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Something has went wrong with the slack task updated notification');
      Sentry.captureException(error);
    });
};

// TODO Ivan: There is no check here if the user has actually integrated with
// slack or not
// Not sure if you checked either if the user had the settings enabled either
export const slackCoreAPISendNotificationForTaskCreate = (
  task: TaskItem,
  context: AuthState,
) => {
  if (task.assignee.data.email === context.email) return;
  if (!task.assignee.external.slack.hasEnabledSlack) return;
  if (!task.assignee.external.slack.notifications.taskCreated) return;
  const actualDueDate = task.date.dueDate.date.date;
  const dueDate = new Date(task.date.dueDate.date.date);
  const meeting = task.meeting.name;
  const { description } = task.data;
  const taskCreatorNames = task.data.reporter.name;
  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${taskCreatorNames}* created a task assigned to you ${actualDueDate.length ? `:date: Due: ${dueDate?.toDateString()}` : ''}\n* Name:* ${task.data.title} ${description.length ? `\n*Description* ${description}` : ''} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const dataType: Notification = {
    email: context.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(dataType)
    .catch((error) => {
      console.log(error);
      toastDanger('Something has went wrong with the slack new task notification');
      Sentry.captureException(error);
    });
};

export const slackCoreAPISendNotificationForTaskDelete = (
  task: TaskItem,
  context: AuthState,
) => {
  if (task.assignee.data.email === context.email) return;
  const actualDueDate = task.date.dueDate.date.date;
  const dueDate = new Date(task.date.dueDate.date.date);
  const meeting = task.meeting.name;
  const { description } = task.data;
  const deleterNames = `${context.firstName} ${context.lastName}`;
  const taskCreatorNames = task.data.reporter.name;
  const blocks: Block[] = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${deleterNames}* deleted a task assigned to you ${actualDueDate.length ? `:date: Due: ${dueDate?.toDateString()}` : ''}\n*Task Name:* ${task.data.title} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${description.length ? `\n*Description* ${description}` : ''} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const deletedTaskData: Notification = {
    email: context.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(deletedTaskData)
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
    });
};

export const slackCoreAPISendNotificationForTaskOverdue = (
  task: TaskItem,
  context: AuthState,
) => {
  if (task.integrations.slack.isOverdueNotificationSent) return;
  if (!task.assignee.external.slack.notifications.taskOverdue) return;
  const actualDueDate = task.date.dueDate.date.date;
  const dueDate = new Date(task.date.dueDate.date.date);
  const taskDescription = task.data.description;
  const meeting = task.meeting.name;
  const taskCreatorNames = task.data.reporter.name;
  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `You have an overdue task \t ${actualDueDate.length ? `:date: Due: ${dueDate?.toDateString()}` : ''} \n *Task Due:* ${task.data.title} ${taskDescription.length ? `\n*Description* ${taskDescription}` : ''} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const overdueTaskData: Notification = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(overdueTaskData)
    .then(() => {
      dbUdpateSlackNotificationSent(task);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Something has went wrong with the slack task overdue notification');
      Sentry.captureException(error);
    });
};

export const slackCoreAPIGetChannelNamesWithCf = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  functions()
    .httpsCallable('getAllTypesSlackChannels')(accessToken)
    .then((response) => {
      const channels = response?.data?.channels ?? [];
      const channelsWithoutBots = channels.filter(
        (channel: any) => !channel?.is_bot,
      );
      const slackChannels = channelsWithoutBots.map(mapSlackAPIChannelToSlackChannel) ?? [];
      if (slackChannels.length === 0) return;
      setSlackChannels(slackChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get Slack channels', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};

export const slackCoreAPIGetDirectMessages = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  functions()
    .httpsCallable('slackGetDirectMessages')({ accessToken })
    .then((response) => {
      const directMessages = response?.data ?? [];
      const channelsWithoutBots = directMessages.filter(
        (channel: any) => !channel?.is_bot,
      );
      const slackChannels = channelsWithoutBots.map(mapSlackAPIChannelToSlackChannel) ?? [];
      if (slackChannels.length === 0) return;
      setSlackChannels(slackChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get direct Slack messages', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};

export const slackCoreAPIGetUserInfo = async (
  accessToken: string,
  slackUserId: string,
  setSlackDmUser: Dispatch<SetStateAction<SlackUser>>,
) => {
  if (slackUserId.length === 0 || accessToken.length === 0) return;
  functions()
    .httpsCallable('getSlackUserInfo')({
      slackUserId,
      accessToken,
    })
    .then((response) => {
      const user = mapAPIUsersToTypeUsers(response.data);
      setSlackDmUser(user);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to retrieve Slack user data');
      Sentry.captureException(error);
    });
};

export const slackCoreGetChannelNamesWithCf = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  functions()
    .httpsCallable('getAllTypesSlackChannels')(accessToken)
    .then((response) => {
      const channels = response?.data?.channels ?? [];
      const channelsWithoutBots = channels.filter(
        (channel: any) => !channel?.is_bot,
      );
      const slackChannels = channelsWithoutBots.map(mapSlackAPIChannelToSlackChannel) ?? [];
      const sortedChannels = sortSlackChannelsAlphabetically(slackChannels);
      if (sortedChannels.length === 0) return;
      setSlackChannels(sortedChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get Slack channels', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};

export const slackCoreGetDirectMessages = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  functions()
    .httpsCallable('slackGetDirectMessages')({ accessToken })
    .then((response) => {
      const directMessages = response?.data ?? [];
      const channelsWithoutBots = directMessages.filter(
        (channel: any) => !channel?.is_bot,
      );
      const channelsWithoutDeactivated = channelsWithoutBots.filter(
        (channel: any) => !channel?.deleted,
      );
      const slackChannels = channelsWithoutDeactivated.map(mapSlackAPIChannelToSlackChannel) ?? [];
      const sortedSlackChannels = sortSlackChannelsAlphabetically(slackChannels);
      if (sortedSlackChannels.length === 0) return;
      setSlackChannels(sortedSlackChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get direct Slack messages', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};
