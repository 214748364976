import { PublicUserDataV2 } from '../../shared/types/types';
import { startTrackingEvent, stopTrackingEvent } from '../../utils/analytics/eventLogger';
import { functions } from '../../utils/firebase';
import { logError } from '../../utils/logging';
import { toastDanger } from '../../utils/notifications';
import { mapPublicUserDataV2ToBeSure, rejectedPublicUserDataV2 } from '../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

const cfSearchPublicUserDataV2ByEmailCore = (email: string) => functions()
  .httpsCallable('searchPublicUserDataV2ByEmail')({ email })
  .then((result) => {
    const publivUserDataV2: PublicUserDataV2 = mapPublicUserDataV2ToBeSure(result.data);
    return publivUserDataV2;
  })
  .catch((error) => {
    logError('Error cfSearchPublicUserDataV2ByEmail', error);
    // Removed toastDanger since if the CF fails, it will give A LOT of
    // toasts
    // toastDanger('Error', error?.message ?? 'Error');
    const rejectedData = rejectedPublicUserDataV2;
    rejectedData.data.email = email;
    return rejectedData;
  });

export default cfSearchPublicUserDataV2ByEmailCore;

export const cfSearchPublicUserDataV2ByEmailsCore = async (emails: string[]) => {
  if (emails.length === 0) return ([] as PublicUserDataV2[]);
  startTrackingEvent('attendeesLoad');
  return functions()
    .httpsCallable('searchPublicUserDataV2ByEmails')({ emails })
    .then((result: any) => {
      const users: PublicUserDataV2[] = result
        .data.map((data: any) => mapPublicUserDataV2ToBeSure(data));
      stopTrackingEvent('attendeesLoad');
      return users;
    })
    .catch((error) => {
      logError('Error cfSearchPublicUserDataV2ByEmails', error);
      toastDanger('Error', error?.message ?? 'Error');
      return ([]) as PublicUserDataV2[];
    });
};
