import styled from 'styled-components';
import {
  gray2, darkBlue2, blue6,
} from '../../colours';

interface TriggerProps {
  'data-active': boolean
}

const DropdownTriggerArrow = styled.button`
  align-items: center; 
  background: ${gray2};
  border-radius: 8px;
  border: none;
  border: ${(props: TriggerProps) => (props['data-active'] ? `2px solid ${blue6}` : `2px solid ${gray2}`)};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  text-align: left;
  transition: box-shadow 0.4s ease;
  width: 100%; 

  &:hover {
    border: 2px solid ${darkBlue2};
  }
`;
export default DropdownTriggerArrow;
