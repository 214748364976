import React, {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react';
import { TagInput } from 'reactjs-tag-input';
import _ from 'lodash';
import { uiText } from '../../typography';
import {
  DeleteIcon, inputStyle, InputValue, isSpacePress, tagStyle, Wrapper, wrapperStyle,
} from '.';

interface InputProps {
  values: InputValue[],
  setValues: Dispatch<SetStateAction<InputValue[]>>,
  setMultiInputText: Dispatch<SetStateAction<string>>,
  setOpenOptions: Dispatch<SetStateAction<boolean>>,
}

const SearchableMultiInput = ({
  values, setValues, setMultiInputText, setOpenOptions,
}: InputProps) => {
  const TagInputRef = useRef<any>(null);

  useEffect(() => {
    // dirty hack https://github.com/leekevinyg/react-tag-input/blob/master/src/lib/TagInput.js
    TagInputRef.current.state.selectedTags = values;
    TagInputRef.current.forceUpdate(() => {
      TagInputRef.current.focusInput();
    });
  }, [values]);

  const handleInputEvent = (event: any) => {
    console.log('here handleSpace');
    setOpenOptions(true);
    setMultiInputText(event?.target?.value.trim());
    if (isSpacePress(event)) {
      const trimmedEmail = event?.target?.value.trim() ?? '';
      const email = {
        index: values.length,
        displayValue: trimmedEmail,
      };
      setValues((prevValues) => [...prevValues, email]);
      // This is a hack to the lib source code
      // The lib `reactjs-tag-input` hasn't been update for 3 years,
      // I think it is not that bad to hack it
      // https://github.com/leekevinyg/react-tag-input/blob/master/src/lib/TagInput.js#L41
      TagInputRef.current.onInputKeyUp({ key: 'Enter', target: { value: trimmedEmail } });
    }
  };

  return (
    <Wrapper onClick={() => { setOpenOptions(true); }}>
      <TagInput
        tags={[]}
        placeholder="Type email and press enter or space"
        onTagsChanged={setValues}
        tagStyle={tagStyle}
        tagDeleteStyle={`
            margin-left: 4px
            & > svg {
              width: ${DeleteIcon.props.width}px;
              display: flex;
              align-items: center;
            }
            &::placeholder {
              text-decoration: none
              ${uiText}
            }
          `}
        tagDeleteIcon={DeleteIcon}
        onInputChanged={(event: any) => handleInputEvent(event)}
        inputStyle={inputStyle}
        wrapperStyle={wrapperStyle}
        ref={TagInputRef}
      />
    </Wrapper>
  );
};

const checkToReRenderOrNot = (
  prevProp: InputProps,
  presentProp: InputProps,
) => _.isEqual(prevProp, presentProp);

export default React.memo(SearchableMultiInput, checkToReRenderOrNot);
