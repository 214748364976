import React from 'react';
import styled from 'styled-components';
import ReactSwitch from 'react-switch';
import { gray6, blue6, gray1 } from '../../colours';
import { messageText } from '../../typography';
import ReactTooltip from '../tooltip/ReactTooltip';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  ${messageText};
`;

interface SwitchProps {
  label?: string,
  checked: boolean,
  // eslint-disable-next-line no-unused-vars
  setChecked: (index: number) => void,
  disabled?: boolean,
  disabledTooltipText?: string,
  index: number,
}

const PrivateNotesSwitch = ({
  label, checked, setChecked, disabled, disabledTooltipText, index,
}: SwitchProps) => {
  const handleChecked = () => {
    setChecked(index);
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <ReactTooltip place="left" tip={disabledTooltipText} disabled={!disabled}>
        <ReactSwitch
          onChange={handleChecked}
          checked={checked}
          uncheckedIcon={false}
          checkedIcon={false}
          height={22}
          width={44}
          boxShadow="0px 2px 4px rgba(0, 35, 11, 0.2)"
          activeBoxShadow="0px 2px 4px rgba(0, 35, 11, 0.2)"
          handleDiameter={18}
          onColor={blue6}
          onHandleColor={gray1}
          offColor={gray6}
          disabled={disabled}
        />
      </ReactTooltip>
    </Wrapper>
  );
};

PrivateNotesSwitch.defaultProps = {
  label: '',
  disabled: false,
  disabledTooltipText: 'Disabled',
};

export default PrivateNotesSwitch;
