/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useContext, Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import Scrollbar from '../../../shared/components/scrollbar';
import ButtonSmall from '../../../shared/components/button-small';
import TextBox from '../../../shared/components/textbox';
import SendIcon from '../../../shared/icons/send';
import Switch from '../../../shared/components/switch';
import ModalLabel from '../../../shared/components/Modal/ModalLabel';
import ModalLine from '../../../shared/components/Modal/ModalLine';
import ModalBodyWrapper from '../../../shared/components/Modal/ModalBodyWrapper';
import { AuthContext, UserDataContext } from '../../../App';
import {
  NOTION_PROCESSING_VIEW, OVERVIEW_VIEW, SEND_HEADLESS_NOTES_TEMPLATE_ID, SLACK_NOTIFICATIONS_VIEW,
  SLACK_PROCESSING_VIEW, SLACK_SELECT_DEFAULT_CHANNEL_VIEW, TRELLO_PROCESSING_VIEW,
} from '../../../utils/constants';
import { assembleEmailHTML } from '../../../utils/email/emailUtils';
import { AGENDA, SHARED_NOTES, PRIVATE_NOTES } from '../../../utils/enums';
import MeetingDataContext from '../context/MeetingDataContext';
import {
  SlackChannel, Note, TaskItem, NotionPageData,
  TrelloData, ShareModalTab, IntegrationsTabView, SecretChat,
} from '../../../shared/types/types';

import { DateToTemplateDateFormat, getUTCString } from '../../../utils/dateUtils/date';
import { mapAttendeesToEmailRecipients, setNoteContent } from '../../../utils/meetings/meetingsUtils';
import { darkBlue1 } from '../../../shared/colours';
import ShareModal from '../../../shared/components/Modal/ShareModal';
import { dbUpdateSlackData } from '../../../database/firebaseSlackAPI';
import IntegrationsTab from './integrations/IntegrationsTab';
import EmailMultiInput from './EmailMultiInput';
import { header200 } from '../../../shared/typography';
import PreviewButton from './PreviewButton';
import ModalFooterdWrapper, { ModalFooterRightButtonsWrapper } from '../../../shared/components/Modal/ModalFooterWrapper';
import ShareSlackCheckboxAndDropdown from './SlackModalComponents/ShareSlackCheckboxAndDropdown';
import ShareNotionCheckboxAndDropdown from './NotionModalComponents/ShareNotionCheckboxAndDropdown';
import { toastDanger } from '../../../utils/notifications';
import { isValidAccessToken } from '../../../utils/slack/SlackAPIUtils';
import { defaultTrelloData } from '../../../utils/trello/trelloUtils';
import { dbSaveTrelloData, removeTrelloIntegration } from '../../../database/firebaseTrelloAPI';
import ShareTrelloCheckboxAndDropdown from './TrelloModalComponents/ShareTrelloCheckboxAndDropdown';
import {
  generateNotionPage,
  generateSlackMessage,
  sendEmails,
  sendToTrello,
  shareNotesDataType,
} from '../../../utils/meetings/ShareNotesUtils';
import { isEmptyHTML, makeSubstringsBold } from '../../../utils/strings';
import defaultSlackNotifications from '../../../database/utils/templateSlackNotifications';
import { slackCoreGetChannelNamesWithCf, slackCoreGetDirectMessages } from '../../../utils/slack/SlackCoreAPI';
import FireworkConfetti from '../../../shared/confetti/FireworkConfetti';
import { mapDefaultChannelToSlackData } from '../../../utils/slack/slackUtils';
import PrivateNotesSwitch from '../../../shared/components/switch/PrivateNotesSwitch';
import { determinePrivateSpaceNotesToShare } from '../notes/secret-chat/secretChatUtils';
import { getCandidatesFromUserData, removeDuplicates } from '../../../utils/user/UserDataUtils';
import SecretNotesSwitchMolecule from './SecretNotesSwitchMolecule';

const MultiInputWrapper = styled.div`
  margin-bottom: 24px;
`;

const TextBoxWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ShareOptionsWrapper = styled.div`
  margin-top: 24px;
`;

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

export const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
`;

const RecipientsText = styled.h2`
  ${header200};
`;

const ModalLineContainer = styled.div`
  margin-top: 24px;
`;

const RecipientsToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type EmailRecipient = {
  index: number,
  displayValue: string,
}

export interface ShareNotesModalProps {
  isModalOpen: boolean,
  closeModal: () => void,
  handleCancel: any,
  handleEmailSent: any,
  onPreviewClick: () => void,
  setEmailHeadTemplate: Dispatch<SetStateAction<string>>,
  emailHeadTemplate: string
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  privateNotesChecked: boolean,
  taskChecked: boolean
  setAgendaChecked: Dispatch<SetStateAction<boolean>>,
  setSharedNotesChecked: Dispatch<SetStateAction<boolean>>,
  setPrivateNotesChecked: Dispatch<SetStateAction<boolean>>,
  setTaskChecked: Dispatch<SetStateAction<boolean>>,
  taskItems: TaskItem[],
  agendaHtml: Note,
  sharedNotesHtml: Note,
  privateNotesHtml: Note,
  setAgendaHtml: Dispatch<SetStateAction<Note>>
  setSharedNotesHtml: Dispatch<SetStateAction<Note>>
  setPrivateNotesHtml: Dispatch<SetStateAction<Note>>
  channelsToReceive: SlackChannel[],
  setChannelsToReceive: Dispatch<SetStateAction<SlackChannel[]>>,
  secretNotes: SecretChat[],
  privateSpaceNotesChecked: boolean[],
  handlePrivateSpaceChecked: (index: number) => void,
  secretNotesHtml: Note[],
  setSecretNotesHtml: Dispatch<SetStateAction<Note[]>>,
}

const ShareNotesModal = ({
  isModalOpen,
  closeModal,
  handleCancel,
  handleEmailSent,
  onPreviewClick,
  setEmailHeadTemplate,
  emailHeadTemplate,
  agendaChecked,
  sharedNotesChecked,
  privateNotesChecked,
  setAgendaChecked,
  taskChecked,
  setSharedNotesChecked,
  setPrivateNotesChecked,
  setTaskChecked,
  taskItems,
  agendaHtml,
  sharedNotesHtml,
  privateNotesHtml,
  setAgendaHtml,
  setSharedNotesHtml,
  setPrivateNotesHtml,
  channelsToReceive,
  setChannelsToReceive,
  secretNotes,
  privateSpaceNotesChecked,
  handlePrivateSpaceChecked,
  secretNotesHtml,
  setSecretNotesHtml,
}: ShareNotesModalProps) => {
  const [shareModalTab, setShareModalTab] = useState<ShareModalTab>('sendNotes');

  const [integrationsTabView, setIntegrationsTabView] = useState<IntegrationsTabView>(
    OVERVIEW_VIEW,
  );
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [slackDms, setSlackDms] = useState<SlackChannel[]>([]);
  const [newDefaultChannels, setNewDefaultChannels] = useState<SlackChannel[]>([]);
  // TODO: Use userData object to get trello data
  const [trelloData, setTrelloData] = useState<TrelloData>(defaultTrelloData);
  const [taskToSend, setTaskToSend] = useState<TaskItem[]>([]);
  const [notionPagesToReceive, setNotionPagesToReceive] = useState<NotionPageData[]>([]);

  // TODO: Merge all checked and html states into one state
  const [emailsChecked, setEmailsChecked] = useState<boolean>(true);
  const [slackChecked, setSlackChecked] = useState<boolean>(false);
  const [notionChecked, setNotionChecked] = useState<boolean>(false);
  const [trelloChecked, setTrelloChecked] = useState<boolean>(false);

  const [showAgenda, setShowAgenda] = useState<boolean>(true);

  const [isIntegratingSlack, setIsIntegratingSlack] = useState<boolean>(false);
  const [activateConfetti, setActivateConffetti] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const authState = useContext(AuthContext);
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const {
    meetingId, data: { title, attendees }, date: { start }, version,
  } = useContext(MeetingDataContext);
  const { userId } = useContext(AuthContext);
  const { trackEvent } = useIntercom();

  const userData = useContext(UserDataContext);
  const assigneeCandidates = getCandidatesFromUserData(userData);
  const slackUserAccessToken = userData?.integrations?.slack[0]?.userAccessToken ?? '';
  const trelloUserData = userData?.integrations?.trello ?? defaultTrelloData;
  const notionWorkspace = userData?.integrations?.notion ?? [];
  const isSlackEnabled = slackUserAccessToken.length > 10;
  const isNotionEnabled = notionWorkspace.length > 0;
  const { isTrelloEnabled } = trelloUserData.settings;

  useEffect(() => {
    if (version <= 3) setShowAgenda(true);
    if (version >= 4) setShowAgenda(false);
  }, [version]);

  useEffect(() => {
    if (userData?.integrations?.trello?.accessToken.length > 10) {
      setTrelloData(userData.integrations.trello);
    }
  }, [userData]);

  useEffect(() => {
    if (!isSlackEnabled) return;
    if (isSlackEnabled) slackCoreGetChannelNamesWithCf(slackUserAccessToken, setSlackChannels);
    if (isSlackEnabled) slackCoreGetDirectMessages(slackUserAccessToken, setSlackDms);
  }, [slackUserAccessToken, isSlackEnabled]);

  useEffect(() => {
    if (!attendees.length) return;
    const newEmailList = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newEmailList);
  }, [attendees]);

  useEffect(() => {
    if (activateConfetti) {
      setTimeout(() => {
        setActivateConffetti(false);
      }, 4000);
    }
  }, [activateConfetti]);

  useEffect(() => {
    if (!meetingId) return;

    if (version <= 2) {
      setNoteContent(setAgendaHtml, AGENDA, meetingId, userId);
      setNoteContent(setSharedNotesHtml, SHARED_NOTES, meetingId, userId);
      setNoteContent(setPrivateNotesHtml, PRIVATE_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (emailRecipients.length) setEmailsChecked(true);
  }, [emailRecipients]);

  const handleSendClick = () => {
    setLoading(true);
    const promises: Promise<any>[] = [];
    const emails = emailRecipients.map(({ displayValue }) => ({ email: displayValue }));
    const emailHeadHtml = makeSubstringsBold(emailHeadTemplate, [title, getUTCString(start.date)]);
    const checkedSecretNotesHtml = determinePrivateSpaceNotesToShare(
      privateSpaceNotesChecked,
      secretNotesHtml,
    );
    const notesAndTasksHtml = assembleEmailHTML(
      agendaChecked && showAgenda, sharedNotesChecked, privateNotesChecked, taskChecked,
      emailHeadHtml, agendaHtml, sharedNotesHtml, privateNotesHtml,
      taskItems, privateSpaceNotesChecked, checkedSecretNotesHtml,
    );
    const shareNotesData: shareNotesDataType = {
      sendHeadlessNotesTemplateId: SEND_HEADLESS_NOTES_TEMPLATE_ID,
      emails,
      html: notesAndTasksHtml,
      title,
      startTime: getUTCString(start.date),
      startDate: DateToTemplateDateFormat(start.date),
      agendaChecked: agendaChecked && showAgenda,
      sharedNotesChecked,
      privateNotesChecked,
      taskChecked,
      emailHeadHtml,
      emailHeadTemplate,
      agendaHtml,
      sharedNotesHtml,
      privateNotesHtml,
      taskItems,
      checkedSecretNotesHtml,
      privateSpaceNotesChecked,
    };

    if (slackChecked && channelsToReceive.length !== 0) {
      if (!isValidAccessToken(slackUserAccessToken)) {
        toastDanger('Error', 'Invalid slack access token');
      } else {
        generateSlackMessage(
          shareNotesData,
          channelsToReceive,
          slackUserAccessToken,
          promises,
        );
      }
    }

    if (notionChecked) {
      generateNotionPage(shareNotesData, notionPagesToReceive, promises);
    }

    if (emailsChecked && emailRecipients.length) {
      sendEmails(shareNotesData, trackEvent, promises);
    }

    if (trelloChecked && taskToSend.length) {
      sendToTrello(trelloChecked, taskToSend, trelloUserData, promises);
    }

    Promise.all(promises).then(() => {
      setChannelsToReceive([]);
      setNotionPagesToReceive([]);
      setEmailRecipients([]);
      setTaskToSend([]);
      setSecretNotesHtml([]);
      handleEmailSent(true);
    }).catch((error: any) => {
      console.error('error in sending');
      console.error(error);
      toastDanger('Error', 'Something went wrong while sharing notes. If this persists, please contact customer support');
      handleEmailSent(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSlackSaveDefaultChannel = () => {
    if (isIntegratingSlack) {
      setIntegrationsTabView(SLACK_NOTIFICATIONS_VIEW);
    } else {
      setIntegrationsTabView(OVERVIEW_VIEW);
    }
    const slackData = mapDefaultChannelToSlackData(
      newDefaultChannels,
      userData?.integrations?.slack,
    );
    dbUpdateSlackData(authState.userId, slackData);
  };

  const handleTrelloConfirmClick = () => {
    // TODO: Find more elegant way to do this
    trelloData.settings.isTrelloEnabled = true;
    dbSaveTrelloData(userId, trelloData, () => { });
    setTrelloData(defaultTrelloData);
    setActivateConffetti(true);
    setIntegrationsTabView(OVERVIEW_VIEW);
  };

  const handleAddEmails = () => {
    const newemailRecipients = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newemailRecipients);
  };

  const handleRemoveEmails = () => {
    setEmailRecipients([]);
    setEmailsChecked(false);
  };

  const handleCancelClick = () => {
    if (integrationsTabView === TRELLO_PROCESSING_VIEW) {
      removeTrelloIntegration(userId);
    }
    if (integrationsTabView !== OVERVIEW_VIEW) {
      setIntegrationsTabView(OVERVIEW_VIEW);
      return;
    }
    if (shareModalTab === 'integrations') {
      setShareModalTab('sendNotes');
    }
    handleCancel();
  };

  const handleClickDoneInIntegrationsTab = () => {
    if (integrationsTabView === NOTION_PROCESSING_VIEW) {
      setIntegrationsTabView(OVERVIEW_VIEW);
      return;
    }

    setIntegrationsTabView(OVERVIEW_VIEW);
    setShareModalTab('sendNotes');
  };

  const selectModalFooterForIntegrations = () => {
    if (shareModalTab !== 'integrations') return null;

    switch (integrationsTabView) {
      case OVERVIEW_VIEW:
        return <ButtonSmall onClick={handleClickDoneInIntegrationsTab} text="Done" />;
      case SLACK_PROCESSING_VIEW:
        if (!isSlackEnabled) return null;
        return <ButtonSmall text="Next" onClick={() => setIntegrationsTabView(SLACK_SELECT_DEFAULT_CHANNEL_VIEW)} />;
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleSlackSaveDefaultChannel} />;
      case TRELLO_PROCESSING_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleTrelloConfirmClick} />;
      case NOTION_PROCESSING_VIEW:
        if (!isNotionEnabled) return null;
        return <ButtonSmall text="Done" onClick={handleClickDoneInIntegrationsTab} />;
      default:
        return null;
    }
  };

  const renderCloseButtonForFooter = () => {
    if (isNotionEnabled && integrationsTabView === NOTION_PROCESSING_VIEW && shareModalTab !== 'sendNotes') return null;

    return <ButtonSmall onClick={handleCancelClick} text="Cancel" isOutline />;
  };

  const agendaDisabled = isEmptyHTML(agendaHtml);
  const sharedNotesDisabled = isEmptyHTML(sharedNotesHtml);
  const privateNotesDisabled = isEmptyHTML(privateNotesHtml);
  const taskDisabled = taskItems.length === 0;
  const isSendBtnEnabled = (
    atLeastOneNoteOrTaskEnabled(agendaChecked, sharedNotesChecked, privateNotesChecked, taskChecked)
    && (
      slackEnabledAndHaveChannels(slackChecked, channelsToReceive)
      || emailEnabledAndHaveRecipients(emailsChecked, emailRecipients)
      || notionEnabledAndHavePages(notionChecked, notionPagesToReceive)
      || trelloEnabledAndHaveTasks(trelloChecked, taskToSend)
    )
  );

  return (
    <>
      <ShareModal
        isOpen={isModalOpen}
        setModalClosed={closeModal}
        modalTab={shareModalTab}
        setModalTab={setShareModalTab}
      >
        <Scrollbar maxHeight="71vh">
          <ModalBodyWrapper>
            {shareModalTab === 'sendNotes' ? (
              <>
                <MultiInputWrapper>
                  <SwitchGroup>
                    <RecipientsToggleContainer>
                      <RecipientsText>Enter email recipients</RecipientsText>
                      <Switch
                        checked={emailsChecked}
                        setChecked={setEmailsChecked}
                      />
                    </RecipientsToggleContainer>
                  </SwitchGroup>
                  <EmailMultiInput
                    recipients={emailRecipients}
                    handleAddEmails={handleAddEmails}
                    handleRemoveEmails={handleRemoveEmails}
                    setEmailRecipients={setEmailRecipients}
                    isChecked={emailsChecked}
                    candidates={assigneeCandidates}
                  />
                  <ShareSlackCheckboxAndDropdown
                    isSlackEnabled={isSlackEnabled}
                    isChecked={slackChecked}
                    setIsChecked={setSlackChecked}
                    slackChannels={slackChannels}
                    // defaultChannels={userDefaultSlackChannels}
                    slackDms={slackDms}
                    setChannelsToReceive={setChannelsToReceive}
                    channelsToReceive={channelsToReceive}
                  />
                  <ShareNotionCheckboxAndDropdown
                    isNotionEnabled={isNotionEnabled}
                    notionWorkspace={notionWorkspace}
                    isChecked={notionChecked}
                    setIsChecked={setNotionChecked}
                    setPagesToReceive={setNotionPagesToReceive}
                  />
                  <ShareTrelloCheckboxAndDropdown
                    isTrelloEnabled={isTrelloEnabled}
                    isChecked={trelloChecked}
                    setIsChecked={setTrelloChecked}
                    taskItems={taskItems}
                    taskToSend={taskToSend}
                    setTaskToSend={setTaskToSend}
                  />
                </MultiInputWrapper>
                <ModalLine />
                <ShareOptionsWrapper>
                  <ModalLabel text="Select to share" />
                  <SwitchGroup>
                    {showAgenda && (
                      <Switch
                        label="Agenda"
                        checked={agendaChecked}
                        setChecked={setAgendaChecked}
                        disabled={agendaDisabled}
                        disabledTooltipText="No agenda"
                      />
                    )}
                    <Switch
                      label="Shared notes"
                      checked={sharedNotesChecked}
                      setChecked={setSharedNotesChecked}
                      disabled={sharedNotesDisabled}
                      disabledTooltipText="No shared notes"
                    />
                    <Switch
                      label="Private Space notes"
                      checked={privateNotesChecked}
                      setChecked={setPrivateNotesChecked}
                      disabled={privateNotesDisabled}
                      disabledTooltipText="No private notes"
                    />
                    <Switch
                      label="Tasks"
                      checked={taskChecked}
                      setChecked={setTaskChecked}
                      disabled={taskDisabled}
                      disabledTooltipText="No tasks"
                    />
                    <SecretNotesSwitchMolecule
                      secretNotes={secretNotes}
                      handlePrivateSpaceChecked={handlePrivateSpaceChecked}
                      privateSpaceNotesChecked={privateSpaceNotesChecked}
                      secretNotesHtml={secretNotesHtml}
                    />
                  </SwitchGroup>
                </ShareOptionsWrapper>
                <ModalLineContainer>
                  <ModalLine />
                </ModalLineContainer>
                <TextBoxWrapper>
                  <ModalLabel text="Message to recipients" />
                  <TextBox
                    placeholder="Write here to add additional notes"
                    onChange={setEmailHeadTemplate}
                    defaultValue={emailHeadTemplate}
                  />
                </TextBoxWrapper>
              </>
            ) : (
              <IntegrationsTab
                isSlackEnabled={isSlackEnabled}
                isNotionEnabled={isNotionEnabled}
                isTrelloEnabled={isTrelloEnabled}
                view={integrationsTabView}
                setView={setIntegrationsTabView}
                slackChannels={slackChannels}
                setNewDefaultChannels={setNewDefaultChannels}
                trelloData={trelloData}
                setTrelloData={setTrelloData}
                setActiveConfetti={setActivateConffetti}
                setIsIntegratingSlack={setIsIntegratingSlack}
                isIntegratingSlack={isIntegratingSlack}
              />
            )}
          </ModalBodyWrapper>
        </Scrollbar>
        <ModalLineLocal />
        <ModalFooterdWrapper>
          {shareModalTab === 'sendNotes' ? (
            <PreviewButton onClick={onPreviewClick} />
          ) : (<div />)}
          {/* TODO Matt: Double check Harry's merging went fine */}
          <ModalFooterRightButtonsWrapper>
            {renderCloseButtonForFooter()}
            {selectModalFooterForIntegrations()}
            {shareModalTab === 'sendNotes' && (
              <ButtonSmall type="submit" onClick={handleSendClick} text="Send" hasLeftIcon Icon={SendIcon} loading={loading} isDisabled={!isSendBtnEnabled} />
            )}
          </ModalFooterRightButtonsWrapper>
        </ModalFooterdWrapper>
        <FireworkConfetti shouldTrigger={activateConfetti} />
      </ShareModal>
    </>
  );
};

export default ShareNotesModal;

const slackEnabledAndHaveChannels = (
  enabled: boolean, channels: any[],
) => enabled && channels.length > 0;
const emailEnabledAndHaveRecipients = (
  enabled: boolean, recipients: any[],
) => enabled && recipients.length > 0;
const notionEnabledAndHavePages = (
  enabled: boolean, pages: any[],
) => enabled && pages.length > 0;
const trelloEnabledAndHaveTasks = (
  enabled: boolean, tasks: any[],
) => enabled && tasks.length > 0;

const atLeastOneNoteOrTaskEnabled = (
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  privateNotesChecked: boolean,
  taskChecked: boolean,
) => agendaChecked || sharedNotesChecked || privateNotesChecked || taskChecked;
