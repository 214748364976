import React from 'react';
import styled from 'styled-components';
import { darkBlue1, gray1 } from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const Container = styled.div`
  background-color: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 250px;
  z-index: 100;

  div:first-child{
    margin-top: 10px;
  }

  div:last-child{
    margin-bottom: 10px;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${(props: OptionsProps) => props.color};
  width: 187px;
  height: 32px;
  background-color: ${gray1};
  ${uiTextMedium};
  margin: 0px 8px 0px 8px;
  border-radius: 10px;
  padding: 6px 0px 6px 8px;

  &:hover{
    background-color: ${darkBlue1};
  }
`;

export interface OptionsDropdownItem {
  id: number,
  value: string,
  color: string,
  onClick: () => void,
}

interface OptionsProps {
  color: string
}

interface Props {
  isOpen: boolean,
  options: OptionsDropdownItem[],
  optionsRef: React.MutableRefObject<null>,
}

const OptionsDropdown = ({ isOpen, options, optionsRef }: Props) => {
  if (!isOpen) return null;
  return (
    <Container ref={optionsRef}>
      {options.map((option) => (
        <Option
          key={option.id}
          color={option.color}
          onClick={option.onClick}
        >
          {option.value}
        </Option>
      ))}
    </Container>
  );
};

export default OptionsDropdown;
