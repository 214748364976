/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction } from 'react';
import CreateTask from '../../../shared/components/create-task';
import PreviousMeetingButtonMol from '../../../shared/components/previous-meeting-notes/components/PreviousMeetingButtonMol';
import Templates from '../../../shared/components/templates';
import PaperPlusIcon from '../../../shared/icons/PaperPlusIcon';
import TagIcon from '../../../shared/icons/TagIcon';
import {
  MeetingData, MeetingSections, SecretChatData, TaskItem, UserCenterSection,
} from '../../../shared/types/types';
import ShareMeetingNotes from '../../meeting/share-meeting';
// import ButtonMolecule from './ButtonMolecule';
import ContainerAtom from './ContainerAtom';

interface Props {
  meetingData: MeetingData,
  section: UserCenterSection,
  taskItems: TaskItem[],
  meetingTab: MeetingSections,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  isCreateTaskModalOpen: boolean,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>,
  secretNotes: SecretChatData[],
}

const BottomBar = ({
  meetingData,
  section, taskItems, meetingTab, setIsTemplatesOpen,
  isCreateTaskModalOpen, setCreateTaskModalOpen, setIsRecurringMeeting, secretNotes,
}: Props) => {
  if (section === 'currentMeeting') {
    return (
      <ContainerAtom>
        <PreviousMeetingButtonMol
          meetingData={meetingData}
          setIsRecurringMeeting={setIsRecurringMeeting}
        />
        <CreateTask
          meetingData={meetingData}
          isOpen={isCreateTaskModalOpen}
          setIsOpen={setCreateTaskModalOpen}
        />
        <Templates
          meetingData={meetingData}
          meetingTab={meetingTab}
          setIsTemplatesOpen={setIsTemplatesOpen}
        />
        <ShareMeetingNotes
          secretNotes={secretNotes}
          taskItems={taskItems}
        />
      </ContainerAtom>
    );
  }
  return (
    <ContainerAtom>
      <CreateTask
        meetingData={meetingData}
        isOpen={isCreateTaskModalOpen}
        setIsOpen={setCreateTaskModalOpen}
      />
      {/* <ButtonMolecule
        onClick={() => console.log('clicked')}
        text="Note"
        icon={<PaperPlusIcon />}
      />
      <ButtonMolecule
        onClick={() => console.log('clicked')}
        text="Tag"
        icon={<TagIcon />}
      /> */}
    </ContainerAtom>
  );
};

export default BottomBar;
