import styled from 'styled-components';
import { uiText } from '../../typography';
import { gray7 } from '../../colours';

const DropdownInputPlaceholder = styled.div`
  ${uiText};
  color: ${gray7};
`;

export default DropdownInputPlaceholder;
