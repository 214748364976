import React from 'react';
import { gray1, gray3 } from '../../colours';

interface Props {
  fill?: string,
}
const EmptyIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="3" fill={fill} />
    <rect x="1" y="1" width="18" height="18" rx="3" stroke={gray3} strokeWidth="2" />
  </svg>

);

EmptyIcon.defaultProps = {
  fill: gray1,
};

export default EmptyIcon;
