import styled from 'styled-components';
import {
  gray3, red6, gray7, surface, darkBlue1, gray1,
} from '../../../../../../shared/colours';
import { defaultSmall, header200, uiTextS } from '../../../../../../shared/typography';

export const InputContainer = styled.div`
  width: 100%;
`;

interface InputStyleProps {
  value: string
}

export const InputStyle = styled.input<InputStyleProps>`
  ${uiTextS};
  color: ${surface};
  
  background: ${({ value }) => (value.length > 0 ? gray1 : gray3)};
  
  box-sizing: border-box;
  border: 2px solid ${gray3};
  border-radius: 10px;
  
  width: 100%; 

  padding: 8px 10px;

  :hover {
    cursor: default;
    border: 2px solid ${darkBlue1};
  }

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${gray7};
  }
`;

export const HiddenLabel = styled.label`

`;

export const Label = styled.p`
  ${header200};
  color: ${surface};
  margin: 0 0 8px;
`;

export const Error = styled.p`
  ${defaultSmall};
  color: ${red6};
  font-size: 10px;
  line-height: 12px;
  height: 12px;

  margin: 6px 0 0 0;
`;
