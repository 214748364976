import React from 'react';
import styled from 'styled-components';
import ColorDropdownColorCircleAtom from './ColorDropdownColorCircleAtom';
import ColorDropdownColorObjects, { ColorDropdownColors } from './ColorDropdownUtils';

const ColorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 8px 2px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . .";
`;

interface Props {
  selectedColor: ColorDropdownColors;
  // eslint-disable-next-line no-unused-vars
  setSelectedColor: (color: ColorDropdownColors) => void;
}

const ColorDropdownColorCirclesGridMol = ({ selectedColor, setSelectedColor }: Props) => (
  <ColorContainer>
    {ColorDropdownColorObjects.map((color) => (
      <ColorDropdownColorCircleAtom
        color={color.color}
        isSelected={color.color === selectedColor}
        onClick={setSelectedColor}
      />
    ))}
  </ColorContainer>
);

export default ColorDropdownColorCirclesGridMol;
