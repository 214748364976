/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import EditSecretChatModal from '../../notes/secret-chat/add-members-modal/EditSecretChatModal';
import ExtraChatsDropdownMolecule from './ExtraChatsDropdownMolecule';
import { emptySecretChat, getSecretChatId } from '../../notes/secret-chat/secretChatUtils';
import { MeetingSections, SecretChat } from '../../../../shared/types/types';

interface Props {
  handleUpdate: () => void,
  // eslint-disable-next-line no-unused-vars
  handleDelete: (chat: SecretChat) => void,
  secretChats: SecretChat[],
  dropdownOpen: boolean,
  dropdownRef: React.MutableRefObject<null>,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  // eslint-disable-next-line no-unused-vars
  handleNotesSelected: (chat: SecretChat) => void,
  handleOpenModalCloseDropdown: () => void,
}

const ChatsDropdownEditModalOrganism = ({
  handleUpdate,
  handleDelete,
  secretChats,
  dropdownOpen,
  dropdownRef,
  selectedChat,
  handleNotesSelected,
  setSelectedChat,
  handleOpenModalCloseDropdown,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [chatToEdit, setChatToEdit] = useState<SecretChat>(emptySecretChat);

  const handleDeleteChat = (chat: SecretChat) => {
    handleDelete(chat);
    setEditModalOpen(false);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    handleUpdate();
  };

  return (
    <>
      <ExtraChatsDropdownMolecule
        isOpen={dropdownOpen}
        secretChats={secretChats}
        refOutsideClick={dropdownRef}
        handleSecretNotesSelected={handleNotesSelected}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        handleOpenCreateSecretChat={handleOpenModalCloseDropdown}
        setChatToEdit={setChatToEdit}
        setEditModalOpen={setEditModalOpen}
      />
      <EditSecretChatModal
        isOpen={editModalOpen}
        setClosed={handleEditModalClose}
        currentAttendees={chatToEdit.members}
        secretChatId={getSecretChatId(chatToEdit)}
        chat={chatToEdit}
        handleDelete={handleDeleteChat}
      />
    </>
  );
};

export default ChatsDropdownEditModalOrganism;
