import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../shared/colours';
import { uiTextMedium } from '../../../shared/typography';

const Text = styled.span<TextProps>`
  ${uiTextMedium}
  color: ${({ textColor }) => (textColor)};
  cursor: ${({ textColor }) => (textColor === blue6 ? 'pointer' : 'auto')};
`;

interface TextProps {
  textColor: string,
}

interface Props {
  onClick?: () => void,
  text: string,
  color?: string,
}

const TextAtom = ({ onClick, text, color = '#1B2124' }: Props) => (
  <Text textColor={color} onClick={onClick}>
    {text}
  </Text>
);

TextAtom.defaultProps = {
  onClick: undefined,
  color: '#1B2124',
};

export default TextAtom;
