import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { listenToWindowRouteMessages } from './utils/chromeExtension/chromeExtension';

interface Props {
  children: React.ReactNode;
}

// TODO use new hook
const ListenToRouteMessageContainer = ({ children }: Props) => {
  const history = useHistory();

  useEffect(() => {
    listenToWindowRouteMessages(history);
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default ListenToRouteMessageContainer;
