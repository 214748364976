import { NodeSpec } from 'prosemirror-model';

const MentionSpec: NodeSpec = {
  attrs: {
    email: {
      default: 'unknown',
    },
    name: {
      default: 'unknown',
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text?',
  atom: true,
  group: 'inline',
  /**
   * Here an empty string of marks is given, since
   * it is not desirable to apply diverse and possibly
   * confusing styles to what should be a static
   * mention element.
   */
  marks: '',
  toDOM: (node) => [
    'span',
    {
      mention: undefined,
      email: node.attrs.email,
      name: node.attrs.name,
      class: 'resolved-mention',
      contenteditable: 'false',
    },
    0,
  ],
  parseDOM: [{
    tag: 'span[mention]',
    getAttrs: (dom) => ({
      email: (dom as Element).getAttribute('email') ?? '',
      name: (dom as Element).getAttribute('name') ?? '',
    }),
  }],
};

export default MentionSpec;
