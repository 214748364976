import React, {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { gray1 } from '../../colours';
import { PublicUserDataV2 } from '../../types/types';
import Scrollbar from '../scrollbar';
import DropdownItem from './dropdownItem';

export const DropdownContainer = styled.div`
  position: fixed;
  height: auto;
  width: 276px;
  background: ${gray1};
  padding: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DropdownItemContainer = styled.div`
  display: flex;
`;

interface Props {
  assigneeCandidates: PublicUserDataV2[],
  isOpen: boolean,
  setAssignee: Dispatch<SetStateAction<PublicUserDataV2>>,
  selectedEmail: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

const AttendeeDropdown = ({
  assigneeCandidates, isOpen, setAssignee,
  selectedEmail, setIsOpen,
}: Props) => {
  const node = useRef<HTMLDivElement>(null);

  // TODO reuse src\utils\detectOutsideClick.jsx instead
  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current.contains(e.target)) {
        // inside click
        console.log('does contain it');
        return;
      }
      setIsOpen(!isOpen);
      console.log('does not contain it');
      // outside click
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  const handleSetAssignee = (assignee: PublicUserDataV2) => {
    setAssignee(assignee);
    setIsOpen(false);
    console.log(assignee);
  };

  if (!isOpen) return null;

  return (
    <DropdownContainer ref={node}>
      <Scrollbar maxHeight="195px">
        {assigneeCandidates.map((candidate) => (
          <DropdownItemContainer
            key={candidate.data.email}
            onClick={() => handleSetAssignee(candidate)}
          >
            <DropdownItem
              email={candidate.data.email}
              selectedEmail={selectedEmail}
            />
          </DropdownItemContainer>
        ))}
      </Scrollbar>
    </DropdownContainer>
  );
};

export default AttendeeDropdown;
