import React from 'react';
import styled from 'styled-components';
import { paragraph } from '../../../typography';

const Paragraph = styled.span`
  ${paragraph};
`;

interface Props {
  title: string;
  value: string;
}

const InformationItemAtom = ({ title, value }: Props) => (
  <div>
    <Paragraph>
      <b>
        {title}
        :
      </b>
      {' '}
      {value}
      {' '}
    </Paragraph>
  </div>
);

export default InformationItemAtom;
