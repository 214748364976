import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../../shared/colours';
import { SecretChat } from '../../../../shared/types/types';
import PrivateSpaceButtonMolecule from '../../notes/secret-chat/individual-chat-modal/PrivateSpaceButtonMolecule';
import { getRandomSecretChatIndex } from '../../notes/secret-chat/secretChatUtils';
import ExtraChatMolecule from './ExtraChatAtom';

const Container = styled.div`
  width: 266px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px;
  background: ${gray1};
  box-shadow: 0px 8px 16px rgba(27, 33, 36, 0.16), 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
  border-radius: 8px;
  z-index: 20;
  top: 250px;
  right: 125px;
`;

export const ItemContainer = styled.div<ItemContainerProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.isLastChatItem ? 'opx' : '8px')};
`;

export interface ItemContainerProps {
  isLastChatItem: boolean,
}

interface Props {
  secretChats: SecretChat[],
  isOpen: boolean,
  refOutsideClick: React.MutableRefObject<null>,
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chat: SecretChat) => void,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  handleOpenCreateSecretChat: () => void,
  setChatToEdit: Dispatch<SetStateAction<SecretChat>>,
  setEditModalOpen: Dispatch<SetStateAction<boolean>>,
}

const ExtraChatsDropdownMolecule = ({
  secretChats,
  isOpen,
  refOutsideClick,
  handleSecretNotesSelected,
  selectedChat,
  setSelectedChat,
  handleOpenCreateSecretChat,
  setChatToEdit,
  setEditModalOpen,
}: Props) => {
  if (!isOpen) return null;

  const calculatedIndex = getRandomSecretChatIndex(selectedChat, secretChats);

  const handleChatSelected = (secretChat: SecretChat, index: number) => {
    handleSecretNotesSelected(secretChat);
    setSelectedChat(index);
  };

  return (
    <Container ref={refOutsideClick}>
      {
        secretChats.map((secretChat, index) => (
          <ItemContainer
            isLastChatItem={index === secretChats.length - 1}
            key={secretChat.chatPathInRealtimeDb}
            onClick={() => handleChatSelected(secretChat, index)}
          >
            <ExtraChatMolecule
              isSelected={calculatedIndex === index}
              secretChat={secretChat}
              key={secretChat.chatPathInRealtimeDb}
              setChatToEdit={setChatToEdit}
              setEditModalOpen={setEditModalOpen}
            />
          </ItemContainer>
        ))
      }
      <PrivateSpaceButtonMolecule onClick={handleOpenCreateSecretChat} />
    </Container>
  );
};

export default ExtraChatsDropdownMolecule;
