/* eslint-disable no-unused-vars */
import React, { ReactNode, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import {
  darkBlue1, darkBlue3, gray1, gray8, gray9, overlaySurface, red6, blue1, blue7, blue6,
} from '../../colours';

import CloseSquareIcon from '../../icons/closeSquare';
import { ShareModalTab } from '../../types/types';
import { header500 } from '../../typography';
import Scrollbar from '../scrollbar';

type PageProp = {
  isOpen: boolean;
}

const Title = styled.div<PageProp>`
  ${header500};
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;

  ${({ isOpen }) => (isOpen && css`
    color: ${blue6};
    /* background: ${gray9};  */
  `)};
  ${({ isOpen }) => (!isOpen && css`
    color: ${darkBlue3};
    background: 'none';
    :hover {
      background: ${blue1};
      color: ${blue7};
    }
  `)};
`;

const Container = styled.div`
  display: flex;
`;

const IconWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  :hover {
    path {
      fill: ${gray8};
    }
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  border-bottom: 2px solid ${darkBlue1};
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  & > div:nth-child(1) {
    margin-right: 10px;
  }
  & > div:nth-child(2) {
    color: ${darkBlue3};
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;

const NewIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 20px;
  background: ${red6};
  color: ${gray1};
  border-radius: 4px;
  margin-left: 4px;
`;

interface ModalProps {
  isOpen: boolean;
  setModalClosed: () => void,
  // eslint-disable-next-line no-unused-vars
  modalTab: ShareModalTab,
  // eslint-disable-next-line no-unused-vars
  setModalTab: (tab: ShareModalTab) => void,
  children: ReactNode;
}

const ShareModal = ({
  isOpen, setModalClosed, children, modalTab, setModalTab,
}: ModalProps) => {
  const handleSendNotesClick = () => {
    setModalTab('sendNotes');
  };

  const handleIntegrationsClick = () => {
    setModalTab('integrations');
  };

  const isIntegrationsTabOpen = modalTab === 'integrations';
  const isSendNotesTabOpen = modalTab === 'sendNotes';

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={setModalClosed}
      style={{
        content: {
          background: gray1,
          borderRadius: 10,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          height: 'fit-content',
          inset: '9vh 32px',
          width: 457,
          padding: 0,
          border: 'none',
        },
        overlay: {
          zIndex: 20,
          background: overlaySurface,
        },
      }}
    >
      <ModalHeader>
        <LeftContainer>
          <Container>
            <Title onClick={handleSendNotesClick} isOpen={isSendNotesTabOpen}> Send notes</Title>
            <IntegrationsContainer>
              <Title onClick={handleIntegrationsClick} isOpen={isIntegrationsTabOpen}>
                Integrations
              </Title>
              <NewIcon>New</NewIcon>
            </IntegrationsContainer>
          </Container>
        </LeftContainer>
        <IconWrapper onClick={setModalClosed}>
          <CloseSquareIcon fill={gray9} width="24" height="24" />
        </IconWrapper>
      </ModalHeader>
      <Scrollbar maxHeight="77vh">
        {children}
      </Scrollbar>
    </ReactModal>
  );
};

export default ShareModal;
