import { Dispatch, SetStateAction } from 'react';
import { cfSendWelcomeEmailAPI } from '../../../../database/cloudFunctionEmailAPI';
import { dbUpdateUserReceivedWelcomeEmailStatusToTrue, dbUserUpdateInfo as dbUserUpdateInfoAPI } from '../../../../database/firebaseUsersAPI';
import { AuthState, ResolveState } from '../../../../shared/types/types';
import setUserOnboardingPropertiesInMixpanel from '../../../../utils/mixpanel/functions';
import { toastDanger } from '../../../../utils/notifications';
import { DropdownOption, PersonalOnboardingData } from './types';

export const SortedArrayAlphabetically = (input: DropdownOption[]) => {
  const sorted = input.sort((a:DropdownOption, b:DropdownOption) => {
    if (a.value === 'Other') return 1;
    if (b.value === 'Other') return -1;

    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });

  return sorted;
};

export const mapOnboardingFormValuesToDatabaseUserOnboardingFormat = (
  formValues: PersonalOnboardingData,
) => {
  const dbOnboardingObj = {
    'data.firstName': formValues.firstName ?? '',
    'data.lastName': formValues.lastName ?? '',
    'data.name': `${formValues.firstName} ${formValues.lastName}` ?? '',
    'data.hasOnboarded': true,
    'data.onboarding.personalShepherdUsage': formValues.useShepherd ?? '',
    'data.onboarding.jobType': formValues.jobType ?? '',
    'data.onboarding.companySize': formValues.companySize ?? '',
    'data.onboarding.productivityTool': formValues.productivityTool ?? '',
    'data.onboarding.remotePolicy': formValues.remotePolicy ?? '',
  };

  return dbOnboardingObj;
};

export const dbUpdateUserDataWithOnboardingData = async (
  authState: AuthState,
  onboardingValues: PersonalOnboardingData,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  logAnalytics: () => void,
) : Promise<ResolveState> => {
  const dbOnboardingObj = mapOnboardingFormValuesToDatabaseUserOnboardingFormat(onboardingValues);
  setIsLoading(true);

  const updateUserStatus = await dbUserUpdateInfoAPI(authState.userId, dbOnboardingObj);
  if (updateUserStatus !== 'resolved') {
    toastDanger('Error', 'Error while updating user data with onboarding values');
    setIsLoading(false);
    return 'rejected';
  }

  const firstName = dbOnboardingObj['data.firstName'] ?? '';
  const sendWelcomeEmailStatus = await cfSendWelcomeEmailAPI(firstName);
  if (sendWelcomeEmailStatus === 'resolved') {
    dbUpdateUserReceivedWelcomeEmailStatusToTrue(authState);
  }

  logAnalytics();
  setUserOnboardingPropertiesInMixpanel(onboardingValues);
  setIsLoading(false);
  return 'resolved';
};
