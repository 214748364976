import { captureException } from '@sentry/browser';
import { TaskItem, TaskStatus, TrelloData } from '../../shared/types/types';
import { sendTaskToTrelloBoard, updateTrelloTask } from './trelloAPIs';

export type TrelloUpdateType = 'single' | 'many'

export type TrelloUpdateField = 'idList' | 'name' | 'desc' | 'due' | 'all'

export const trelloAuthUrl = 'https://trello.com/1/authorize?expiration=never&name=meetshepherd&scope=read,write&response_type=token&key=cd6fea696f1a635621808b198ce72fda&return_url=https://app.meetshepherd.com/trello/';

export const defaultTrelloData: TrelloData = {
  settings: {
    isTrelloEnabled: false,
    isAutoSyncEnabled: false,
    isAllowOtherToSyncEnabled: false,
  },
  accessToken: '',
  webhookId: '',
  workspaceId: '',
  workspaceName: '',
  board: {
    boardId: '',
    boardName: '',
    boardURL: '',
    todoList: {
      listId: '',
      listName: '',
    },
    inProgressList: {
      listId: '',
      listName: '',
    },
    completedList: {
      listId: '',
      listName: '',
    },
  },
};

export interface getTrelloBoardsAPIResponse {
  name: string,
  id: string,
  url: string
}

export interface getTrelloBoardsListAPIResponse {
  id: string,
  name: string,
  closed: boolean,
  pos: number,
  softLimit: any,
  idBoard: string,
  subscribed: boolean
}

export interface TrelloTaskCreate {
  name: string,
  idList: string
}

export interface TrelloUpdateObj {
  accessToken: string
  cardId: string,
  idList: string
  name: string,
  desc: string,
  due: string | null
}

export const mapTasksToTrelloTask = (selectedTasks: TaskItem[], trelloUserData: TrelloData) => {
  const trelloTaskArr: TrelloTaskCreate[] = selectedTasks.map((task: TaskItem) => (
    {
      taskId: task.taskId,
      name: task.data.title,
      due: task.date.dueDate.date.date || null,
      desc: task.data.description,
      idList: mapTaskStatusToListId(task.data.status, trelloUserData),
    }));
  return trelloTaskArr;
};

export const mapTaskStatusToListId = (taskStatus: TaskStatus, trelloData: TrelloData) => {
  if (taskStatus === 'todo') {
    return trelloData.board.todoList.listId;
  }
  if (taskStatus === 'inProgress') {
    return trelloData.board.inProgressList.listId;
  }
  if (taskStatus === 'completed') {
    return trelloData.board.completedList.listId;
  }
  return trelloData.board.todoList.listId;
};

// TODO Asish: Should return Promise<ResolveState>
// TODO Asish: You can actually go over all your Trello functions and make sure all
// functions that calls any API, will:
// If it saves or updates something, it should return a Promise<ResolveState>
// If the function fetches something, you should return a Promise<ItemToBeFetched>,
// where one of the fields of ItemToBeFetched is resolveState of type ResolveState
// (Similiar to how MeetingData type is)
export const updateTrelloCard = async (
  trelloData: TrelloData,
  taskItem: TaskItem,
  updateType: TrelloUpdateType,
  updateField: TrelloUpdateField,
  updateValue: string = '',
) => {
  if (updateType === 'single' && isTrelloSyncAvailable(taskItem)) {
    const updateObj: TrelloUpdateObj = {
      accessToken: trelloData.accessToken,
      cardId: taskItem.integrations.trello.trelloTaskId,
      idList: '',
      name: taskItem.data.title,
      desc: taskItem.data.description,
      due: taskItem.date.dueDate.date.date || null,
    };
    if (updateField === 'idList') {
      updateObj.idList = mapTaskStatusToListId(
        updateValue as TaskStatus, trelloData,
      );
    } else {
      updateObj.idList = mapTaskStatusToListId(
        taskItem.data.status,
        trelloData,
      );
    }
    if (updateField === 'name') {
      updateObj.name = updateValue;
    }
    if (updateField === 'desc') {
      updateObj.desc = updateValue;
    }
    if (updateField === 'due') {
      updateObj.due = updateValue;
    }
    updateTrelloTask(updateObj);
  }
  if (updateType === 'many') {
    if (isTrelloSyncAvailable(taskItem)) {
      const updateObj: TrelloUpdateObj = {
        accessToken: trelloData.accessToken,
        cardId: taskItem.integrations.trello.trelloTaskId,
        idList: mapTaskStatusToListId(taskItem.data.status, trelloData),
        name: taskItem.data.title,
        desc: taskItem.data.description,
        due: taskItem.date.dueDate.date.date || null,
      };
      updateTrelloTask(updateObj);
    }
    // TODO: Can this if be replaced by isTrelloSyncAvailable?
    if (taskItem.integrations.trello.trelloTaskId.length === 0
      && taskItem.integrations.trello.isTrelloSyncEnabled) {
      try {
        await sendTaskToTrelloBoard(true, [taskItem], trelloData);
      } catch (error) {
        captureException(error);
        console.log('Error sending task to trello board');
        console.log(error);
      }
    }
  }
};

export const isTrelloSyncAvailable = (
  taskItem: TaskItem,
) => taskItem.integrations.trello.trelloTaskId.length
  && taskItem.integrations.trello.isTrelloSyncEnabled;
