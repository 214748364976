import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../App';
import ShepherdLogoLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page';
import { Quote } from '../../shared/types/types';
import { emptyQuote, getQuoteForCertainDay } from './components/WelcomeScreenUtils';
import WelcomeTemplate from './components/WelcomeTemplate';

const DashboardWelcomePage = () => {
  const userData = useContext(UserDataContext);
  const [quote, setQuote] = useState<Quote>(emptyQuote);

  useEffect(() => {
    setQuote(getQuoteForCertainDay());
  }, []);

  if (userData.resolvedState === 'pending') return <ShepherdLogoLoadingPage />;

  return (
    <WelcomeTemplate firstName={userData.data.firstName} quote={quote} />
  );
};

export default DashboardWelcomePage;
