/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../App';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { toastInfo } from '../../utils/notifications';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const ShaokunTestingPage = () => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  useEffect(() => {
    console.log('User Data');
    console.log(userData);
    return () => { };
  }, [authData]);

  const handleClick1 = () => {
    toastInfo('Clicked', 'Button 1 clicked');
  };
  const handleClick2 = () => {
    toastInfo('Clicked', 'Button 2 clicked');
  };
  const handleClick3 = () => {
    toastInfo('Clicked', 'Button 3 clicked');
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
  };

  return (
    <Container>
      <TitleAtom>Shaokun Testing Page</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      <ButtonSmall text="Click 4" onClick={handleClick4} />
    </Container>
  );
};

export default ShaokunTestingPage;
