import React from 'react';
import styled from 'styled-components';
import { surface, gray4 } from '../../colours';
import TickSquare from '../../icons/tickSquare';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 260px;
  color: ${surface};
  border-radius: 6px;
  padding: 2px 6px 2px 6px;

  &:hover {
      background: ${gray4};
      cursor: pointer;
    }  
`;

const IconWrapper = styled.div`
  padding-right:10px;
`;

interface Props {
  email: string,
  selectedEmail: string
}

const DropdownItem = ({
  email, selectedEmail,
}: Props) => {
  const showTick = email === selectedEmail;

  return (
    <Container>
      {email}
      <IconWrapper>
        {showTick && <TickSquare />}
      </IconWrapper>
    </Container>
  );
};

export default DropdownItem;
