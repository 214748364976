import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  onboardingFirstMeetingPath, onboardingPath, onboardingPersonalPath,
  onboardingWelcomePath, onboardingWorkspacePath,
} from '../../routes';
import FirstMeeting from './first-meeting';
import PersonalOnboarding from './personal';
import OnboardingWelcomeScreens from './welcome';
import WorkspaceOnboarding from './workspace';

const OnboardingRoutes = () => (
  <Switch>
    <Route path={onboardingPersonalPath} component={PersonalOnboarding} />
    <Route path={onboardingWorkspacePath} component={WorkspaceOnboarding} />
    <Route path={onboardingWelcomePath} component={OnboardingWelcomeScreens} />
    <Route path={onboardingFirstMeetingPath} component={FirstMeeting} />
    <Route exact path={onboardingPath}>
      <Redirect to={onboardingPersonalPath} />
    </Route>
  </Switch>
);

export default OnboardingRoutes;
