import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase';
import {
  AuthState, User, MeetingRole, PublicUserData,
  SimpleUserData, GoogleAttendee, AttendeeProfile, UserSettings,
  SlackNotifications,
  MeetingData,
  ResolveState,
  SlackData,
} from '../shared/types/types';
import { dateISOObject, dateObject } from '../utils/dateUtils/date';
import { firestore, functions } from '../utils/firebase';
import {
  mapAuthStateToDatabaseUser, mapDatabaseDataToUser, rejectedUser,
} from '../utils/user/UserDataUtils';
import { handleOnSnapshotError, handleUpdateError } from './firebaseHandleError';
import mapDatabaseAttendeeDataToAttendeeData from './utils/mapAttendeesData';
import { logDatabaseEvent } from '../utils/analytics/eventLogger';
import { toastDanger } from '../utils/notifications';
import { defaultTrelloData } from '../utils/trello/trelloUtils';
import { DATABASE_PHOTO_URL_FIELD, UPDATE_EVENT } from '../utils/analytics/enums';
import dbUpdateFriendListCore from './firebaseUsersAPICore';
import dbGetUserWithFriendList from '../external/FriendListV2/dbFriendListV2Api';
import { cfSendWelcomeEmailAPI } from './cloudFunctionEmailAPI';

type SetUserType = Dispatch<SetStateAction<User>>

export const dbUserListenToMyUserData = (
  authState: AuthState, setUserData: SetUserType,
) => {
  if (authState.userState !== 'loggedIn') return () => {};
  return firestore()
    .collection('users')
    .doc(authState.userId)
    .onSnapshot(async (snapshot: any) => {
      if (!snapshot.exists) {
        dbCreateUserData(authState);
        return;
      }
      const user = mapDatabaseDataToUser(snapshot.data(), authState.userId);

      dbUpdatePhotoUrlIfNotUpdated(user, authState);
      dbUpdateNameIfNotUpdated(user, authState);
      dbSendWelcomeEmailIfNotSent(user, authState);

      setUserData(user);

      const userWithFriendListv2 = await dbGetUserWithFriendList(user, snapshot.data());
      setUserData(userWithFriendListv2);
    }, (error) => {
      Sentry.captureException(error);
      handleOnSnapshotError('Something went wrong while listening to user data');
    });
};

const dbSendWelcomeEmailIfNotSent = async (user: User, authState: AuthState) => {
  const { hasOnboarded, receivedWelcomeEmail } = user.data;
  if (hasOnboarded && !receivedWelcomeEmail) {
    const welcomeEmailSentStatus = await cfSendWelcomeEmailAPI(user.data.firstName);

    if (welcomeEmailSentStatus === 'resolved') {
      dbUpdateUserReceivedWelcomeEmailStatusToTrue(authState);
      console.log('welcome email sent');
    }
  }
};

const dbUpdatePhotoUrlIfNotUpdated = (user: User, authState: AuthState) => {
  const { photoUrl } = user.data;
  if (photoUrl.length === 0) {
    console.log('photoUrl does not exist for an existing profile');
    dbUpdateUserPhotoUrlField(authState.userId, authState.photoUrl);
  }
};

const dbUpdateNameIfNotUpdated = (user: User, authState: AuthState) => {
  const { firstName, lastName } = user.data;
  if (firstName.length === 0 && lastName.length === 0) {
    console.log('Name is not updated');
    dbPopulateUserNamesWithAuthData(authState);
  }
};
export const dbUpdateUserPhotoUrlField = (userId: string, photoUrl: string) => {
  const newPictureUrlField = { 'data.photoUrl': photoUrl };

  firestore()
    .collection('users')
    .doc(userId)
    .update(newPictureUrlField)
    .then(() => {
      console.log('photoUrl field successfully updated for user');
      logDatabaseEvent(UPDATE_EVENT, DATABASE_PHOTO_URL_FIELD);
    })
    .catch((err) => {
      console.log('error updating photoUrl field for user');
      console.log(err);
      Sentry.captureException(err);
    });
};

export const dbCreateUserData = async (authState: AuthState) => {
  const databaseUser = mapAuthStateToDatabaseUser(authState);
  firestore()
    .collection('users')
    .doc(authState.userId)
    .set(databaseUser)
    .then(() => {
      console.log('Set user data first first time');
    })
    .catch((err) => {
      console.log('error setting profile url');
      console.log(err);
      Sentry.captureException(err);
      toastDanger('Error', 'Failed to create user data. Please try again');
    });
};

export const cfGetAttendeeProfilesByGoogleAttendees = async (
  googleAttendees: GoogleAttendee[],
) => {
  const attendeesData = googleAttendees.map((
    googleAttendee,
  ) => cfGetAttendeeProfileByGoogleAttendee(googleAttendee));

  const attendeesAfterPromises = await Promise.all(attendeesData);
  return attendeesAfterPromises;
};

export const cfGetAttendeeProfileByGoogleAttendee = async (
  googleAttendee: GoogleAttendee,
) => functions()
  .httpsCallable('searchUserDataByEmail')({ email: googleAttendee.email })
  .then((response) => mapDatabaseAttendeeDataToAttendeeData(
    response.data, googleAttendee.responseStatus,
  ))
  .catch((error) => {
    Sentry.captureException(error);
    console.log(error);
    const attendeeProfile: AttendeeProfile = {
      userId: '',
      name: '',
      email: googleAttendee.email,
      photoUrl: '',
      responseStatus: googleAttendee.responseStatus,
    };
    return attendeeProfile;
  });

export const dbUserUpdateInfo = (
  userId: string, updates: any,
) : Promise<ResolveState> => firestore()
  .collection('users')
  .doc(userId)
  .update(updates)
  .then(() => 'resolved' as ResolveState)
  .catch((error) => {
    Sentry.captureException(error);
    handleUpdateError('Something went wrong while updating user data', { userId, updates });
    return 'rejected' as ResolveState;
  });

export const dbUserUpdateHasSeenNewFeatures = (userId: string, newFeatureId: string) => {
  const ref = firestore()
    .collection('users')
    .doc(userId);

  return ref.update({
    'data.newFeaturesViewed': firebase.firestore.FieldValue.arrayUnion(newFeatureId),
  });
};

export const dbGetSimpleUserDataByUserId = async (userId: string, role: MeetingRole) => {
  const publicUserData = await dbGetPublicUserDataByUserId(userId);
  const simpleUserData: SimpleUserData = {
    access: true,
    userId,
    role,
    name: publicUserData.name,
    email: publicUserData.email,
    date: {
      added: dateObject(),
      created: dateObject(),
      lastViewed: dateObject(),
    },
  };
  return simpleUserData;
};

// TODO: Remove?
export const dbGetPublicUserDataByUserId = async (userId: string) => {
  // TODO HARALD: Refactor
  const publicUserData: PublicUserData = await firestore()
    .collection('users')
    .doc(userId)
    .collection('public_user_data')
    .doc(userId)
    .get()
    .then((docRef) => ({
      name: docRef.data()?.name ?? '',
      email: docRef.data()?.email ?? '',
      userId,
      photoUrl: docRef.data()?.photoUrl ?? '',
      integrations: {
        slack: [{
          version: docRef.data()?.integrations?.slack[0]?.version ?? 1,
          userAccessToken: docRef.data()?.integrations?.slack[0]?.userAccessToken ?? '',
          botAccessToken: docRef.data()?.integrations?.slack[0]?.botAccessToken ?? '',
          userId: docRef.data()?.integrations?.slack[0]?.userId ?? '',
          defaultChannels: docRef.data()?.integrations?.slack[0]?.defaultChannels ?? [],
          notifications: {
            mentionedInNotes:
              docRef.data()?.integrations?.slack?.notifications?.mentionedInNotes ?? false,
            meetingStartsSoon:
              docRef.data()?.integrations?.slack?.notifications?.meetingStartsSoon ?? false,
            taskOverdue: docRef.data()?.integrations?.slack?.notifications?.taskOverdue ?? false,
            taskCreated: docRef.data()?.integrations?.slack?.notifications?.taskCreated ?? false,
            taskUpdated: docRef.data()?.integrations?.slack?.notifications?.taskUpdated ?? false,
            taskDeleted: docRef.data()?.integrations?.slack?.notifications?.taskDeleted ?? false,
          },
          date: {
            created: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
            updated: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
          },
        } as SlackData],
        notion: docRef.data()?.integrations?.notion ?? [],
        trello: docRef.data()?.integrations?.trello ?? defaultTrelloData,
        jira: {},
      },
    }))
    .catch((error) => {
      console.log('Something went wrong while fetching public user data');
      console.log(error);
      Sentry.captureException(error);
      return {
        version: 1,
        name: '',
        email: '',
        userId,
        photoUrl: '',
        integrations: {
          slack: [{
            version: 1,
            userAccessToken: '',
            botAccessToken: '',
            userId: '',
            defaultChannels: [],
            notifications: {
              meetingStartsSoon: false,
              mentionedInNotes: false,
              taskOverdue: false,
              taskCreated: false,
              taskUpdated: false,
              taskDeleted: false,
            },
            date: {
              created: dateISOObject(),
              updated: dateISOObject(),
            },
          } as SlackData],
          notion: [],
          trello: defaultTrelloData,
          jira: {},
        },
      };
    });
  return publicUserData;
};

export const dbPopulateUserNamesWithAuthData = (authState: AuthState) => {
  dbUserUpdateInfo(authState.userId, {
    'data.firstName': authState.firstName,
    'data.lastName': authState.lastName,
    'data.name': `${authState.firstName} ${authState.lastName}`,
  });
};

type DatabaseSimpleUser = {
  userId: string;
  email: string;
  name: string;
}

export const cfSearchUserIdAndNameByEmails = async (emails: string[]) => functions()
  .httpsCallable('searchUserIDAndNameByEmail')({ emails })
  .then((userData) => userData.data.map((user: DatabaseSimpleUser) => user.email) as string[])
  .catch((error) => {
    console.log('Error searching userId by email', error);
    Sentry.captureException(error);
    return [] as string[];
  });

export const cfSearchUserSettingByEmail = async (email: string) => functions()
  .httpsCallable('searchUserSettingByEmail')({ email })
  .then((response) => response.data as UserSettings)
  .catch((error) => {
    console.log('Error searching user setting by email', error);
    Sentry.captureException(error);
    // return {} as UserSettings;
  });

// TODO: Remove
export const cfUpdateUserFriendList = async (
  meetingData: MeetingData, userId: string,
) => {
  if (meetingData.resolvedState !== 'resolved') return;
  if (meetingData.meetingId.length === 0) return;
  if (meetingData.attendees.attendees.length === 0) return;
  console.log('updating friend list v1');
  console.log(meetingData.attendees);
  dbUpdateFriendListCore(meetingData.data.attendees, userId);
};

export const dbGetUserByUserId = async (userId: string) => {
  const databaseUser: User = await firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then((docRef) => mapDatabaseDataToUser(docRef.data(), userId))
    .catch((error) => {
      console.log('Something went wrong while fetching database user data');
      console.log(error);
      Sentry.captureException(error);
      return rejectedUser;
    });
  return databaseUser;
};

export const dbUpdateUserSlackNotifications = (
  slackNotifications: SlackNotifications,
  userId: string,
) => {
  const updateData = {
    'integrations.slack.notifications': slackNotifications,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(updateData)
    .then(() => {
      console.log('slack notifications updated successfully');
    })
    .catch((error) => {
      console.log(error);
    });
};

export const dbUserRemoveWhatsNewId = (userId: string, whatsNewId: string) => {
  const ref = firestore()
    .collection('users')
    .doc(userId);

  return ref.update({
    'data.newFeaturesViewed': firebase.firestore.FieldValue.arrayRemove(whatsNewId),
  })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbUpdateUserReceivedWelcomeEmailStatusToTrue = async (authState: AuthState) => {
  const dbReceivedWelcomeEmailObj = {
    'data.receivedWelcomeEmail': true,
  };

  const updateUserReceivedWelcomeEmailStatus = await dbUserUpdateInfo(
    authState.userId,
    dbReceivedWelcomeEmailObj,
  );

  if (updateUserReceivedWelcomeEmailStatus === 'rejected') {
    console.log('error updating received welcome email status to true');
    Sentry.captureException('error updating received welcome email status to true');
  }
};
