import {
  triggerEventMeetingHasAgenda,
  triggerEventMeetingHasPrivateNotes,
  triggerEventMeetingHasSharedNotes,
  triggerEventMeetingWithShepherdUsed,
} from '../../../../pages/meeting/events';
import { MeetingAnalyticsData, MeetingSections } from '../../../types/types';

const triggerLogEvent = (
  isSidebarOpen: boolean,
  page: MeetingSections,
  meetingAnalyticsData: MeetingAnalyticsData,
  html: string,
  meetingId: string,
  intercomTrackEvent: any,
  userId: string,
) => {
  if (!isSidebarOpen || meetingId.length === 0) return;
  triggerEventMeetingWithShepherdUsed(meetingAnalyticsData,
    html, meetingId, userId, intercomTrackEvent);
  triggerEventMeetingHasAgenda(meetingAnalyticsData,
    html, page, meetingId, intercomTrackEvent);
  triggerEventMeetingHasSharedNotes(meetingAnalyticsData,
    html, page, meetingId, intercomTrackEvent);
  triggerEventMeetingHasPrivateNotes(meetingAnalyticsData,
    html, page, meetingId, intercomTrackEvent);
};

export default triggerLogEvent;
