import React from 'react';
import Icon from '../../icons/attendeeIcon';
import { PublicUserDataV2 } from '../../types/types';

export interface Props {
  user: PublicUserDataV2,
  color?: string,
  size?: number,
  // only affects profile picture
  borderWidth?: number
}

const NoTooltipIcon = ({
  user, color, size = 24, borderWidth = 2,
}: Props) => (
  <Icon
    color={color}
    size={size}
    email={user.data.email}
    nonUser={user.isShepherdUser}
    photoUrl={user.data.photoUrl}
    borderWidth={borderWidth}
  />
);

export default NoTooltipIcon;
