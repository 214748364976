/* eslint-disable no-shadow */
import React, {
  Dispatch, SetStateAction,
} from 'react';
import TaskTabs, { TabButton, TabButtonsContainer, TabContent } from '../task-tabs';
import {
  TaskItem, TasksPage,
} from '../../types/types';
import TaskTabContent from './TaskTabContent';
import {
  mapTaskScopeToOrderField,
} from '../../../utils/tasks/tasksUtils';

interface Props {
  taskItems: TaskItem[],
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  height?: string,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  page: TasksPage,
  disableGoToMeeting?: boolean,
}

const Tasks = ({
  taskItems, setCreateTaskModalOpen, height, page,
  disableAssigneeSelect, disableCalendarSelect, disableGoToMeeting,
}: Props) => {
  const todoTasks = taskItems.filter((taskItem) => taskItem.data.status === 'todo');
  const inProgressTasks = taskItems.filter((taskItem) => taskItem.data.status === 'inProgress');
  const completedTasks = taskItems.filter((taskItem) => taskItem.data.status === 'completed');
  const tasksLists = { todoTasks, inProgressTasks, completedTasks };
  const tasksOrderField = mapTaskScopeToOrderField(page);

  return (
    <TaskTabs height={height as string}>
      <TabButtonsContainer>
        <TabButton>To do</TabButton>
        <TabButton>In progress</TabButton>
        <TabButton>Completed</TabButton>
      </TabButtonsContainer>
      <TabContent key="todo">
        <TaskTabContent
          tasks={tasksLists.todoTasks}
          taskOrderField={tasksOrderField}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          disableAssigneeSelect={disableAssigneeSelect}
          disableCalendarSelect={disableCalendarSelect}
          disableGoToMeeting={disableGoToMeeting}
          page={page}
        />
      </TabContent>
      <TabContent key="inProgress">
        <TaskTabContent
          tasks={tasksLists.inProgressTasks}
          taskOrderField={tasksOrderField}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          disableAssigneeSelect={disableAssigneeSelect}
          disableCalendarSelect={disableCalendarSelect}
          disableGoToMeeting={disableGoToMeeting}
          page={page}
        />
      </TabContent>
      <TabContent key="complete">
        <TaskTabContent
          tasks={tasksLists.completedTasks}
          taskOrderField={tasksOrderField}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          disableAssigneeSelect={disableAssigneeSelect}
          disableCalendarSelect={disableCalendarSelect}
          disableGoToMeeting={disableGoToMeeting}
          page={page}
        />
      </TabContent>
    </TaskTabs>
  );
};

Tasks.defaultProps = {
  height: 'calc(100vh - 310px)',
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
};

export default Tasks;
