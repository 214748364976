import React, { useState } from 'react';
import Header from '../../shared/components/header';
import { UserCenterSection } from '../../shared/types/types';
import Navbar from './navbar';
import AllTasksOrganism from './AllTasksOrganism';
import MeetingDashboard from './meetingDashboard';

const UserCenter = () => {
  const [section, setSection] = useState<UserCenterSection>('meetings');

  return (
    <>
      <Header />
      <Navbar section={section} setSection={setSection} />
      <AllTasksOrganism section={section} />
      <MeetingDashboard section={section} />
    </>
  );
};

export default UserCenter;
