import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../colours';
import { ShortcutInfo } from '../../../types/types';
import { paragraph } from '../../../typography';
import ShortcutCommandButton from './ShortcutCommandButton';

const SingleShortcutContainer = styled.div`
  width: 100%;
  height: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
`;

const TextContainer = styled.div`
  ${paragraph};
  color: ${gray8};

  width: 200px;
`;

const ShortcutButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-self: flex-end;

  gap: 8px;
`;

interface SingleShortcutDetailsProps {
  shortcut: ShortcutInfo;
}

const SingleShortcutDetails = ({ shortcut }:SingleShortcutDetailsProps) => {
  const { name, commands } = shortcut;
  return (
    <SingleShortcutContainer>
      <TextContainer>
        {name}
      </TextContainer>
      <ShortcutButtonsContainer>
        {commands.map((command) => <ShortcutCommandButton key={command} command={command} />)}
      </ShortcutButtonsContainer>
    </SingleShortcutContainer>
  );
};

export default SingleShortcutDetails;
