import React from 'react';
import styled from 'styled-components';
import { gray1, red6, red1 } from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const Container = styled.div`
  position: absolute;
  margin-top: 30px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  list-style: none;
  padding: 6px;
  margin: 0px;

  font-size: ${uiTextMedium};

  background-color: ${gray1};
  border-radius: 8px;
  border: none;
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);
`;

const Option = styled.div`
  padding: 6px 8px;
  border-radius: 8px;
  color: ${red6};

  &:hover {
    cursor: pointer;
    background-color: ${red1};
  }
`;

interface Props {
  isDropDownOpen: boolean,
  dropDownRef: React.MutableRefObject<null>
  handleDeleteClick: () => void
}

const DropDownOptions = ({ isDropDownOpen, dropDownRef, handleDeleteClick }: Props) => {
  if (isDropDownOpen) {
    return (
      <Container ref={dropDownRef}>
        <OptionsContainer>
          <Option onClick={handleDeleteClick}>
            Delete
          </Option>
        </OptionsContainer>
      </Container>
    );
  }

  return null;
};

export default DropDownOptions;
