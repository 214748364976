import React, { Dispatch, SetStateAction } from 'react';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import {
  CrossIconContainer, EnableButton, InnerContainer, IntegrationText, IntegrationContainer,
} from '../integrations/Integrations';
import { notionAuthUrl } from '../../../../utils/notion/notionUtils';
import { IntegrationsTabView } from '../../../../shared/types/types';
import { NOTION_PROCESSING_VIEW } from '../../../../utils/constants';
import { NotionIcon } from '../../../../shared/icons/integration-icons';

interface Props {
  enable: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>
}

export default function NotionNotIntegrated({ enable, setView }: Props) {
  const handleNotionButtonClick = () => {
    try {
      window.open(notionAuthUrl);
      setView(NOTION_PROCESSING_VIEW);
    } catch (error) {
      console.log(error);
    }
  };
  if (enable) return null;
  return (
    <div>
      <IntegrationContainer>
        <InnerContainer>
          <NotionIcon width="28" />
          <IntegrationText>Notion (Beta)</IntegrationText>
        </InnerContainer>
        <InnerContainer>
          <CrossIconContainer onClick={handleNotionButtonClick}>
            <EnableCrossIcon />
            <EnableButton>Enable</EnableButton>
          </CrossIconContainer>
        </InnerContainer>
      </IntegrationContainer>
    </div>
  );
}
