import React from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../colours';
import { defaultSmall } from '../../../typography';

const Explanation = styled.p`
  ${defaultSmall};
  color: ${gray7};
`;

interface Props {
  explanation: string,
}

const ExplanationAtom = ({ explanation }: Props) => (
  <Explanation>
    {explanation}
  </Explanation>
);

export default ExplanationAtom;
