import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, UserDataContext } from '../../../App';
import { dbUserUpdateHasSeenNewFeatures } from '../../../database/firebaseUsersAPI';
import NewFeaturesModalOrg from './NewFeaturesOrg';
import { NewFeatures } from '../../types/types';

export interface Props {
  newFeature: NewFeatures
}

const NewFeaturesModalOrgCtrl = ({ newFeature }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const userData = useContext(UserDataContext);
  const { newFeaturesViewed } = userData.data;
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    if (!hasSeenNewFeatureBefore(newFeaturesViewed, newFeature.id)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [newFeaturesViewed, newFeature.id]);

  const handleCloseAndUpdate = () => {
    dbUserUpdateHasSeenNewFeatures(userId, newFeature.id);
    setIsOpen(false);
  };

  if (!userData.data.email.length) return null;

  return (
    <NewFeaturesModalOrg
      isOpen={isOpen}
      onClose={handleCloseAndUpdate}
      features={newFeature.features}
    />
  );
};

export default NewFeaturesModalOrgCtrl;

const hasSeenNewFeatureBefore = (
  newFeaturesViewed: string[], newFeaturesId: string,
) => newFeaturesViewed.includes(newFeaturesId);
