import {
  blue1, blue6, green1, green7, red1, red7, yellow1, yellow6,
} from '../../colours';
import { ToastNotificationType } from '../../types/types';

export const getToastNotificationColor = (type: ToastNotificationType) => {
  if (type === 'success') {
    return green7;
  }
  if (type === 'info') {
    return blue6;
  }
  if (type === 'warning') {
    return yellow6;
  }
  return red7;
};

export const getToastNotificationBackgroundColor = (type: ToastNotificationType) => {
  if (type === 'success') {
    return green1;
  }
  if (type === 'info') {
    return blue1;
  }
  if (type === 'warning') {
    return yellow1;
  }
  return red1;
};
