import React from 'react';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import ShepherdLogo from '../../../icons/shepherd-logo';

const ShepherdLogoLoadingPage = () => (
  <Container>
    <ShepherdLogo width={267} spin />
  </Container>
);

export default ShepherdLogoLoadingPage;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: ${SIDEBAR_WIDTH};
  height: 100vh;
`;
