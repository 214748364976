import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  gray1, gray4, darkBlue1, red6, gray7,
} from '../../colours';
import {
  uiTextMedium, messageText, UiTextMedium, SmallText,
} from '../../typography';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { signOutOfGoogleAndFirebase } from '../../../utils/google/GoogleCalendarSetup';
import InviteMembersModal from './invite-members';
import Settings from './settings';
import {
  logEventButtonClickInviteMembers,
  logEventButtonClickOpenIntercom,
  logEventButtonClickQuickSettings,
  logEventButtonClickSettings,
} from '../../../utils/analytics/eventLogger';
import ProfilePicture from '../profile-picture';
import FeedbackModal from '../Modal/FeedbackModal';
import IntegrationsModal from './integrations/Modal';
import MessageIcon from '../../icons/MessageIcon';
import SettingsIcon18x18 from '../../icons/SettingsIcon18x18';
import StarIcon from '../../icons/StarIcon';
import ThumbsUp18x18 from '../../icons/ThumbsUp18x18';
import Chat18x18 from '../../icons/Chat18x18';
import HelpCenter from '../../icons/HelpCenter';
import ReactTooltip from '../tooltip/ReactTooltip';
import KeyboardIcon from '../../icons/KeyboardIcon';
import ShortcutsModal from '../shortcuts';
import FireworkConfetti from '../../confetti/FireworkConfetti';
import { DetectedOperatingSystemContext } from '../../../utils/context/OperatingSystemContext';
import CameraIcon from '../../icons/CameraIcon';
import { tutorialVideoPath } from '../../../routes';
import Sparkles from '../../icons/Sparkles';
import { dbUserRemoveWhatsNewId } from '../../../database/firebaseUsersAPI';
import { AuthContext, UserDataContext } from '../../../App';
import { NewFeaturesV2 } from '../new-feaures-modal/newFeaturesConstant';
import ProfileIcon from '../profile-icon';
import { calculateIfSlackIsEnabled } from '../../../utils/slack/slackUtils';
import AboutModal from './aboutModal/AboutModal';
import { isKnownUserUserId } from '../../../utils/user/UserIds';
import SlackNotificationsModalCtrl from './slack-notifications-modal/SlackNotificationsModalCtrl';

const Container = styled.div`
  position: relative;
  margin: 0 16px;
  display: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'flex')};
  align-items: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'center')};
  z-index: 26;
  margin-left: 0px;
`;

const Trigger = styled.div`
  cursor: pointer;
  border-radius: 4px;
`;

const Menu = styled.div`
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  right: 0;
  left: 10px;
  visibility: ${(props: MenuProps) => (props['data-active'] ? 'visible' : 'hidden')};
  width: 276px;
  z-index: 5;
`;

const TopSection = styled.div`
  display: flex;
  padding: 16px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 3px;
  }
`;

const Links = styled.div`
  border-top: 2px solid ${darkBlue1};
  border-bottom: 2px solid ${darkBlue1};
  padding: 8px;
`;

const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${uiTextMedium};
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 8px;

  *:first-child{
    margin-right: 8px;
  }

  :hover {
    background: ${gray4};
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${gray4};
  }
`;

const NewIcon = styled.div`
  ${messageText};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 20px;
  background: ${red6};
  color: ${gray1};
  border-radius: 8px;
  margin-left: 90px;
`;

const Logout = styled.div`
  ${uiTextMedium};
  border-radius: 6px;
  color: ${red6};
  cursor: pointer;
  margin: 8px;
  padding: 8px;

  :hover {
    background: ${gray4};
  }
`;

interface MenuProps {
  'data-active': boolean
}

interface ProfileSetting {
  photoUrl: string,
}

const QuickSettings = () => {
  const { detectedOperatingSystem } = useContext(DetectedOperatingSystemContext);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // TOOD: Fix name
  const [membersInvited, setMembersInivted] = useState(false);
  const setModalClosed = () => setIsModalOpen(false);
  const [chatBubbleOpen, setChatBubbleOpen] = useState(false);
  const [slackNotificationsModalOpen, setSlackNotificationsModalOpen] = useState(false);
  const { show, hide, trackEvent } = useIntercom();
  const history = useHistory();
  const authData = useContext(AuthContext);
  const {
    firstName, lastName, email, photoUrl, userId,
  } = authData;
  const userData = useContext(UserDataContext);

  const toggleIntercom = () => {
    if (chatBubbleOpen) {
      logEventButtonClickOpenIntercom(trackEvent);
      hide();
      setChatBubbleOpen(false);
    } else {
      show();
      setChatBubbleOpen(true);
    }
  };
  const [integrationsModalOpen, setIntegrationsModalOpen] = useState<boolean>(false);
  const [activateConfetti, setActivateConffetti] = useState<boolean>(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const initial = firstName[0];
  const fullName = `${firstName} ${lastName}`;

  useHotkeys('ctrl+/', () => {
    if (detectedOperatingSystem === 'Windows' || detectedOperatingSystem === 'Linux') {
      setIsActive(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  useHotkeys('Command+/', () => {
    if (detectedOperatingSystem === 'Mac') {
      setIsActive(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  const onClick = () => {
    setIsActive(!isActive);
    logEventButtonClickQuickSettings(trackEvent);
    setMembersInivted(false);
  };

  const handleShortcutsModalOpen = () => {
    setIsShortcutsModalOpen(true);
    setIsActive(false);
  };

  const handleFeedbackModalOpen = () => {
    setIsModalOpen(true);
    setIsActive(false);
  };

  const handleToggleIntegrationsModal = () => {
    setIntegrationsModalOpen(!integrationsModalOpen);
    onClick();
  };

  const openMembersModal = () => {
    setIsActive(false);
    setInviteModalOpen(true);
    logEventButtonClickInviteMembers(trackEvent);
  };

  const openSettingsModal = () => {
    setIsActive(false);
    setSettingsModalOpen(true);
    logEventButtonClickSettings(trackEvent);
  };

  useEffect(() => {
    if (activateConfetti) {
      setTimeout(() => {
        setActivateConffetti(false);
      }, 4000);
    }
  }, [activateConfetti]);

  const handleTutorialVideoClick = () => {
    history.push(tutorialVideoPath);
    setIsActive(false);
  };

  const handleWhatsNewClick = () => {
    // TODO Harald. This doesn't work
    // The what's new doesn't show
    dbUserRemoveWhatsNewId(userId, NewFeaturesV2.id);
    setIsActive(false);
  };

  const setSlackNotifModalClosed = () => {
    setSlackNotificationsModalOpen(false);
  };

  const handleSlackNotifModalOpen = () => {
    setSlackNotificationsModalOpen(true);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
    setIsActive(false);
  };

  const isKnownUser = isKnownUserUserId(authData.userId);
  const isSlackEnabled = calculateIfSlackIsEnabled(userData);

  return (
    <Container photoUrl={photoUrl}>
      <ReactTooltip tip="Profile & Settings" delay>
        <Trigger onClick={onClick} data-active={isActive}>
          <ProfileIcon user={userData.publicUserData} />
        </Trigger>
      </ReactTooltip>
      <Menu
        data-active={isActive}
        ref={dropdownRef}
      >
        <div>
          <TopSection>
            <ProfilePicture photoUrl={photoUrl} initial={initial} />
            <ProfileDetails>
              <UiTextMedium>{fullName}</UiTextMedium>
              <SmallText color={gray7}>{email}</SmallText>
              {/* <div>{fullName}</div> */}

              {/* <div>{email}</div> */}
            </ProfileDetails>
          </TopSection>
          <Links>
            {/* <Setting>
              <UserIcon18x18 />
              My account
            </Setting> */}
            {/* <Setting>
              <BellIcon />
              Notifications
            </Setting> */}
            <Setting onClick={openMembersModal}>
              <MessageIcon />
              Invite members to Shepherd
            </Setting>
            <Setting onClick={openSettingsModal}>
              <SettingsIcon18x18 />
              Settings
            </Setting>
            <IntegrationsContainer onClick={handleToggleIntegrationsModal}>
              <Setting>
                <StarIcon />
                Integrations
              </Setting>
              <NewIcon>New</NewIcon>
            </IntegrationsContainer>
            <Setting onClick={handleTutorialVideoClick}>
              <CameraIcon />
              Tutorial video
            </Setting>
            <Setting onClick={handleShortcutsModalOpen}>
              <KeyboardIcon />
              Keyboard shortcuts
            </Setting>
            <Setting onClick={handleFeedbackModalOpen}>
              <ThumbsUp18x18 />
              Give us feedback
            </Setting>
            <FeedbackModal
              isModalOpen={isModalOpen}
              setModalClosed={setModalClosed}
            />
            <Setting onClick={toggleIntercom}>
              <Chat18x18 />
              Chat with us
            </Setting>
            <Setting onClick={toggleIntercom}>
              <HelpCenter />
              Help Center
            </Setting>
            <Setting onClick={handleWhatsNewClick}>
              <Sparkles />
              {'What\'s new'}
            </Setting>
            {isKnownUser && (
              <Setting onClick={toggleAboutModal}>
                <HelpCenter />
                About
              </Setting>
            )}
          </Links>
          <Logout onClick={signOutOfGoogleAndFirebase}>Log out</Logout>
        </div>
      </Menu>
      <IntegrationsModal
        isOpen={integrationsModalOpen}
        setClosed={handleToggleIntegrationsModal}
        setActivateConffetti={setActivateConffetti}
        activateConfetti={activateConfetti}
      />
      <ShortcutsModal isOpen={isShortcutsModalOpen} setIsOpen={setIsShortcutsModalOpen} />
      <InviteMembersModal
        isOpen={inviteModalOpen}
        setIsOpen={setInviteModalOpen}
        setMembersInvited={setMembersInivted}
      />
      <Settings
        isOpen={settingsModalOpen}
        setIsOpen={setSettingsModalOpen}
        handleSlackNotifModalOpen={handleSlackNotifModalOpen}
        isSlackEnabled={isSlackEnabled}
      />
      <SlackNotificationsModalCtrl
        isOpen={slackNotificationsModalOpen}
        onClose={setSlackNotifModalClosed}
      />
      <AboutModal isOpen={isAboutModalOpen} setModalClosed={toggleAboutModal} />
      <FireworkConfetti shouldTrigger={membersInvited || activateConfetti} />
    </Container>
  );
};

export default QuickSettings;
