import React from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../colours';
import { uiText } from '../../typography';

const ParagraphText = styled.span`
  ${uiText};
  color: ${darkBlue4};
  margin: 0px;
`;

interface Props {
  children: React.ReactNode
}

const ParagraphAtom = ({ children }: Props) => (
  <ParagraphText>{children}</ParagraphText>
);

export default ParagraphAtom;
