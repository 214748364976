import React from 'react';
import { store } from 'react-notifications-component';
import ToastNotification from '../shared/components/ToastNotification';
import { ToastNotificationType } from '../shared/types/types';

export const toastSuccess = (title: string, message: string) => {
  toastCore(title, message, 'success');
};

export const toastInfo = (title: string, message: string) => {
  toastCore(title, message, 'info');
};

export const toastDanger = (title: string, message: string = '') => {
  toastCore(title, message, 'danger');
};

export const toastWarning = (title: string, message: string = '') => {
  toastCore(title, message, 'warning');
};

const toastCore = (title: string, message: string, type: ToastNotificationType) => {
  const content = () => (<ToastNotification type={type} title={title} message={message} />);
  store.addNotification({
    content,
    title,
    message,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
    },
  });
};

const H2 = () => (<p>Success</p>);

export const toastCore2 = () => {
  store.addNotification({
    content: H2,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
    },
  });
};
