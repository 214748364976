/* eslint-disable no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';
import {
  blue6, darkBlue1, darkBlue3, gray1, gray2, gray3, gray4, gray5, gray7, gray8, gray9,
} from '../../../shared/colours';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { header400 } from '../../../shared/typography';
import BlueLineAtom from './BlueLineAtom';

const Container = styled.div<ContainerProps>`
  height: 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  background: ${(props: ContainerProps) => (props.isSelected ? gray1 : 'none')};
  border-radius: 14px 14px 0px 0px;
  padding: ${(props: ContainerProps) => (props.isSelected ? '10px 24px 14px 24px' : '10px 16px 14px 16px')};

  ${({ isSelected }) => (isSelected && css`
    background: ${gray1};
    padding: 10px 24px 14px 24px;
  `)}
  ${({ isSelected }) => (!isSelected && css`
    &:hover {
      color: ${gray9};
      background: ${gray3}
    }
  `)}
`;

const Text = styled.span<ContainerProps>`
  color: ${(props: ContainerProps) => (props.isSelected ? blue6 : 'inherit')};
  ${header400};
`;

const LineContainer = styled.div`
  margin-top: 16px;
`;

interface ContainerProps {
  isSelected: boolean,
}

interface Props {
  text: string,
  onClick: () => void,
  isSelected: boolean,
  tooltipText: string,
}

const PageTabButtonAtom = ({
  text, onClick, isSelected, tooltipText,
}: Props) => (
  <ReactTooltip tip={tooltipText} place="top">
    <Container onClick={onClick} isSelected={isSelected}>
      <Text isSelected={isSelected}>{text}</Text>
      {/* {isSelected && (
        <LineContainer>
          <BlueLineAtom />
        </LineContainer>
      )} */}
    </Container>
  </ReactTooltip>
);

export default PageTabButtonAtom;
