import React from 'react';
import styled from 'styled-components';
import NewFeatureMinusIcon from '../../icons/NewFeatureMinusIcon';
import NewFeaturePlusIcon from '../../icons/NewFeaturePlusIcon';
import TitleAtom from './TitleAtom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

interface Props {
  onClick: () => void,
  isOpen: boolean,
  isExpandable?: boolean,
  children: React.ReactNode,
}

const ModalHeaderExpandableMol = ({
  onClick, isOpen, isExpandable = true, children,
}: Props) => (
  <Container onClick={onClick}>
    <TitleAtom>{children}</TitleAtom>
    {isExpandable
    && (
    <IconContainer>
      {isOpen ? (
        <NewFeatureMinusIcon />
      ) : (
        <NewFeaturePlusIcon />
      )}
    </IconContainer>
    )}
  </Container>
);

ModalHeaderExpandableMol.defaultProps = {
  isExpandable: true,
};

export default ModalHeaderExpandableMol;
