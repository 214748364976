import React from 'react';
import styled from 'styled-components';
import { header500 } from '../../../typography';

const FieldHeader = styled.div`
  text-align: center;
  margin: 40px 0px;
  ${header500};
`;

const SuccessMessage = () => (
  <FieldHeader>
    Template saved successfully!
  </FieldHeader>
);

export default SuccessMessage;
