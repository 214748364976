import React from 'react';
import styled from 'styled-components';
import { uiTextMedium } from '../../../shared/typography';
import { surface } from '../../../shared/colours';

const Text = styled.div`
  width: 100%;
  ${uiTextMedium};
  color: ${surface};
`;

interface Props {
  text: string,
}

const ConnectionLostTextAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default ConnectionLostTextAtom;
