import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import CssSpinner from '../../../../shared/components/css-spinner';
import { ResolveState } from '../../../../shared/types/types';
import IntegrationSuccessModal from './IntegrationSuccessModal';

const WaitingOnIntegrationSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  setActiveConfetti?: Dispatch<SetStateAction<boolean>>,
}

const NotionIntegrationProcessStatus = ({ setActiveConfetti }: Props) => {
  const [status, setStatus] = useState<ResolveState>('pending');
  const userData = useContext(UserDataContext);
  const notionData = userData.integrations.notion;

  useEffect(() => {
    if (notionData.length) {
      setStatus('resolved');
    }
  }, [notionData]);

  useEffect(() => {
    if (status === 'resolved' && setActiveConfetti) setActiveConfetti(true);
  }, [status]);

  useEffect(() => {
    const milliSecondsBeforeTimeout = 60 * 1000;
    setTimeout(() => {
      setStatus((prev) => (prev === 'resolved' ? 'resolved' : 'rejected'));
    }, milliSecondsBeforeTimeout);
  }, [notionData]);

  if (status === 'pending') {
    return (
      <WaitingOnIntegrationSetupContainer>
        <CssSpinner color="black" />
      </WaitingOnIntegrationSetupContainer>
    );
  }

  if (status === 'resolved') {
    return (
      <IntegrationSuccessModal
        headText="Shepherd is successfully integrated into your Notion workspace!"
        bottomText="This integration allows you to post Shepherd notes to Notion pages."
      />
    );
  }
  if (status === 'rejected') {
    return (
      <div>
        <p>
          There was an error connecting to Notion. Please try again.
        </p>
      </div>
    );
  }
  return null;
};

NotionIntegrationProcessStatus.defaultProps = {
  setActiveConfetti: () => {},
};

export default NotionIntegrationProcessStatus;
