import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { surface } from '../../../../shared/colours';
import ReactTooltip from '../../../../shared/components/tooltip/ReactTooltip';
import { uiTextMedium } from '../../../../shared/typography';

const Text = styled.span`
  ${uiTextMedium};
  color: ${surface};
  margin: 0px;
`;

interface Props {
  text: string,
}

const PrivateNoteTextAtom = ({ text }: Props) => {
  const shouldShortenText = text.length > 25;
  const [textToDisplay, setTextToDisplay] = useState<string>(text);

  useEffect(() => {
    if (shouldShortenText) setTextToDisplay(text.substring(0, 25));
  }, [shouldShortenText]);

  if (shouldShortenText) {
    return (
      <ReactTooltip tip={text}>
        <Text>
          {textToDisplay}
          ...
        </Text>
      </ReactTooltip>
    );
  }

  return (
    <Text>
      {text}
    </Text>
  );
};

export default PrivateNoteTextAtom;
