import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export default new Plugin({
  state: {
    init() { return DecorationSet.empty; },
    apply(tr, value) {
      const set = value.map(tr.mapping, tr.doc);

      const immediateDecoration = tr.getMeta(this);
      if (immediateDecoration) {
        if (immediateDecoration.removeDecoration) {
          // const { from, to, options } = immediateDecoration;
          return DecorationSet.empty;
        }
        const { from, to, options } = immediateDecoration;
        return DecorationSet.create(tr.doc, [
          Decoration.inline(from, to, options),
        ]);
      }
      return set;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
});
