/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import Header from '../../shared/components/header';
import {
  MeetingData, MeetingSections, SecretChat, SecretChatData, TaskItem, UserCenterSection,
} from '../../shared/types/types';
import Meeting from '../meeting';
import AllTasksOrganism from '../userCenter/AllTasksOrganism';
import MeetingDashboard from '../userCenter/meetingDashboard';
import Navbar from '../userCenter/navbar';
import BottomBar from './bottom-bar';
import MeetingDataContext from '../meeting/context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../meeting/context/ProseMirrorEditorViewContext';
import SecretChatModal from '../meeting/notes/secret-chat/modal/modal';
import NewFeaturesModalOrgCtrl from '../../shared/components/new-feaures-modal/NewFeaturesModalOrgCtrl';
import { NewFeaturesV2 } from '../../shared/components/new-feaures-modal/newFeaturesConstant';
import ConnectionLostPopupAndModal from './connection-lost/ConnectionLostPopupAndModal';
import { SHARED_NOTES } from '../../utils/enums';
import PrivateSpaceInvitedCtrl from '../meeting/modal/PrivateSpaceInvitedPopup/PrivateSpaceInvitedCtrl';
import { dbListenToSecretNotes, dbListenToSecretNotesData } from '../../database/firebaseSecretChatAPI';
import { AuthContext } from '../../App';

interface Props {
  match: any,
}

const AllPages = ({ match }: Props) => {
  const authState = useContext(AuthContext);
  const [taskItems, setTaskItems] = useState<TaskItem[]>([]);
  const [section, setSection] = useState<UserCenterSection>('currentMeeting');
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);
  const [isInvitedToPS, setIsInvitedToPS] = useState<boolean>(false);

  const [meetingTab, setMeetingTab] = useState<MeetingSections>(SHARED_NOTES);
  const [isRecurringMeeting, setIsRecurringMeeting] = useState<boolean>(false);

  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingTab]);

  const [isSecretChatModalOpen, setIsSecretChatModalOpen] = useState<boolean>(false);
  const [secretChatId, setSecretChatId] = useState<string>('');
  const [secretNotes, setSecretNotes] = useState<SecretChat[]>([]);
  const [secretChatsData, setSecretChatsData] = useState<SecretChatData[]>([]);

  const [meetingData, setMeetingData] = useState<MeetingData>(pendingMeetingData);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<any>({ page: '', editorView: '' });

  const handleClose = () => {
    setIsSecretChatModalOpen(false);
  };

  const handleSecretChatSelected = (chatId: string) => {
    if (chatId.length === 0) return;
    setSecretChatId(chatId);
    setMeetingTab('secret');
  };

  const handleIsInvitedToPS = () => {
    setIsInvitedToPS(false);
  };

  useEffect(() => {
    if (meetingData.meetingId.length === 0) return;
    dbListenToSecretNotesData(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setSecretChatsData,
    );
    if (secretChatId.length !== 0) setMeetingTab('secret');
  }, [secretNotes, meetingData, secretChatId]);

  useEffect(() => {
    if (meetingData.meetingId.length === 0) return;
    dbListenToSecretNotes(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setSecretNotes,
    );
    if (secretChatId.length !== 0) setMeetingTab('secret');
  }, [meetingData, secretChatId]);

  useEffect(() => {
    if (secretChatsData.length > 0) setIsInvitedToPS(true);
  }, [secretChatsData]);

  useEffect(() => {
    if (meetingTab !== 'secret') setSecretChatId('');
  }, [meetingTab]);

  return (
    <MeetingDataContext.Provider value={meetingData}>
      <ProseMirrorEditorViewContext.Provider value={proseMirrorEditorView}>
        <Header />
        <Navbar section={section} setSection={setSection} />
        <AllTasksOrganism section={section} />
        <MeetingDashboard section={section} />
        <Meeting
          section={section}
          meetingData={meetingData}
          setMeetingData={setMeetingData}
          taskItems={taskItems}
          setTaskItems={setTaskItems}
          match={match}
          meetingTab={meetingTab}
          setMeetingTab={setMeetingTab}
          isTemplatesOpen={isTemplatesOpen}
          setIsTemplatesOpen={setIsTemplatesOpen}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isRecurringMeeting={isRecurringMeeting}
          setProseMirrorEditorView={setProseMirrorEditorView}
          setSecretChatOpen={setIsSecretChatModalOpen}
          secretChatId={secretChatId}
          handleSecretNotesSelected={handleSecretChatSelected}
          secretNotes={secretNotes}
          setSecretNotes={setSecretNotes}
        />
        <BottomBar
          meetingData={meetingData}
          setIsTemplatesOpen={setIsTemplatesOpen}
          meetingTab={meetingTab}
          taskItems={taskItems}
          section={section}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isCreateTaskModalOpen={isCreateTaskModalOpen}
          setIsRecurringMeeting={setIsRecurringMeeting}
          secretNotes={secretChatsData}
        />
        <SecretChatModal
          isOpen={isSecretChatModalOpen}
          setClosed={handleClose}
          setSecretChatId={setSecretChatId}
        />
        <PrivateSpaceInvitedCtrl
          isOpen={isInvitedToPS}
          setClosed={handleIsInvitedToPS}
          secretChat={secretChatsData}
          handleSecretChatSelected={handleSecretChatSelected}
        />
        <NewFeaturesModalOrgCtrl newFeature={NewFeaturesV2} />
        <ConnectionLostPopupAndModal />
      </ProseMirrorEditorViewContext.Provider>
    </MeetingDataContext.Provider>
  );
};

export default AllPages;
