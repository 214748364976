import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { TaskItem } from '../../../../shared/types/types';
import PlatformShareCheckbox from '../PlatformShareCheckbox';
import TrelloMultiInput from './TrelloMultiInput';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

export interface Props {
  isTrelloEnabled: boolean,
  isChecked: boolean,
  setIsChecked: Dispatch<SetStateAction<boolean>>
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>
  taskToSend:TaskItem[]
  taskItems:TaskItem[]
}

const ShareTrelloCheckboxAndDropdown = ({
  isTrelloEnabled, isChecked, setIsChecked, taskItems, setTaskToSend, taskToSend,
}: Props) => {
  if (!isTrelloEnabled) return null;

  return (
    <>
      <SwitchGroup>
        <PlatformShareCheckbox platform="trello" isChecked={isChecked} setIsChecked={setIsChecked} />
        <TrelloMultiInput
          isChecked={isChecked}
          taskItems={taskItems}
          taskToSend={taskToSend}
          setTaskToSend={setTaskToSend}
        />
      </SwitchGroup>
    </>
  );
};

export default ShareTrelloCheckboxAndDropdown;
