import React from 'react';
import styled from 'styled-components';
import ExplanationAtom from './ExplanationAtom';
import TitleAtom from './TitleAtom';

const Container = styled.div`
  width: 100%;

  *:first-child {
    margin-right: 8px;
  }
`;

interface Props {
  createdAgo: string,
}

const TitleCreatedMolecule = ({ createdAgo }: Props) => (
  <Container>
    <TitleAtom title="Private Space invitation" />
    <ExplanationAtom explanation={createdAgo} />
  </Container>
);

export default TitleCreatedMolecule;
