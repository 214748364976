import React from 'react';
import { gray1 } from '../colours';

interface Props {
  fill?: string,
  width?: string,
  height?: string,
}
const CloseIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M20.5253 3.47669C20.8182 3.76958 20.8182 4.24446 20.5253 4.53735L4.53869 20.524C4.2458 20.8169 3.77092 20.8169 3.47803 20.524C3.18514 20.2311 3.18514 19.7562 3.47803 19.4633L19.4647 3.47669C19.7576 3.1838 20.2325 3.1838 20.5253 3.47669Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M3.46973 3.46961C3.76265 3.17675 4.23752 3.1768 4.53039 3.46973L20.5304 19.4731C20.8232 19.766 20.8232 20.2409 20.5303 20.5337C20.2374 20.8266 19.7625 20.8265 19.4696 20.5336L3.46961 4.53027C3.17675 4.23735 3.1768 3.76248 3.46973 3.46961Z" />
  </svg>

);

CloseIcon.defaultProps = {
  fill: gray1,
  width: '24',
  height: '24',
};

export default CloseIcon;
