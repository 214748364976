import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gray10 } from '../../../shared/colours';
import { MeetingSections } from '../../../shared/types/types';
import { getTabButtonStartingPosition, getTabButtonWidth } from './utils';

const Indicator = styled.div<DivProps>`
  position:absolute;
  width:${(props) => `${props.width}px`};
  height: 32px;
  background:${gray10};
  border-radius:10px;
  z-index: 0;
  left: ${(props) => `${props.tab}px`}; // shared 83px, private - private 205px tasks - 305px
  transition:all 300ms ease-in-out;
  transform: translateX(15px);
`;

interface DivProps {
  tab: number,
  width: number,
}

interface Props {
  meetingTab: MeetingSections,
  buttonsParentRef: React.RefObject<HTMLElement>,
}

const TabIndicator = ({ meetingTab, buttonsParentRef }: Props) => {
  if (meetingTab === 'secret') return null;
  const indicatorRef = useRef<HTMLDivElement>(window.document.createElement('div'));
  const [tabLeft, setTabLeft] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [waitWithRender, setWaitWithRender] = useState<boolean>(true);

  const AssignTabToNumber = () => {
    if (!buttonsParentRef.current) return;
    const buttons = Array.from(buttonsParentRef.current.querySelectorAll<HTMLDivElement>('*[data-meeting-tab]'));
    setTabLeft(getTabButtonStartingPosition(indicatorRef.current, buttons, meetingTab));
    setWidth(getTabButtonWidth(buttons, meetingTab));
  };

  useEffect(() => {
    if (waitWithRender) return;
    AssignTabToNumber();
  }, [meetingTab, waitWithRender]);

  useEffect(() => {
    setTimeout(() => {
      // Wait a second before render button since just in the start
      // it looks like the button is miss-alligned
      setWaitWithRender(false);
    }, 1000);
  }, []);

  if (waitWithRender) return null;

  return (
    <Indicator width={width} tab={tabLeft} ref={indicatorRef} />
  );
};

export default TabIndicator;
