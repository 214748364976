import { Dispatch, SetStateAction } from 'react';
import { dbDeleteSecretChat } from '../../../../database/firebaseSecretChatAPI';
import {
  Assignee,
  AssigneeV2,
  AttendeeProfile,
  AttendeeV2,
  Note,
  SecretChat,
  SecretChatAttendeeV2,
  SecretChatData,
} from '../../../../shared/types/types';
import { dateISOObject } from '../../../../utils/dateUtils/date';
import { emptyPublicUserDataV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

export const mapAttendeeProfileToAssignee = (attendees: AttendeeProfile[]) => {
  const assignees: Assignee[] = [];
  attendees.forEach((attendee) => {
    assignees.push({
      name: attendee.name,
      email: attendee.email,
      photoUrl: attendee.photoUrl,
      userId: attendee.userId,
    });
  });
  return assignees;
};

export const mapAttendeeV2ToAssigneeV2 = (attendees: AttendeeV2[]): AssigneeV2[] => {
  const assignees: AssigneeV2[] = [];
  attendees.forEach((attendee) => {
    assignees.push({
      resolvedState: attendee.resolvedState,
      userId: attendee.userId,
      isShepherdUser: attendee.isShepherdUser,
      data: attendee.data,
      external: attendee.external,
    });
  });
  return assignees;
};

export const mapAssigneeToAttendeeProfile = (assignees: Assignee[]) => {
  const attendees: AttendeeProfile[] = [];
  assignees.forEach((assignee) => {
    attendees.push({
      name: assignee.name,
      email: assignee.email,
      photoUrl: assignee.photoUrl,
      userId: assignee.userId,
      responseStatus: '',
    });
  });
  return attendees;
};

export const mapDatabaseDataToSecretNotesData = (
  dbData: any,
  userId: string,
  userEmail: string,
): SecretChatData[] => {
  const secretNotes: SecretChatData[] = [];
  dbData.forEach((data: any) => {
    secretNotes.push({
      chatId: data.id,
      title: data.data()?.title ?? 'Private space',
      chatPathInRealtimeDb: data.data()?.chatPathInRealtimeDb ?? '',
      userIds: data.data()?.userIds ?? [],
      members: data.data()?.members ?? [],
      meetingId: data.data()?.meetingId ?? [],
      created: data.data()?.created ?? dateISOObject,
      updated: data.data()?.created ?? dateISOObject,
      creator: data.data()?.creator ?? {},
    });
  });
  const chats = returnSecretChatsDataCurrentUserIsPartOf(secretNotes, userEmail, userId);
  return chats;
};

export const mapDatabaseDataToSecretNotes = (
  dbData: any,
  userId: string,
  userEmail: string,
): SecretChat[] => {
  const secretNotes: SecretChat[] = [];
  dbData.forEach((data: any) => {
    secretNotes.push({
      title: data.data()?.title ?? 'Private space',
      chatPathInRealtimeDb: data.data()?.chatPathInRealtimeDb ?? '',
      userIds: data.data()?.userIds ?? [],
      members: data.data()?.members ?? [],
      meetingId: data.data()?.meetingId ?? [],
      created: data.data()?.created ?? dateISOObject,
      updated: data.data()?.created ?? dateISOObject,
      creator: data.data()?.creator ?? {},
    });
  });
  const chats = returnSecretChatsCurrentUserIsPartOf(secretNotes, userEmail, userId);
  return chats;
};

export const returnSecretChatsCurrentUserIsPartOf = (
  secretChats: SecretChat[],
  userEmail: string,
  userId: string,
): SecretChat[] => {
  const filteredChats: SecretChat[] = [];
  secretChats.forEach((secretChat) => {
    secretChat.members.forEach((member) => {
      if (member.data.email === userEmail || member.userId === userId) {
        filteredChats.push(secretChat);
      }
    });
  });
  return filteredChats;
};

export const returnSecretChatsDataCurrentUserIsPartOf = (
  secretChats: SecretChatData[],
  userEmail: string,
  userId: string,
): SecretChatData[] => {
  const filteredChats: SecretChatData[] = [];
  secretChats.forEach((secretChat) => {
    secretChat.members.forEach((member) => {
      if (member.data.email === userEmail || member.userId === userId) {
        filteredChats.push(secretChat);
      }
    });
  });
  return filteredChats;
};

export const emptySecretChat = (): SecretChat => {
  const secretChat: SecretChat = {
    title: '',
    created: dateISOObject(),
    updated: dateISOObject(),
    userIds: [],
    members: [],
    chatPathInRealtimeDb: '',
    meetingId: '',
    creator: emptyPublicUserDataV2,
  };
  return secretChat;
};

export const getSecretChatId = (secretChat: SecretChat) => {
  const tempStringId = secretChat.chatPathInRealtimeDb.split('/').pop()?.split('_')[0];
  if (typeof tempStringId !== 'undefined') return tempStringId;
  return '';
};

export const deleteSecretChat = (
  secretChat: SecretChat,
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
  meetingId: string,
) => {
  const secretChatId = getSecretChatId(secretChat);
  dbDeleteSecretChat(meetingId, secretChatId);
  setSecretNotes((prevState) => (prevState.filter(
    (chat) => (chat.chatPathInRealtimeDb !== secretChat.chatPathInRealtimeDb),
  )));
};

export const getRandomSecretChatIndex = (selectedChatIndex: number, secretChats: SecretChat[]) => {
  if (selectedChatIndex !== -1) return selectedChatIndex;
  return Math.floor(Math.random() * (secretChats.length - 0) + 0); // if there is
  // no selected secret chat, one will be marked as selected by random
};

export const mapMemberIsNotified = (member: SecretChatAttendeeV2): SecretChatAttendeeV2 => ({
  isNotified: true,
  resolvedState: member.resolvedState,
  userId: member.userId,
  isShepherdUser: member.isShepherdUser,
  data: {
    name: member.data.name,
    email: member.data.email,
    firstName: member.data.firstName,
    lastName: member.data.lastName,
    photoUrl: member.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: member.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: member.external.slack.hasEnabledSlack,
      notifications: member.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: member.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: member.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: member.external.trello.isAllowOtherToSyncEnabled,
    },
  },
});

export const returnNotifiedMembers = (secretChatData: SecretChatData, currentUserId: string) => {
  const nonCurrentAttendees = secretChatData.members.filter(
    (member) => member.userId !== currentUserId,
  );
  const currentAttendee = secretChatData.members.find((member) => member.userId === currentUserId);
  const updatedCurrentUser = mapMemberIsNotified(currentAttendee!);
  return [...nonCurrentAttendees, updatedCurrentUser];
};

export const sortSecretChatDataChronologically = (secretChatData: SecretChatData[]) => {
  if (secretChatData.length === 1) return secretChatData;
  return secretChatData.sort((a, b) => (b.created.timestamp - a.created.timestamp));
};

export const determinePrivateSpaceNotesToShare = (
  checkedNotes: boolean[],
  notes: Note[],
): string[] => {
  const notesToSend: Note[] = [];
  if (checkedNotes.length !== notes.length) return [];
  checkedNotes.map((checked, index) => {
    if (checked) notesToSend.push(notes[index]);
    return [];
  });
  return notesToSend;
};
