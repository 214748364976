import React from 'react';
import ErrorMessage from './utils/styles';

interface DropdownErrorMessageProps {
  isDropdownOpen: boolean,
  error: any,
  touched: boolean,
}

const DropdownErrorMessage = ({
  isDropdownOpen,
  error,
  touched,
}: DropdownErrorMessageProps) => {
  const displayError = !isDropdownOpen && error && touched;
  if (!displayError) return null;

  return (
    <ErrorMessage>
      {displayError && error}
    </ErrorMessage>
  );
};

export default DropdownErrorMessage;
