import React from 'react';
import styled from 'styled-components';
import { ResolveStateExtended, SlackNotifications } from '../../../../../shared/types/types';
import NotifyAtom from './NotifyAtom';
import ToggleAtom from './ToggleAtom';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
  width: 100%;
  margin-bottom: 0px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 22px;
`;

interface Props {
  notifications: SlackNotifications,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotifications) => void,
  isResolved: ResolveStateExtended,
}

const SlackNotificationToggleMolecule = ({
  notifications,
  updateSlackNotifications,
  isResolved,
}: Props) => {
  const {
    taskCreated, taskDeleted, taskOverdue, taskUpdated,
  } = notifications;

  // TODO: Check if these are correct
  const setTaskOverdue = () => {
    updateSlackNotifications({
      ...notifications,
      taskOverdue: !taskOverdue,
    });
  };

  const setTaskCreated = () => {
    updateSlackNotifications({
      ...notifications,
      taskCreated: !taskCreated,
    });
  };

  const setTaskUpdated = () => {
    updateSlackNotifications({
      ...notifications,
      taskUpdated: !taskUpdated,
    });
  };

  const setTaskDeleted = () => {
    updateSlackNotifications({
      ...notifications,
      taskDeleted: !taskDeleted,
    });
  };

  return (
    <>
      <TextContainer>
        <NotifyAtom />
      </TextContainer>
      <SwitchGroup>
        {/* <ToggleAtom label="Meeting is about to start (1 min)"
         isToggled={meetingStartsSoon} setIsToggled={setMeetingStartsSoon} />
        <ToggleAtom label="You are mentioned in notes"
        isToggled={mentionedInNotes} setIsToggled={setMentionedInNotes} /> */}
        <ToggleAtom label="I have overdue tasks" isToggled={taskOverdue} onToggle={setTaskOverdue} isResolved={isResolved} />
        <ToggleAtom label="A task is assigned to me" isToggled={taskCreated} onToggle={setTaskCreated} isResolved={isResolved} />
        <ToggleAtom label="Any of my tasks are updated" isToggled={taskUpdated} onToggle={setTaskUpdated} isResolved={isResolved} />
        <ToggleAtom label="When a task assigned to me is deleted" isToggled={taskDeleted} onToggle={setTaskDeleted} isResolved={isResolved} />
      </SwitchGroup>
    </>
  );
};

export default SlackNotificationToggleMolecule;
