import React from 'react';
import styled from 'styled-components';
import MeetingCard from '.';
import { MeetingData } from '../../../../shared/types/types';

const CardContainer = styled.div`
  margin-bottom: 5px;
`;

interface Props {
  meetings: MeetingData[],
  isSelected: boolean,
}

const MeetingCardsOrganism = ({ meetings, isSelected }: Props) => (
  <div>
    {meetings.map((meeting) => (
      <CardContainer>
        <MeetingCard meeting={meeting} isSelected={isSelected} />
      </CardContainer>
    ))}
  </div>
);

export default MeetingCardsOrganism;
