import { NewFeature } from '../../types/types';
import JamieNewTextEditor from '../../gif/jamie-text-editor-new-features.gif';
import SlackGif from '../../gif/slack.gif';
import NotionGif from '../../gif/notion.gif';
import PreviousMeeting from '../../gif/previousMeeting.gif';
import allTasks from '../../gif/allTasks.gif';

export const NewFeaturesV1: NewFeature[] = [
  {
    id: 0, title: 'Slack Integration ⚡', media: SlackGif, explanation: 'Quickly share a meeting summary with your team via #channels or DMs',
  },
  {
    id: 1, title: 'Notion Integration 🚀', media: NotionGif, explanation: 'You can now instantly export your meeting notes to Notion from Shepherd',
  },
  {
    id: 2, title: 'Preview Previous Meetings 🔙', media: PreviousMeeting, explanation: 'Take a peek at what happened during the previous meeting in the recurring series from your current meeting.',
  },
  {
    id: 3, title: 'New Text Editor ✍', media: JamieNewTextEditor, explanation: 'Now you can @mention, insert links, GIFs, emojis, tables, and so much more!',
  },
  {
    id: 4, title: 'All your tasks in one place ✅', media: allTasks, explanation: "You can now see a complete list of all your tasks from every meeting you've use Shepherd with.",
  },
];

export const NewFeaturesV2 = {
  id: '26102021',
  features: NewFeaturesV1,
};

// ids:
//
