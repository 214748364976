import React, { Dispatch, SetStateAction } from 'react';
import InviteMembersModal from '../quick-settings/invite-members';
import { AttendeeV2 } from '../../types/types';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  attendees: AttendeeV2[];
  setMembersInvited: Dispatch<SetStateAction<boolean>>;
}

const InviteMembersToShepherdModal = ({
  isOpen,
  setIsOpen,
  attendees,
  setMembersInvited,
}: Props) => {
  const emails = attendees.map((attendee) => attendee.data.email);
  if (emails.length === 0) return null;

  return (
    <InviteMembersModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      emailList={emails}
      setMembersInvited={setMembersInvited}
    />
  );
};

export default InviteMembersToShepherdModal;
