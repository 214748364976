import React from 'react';
import styled from 'styled-components';
import CssSpinner from '../../../shared/components/css-spinner';

const NoteLoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 320px - 75px);
`;

const NotesLoading = () => (
  <NoteLoadingDiv>
    <CssSpinner color="black" size={70} />
  </NoteLoadingDiv>
);

export default NotesLoading;
