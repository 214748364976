import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import {
  DatabaseAssignee,
  DatabaseTaskItem, TaskItem, TaskOrderField, TaskStatus,
} from '../shared/types/types';
import { firestore } from '../utils/firebase';
import { handleOnSnapshotError } from './firebaseHandleError';
import { mapDocumentsToTaskItems } from './utils/mapTaskData';
import { TASKS_COLLECTION } from '../utils/constants';
import { toastDanger, toastSuccess } from '../utils/notifications';
import { filterTasksExcludeOtherUsersPrivateTasks, mapTaskItemToDatabaseTaskItem, sortTasksByCreated } from '../utils/tasks/tasksUtils';
import { dateToSDateObject } from '../utils/dateUtils/date';

type SetTaskType = Dispatch<SetStateAction<TaskItem>>

export const dbCreateTask = (taskData: DatabaseTaskItem) => firestore()
  .collection(TASKS_COLLECTION)
  .add(taskData)
  .then((docRef) => {
    console.log('task added successfully', taskData);
    toastSuccess('Task Created', `Task '${taskData.data.title}' created`);
    return docRef.id;
  })
  .catch((error) => {
    console.log('task could not be added');
    console.log(error);
    console.log(taskData);
    Sentry.captureException(error);
    toastDanger('Task Could Not Be Created', error.message);
    return '';
  });

export const dbUpdateTask = (
  taskId: string, taskItem: TaskItem, callback: any = () => { },
) => {
  const databaseTaskItem = mapTaskItemToDatabaseTaskItem(taskItem);
  return firestore()
    .collection(TASKS_COLLECTION)
    .doc(taskId)
    .update(databaseTaskItem)
    .then(() => {
      callback();
      console.log(`successfully updated task ${taskId}`);
      console.log(databaseTaskItem);
    })
    .catch((error) => {
      throw error;
    });
};

export const dbDeleteTask = (taskId: string) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId).delete()
  .then(() => {
    console.log('Task successfully deleted');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
    toastDanger('Task Could Not Be Deleted', error.message);
  });

export const dbListenToTask = (taskId: string, setTaskData: SetTaskType) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .onSnapshot(async (snapshot) => {
    if (!snapshot.exists) return;

    const tasks = await mapDocumentsToTaskItems([snapshot]);
    setTaskData(tasks[0]);
  }, (error) => {
    Sentry.captureException(error);
    handleOnSnapshotError('Something went wrong while listening to task data');
  });

// TODO: I think we need to listen two ways to all the tasks properly
// 1. Listen to my private tasks
// 2. Listen to all not private tasks
// This way, we never listen to other peoples private tasks
export const dbListenToTasksForMeeting = (
  meetingId: string,
  userEmail: string,
  setTasksData: Dispatch<SetStateAction<TaskItem[]>>,
) => firestore()
  .collection(TASKS_COLLECTION)
  .where('meeting.meetingId', '==', meetingId)
  .onSnapshot(async (snapshot) => {
    if (snapshot.empty) return setTasksData([]);

    const tasks = await mapDocumentsToTaskItems(snapshot.docs);
    const tasksSorted = tasks.sort(sortTasksByCreated);
    const tasksFiltered = tasksSorted.filter(
      (task) => filterTasksExcludeOtherUsersPrivateTasks(task, userEmail),
    );
    return setTasksData(tasksFiltered);
  }, handleOnSnapshotError(`Something went wrong while listening tasks for a meetingId: ${meetingId}`));

// better name dbListenForTasksForUser, same for the funcs above
export const dbListenToTasksForUser = (
  // userId: string,
  email: string,
  setTasksData: Dispatch<SetStateAction<TaskItem[]>>,
) => firestore()
  .collection(TASKS_COLLECTION)
  // .where('data.assignee.userId', '==', userId)
  .where('data.assignee.email', '==', email)
  .onSnapshot(async (snapshot) => {
    if (snapshot.empty) return setTasksData([]);

    const tasks = await mapDocumentsToTaskItems(snapshot.docs);
    const tasksSorted = tasks.sort(sortTasksByCreated);
    return setTasksData(tasksSorted);
  }, handleOnSnapshotError(`Something went wrong while listening tasks for the email: ${email}`));

export const dbBatchUpdateTaskOrder = (tasks: TaskItem[], fieldName: TaskOrderField) => {
  const batch = firestore().batch();
  tasks.forEach((task: TaskItem, index) => {
    const taskDocRef = firestore().collection(TASKS_COLLECTION).doc(task.taskId);
    batch.update(taskDocRef, { [`order.${fieldName}`]: index });
  });
  batch.commit().then(() => {
    console.log(`batch updated tasks orders. field: ${fieldName}`);
  }).catch((error) => {
    Sentry.captureException(error);
    console.log(error);
    console.log(`batch update tasks orders failed. field: ${fieldName}`);
  });
};

export const dbUpdateUnseenTask = (taskId: string) => {
  setTimeout(() => {
    firestore()
      .collection(TASKS_COLLECTION)
      .doc(taskId)
      .update({
        'data.isViewed': 'true',
      })
      .then(() => {
        console.log('updated successfully');
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
      });
  }, 10000);
};

export const dbUpdateSlackIntegrationUserNotified = (taskId: string) => {
  firestore()
    .collection('tasks')
    .doc(taskId)
    .update({
      'integrations.slack.userNotifiedOverdue': true,
    })
    .then(() => {
      console.log('updated successfully');
    })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbTaskUpdateAssignee = (
  taskId: string, databaseAssignee: DatabaseAssignee,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .update({
    'data.assignee': databaseAssignee,
    assignee: databaseAssignee,
  })
  .then(() => {
    console.log('updated assignee successfully');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbTaskUpdateDueDate = (
  taskId: string, newDueDate: Date,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .update({
    'date.dueDate.date': dateToSDateObject(newDueDate),
    'date.dueDate.type': 'date',
  })
  .then(() => {
    console.log('updated due date successfully');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbTaskUpdateTitle = (
  taskId: string, title: string,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .update({
    'data.title': title,
  })
  .then(() => {
    console.log('updated title successfully');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbTaskUpdateStatus = (
  taskId: string, status: TaskStatus,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .update({
    'data.status': status,
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbTaskUpdateTrelloData = (
  taskId: string, trelloId: string, trelloChecked: boolean,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskId)
  .update({
    'integrations.trello.trelloTaskId': trelloId,
    'integrations.trello.isTrelloSyncEnabled': trelloChecked,
  })
  .then(() => {
    console.log('updated trello info in task successfully');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });

export const dbUdpateSlackNotificationSent = (
  taskItem: TaskItem,
) => firestore()
  .collection(TASKS_COLLECTION)
  .doc(taskItem.taskId)
  .update({
    'integrations.slack.isOverdueNotificationSent': true,
  })
  .then(() => {
    console.log('Slack overdue notification updated');
  })
  .catch((error) => {
    console.log(error);
    Sentry.captureException(error);
  });
