import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { TabDropdownItem } from '../../../../../../shared/components/simple-dropdown';
import { TrelloData } from '../../../../../../shared/types/types';
import { getTrelloBoardsList } from '../../../../../../utils/trello/trelloAPIs';
import { updateList } from '../../../../../../utils/trello/trelloSettingsUpdateUtil';
import { getTrelloBoardsListAPIResponse } from '../../../../../../utils/trello/trelloUtils';
import { SettingContainer, SettingLabel } from '../SettingTab';
import ListSettingTab from './ListSettingTab';

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

// TODO: Better naming of component
export default function ListSettings({ setTrelloData, trelloData }:Props) {
  const [accessToken, setAccessToken] = useState('');
  const [listsLoading, setListsLoading] = useState(false);

  const [todoPlaceholder, setToDoPlaceholder] = useState('');
  const [inProgressPlaceholder, setInProgressPlaceholder] = useState('');
  const [completedPlaceholder, setCompletedPlaceholder] = useState('');

  const [listsResponse, setListsResponse] = useState<getTrelloBoardsListAPIResponse[]>([]);
  const [listsOption, setListsOption] = useState<TabDropdownItem[]>([]);

  useEffect(() => {
    setAccessToken(trelloData.accessToken);
    setToDoPlaceholder(trelloData.board.todoList.listName || 'Select a Trello List');
    setInProgressPlaceholder(trelloData.board.inProgressList.listName || 'Select a Trello List');
    setCompletedPlaceholder(trelloData.board.completedList.listName || 'Select a Trello List');
    setListsResponse([]); // TODO: Better naming
    setListsOption([]); // TODO: Better naming
  }, [trelloData]);

  const toDoLabel = (
    <>
      Tasks in
      {' '}
      <b>To do</b>
      {' '}
      should always export to...
    </>
  );

  const inProgressLabel = (
    <>
      Tasks in
      {' '}
      <b>In progress</b>
      {' '}
      should always export to...
    </>
  );

  const completedLabel = (
    <>
      Tasks in
      {' '}
      <b>Complete</b>
      {' '}
      should always export to...
    </>
  );

  const onToDoListChange = (selectedOption: TabDropdownItem) => {
    setToDoPlaceholder(selectedOption.value);
    updateList('todo', selectedOption, listsResponse, setTrelloData);
  };

  const onInProgressListChange = (selectedOption: TabDropdownItem) => {
    setInProgressPlaceholder(selectedOption.value);
    updateList('inProgress', selectedOption, listsResponse, setTrelloData);
  };

  const onCompletedListChange = (selectedOption: TabDropdownItem) => {
    setCompletedPlaceholder(selectedOption.value);
    updateList('completed', selectedOption, listsResponse, setTrelloData);
  };

  const onListDropdownClick = () => {
    if (accessToken.length > 10 && listsResponse.length === 0) {
      setListsLoading(true);
      getTrelloBoardsList(accessToken, trelloData.board.boardId)
        .then((response:getTrelloBoardsListAPIResponse[]) => {
          setListsResponse(response);
          const options:TabDropdownItem[] = response.map((list:getTrelloBoardsListAPIResponse) => ({
            id: list.id,
            value: list.name,
          }));
          setListsOption(options);
        }).finally(() => {
          setListsLoading(false);
        });
    }
  };

  return (
    <SettingContainer>
      <SettingLabel>Choose a Trello List</SettingLabel>
      <ListSettingTab
        label={toDoLabel}
        onClick={onListDropdownClick}
        onChange={onToDoListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={todoPlaceholder}
        name="trello-todo-list"
      />
      <ListSettingTab
        label={inProgressLabel}
        onClick={onListDropdownClick}
        onChange={onInProgressListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={inProgressPlaceholder}
        name="trello-inProgress-list"
      />
      <ListSettingTab
        label={completedLabel}
        onClick={onListDropdownClick}
        onChange={onCompletedListChange}
        loading={listsLoading}
        options={listsOption}
        placeholder={completedPlaceholder}
        name="trello-complete-list"
      />
    </SettingContainer>
  );
}
