import { setMixpanelUserProperty } from '..';
import { PersonalOnboardingData } from '../../../pages/onboarding/personal/utils/types';

const setUserOnboardingPropertiesInMixpanel = (onboardingValues: PersonalOnboardingData) => {
  const mixpanelUserPropertiesObj = {
    useShepherd: onboardingValues.useShepherd ?? '',
    jobType: onboardingValues.jobType ?? '',
    companySize: onboardingValues.companySize ?? '',
    productivityTool: onboardingValues.productivityTool ?? '',
    remotePolicy: onboardingValues.remotePolicy ?? '',
  };

  setMixpanelUserProperty(mixpanelUserPropertiesObj);
};

export default setUserOnboardingPropertiesInMixpanel;
