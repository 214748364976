import React from 'react';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import { header600 } from '../../../typography';
import CssSpinner from '../../css-spinner';

const Container = styled.div`
  padding: 80px 0;
  width: ${SIDEBAR_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    ${header600};
  }
`;

const AppLoading = () => (
  <Container>
    <CssSpinner color="black" size={70} thickness={8} />
  </Container>
);

export default AppLoading;
