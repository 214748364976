/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { dbListenToSecretNotes } from '../../../../database/firebaseSecretChatAPI';
import {
  darkBlue1, surface, gray9, gray8, gray1,
} from '../../../../shared/colours';
import SmallArrowDown from '../../../../shared/icons/text-editor/SmallArrowDown';
import { MeetingSections, SecretChat } from '../../../../shared/types/types';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import MeetingDataContext from '../../context/MeetingDataContext';
import { getSecretChatId } from '../../notes/secret-chat/secretChatUtils';
import ChatsDropdownEditModalOrganism from './ChatsDropdownEditModalOrganism';
import SecretChatBubbles from './SecretChatBubblesMolecule';
import { SECRET } from '../../../../utils/enums';

const Container = styled.div<ContainersProps>`
  height: 32px;
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isSecretChatSelected ? surface : 'none')};
  border-radius: 8px;
  cursor: pointer;
`;

const IconsContainer = styled.div<IconsContainerProps>`
  width: 100%;
  padding: ${(props) => (props.multipleChatsPresent ? '0px 1px 0px 2px' : '10px 8px')};
`;

const ArrowContainer = styled.div<ContainersProps>`
  width: 30px;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => (props.backgroundColor)};
  cursor: pointer;
  border-left: ${(props) => (props.isSecretChatSelected ? 'none' : `1px solid ${darkBlue1}`)};
  border-spacing: 4px;

  :hover {
    background-color: ${({ isSecretChatSelected }) => (isSecretChatSelected ? gray8 : 'none')};
  }
`;

interface ContainersProps {
  isSecretChatSelected: boolean,
  backgroundColor?: string,
}

interface IconsContainerProps {
  multipleChatsPresent: boolean,
}

interface Props {
  secretChats: SecretChat[],
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setMeetingTab: Dispatch<SetStateAction<MeetingSections>>,
  meetingTab: MeetingSections,
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
  handleOpenCreateSecretChat: () => void,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  setDeletedModalOpen: Dispatch<SetStateAction<boolean>>,
  setChatToDelete: Dispatch<SetStateAction<SecretChat>>,
}

const SecretChatButton = ({
  secretChats,
  handleSecretNotesSelected,
  meetingTab,
  setMeetingTab,
  setSecretNotes,
  handleOpenCreateSecretChat,
  selectedChat,
  setSelectedChat,
  setDeletedModalOpen,
  setChatToDelete,
}: Props) => {
  if (secretChats.length === 0) return null;

  const extraSecretNotesDropdown = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useDetectOutsideClick(extraSecretNotesDropdown, false);

  const firstChat = selectedChat === -1 ? secretChats[0] : secretChats[selectedChat];

  const isSecretChatSelected = meetingTab === 'secret';
  const initialBgColor = isSecretChatSelected ? gray9 : 'none';
  const backgroundColor = dropdownOpen === true ? gray8 : initialBgColor;

  const meetingData = useContext(MeetingDataContext);
  const authState = useContext(AuthContext);

  const setOpen = () => {
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (!isSecretChatSelected) setSelectedChat(-1);
  }, [meetingTab]);

  const handleNotesSelected = (chat: SecretChat) => {
    handleSecretNotesSelected(getSecretChatId(chat));
    setMeetingTab(SECRET);
    handleClose();
  };

  const handleDelete = (secretChat: SecretChat) => {
    setDeletedModalOpen(true);
    setChatToDelete(secretChat);
  };

  const handleUpdate = () => {
    dbListenToSecretNotes(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setSecretNotes,
    );
  };

  const handleOpenModalCloseDropdown = () => {
    handleOpenCreateSecretChat();
    setDropdownOpen(false);
  };

  return (
    <Container isSecretChatSelected={isSecretChatSelected}>
      <IconsContainer
        multipleChatsPresent={false}
        onClick={() => handleNotesSelected(firstChat)}
      >
        <SecretChatBubbles secretChat={firstChat} />
      </IconsContainer>
      <ArrowContainer
        onClick={setOpen}
        isSecretChatSelected={isSecretChatSelected}
        backgroundColor={backgroundColor}
      >
        <SmallArrowDown fill={gray1} />
      </ArrowContainer>
      <ChatsDropdownEditModalOrganism
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        secretChats={secretChats}
        dropdownOpen={dropdownOpen}
        dropdownRef={extraSecretNotesDropdown}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        handleNotesSelected={handleNotesSelected}
        handleOpenModalCloseDropdown={handleOpenModalCloseDropdown}
      />
    </Container>
  );
};

export default SecretChatButton;
