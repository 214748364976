/* eslint-disable no-unused-vars */
import { toggleMark } from 'prosemirror-commands';
import { Mark, Schema } from 'prosemirror-model';
import React, {
  Context,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { darkBlue1, gray1, gray9 } from '../../../colours';
import {
  header400, header500, header600, uiText, uiTextMedium,
} from '../../../typography';
import { EditorContext, EditorContextType } from '../../prosemirror/index';
import markActive from '../../prosemirror/logic/menu/helpers/mark-active';
import MinusIcon from '../../prosemirror/components/icons/minus';
import PlusIcon from '../../prosemirror/components/icons/plus';

const Container = styled.div`
  width: 122px;
  height: 178px;
  display: flex;
  flex-direction: column;
  background-color: ${gray1};
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const ItemContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 106px;
  height: 36px;
  background-color: ${(props: ContainerProps) => (props.isSelected ? darkBlue1 : 'inherit')};
  border-radius: 10px;
  padding: 6px 0px 6px 8px;
  cursor: pointer;
`;

interface ContainerProps {
  isSelected: boolean,
}

interface TextProps {
  font: FlattenSimpleInterpolation,
}

const Item = styled.h2<TextProps>`
  ${(props: TextProps) => props.font};
  margin: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 26px;
  margin-top: 8px;
`;

const Value = styled.h2`
  ${uiTextMedium};
  color: ${gray9};
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 1.625px;
  border-color: ${darkBlue1};
  padding: 0px;
  margin: 0px;

  &:hover {
    background-color: ${darkBlue1};
  }
`;

type TextStyle = {
  name: string;
  font: FlattenSimpleInterpolation;
  size: number;
  bold: boolean;
  isSelected: boolean;
  onClick: () => void;
};

interface Props {
  value?: string,
  ctx: EditorContextType,
  onStyleChange?: (style: TextStyle) => void;
  onFontChange?: (number: number) => void;
}

const FontSizeDropdown = ({
  value, ctx, onStyleChange, onFontChange,
}: Props) => {
  const types: Array<TextStyle> = [
    {
      name: 'Title', font: header600, size: 20, bold: true, isSelected: true, onClick: () => console.log('Title clicked'),
    },
    {
      name: 'Heading', font: header500, size: 16, bold: true, isSelected: false, onClick: () => console.log('Heading clicked'),
    },
    {
      name: 'Subheading', font: header400, size: 14, bold: true, isSelected: false, onClick: () => console.log('Subheading clicked'),
    },
    {
      name: 'Body', font: uiText, size: 14, bold: false, isSelected: false, onClick: () => console.log('body clicked'),
    },
  ];

  const currentFontSize = useMemo(() => {
    const defaultSize = 14;
    if (!ctx.view) return defaultSize;

    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    const markType = (state.schema as Schema).marks.fontSize;
    let presentMark: Mark | undefined;

    if (empty) {
      if (markType.isInSet(state.storedMarks ?? [])) {
        presentMark = state.storedMarks!.find((mark) => mark.type === markType);
      } else if (markType.isInSet($from.marks())) {
        presentMark = $from.marks().find((mark) => mark.type === markType);
      }
      return presentMark?.attrs.size ?? defaultSize;
    }

    state.doc.nodesBetween(from, to, (node) => {
      const attemptMark = node.marks.find((mark) => mark.type === markType);
      if (attemptMark) {
        presentMark = attemptMark;
      }
    });
    return presentMark?.attrs.size ?? defaultSize;
  }, [ctx.update]);

  const setFontSize = (size: number) => () => {
    if (!ctx?.view) return;
    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    const markType = (state.schema as Schema).marks.fontSize;

    if (markActive(state, markType)) {
      toggleMark(markType)(state, ctx.view.dispatch);
    }

    toggleMark(markType, {
      size,
    })(ctx.view.state, ctx.view.dispatch);
    ctx.setUpdate({});
  };

  const plusAction = setFontSize(currentFontSize + 1);

  const minusAction = setFontSize(currentFontSize - 1);

  return (
    <Container>
      {types.map((type) => (
        <ItemContainer
          isSelected={(() => {
            if (!ctx.view) return false;
            const sizeCondition = currentFontSize === type.size;
            const boldMark = ctx.view?.state.schema.marks.strong;
            const boldCondition = markActive(ctx.view.state, boldMark) === type.bold;
            return boldCondition && sizeCondition;
          })()}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFontSize(type.size)();
            if (!ctx.view) return;
            const boldMark = ctx.view?.state.schema.marks.strong;
            if (markActive(ctx.view.state, boldMark)) {
              toggleMark(boldMark)(ctx.view?.state, ctx.view.dispatch);
            }
            if (type.bold) {
              toggleMark(boldMark)(ctx.view?.state, ctx.view.dispatch);
            }
            onStyleChange!(type);
          }}
        >
          <Item font={type.font}>{type.name}</Item>
        </ItemContainer>
      ))}
      <ButtonsContainer>
        <ButtonContainer
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            minusAction();
            onFontChange!(currentFontSize);
          }}
        >
          <MinusIcon />
        </ButtonContainer>
        <Value>
          {currentFontSize}
        </Value>
        <ButtonContainer
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            plusAction();
            onFontChange!(currentFontSize);
          }}
        >
          <PlusIcon />
        </ButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};

FontSizeDropdown.defaultProps = {
  value: '14pt',
  onFontChange: (number: number) => {},
  onStyleChange: (style: TextStyle) => {},
};

export default FontSizeDropdown;
