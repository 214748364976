import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../../../../shared/colours';
import { header200 } from '../../../../../shared/typography';

const GiveAccessText = styled.span`
  width: 100%;
  ${header200};
  color: ${gray9};
  margin: 0;
  display: inline-block;
`;

interface Props {
  text: string,
}

const AccessAtom = ({ text }: Props) => (
  <GiveAccessText>
    {text}
  </GiveAccessText>
);

export default AccessAtom;
