import React from 'react';
import { surface } from '../colours/index';

interface Props {
  fill?: string,
}

const HelpCenter = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625ZM0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM9.22529 5.83344C8.8325 5.76606 8.42855 5.83988 8.08498 6.0418C7.7414 6.24372 7.48037 6.56073 7.34813 6.93666C7.24504 7.22972 6.92389 7.38372 6.63084 7.28063C6.33778 7.17754 6.18378 6.85639 6.28687 6.56334C6.50728 5.93678 6.94233 5.40844 7.51496 5.0719C8.08758 4.73536 8.76084 4.61234 9.41548 4.72463C10.0701 4.83692 10.6639 5.17727 11.0916 5.6854C11.5193 6.1934 11.7534 6.83632 11.7525 7.50035C11.7523 8.54877 10.9734 9.25792 10.377 9.65553C10.0617 9.86576 9.75031 10.021 9.52002 10.1234C9.40407 10.1749 9.30666 10.2139 9.23693 10.2405C9.20201 10.2538 9.1739 10.264 9.15371 10.2712L9.12947 10.2797L9.12204 10.2822L9.11955 10.2831L9.11861 10.2834C9.11843 10.2834 9.11788 10.2836 8.94 9.75L9.11788 10.2836C8.82316 10.3819 8.50461 10.2226 8.40637 9.92788C8.30822 9.63344 8.4671 9.31522 8.76127 9.21665C8.76137 9.21662 8.76203 9.2164 8.76212 9.21637L8.76344 9.21591L8.77683 9.21122C8.7896 9.20667 8.80982 9.19933 8.83643 9.18919C8.88974 9.16889 8.96812 9.13757 9.06311 9.09536C9.25469 9.01021 9.50584 8.88424 9.75298 8.71947C10.2815 8.36712 10.6275 7.95139 10.6275 7.5V7.49916C10.6281 7.10064 10.4876 6.71477 10.231 6.4099C9.97433 6.10502 9.61807 5.90081 9.22529 5.83344ZM8.4375 12.75C8.4375 12.4393 8.68934 12.1875 9 12.1875H9.0075C9.31816 12.1875 9.57 12.4393 9.57 12.75C9.57 13.0607 9.31816 13.3125 9.0075 13.3125H9C8.68934 13.3125 8.4375 13.0607 8.4375 12.75Z" fill={fill} />
  </svg>
);

HelpCenter.defaultProps = {
  fill: surface,
};

export default HelpCenter;
