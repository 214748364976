import React from 'react';

interface Props {
  icon: any,
}

const IconAtom = ({ icon }: Props) => (
  <div>
    {icon}
  </div>
);

export default IconAtom;
