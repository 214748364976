import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { captureException } from '@sentry/browser';
import { dbCreateTask } from '../../../database/firebaseTasksAPI';
import {
  mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2,
  emptyTaskItem, mapDatabaseTaskItemToTaskItem,
} from '../../../database/utils/mapTaskData';
import { RESET_DATE } from '../../../utils/constants';
import {
  DatabaseTaskItem, MeetingData, TrelloData, TaskItem, AssigneeV2,
} from '../../types/types';
import Modal from '../Modal';
import {
  newDescriptionChange, newTitleChange, resetAssignee, resetTaskDueDate,
  setAssignee, setIsPrivate, setReporter, setTaskDueDate, setTaskMeeting,
} from './TaskModalUtils';
import { AuthContext, UserDataContext } from '../../../App';
import { logTasksUserAction } from '../../../utils/analytics/eventLogger';
import { mapTaskItemToDatabaseTaskItem } from '../../../utils/tasks/tasksUtils';

import TaskModalContent from './TaskModalContent';
import { defaultTrelloData } from '../../../utils/trello/trelloUtils';
import { cfSendTaskAssignNotificationEmail } from '../../../database/cloudFunctionEmailAPI';
import { toastDanger } from '../../../utils/notifications';
import { getUsersTrelloSetting, sendTaskToTrelloBoard } from '../../../utils/trello/trelloAPIs';
import { CREATE_EVENT, TASKS_CREATE_TASK_BUTTON_FIELD } from '../../../utils/analytics/enums';
import { slackCoreAPISendNotificationForTaskCreate } from '../../../utils/slack/SlackCoreAPI';

export interface CreateNewTaskModalProps {
  meetingData: MeetingData,
  isModalOpen: boolean,
  setModalClosed: () => void
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
}

const CreateNewTaskModal = ({
  meetingData, isModalOpen, setModalClosed, disableCalendarSelect, disableAssigneeSelect,
}: CreateNewTaskModalProps) => {
  const [taskItem, setTaskItem] = useState<TaskItem>(emptyTaskItem);
  const [loading, setLoading] = useState<boolean>(false);
  const [trelloChecked, setTrelloChecked] = useState<boolean>(false);
  const [trelloDisabled, setTrelloDisabled] = useState<boolean>(false);
  const [userTrelloData, setUserTrelloData] = useState({
    trelloData: defaultTrelloData,
    self: true,
  });
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    setTaskMeeting(setTaskItem, meetingData);
  }, [meetingData]);

  useEffect(() => {
    checkTrelloIntegration(userTrelloData.trelloData, userTrelloData.self);
  }, [userTrelloData]);

  useEffect(() => {
    if (userData.data.email.length) {
      setUserTrelloData({
        trelloData: userData.integrations.trello,
        self: true,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (userData.resolvedState !== 'resolved') return;
    setAssignee(setTaskItem, userData.publicUserData);
    setReporter(setTaskItem, userData.publicUserData);
  }, [userData, authState.userId, isModalOpen]);

  const checkTrelloIntegration = (trelloData: TrelloData, self: boolean) => {
    if (trelloData.settings.isTrelloEnabled) {
      setTrelloDisabled(false);
      if ((self && trelloData.settings.isAutoSyncEnabled)
        || (!self && trelloData.settings.isAllowOtherToSyncEnabled)) {
        setTrelloChecked(true);
      } else {
        setTrelloChecked(false);
      }
    } else {
      setTrelloChecked(false);
      setTrelloDisabled(true);
    }
  };

  const getAssigneeData = (emailId: string) => {
    if (emailId) {
      getUsersTrelloSetting(emailId).then((response) => {
        setUserTrelloData({
          trelloData: response.data.integrations.trello,
          self: false,
        });
      }).catch((error) => {
        console.log('something went wrong', error);
      });
    } else {
      setTrelloDisabled(true);
    }
  };

  const handleTitleChange = (newTitle: string) => {
    newTitleChange(setTaskItem, newTitle);
  };

  const handleDueDateChange = (newDueDate: Date) => {
    console.log('Handle due date change');
    console.log(newDueDate);
    // TODO: Check if date is the same
    // Then should reset the due date, similar
    // to in handleAssigneeChange

    if (newDueDate === RESET_DATE) {
      resetTaskDueDate(setTaskItem);
      return;
    }
    setTaskDueDate(setTaskItem, newDueDate);
  };

  const handleAssigneeChange = (newAssignee: AssigneeV2) => {
    console.log('Handle attendee change');
    console.log(newAssignee);
    // TODO: Get Shepherd user by email
    // If not found, then send email to guy and ask them
    // to create a Shepherd account
    if (newAssignee.data.email === taskItem.assignee.data.email) {
      console.log('Selected the same email');
      resetAssignee(setTaskItem);
      return;
    }
    setAssignee(setTaskItem, newAssignee);
    if (newAssignee.data.email === userData.data.email) {
      setUserTrelloData({
        trelloData: userData.integrations.trello,
        self: true,
      });
    } else {
      getAssigneeData(newAssignee.data.email);
    }
  };

  const handleDescriptionChange = (description: string) => {
    newDescriptionChange(setTaskItem, description);
  };

  const handlePrivateTaskChange = (value: boolean) => {
    setIsPrivate(setTaskItem, value);
  };

  const resetTaskItem = () => {
    setTaskItem(emptyTaskItem);
    setTaskMeeting(setTaskItem, meetingData);
    setReporter(setTaskItem, userData.publicUserData);
    setAssignee(setTaskItem, userData.publicUserData);
  };

  const validateTaskItem = (item: DatabaseTaskItem) => {
    if (item.data.title.length === 0) return false;
    return true;
  };

  const handleOnCreateClick = () => {
    console.log('Pressed handleOnCreateClick');
    console.log(taskItem);
    const databaseTaskItem = mapTaskItemToDatabaseTaskItem(taskItem);

    if (!validateTaskItem(databaseTaskItem)) {
      console.log('Not valid task item');
      console.log(taskItem);
      return;
    }
    // TODO: Assign reporter
    // const reporter: Assignee = {
    //   email: authState.email,
    //   name: `${authState.firstName} ${authState.lastName}`,
    //   userId: authState.userId,
    // };
    setLoading(true);
    dbCreateTask(databaseTaskItem).then(async (taskId: string) => {
      const taskWithoutAssigneeV2 = mapDatabaseTaskItemToTaskItem(taskId, taskItem);
      const tasks = await mapTasksWithoutPublicUserDataV2ToTasksWithPublicUserDataV2(
        [taskWithoutAssigneeV2],
      );
      if (trelloChecked) { // TODO: Move this if into function
        try {
          await sendTaskToTrelloBoard(trelloChecked, [...tasks],
            userTrelloData.trelloData);
        } catch (error) {
          captureException(error);
          console.log('Error sending task to trello board');
          console.log(error);
        }
      }
      // TODO Ivan: Should not call a core function directly from component
      slackCoreAPISendNotificationForTaskCreate(
        taskItem,
        authState,
      );
      logTasksUserAction(trackEvent, CREATE_EVENT, TASKS_CREATE_TASK_BUTTON_FIELD);
      cfSendTaskAssignNotificationEmail(taskItem, userData)
        .catch((error) => toastDanger('Failed to send notification', error));
    })
      .finally(() => {
        setLoading(false);
        setModalClosed();
        resetTaskItem();
      });
  };

  return (
    <Modal title="Create new task" isOpen={isModalOpen} setModalClosed={setModalClosed} isScrollbarEnabled={false}>
      <TaskModalContent
        taskModalVersion="create"
        trelloChecked={trelloChecked}
        setTrelloToggleChange={setTrelloChecked}
        trelloDisabled={trelloDisabled}
        taskItem={taskItem}
        setTaskItem={setTaskItem}
        setModalClosed={setModalClosed}
        onTitleChange={handleTitleChange}
        onDescriptionChange={handleDescriptionChange}
        onDueDateChange={handleDueDateChange}
        onAssigneeChange={handleAssigneeChange}
        onPrivateTaskChange={handlePrivateTaskChange}
        onConfirmClick={handleOnCreateClick}
        loading={loading}
        disableCalendarSelect={disableCalendarSelect}
        disableAssigneeSelect={disableAssigneeSelect}
      />
    </Modal>
  );
};

CreateNewTaskModal.defaultProps = {
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
};

export default CreateNewTaskModal;
