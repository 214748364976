import React from 'react';

const TickSquare = () => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.02961 1.09662C8.3225 0.80373 8.79738 0.80373 9.09027 1.09662C9.35654 1.36289 9.38074 1.77955 9.16289 2.07316L9.09027 2.15728L4.34427 6.90328C4.07804 7.16951 3.66146 7.19375 3.36784 6.97599L3.28372 6.90339L0.909723 4.5304C0.616768 4.23756 0.616668 3.76269 0.9095 3.46973C1.17571 3.20341 1.59237 3.17912 1.88603 3.39691L1.97016 3.46951L3.81304 5.31175L8.02961 1.09662Z" fill="#058FEF" />
  </svg>
);

const TickSquareBase64 = 'PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMDI5NjEgMS4wOTY2MkM4LjMyMjUgMC44MDM3MyA4Ljc5NzM4IDAuODAzNzMgOS4wOTAyNyAxLjA5NjYyQzkuMzU2NTQgMS4zNjI4OSA5LjM4MDc0IDEuNzc5NTUgOS4xNjI4OSAyLjA3MzE2TDkuMDkwMjcgMi4xNTcyOEw0LjM0NDI3IDYuOTAzMjhDNC4wNzgwNCA3LjE2OTUxIDMuNjYxNDYgNy4xOTM3NSAzLjM2Nzg0IDYuOTc1OTlMMy4yODM3MiA2LjkwMzM5TDAuOTA5NzIzIDQuNTMwNEMwLjYxNjc2OCA0LjIzNzU2IDAuNjE2NjY4IDMuNzYyNjkgMC45MDk1IDMuNDY5NzNDMS4xNzU3MSAzLjIwMzQxIDEuNTkyMzcgMy4xNzkxMiAxLjg4NjAzIDMuMzk2OTFMMS45NzAxNiAzLjQ2OTUxTDMuODEzMDQgNS4zMTE3NUw4LjAyOTYxIDEuMDk2NjJaIiBmaWxsPSIjMDU4RkVGIj48L3BhdGg+PC9zdmc+';

export { TickSquareBase64 };

export default TickSquare;
