import React from 'react';
import { RocketShip } from '../../images';
import OnboardingWelcomeScreensModel from '../../utils/onboarding-welcome-screens-model';
import {
  ImageContainer, Title, Subtitle, TitleContainer,
  VideoIntroductionScreenContainer, VideoContainer,
} from './utils/styles';

const VideoIntroductionScreen = () => {
  const { title, subtitle, videoSrc } = OnboardingWelcomeScreensModel.video;

  return (
    <VideoIntroductionScreenContainer>
      <ImageContainer>
        <RocketShip />
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <VideoContainer>
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title="Tutorial Video - Shepherd"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
    </VideoIntroductionScreenContainer>
  );
};

export default VideoIntroductionScreen;
