import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { red7 } from '../../colours';
import ReactTooltip from '../tooltip/ReactTooltip';

const StyleModifier = styled.span`
  ${(props: any) => (props['data-is-overdue']
    ? `
    color: ${red7};
    `
    : '')}
`;

interface Props {
  children: ReactNode,
  isOverdue: boolean,
}

const TaskDueDateOverdueModifier = ({ children, isOverdue }: Props) => (
  <ReactTooltip tip="Overdue" disabled={!isOverdue} offset={{ top: 0, left: 9 }}>
    <StyleModifier data-is-overdue={isOverdue}>
      {children}
    </StyleModifier>
  </ReactTooltip>
);

export default TaskDueDateOverdueModifier;
