import styled, { css } from 'styled-components';
import {
  darkBlue1, gray1, gray10, gray9, layoutWhite,
} from '../../../../colours';
import { uiTextMedium } from '../../../../typography';
import { DARK_BUTTON } from './constants';
import { ButtonStyles } from './types';

interface ButtonContainerProps {
  color: ButtonStyles
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  all: unset;

  ${uiTextMedium}

  display: flex;
  align-items: center;
  justify-content: center;

  width: 190px;
  height: 45px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  ${({ color }) => {
    if (color === DARK_BUTTON) {
      return css`
        color: ${gray1};
        background-color: ${gray10};

        &:hover {
          background-color: ${gray9};
        }
      `;
    }

    // color === LIGHT_BUTTON
    return css`
      color: ${gray9};
      background-color: ${gray1};
      border: 2px solid ${darkBlue1};

      &:hover {
        background-color: ${layoutWhite};
      }
    `;
  }}
`;

export default ButtonContainer;
