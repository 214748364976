import React from 'react';
import { surface } from '../colours/index';

interface Props {
  fill?: string,
}

const CameraIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.42987 3.375H4.72812C2.80429 3.375 1.50012 4.66432 1.50012 6.516V11.7772C1.50012 13.6289 2.80429 14.9182 4.72812 14.9182H9.42987C11.2435 14.9182 12.5107 13.7666 12.6459 12.0802L14.6311 12.8916L14.7305 12.9279C15.5944 13.2067 16.4999 12.5646 16.4999 11.6366V6.65738L16.4959 6.55161C16.4277 5.64661 15.4904 5.05105 14.6314 5.40156L12.6459 6.21308C12.5112 4.5254 11.2465 3.375 9.42987 3.375ZM11.5329 7.02566C11.5324 7.03922 11.5324 7.05284 11.5329 7.06649V11.2268C11.5324 11.2404 11.5324 11.254 11.5329 11.2676V11.7772C11.5329 13.0027 10.732 13.7932 9.42987 13.7932H4.72812C3.42275 13.7932 2.62512 13.0047 2.62512 11.7772V6.516C2.62512 5.28854 3.42275 4.5 4.72812 4.5H9.42987C10.7348 4.5 11.5329 5.28883 11.5329 6.516V7.02566ZM12.6579 10.8697V7.42354L15.0567 6.44306L15.1076 6.42869C15.2434 6.40666 15.3749 6.51128 15.3749 6.65738V11.6366L15.369 11.6892C15.3381 11.8233 15.1918 11.9053 15.0564 11.8501L12.6579 10.8697Z" fill={fill} />
  </svg>
);

CameraIcon.defaultProps = {
  fill: surface,
};

export default CameraIcon;
