import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string,
}

const ArrowLeftMini = ({ fill }: Props) => (
  <svg width="7" height="13" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.54508 0.534139C7.79347 0.777638 7.81605 1.15868 7.61282 1.42718L7.54508 1.50411L1.93882 6.99992L7.54508 12.4957C7.79347 12.7392 7.81605 13.1203 7.61282 13.3888L7.54508 13.4657C7.2967 13.7092 6.90802 13.7313 6.63413 13.5321L6.55566 13.4657L0.454916 7.4849C0.206533 7.2414 0.183953 6.86037 0.387175 6.59186L0.454916 6.51493L6.55566 0.534139C6.82888 0.26629 7.27186 0.26629 7.54508 0.534139Z" fill={fill} />
  </svg>
);

ArrowLeftMini.defaultProps = {
  fill: surface,
};

export default ArrowLeftMini;
