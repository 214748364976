import { logAppEvent } from './eventLogger';
import { APP_FIELD, LOADED_EVENT } from './enums';

const logAppAnalytics = (
  userId: string,
  hasSidebarOpened: boolean,
) => {
  if (userId.length <= 0 || !hasSidebarOpened) return;

  const urlPath = window.location.pathname;
  const emptyPreviousMeetingPath = '/meeting//previousMeeting/';
  if (urlPath !== emptyPreviousMeetingPath) logAppEvent(APP_FIELD, LOADED_EVENT);
};

export default logAppAnalytics;
