import styled, { css } from 'styled-components';
import { blue6, gray1, gray3 } from '../../../../../../shared/colours';

interface MultiSelectInputContainerProps {
  hasInput: boolean
  isdropdownOpen: boolean
}

const MultiSelectInputContainer = styled.div<MultiSelectInputContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: 8px 12px;
  margin: 0;
  height: 40px;
  line-height: 20px;

  border-radius: 8px;
  border: ${({ isdropdownOpen }) => (isdropdownOpen ? css`2px solid ${blue6}` : css`2px solid ${gray3}`)};
  background-color: ${({ hasInput }) => (hasInput ? gray1 : gray3)};

  &:hover {
    cursor: default;
  }
`;

export default MultiSelectInputContainer;
