/* eslint-disable no-unused-vars */
import React, { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { signinPath, onboardingPath, dashboardPath } from '../../../routes';

interface RouteProps {
  exact?: boolean,
  path: string,
  component: ComponentType<any>,
  isAuthenticated: boolean,
  isOnboarded: boolean
}

const AuthenticatedRoute = ({
  exact, path, component, isAuthenticated, isOnboarded,
}:RouteProps) => {
  if (!isAuthenticated) {
    return (
      <Route exact={exact} path={path}>
        <Redirect to={signinPath} />
      </Route>
    );
  }

  // TODO Matt: Do we need this anymore?
  /*
  if (isOnboarded && path.includes(onboardingPath)) {
    return (
      <Route exact={exact} path={path}>
        <Redirect to={dashboardPath} />
      </Route>
    );
  }
  */

  if (!isOnboarded && !path.includes(onboardingPath)) {
    return (
      <Route exact={exact} path={path}>
        <Redirect to={onboardingPath} />
      </Route>
    );
  }

  return <Route exact={exact} path={path} component={component} />;
};

AuthenticatedRoute.defaultProps = {
  exact: false,
};

export default AuthenticatedRoute;
