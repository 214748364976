import React from 'react';
import styled from 'styled-components';
import ConfirmationModal from '../../../shared/components/Modal/ConfirmationModal';
import { header400 } from '../../../shared/typography';

export interface Props {
  isResponseModalOpen: boolean,
  closeConfModal: () => void,
  isFeedbackSent: boolean
}

const Text = styled.h2`
  ${header400};
  color: white;
  margin: 0;
`;

const NotificationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  z-index: 1000;
`;

const FeedbackResponseModal = ({ isResponseModalOpen, closeConfModal, isFeedbackSent }: Props) => (
  <ConfirmationModal isOpen={isResponseModalOpen} setModalClosed={closeConfModal}>
    <NotificationContainer>
      {
        isFeedbackSent
          ? (<Text>Feedback sent successfully!</Text>)
          : (<Text>Feedback sent unsuccessfully. Please try again.</Text>)
      }
    </NotificationContainer>
  </ConfirmationModal>
);

export default FeedbackResponseModal;
