import React from 'react';
import { gray10, gray1 } from '../../colours';
import QuickSettings from '../quick-settings';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import CloseSquareIcon from '../../icons/closeSquare';
import SendCloseSidebarWindowMessage from '../../../utils/sendWindowMessages/closeSidebar';
import {
  Container, LeftContainer, BetaSign, IconContainer,
} from './utils/styles';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBORD_VIEW } from './utils/constants';

// TODO Ivan: I think we don't need these components
// const Container = styled.div`
//   background-color: ${surface};
//   box-sizing: border-box;
//   height: 56px;
//   width: 100%;
//   padding: 16px 16px;
//   display: flex;
//   justify-content: space-between;
// `;

// const LeftContainer = styled.div`
//   display: flex;
//   align-items: center;

//   & > div:first-child {
//     margin-right: 128.5px;
//   }
// `;

// const BetaSign = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* padding: 5px; */
//   border-radius: 8px;
//   margin-left: 16px;
//   height: 20px;
//   width: 45px;

//   ${header200}
//   color: white;
//   background: ${blue6};
// `;

// const IconContainer = styled.button`
//   all: unset;

//   cursor: pointer;

//   &:hover {
//     opacity: 85%;
//   }
// `;

export interface Props {
  view?: string
}

const Header = ({ view = DEFAULT_HEADER_MEETING_VIEW }:Props) => {
  const handleCloseSidebarClick = () => {
    SendCloseSidebarWindowMessage();
  };

  const renderSettingsAndLogo = () => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBORD_VIEW) {
      return (
        <LeftContainer>
          <QuickSettings />
          <ShepherdLogo />
          <BetaSign>BETA</BetaSign>
        </LeftContainer>
      );
    }

    return null;
  };

  const iconColor = (
    view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBORD_VIEW ? gray1 : gray10
  );

  return (
    <Container view={view}>
      { renderSettingsAndLogo() }
      <IconContainer onClick={handleCloseSidebarClick}>
        <CloseSquareIcon width="24" height="24" fill={iconColor} />
      </IconContainer>
    </Container>
  );
};

Header.defaultProps = {
  view: DEFAULT_HEADER_MEETING_VIEW,
};

export default Header;
