import styled, { css } from 'styled-components';
import { header200 } from '../../../typography';
import { surface, blue6 } from '../../../colours';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';

interface ContainerProps {
  view: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  box-sizing: border-box;
  height: 48px;
  padding: 12px 16px;

  ${({ view }) => {
    if (view === 'meeting' || view === 'dashboard') {
      return css`
        justify-content: space-between;
        background-color: ${surface};
        width: 100%;
      `;
    }

    return css`
      position: absolute;
      top: 0;
      left: 0;
      width: ${SIDEBAR_WIDTH};
      justify-content: flex-end;
    `;
  }}
  
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 128.5px;
  }
`;

export const BetaSign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 16px;
  height: 20px;
  width: 45px;

  ${header200}
  color: white;
  background: ${blue6};
`;

export const IconContainer = styled.button`
  all: unset;

  cursor: pointer;

  &:hover {
    opacity: 85%;
  }
`;
