/* eslint-disable no-unused-vars */

import React, {
  ReactNode,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import ButtonSmall from '../button-small';
import {
  gray1, gray3, gray4, blue5, layoutWhite,
} from '../../colours';
import {
  header300, header500, header700, uiText, uiTextMedium,
} from '../../typography';
import { ResponseToMediaResources, MediaResources, MediaResource } from '../../logic/MediaSearchParams/types/index';
import SearchIcon from '../prosemirror/components/icons/magnifying-glass';
import Scrollbar from '../scrollbar';

/**
 * Helper function used to split resources in 2 arrays of identical lengths
 */
type ParityFilter = (element: any, index: number) => boolean;
type FilterEven = (parity: boolean) => ParityFilter;
const filterEven: FilterEven = (parity) => ((element, index) => !!(index % 2) === parity);

const MediaContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PaginationControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 8px;
  margin: 4px 16px 0 16px;
`;

const SearchWebContainer = styled.div`
  position: relative;
  margin: 0 0.75rem 0 0.75rem;
  margin-top: 1rem;
  overflow: hidden;
  background: ${gray3};
  border: 2px solid ${gray4};
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 175ms;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchWebInput = styled.input`
  outline: 0px solid transparent;
  outline-offset: 2px;
  border: 2px solid ${gray1};
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  flex-grow: 1;
  ${uiText}
`;

const SearchWebIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65rem;
  background: white;
  cursor: pointer;

  ::hover {
    background: ${layoutWhite};
  }
`;

const MediaResourcesContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 4px;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  
  padding: 0.25rem 0.85rem 0.25rem 0.85rem;
  /* margin-bottom: -52px; // TODO this is not perfect, fix padding y on parent instead */
  /* margin-top: 0.45rem; */
  border-radius: 0 0 24px 24px;

  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  ::-webkit-scrollbar { // chrome
    display: none;
  };
`;

const MediaResourceColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const MediaResourceContainer = styled.div`
  padding: 0.15rem 0.075rem 0.075rem 0.15rem;

  & > div {
    border-radius: 8px;
  }

  & > div > img {
    border-radius: 8px;
    object-fit: contain;
    width: 100%;
    cursor: pointer;

    :hover {
      border-radius: 8px;
      margin: -4px;
      border: 4px solid ${blue5};
    };
  };
`;

interface MediaPaginatorProps {
  hasPagination: boolean;
  // paginateNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // paginatePrevious: (e: React.MouseEvent<HTMLButtonElement>) => void;
  mediaFetcher: (rawInput: string) => Promise<any>;
  responseParser: ResponseToMediaResources<any>;
  mediaSelection: (resource: MediaResource) => void;
}

const MediaPaginator = ({
  hasPagination, mediaFetcher, responseParser, mediaSelection,
  // paginateNext, paginatePrevious
}: MediaPaginatorProps) => {
  const [rawText, setRawText] = useState<string>('');
  const [mediaResources, setMediaResources] = useState<MediaResources>([] as Array<MediaResource>);
  // async fetch request to populate the media resource
  useEffect(() => {
    mediaFetcher('')
      .then(responseParser)
      .then(setMediaResources)
      .catch(() => {});
    (document.querySelector('#shepherd-search-gif-input') as HTMLInputElement).focus();
  }, []);

  const mediaFetch = async (rawInput: string) => {
    const response: MediaResources = await mediaFetcher(rawInput).then(responseParser);
    setMediaResources(response);
  };

  const debouncedImageQuery = useDebouncedCallback(mediaFetch, 500);

  return (
    <MediaContentWrapper>
      <SearchWebContainer
        style={{
          marginBottom: '8px',
        }}
      >
        <SearchWebInput
          id="shepherd-search-gif-input"
          maxLength={99}
          onChange={(e) => {
            const input = e.target.value;
            setRawText(input);
            debouncedImageQuery(input);
          }}
          placeholder="Search"
        />
        <SearchWebIconContainer
          onClick={(e) => {
            mediaFetch(rawText);
          }}
        >
          <SearchIcon />
        </SearchWebIconContainer>
      </SearchWebContainer>
      <Scrollbar maxHeight="300px">
        <MediaResourcesContainer>
          <MediaResourceColumn>
            {
            mediaResources
              .filter(filterEven(true))
              .map(({ id, thumbnailURL, webformatURL }) => (
                <MediaResourceContainer>
                  <div
                    id={id}
                    key={id}
                  >
                    <img
                      src={webformatURL}
                      alt={id}
                      aria-hidden="true"
                      onClick={() => {
                        mediaSelection({ id, thumbnailURL, webformatURL } as MediaResource);
                      }}
                    />
                  </div>
                </MediaResourceContainer>
              ))
            }
          </MediaResourceColumn>
          <MediaResourceColumn>
            {
            mediaResources
              .filter(filterEven(false))
              .map(({ id, thumbnailURL, webformatURL }) => (
                <MediaResourceContainer>
                  <div
                    id={id}
                    key={id}
                  >
                    <img
                      src={webformatURL}
                      alt={id}
                      aria-hidden="true"
                      onClick={() => {
                        mediaSelection({ id, thumbnailURL, webformatURL } as MediaResource);
                      }}
                    />
                  </div>
                </MediaResourceContainer>
              ))
            }
          </MediaResourceColumn>
        </MediaResourcesContainer>
      </Scrollbar>
      <PaginationControlsWrapper style={{
        display: (hasPagination ? 'initial' : 'none'),
      }}
      >
        <ButtonSmall
          text="Previous"
          isOutline
          // onClick={(e) => paginatePrevious(e)}
        />
        <ButtonSmall
          text="Next"
          isOutline
          // onClick={(e) => paginateNext(e)}
        />
      </PaginationControlsWrapper>
    </MediaContentWrapper>
  );
};

export default MediaPaginator;
