import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface AddColumnAfterProps {
  fill?: string;
  width?: number;
  height?: number;
}

const AddColumnAfterIcon = ({
  fill,
  width,
  height,
}: AddColumnAfterProps) => (
  <svg width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 4.75,0 C 3.23122,0 2,1.23122 2,2.75 v 0.5 C 2,3.66421 2.33579,4 2.75,4 3.16421,4 3.5,3.66421 3.5,3.25 V 2.75 C 3.5,2.05964 4.05964,1.5 4.75,1.5 h 4 c 0.6904,0 1.2499997,0.55964 1.2499997,1.25 v 10 C 9.9999997,13.4404 9.4404,14 8.75,14 h -4 C 4.05964,14 3.5,13.4404 3.5,12.75 v -0.4375 c 0,-0.4142 -0.33579,-0.75 -0.75,-0.75 -0.41421,0 -0.75,0.3358 -0.75,0.75 V 12.75 c 0,1.5188 1.23122,2.75 2.75,2.75 h 4 c 1.5188,0 2.75,-1.2312 2.75,-2.75 v -10 C 11.5,1.23122 10.2688,0 8.75,0 Z m -2,5 C 2.33579,5 2,5.33579 2,5.75 V 7 H 0.75 C 0.33579,7 0,7.33579 0,7.75 0,8.16421 0.33579,8.5 0.75,8.5 H 2 v 1.25 c 0,0.4142 0.33579,0.75 0.75,0.75 0.41421,0 0.75,-0.3358 0.75,-0.75 V 8.5 H 4.75 C 5.16421,8.5 5.5,8.16421 5.5,7.75 5.5,7.33579 5.16421,7 4.75,7 H 3.5 V 5.75 C 3.5,5.33579 3.16421,5 2.75,5 Z"
      fill={fill}
    />
  </svg>
);

AddColumnAfterIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 16,
};

export default AddColumnAfterIcon;
