import { Keymap } from 'prosemirror-commands';
import { liftAny, sinkAny } from '../sink-lift';

export default {
  Tab(state, dispatch, view) {
    if (!view) return false;
    if (sinkAny(view)(state, dispatch)) {
      return true;
    }
    /**
     * This return prevents Tab from performing its
     * native behaviour. It is functionally incorrect
     * but keeps the user experience coherent.
     */
    return true;
  },
  'Shift-Tab': (state, dispatch, view) => {
    if (!view) return false;
    if (liftAny(view)(state, dispatch)) {
      return true;
    }
    /**
     * This return prevents Shift-Tab from performing its
     * native behaviour. It is functionally incorrect
     * but keeps the user experience coherent.
     */
    return true;
  },
} as Keymap;
