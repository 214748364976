import React from 'react';
import styled from 'styled-components';
import { AttendeeV2 } from '../../../../../shared/types/types';
import IconEmailMolecule from './IconEmailMolecule';

const TagContainer = styled.div`
  margin-bottom: 8px;
`;

interface Props {
  assignees: AttendeeV2[],
  /* eslint-disable no-unused-vars */
  handleRemove: (email: string) => void,
}

const IconEmailMolecules = ({ assignees, handleRemove }: Props) => {
  if (assignees.length === 0) return null;
  return (
    <>
      {
        assignees.map((attendee: AttendeeV2, index: number) => (
          <TagContainer key={attendee.data.email}>
            <IconEmailMolecule handleRemove={handleRemove} attendee={attendee} index={index} />
          </TagContainer>
        ))
      }
    </>
  );
};

export default IconEmailMolecules;
