import React, {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react';
import { TagInput } from 'reactjs-tag-input';
import styled from 'styled-components';
import CrossIcon from '../../icons/cross';
import { gray2, gray4, surface } from '../../colours';
import { messageText, uiText } from '../../typography';

export const Wrapper = styled.div`
  position: relative;
`;

export const tagStyle = `
  ${messageText};
  background: ${gray4};
  border-radius: 4px;
  color: ${surface};
  margin: 2px;
  padding: 2px 6px;
  flex-shrink: 0;
  max-width: 100%;
  box-sizing: border-box;
  line-break: anywhere;
  display: flex;
  align-items: center;
  letter-spacing: -0.08px;
  padding-right: 2px;
`;

export const inputStyle = `
  ${messageText};
  background: ${gray2};
  color: ${surface};
  cursor: pointer;
  display: inline-block;
  width: auto;
  flex-grow: 1;
  margin: 4px 2px;
`;

export const wrapperStyle = `
  ${messageText};
  background: ${gray2};
  border-radius: 10px
  box-shadow: none;
  color: ${surface};
  cursor: pointer;
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  width: 100%;
  position: unset;
  transform: unset;
  top: unset;
  left: unset;
  min-height: 36px
`;

export const DeleteIcon = CrossIcon({ fill: surface, width: 16, height: 16 });

export type InputValue = {
  index: number,
  displayValue: string,
}

export const isSpacePress = (event: any) => event?.target?.value.slice(-1) === ' ';

interface InputProps {
  values: InputValue[],
  setValues: Dispatch<SetStateAction<InputValue[]>>,
}

const MultiInput = ({ values, setValues }: InputProps) => {
  const TagInputRef = useRef<any>(null);
  useEffect(() => {
    // dirty hack https://github.com/leekevinyg/react-tag-input/blob/master/src/lib/TagInput.js
    TagInputRef.current.state.selectedTags = values;
    TagInputRef.current.forceUpdate(() => {
      TagInputRef.current.focusInput();
    });
  }, [values]);

  const handleSpacePress = (event: any) => {
    if (isSpacePress(event)) {
      const trimmedEmail = event?.target?.value.trim() ?? '';
      const email = {
        index: values.length,
        displayValue: trimmedEmail,
      };
      const addedValue = [...values, email];
      setValues(addedValue);
      // This is a hack to the lib source code
      // The lib `reactjs-tag-input` hasn't been update for 3 years,
      // I think it is not that bad to hack it
      // https://github.com/leekevinyg/react-tag-input/blob/master/src/lib/TagInput.js#L41
      TagInputRef.current.onInputKeyUp({ key: 'Enter', target: { value: trimmedEmail } });
    }
  };

  return (
    <Wrapper>
      <TagInput
        tags={[]}
        placeholder="Type email and press enter or space"
        onTagsChanged={setValues}
        tagStyle={tagStyle}
        tagDeleteStyle={`
            margin-left: 4px
            & > svg {
              width: ${DeleteIcon.props.width}px;
              display: flex;
              align-items: center;
            }
            &::placeholder {
              text-decoration: none
              ${uiText}
            }
          `}
        tagDeleteIcon={DeleteIcon}
        onInputChanged={(event: any) => handleSpacePress(event)}
        inputStyle={inputStyle}
        wrapperStyle={wrapperStyle}
        ref={TagInputRef}
      />
    </Wrapper>
  );
};

export default MultiInput;
