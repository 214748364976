import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface UndoProps {
  fill?: string;
  width?: number;
  height?: number;
}

const UndoIcon = ({
  fill,
  width,
  height,
}: UndoProps) => (
  <svg width={width} height={height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 4.06,12.219668 c 0,0.4142 0.336,0.75 0.75,0.75 h 4.125 c 2.693,0 4.875,-2.182601 4.875,-4.8750005 0,-2.6924 -2.182,-4.875 -4.875,-4.875 H 2.871 l 1.939,-1.9393 c 0.293,-0.2929 0.293,-0.7678 0,-1.0607 -0.293,-0.29289 -0.768,-0.29289 -1.06,0 l -3.75,3.75 3.75,3.75 c 0.292,0.2929 0.767,0.2929 1.06,0 0.293,-0.2929 0.293,-0.7678 0,-1.0607 l -1.939,-1.9393 h 6.064 c 1.864,0 3.375,1.511 3.375,3.375 0,1.864 -1.511,3.3750005 -3.375,3.3750005 H 4.81 c -0.414,0 -0.75,0.335799 -0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

UndoIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 13,
};

export default UndoIcon;
