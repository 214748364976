/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../App';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import ErrorPage from '../../shared/components/error-page';
import ErrorModal from '../../shared/components/errors/error-modal';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import ErrorImage from '../../shared/icons/ErrorImage';
import { toastInfo } from '../../utils/notifications';
import ConnectionLostPopup from '../all-pages/no-internet-modals/ConnectionLostModal';
import OnboardingMultiSelectDropdown from '../onboarding/personal/components/onboarding-multi-select-dropdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const MatthewTestingPage = () => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  useEffect(() => {
    console.log('User Data');
    console.log(userData);
    return () => { };
  }, [authData]);

  const handleClick1 = () => {
    toastInfo('Clicked', 'Button 1 clicked');
  };
  const handleClick2 = () => {
    toastInfo('Clicked', 'Button 2 clicked');
  };
  const handleClick3 = () => {
    toastInfo('Clicked', 'Button 3 clicked');
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
  };

  const ErrorModalData = {
    error: 'Trouble connecting to the server',
    errorDescription: `Please refresh and try again. If the problem 
persists, please reach out.`,
    action: 'Restart',
  };

  const ErrorModalFunctions = {
    handleModalClose: () => {},
    handleActionClick: () => {},
  };

  return (
    <Container />
  );
};

export default MatthewTestingPage;
