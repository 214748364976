import React from 'react';
import styled from 'styled-components';
import { error6 } from '../../../../../shared/colours';
import { uiTextMedium } from '../../../../../shared/typography';

const DeleteButton = styled.div`
  color: ${error6};
  background: none;
  ${uiTextMedium};
  cursor: pointer;
`;

interface Props {
  onClick: () => void,
  text: string,
}

const DeleteChatButtonAtom = ({ onClick, text }: Props) => (
  <DeleteButton onClick={onClick}>
    {text}
  </DeleteButton>
);

export default DeleteChatButtonAtom;
