import styled from 'styled-components';
import { gray2, surface } from '../../../../../../shared/colours';
import { link } from '../../../../../../shared/typography';

const MultiSelectDropdownOptionContainer = styled.li`
  margin: 0;

  ${link};
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: none;
  background: none;
  padding: 11px 16px;
  color: ${surface};
  cursor: pointer;

  &:hover {
    background: ${gray2};
  }
`;

export default MultiSelectDropdownOptionContainer;
