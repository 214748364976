import {
  DatabasePermissions,
  MeetingRole,
  SimpleUserData,
  UserPermissions,
} from '../../shared/types/types';
import { mapDatabaseUsersToUsers } from '../../utils/user/UserDataUtils';

const calculateUserPermissions = (
  userId: string,
  permissions: DatabasePermissions,
): UserPermissions => {
  const users = mapDatabaseUsersToUsers(permissions?.users);
  const roleFromPermissions = users.filter((user) => user.userId === userId)[0]
    ?.role;
  if (roleFromPermissions === 'admin') {
    return {
      canEdit: true,
      canEditTitle: true,
      canEditMeetingSeries: true,
      canEditLinkSettings: true,
      canAddUser: true,
      canAddAdmin: true,
      canAddModerator: true,
      canAddEditor: true,
      canAddCommenter: true,
      canAddViewer: true,
      canRemoveUser: true,
      canAddGroup: true,
      canDeleteMeeting: true,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'admin',
    };
  }
  if (roleFromPermissions === 'moderator') {
    return {
      canEdit: true,
      canEditTitle: true,
      canEditMeetingSeries: true,
      canEditLinkSettings: true,
      canAddUser: true,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: true,
      canAddCommenter: true,
      canAddViewer: true,
      canRemoveUser: true,
      canAddGroup: true,
      canDeleteMeeting: false,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'moderator',
    };
  }
  if (roleFromPermissions === 'editor') {
    return {
      canEdit: true,
      canEditTitle: true,
      canEditMeetingSeries: true,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'editor',
    };
  }
  if (permissions.linkPermissions === 'public_edit') {
    return {
      canEdit: true,
      canEditTitle: false,
      canEditMeetingSeries: false,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'public_edit',
    };
  }
  if (roleFromPermissions === 'commenter') {
    return {
      canEdit: false,
      canEditTitle: false,
      canEditMeetingSeries: false,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'commenter',
    };
  }
  if (roleFromPermissions === 'viewer') {
    return {
      canEdit: false,
      canEditTitle: false,
      canEditMeetingSeries: false,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: false,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'viewer',
    };
  }
  if (permissions.linkPermissions === 'public_view') {
    return {
      canEdit: false,
      canEditTitle: false,
      canEditMeetingSeries: false,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: false,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'public_view',
    };
  }
  if (permissions.linkPermissions === 'private') {
    // Got access through a group
    return {
      canEdit: true,
      canEditTitle: true,
      canEditMeetingSeries: true,
      canEditLinkSettings: false,
      canAddUser: false,
      canAddAdmin: false,
      canAddModerator: false,
      canAddEditor: false,
      canAddCommenter: false,
      canAddViewer: false,
      canRemoveUser: false,
      canAddGroup: false,
      canDeleteMeeting: false,
      canComment: true,
      canView: true,
      isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
      role: 'editor',
    };
  }
  return {
    canEdit: false,
    canEditTitle: false,
    canEditMeetingSeries: false,
    canEditLinkSettings: false,
    canAddUser: false,
    canAddAdmin: false,
    canAddModerator: false,
    canAddEditor: false,
    canAddCommenter: false,
    canAddViewer: false,
    canRemoveUser: false,
    canAddGroup: false,
    canDeleteMeeting: false,
    canComment: false,
    canView: false,
    isLastAdmin: calculateIfIsLastAdmin(roleFromPermissions, users),
    role: 'noAccess',
  };
};

const calculateIfIsLastAdmin = (role: MeetingRole, users: SimpleUserData[]) => {
  const numberOfAdmins = users.filter((user) => user.role === 'admin').length;
  return role === 'admin' && numberOfAdmins === 1;
};

export default calculateUserPermissions;
