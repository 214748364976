import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { functions } from '../../../../utils/firebase';
import { logEventInviteMembers } from '../../../../utils/analytics/eventLogger';
import { gray7 } from '../../../colours';
import SendIcon from '../../../icons/send';
import { defaultSmall } from '../../../typography';
import ButtonSmall from '../../button-small';
import ModalLabel from '../../Modal/ModalLabel';
import ModalFooterButtonsRightAlignedWrapper from '../../Modal/ModalFooterButtonsRightAlignedWrapper';
import Modal from '../../Modal';
import MultiInput from '../../multi-input';
import { toastSuccess } from '../../../../utils/notifications';

const Content = styled.div`
  padding: 30px 24px;

  p {
    ${defaultSmall};
    color: ${gray7};
    margin: 0 0 16px;
  }
`;

interface Props {
  emailList?: string[],
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
}

type InputValue = {
  index: number,
  displayValue: string,
}

const InviteMembersModal = ({
  isOpen, setIsOpen, emailList, setMembersInvited,
}: Props) => {
  const [values, setValues] = useState<InputValue[]>([]);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useIntercom();
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const emailListValues: InputValue[] | any = emailList?.map((email: string, index: number) => (
      { index, displayValue: email }
    ));
    setValues(emailListValues);
  }, [emailList]);

  const sendEmail = () => {
    setLoading(true);
    const emails = values.map(({ displayValue }) => ({ email: displayValue }));

    emails.map((email) => functions()
      .httpsCallable('sendInviteMembersEmails')({ emails: email })
      .then(() => {
        setValues([]);
        closeModal();
        setMembersInvited(true);
        toastSuccess('Success', 'Invite sent');
        logEventInviteMembers(emails.length, trackEvent);
      })
      .catch((error) => console.log('Error sending email', error)) // TODO: Add sentry
      .finally(() => setLoading(false)));
  };

  return (
    <Modal isOpen={isOpen} setModalClosed={closeModal} title="Invite members to Shepherd">
      <div>
        <Content>
          <p>Enter the emails of people you’d like to use Shepherd</p>
          <ModalLabel text="Email recipients" />
          <MultiInput values={values} setValues={setValues} />
        </Content>
        <ModalFooterButtonsRightAlignedWrapper>
          <ButtonSmall onClick={closeModal} text="Cancel" isOutline />
          <ButtonSmall type="submit" onClick={sendEmail} text="Send" hasLeftIcon Icon={SendIcon} loading={loading} isDisabled={values.length === 0} />
        </ModalFooterButtonsRightAlignedWrapper>
      </div>
    </Modal>
  );
};

InviteMembersModal.defaultProps = {
  emailList: [],
};

export default InviteMembersModal;
