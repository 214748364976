import React, {
  Dispatch, SetStateAction,
} from 'react';
import { MeetingSections, Shortcut, TaskItem } from '../../../shared/types/types';
import { MEETING_PAGE, TASK } from '../../../utils/enums';
import Tasks from '../../../shared/components/tasks';
import Notes from '../notes';
import TemplatesView from '../templates';

interface Props {
  tab: MeetingSections,
  userId: string,
  meetingId: string,
  setNotesContent: Dispatch<SetStateAction<string>>,
  setIsSynchronized: Dispatch<SetStateAction<boolean>>,
  taskItems: TaskItem[],
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>
  secretChatId: string,
}

const TabBody = ({
  tab,
  userId,
  meetingId,
  setNotesContent,
  setIsSynchronized,
  taskItems,
  setCreateTaskModalOpen,
  isTemplatesOpen,
  setIsTemplatesOpen,
  onShortcutTrigger,
  setProseMirrorEditorView,
  secretChatId,
}: Props) => {
  if (tab === TASK) {
    return (
      <Tasks
        taskItems={taskItems}
        page={MEETING_PAGE}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        disableGoToMeeting
      />
    );
  }

  return (
    <>
      {isTemplatesOpen && (
        <TemplatesView
          tab={tab}
          setIsTemplatesOpen={setIsTemplatesOpen}
        />
      )}
      <Notes
        page={tab}
        userId={userId}
        meetingId={meetingId}
        setNotesContent={setNotesContent}
        setIsSynchronized={setIsSynchronized}
        setProseMirrorEditorView={setProseMirrorEditorView}
        onShortcutTrigger={onShortcutTrigger}
        displayNotes={!isTemplatesOpen}
        secretChatId={secretChatId}
      />
    </>
  );
};

export default TabBody;
