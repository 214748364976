import React, {
  Dispatch, KeyboardEventHandler, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../App';
import { dbSaveSecretNotes, dbUpdateSecretChatPath } from '../../../../../database/firebaseSecretChatAPI';
import ButtonSmall from '../../../../../shared/components/button-small';
import Modal from '../../../../../shared/components/Modal';
import { SingleButtonModalFooterdWrapper } from '../../../../../shared/components/Modal/ModalFooterWrapper';
import SearchableAssign from '../../../../../shared/components/searchable-assign';
import PaperLockIcon from '../../../../../shared/icons/PaperLockIcon';
import {
  AssigneeV2, SDate, SecretChatAttendeeV2,
} from '../../../../../shared/types/types';
import { dateISOObject } from '../../../../../utils/dateUtils/date';
import { mapAssigneeV2ToSecretChatAttendeeV2, mapAttendeeV2ToSecretChatAttendeeV2, mapSecretChatAttendeeV2ToAttendeeV2 } from '../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import MeetingDataContext from '../../../context/MeetingDataContext';
import { ModalLineLocal } from '../../../modal/ShareNotesModal';
import NameInputAtom from '../add-members-modal/NameInputAtom';
import AccessAtom from './AccessAtom';
import ExplanationTextAtom from './ExplanationAtom';
import IconEmailMolecules from './IconEmailMolecules';
import { createSecretChatObject } from './secretModalUtils';

const Container = styled.div`
  padding: 24px 24px 8px 24px;
  span:first-child {
    margin-bottom: 18px;
  }
  span:nth-child(1) {
    margin-bottom: 16px;
  }

  span:nth-child(3) {
    margin-bottom: 8px;
  }
`;

export const NameInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  :first-child {
    margin-bottom: 8px;
  }

  input {
    margin-bottom: 20px;
  }
`;

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  setSecretChatId: Dispatch<SetStateAction<string>>,
}

const SecretChatModal = ({
  isOpen, setClosed, setSecretChatId,
}: Props) => {
  const [participants, setParticipants] = useState<SecretChatAttendeeV2[]>([]);
  const [title, setTitle] = useState<string>('');
  const meetingData = useContext(MeetingDataContext);
  const authData = useContext(AuthContext);
  const attendees = meetingData.attendees.attendees.filter(
    (attendee) => attendee.userId.length !== 0,
  ) ?? [];
  const searchableAttendees = attendees.filter(
    (attendee) => attendee.data.email !== authData.email,
  );
  const currentUser = attendees.find((attendee) => attendee.userId === authData.userId);
  const userData = useContext(UserDataContext);

  const onAssigneeChange = (assignee: AssigneeV2) => {
    if (assignee.data.email.length === 0) return;
    const properAssignee = mapAssigneeV2ToSecretChatAttendeeV2(assignee);
    if (participants.filter(
      (tag) => tag.data.email === properAssignee.data.email,
    ).length > 0) return;
    setParticipants((prevState) => [...prevState, properAssignee]);
  };

  const handleRemove = (email: string) => {
    const tempArray = participants.filter((participant) => participant.data.email !== email);
    setParticipants(tempArray);
  };

  useEffect(() => {
    if (participants.some(
      (participant) => participant?.data?.email === authData.email,
    )) return;
    if (typeof currentUser !== 'undefined') {
      const convertedCurrentUser = mapAttendeeV2ToSecretChatAttendeeV2(currentUser);
      setParticipants((prevState) => [...prevState, convertedCurrentUser]);
    }
  }, [isOpen]);

  const handleOnCreateClick = () => {
    const created: SDate = dateISOObject();
    const userIds = participants.map((participant) => participant.userId);

    const secretChat = createSecretChatObject(
      title,
      '',
      participants,
      meetingData.meetingId,
      userIds,
      created,
      created,
      userData.publicUserData,
    );
    dbSaveSecretNotes(secretChat, meetingData.meetingId, saveChatPath);
    setParticipants([]);
    setClosed();
  };

  const handleClose = () => {
    setParticipants([]);
    setClosed();
  };

  const saveChatPath = (chatId: string) => {
    if (chatId.length === 0) return;
    setSecretChatId(chatId);
    const suffix = meetingData.version === 3 ? '_Prosemirror' : '_Firepad';
    const path = `secretNotes/${chatId}${suffix}`;
    dbUpdateSecretChatPath(
      meetingData.meetingId,
      chatId,
      path,
    );
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleOnCreateClick();
    }
  };

  if (searchableAttendees.length === 0) return null;
  return (
    <Modal
      title="Create new Private Note"
      isOpen={isOpen}
      setModalClosed={handleClose}
      icon={<PaperLockIcon />}
      isScrollbarEnabled={false}
    >
      <Container onKeyDown={handleKeyDown}>
        <ExplanationTextAtom />
        <NameInputContainer>
          <AccessAtom text="Name your Private Note" />
          <NameInputAtom setTitle={setTitle} placeholder="Type title here" />
        </NameInputContainer>
        <AccessAtom text="Add others" />
        <SearchableAssign
          candidates={searchableAttendees}
          selectedEmail=""
          onAssigneeChange={onAssigneeChange}
        />
        <IconEmailMolecules
          handleRemove={handleRemove}
          assignees={participants.map(mapSecretChatAttendeeV2ToAttendeeV2)}
        />
      </Container>
      <ModalLineLocal />
      <SingleButtonModalFooterdWrapper>
        <ButtonSmall text="Create" onClick={handleOnCreateClick} />
      </SingleButtonModalFooterdWrapper>
    </Modal>
  );
};

export default SecretChatModal;
