import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { darkBlue1, gray9 } from '../../../../shared/colours';
import ButtonSmall from '../../../../shared/components/button-small';
import Modal from '../../../../shared/components/Modal';
import Scrollbar from '../../../../shared/components/scrollbar';
import SelectTaskItem from '../../../../shared/components/task-card/SelectTaskCard';
import { TaskItem } from '../../../../shared/types/types';
import { header300, header400, uiText } from '../../../../shared/typography';
import CheckIconsAtom from '../../../userCenter/meetingDashboard/meetingCard/CheckIconsAtom';

const Container = styled.div`
 padding: 16px 24px 4px 24px ;
 height: 450px;
`;

const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
  margin-top: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const HeadText = styled.span`
  ${header300}
  color: ${gray9};
`;

const NoTaskAvailable = styled.span`
  ${header400}
  text-align: center;
  color: ${gray9};
`;

const SelectAllContainer = styled.div`
  display: flex;
  margin: 10px 0px 8px 0px; 
`;

const CheckBoxContainer = styled.div`
  cursor: pointer;
`;

const SelectAllText = styled.span`
  ${uiText}
  margin: 0px 0px 0px 16px;
`;

interface Props {
  taskItems:TaskItem[],
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>
  isOpen: boolean,
  setModalClosed: ()=> void,
}

export default function SelectTaskModal({
  isOpen, setModalClosed, taskItems, setTaskToSend,
}:Props) {
  const [selectAllSelected, setSelectAllEnabled] = useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<TaskItem[]>([]);
  const handleUpdate = () => {
    setTaskToSend(selectedTasks);
    closeModal();
  };

  const closeModal = () => {
    setSelectAllEnabled(false);
    setSelectedTasks([]);
    setModalClosed();
  };

  const handleSelectAll = () => {
    setSelectAllEnabled(!selectAllSelected);
    setSelectedTasks(taskItems);
  };

  const handleSelect = (taskItem: TaskItem) => {
    const isTaskSelected = selectedTasks.filter((task) => task.taskId === taskItem.taskId).length;
    if (isTaskSelected) {
      const filteredTasks = selectedTasks.filter((task) => task.taskId !== taskItem.taskId);
      setSelectedTasks(filteredTasks);
    } else {
      setSelectedTasks([...selectedTasks, taskItem]);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={closeModal} title="Select tasks to export">
        <Scrollbar maxHeight="100vh">
          <Container>
            {taskItems.length
              ? <HeadText>Select tasks from this meeting to export to Trello</HeadText>
              : <NoTaskAvailable>No task available </NoTaskAvailable>}
            {taskItems.length > 0
            && (
              <SelectAllContainer>
                <CheckBoxContainer onClick={handleSelectAll}>
                  <CheckIconsAtom isChecked={selectAllSelected} />
                </CheckBoxContainer>
                <SelectAllText>Select all</SelectAllText>
              </SelectAllContainer>
            )}
            <SelectTaskItem
              selectAll={selectAllSelected}
              onSelectClick={handleSelect}
              taskItems={taskItems}
            />
          </Container>
        </Scrollbar>
        <ModalLineLocal />
        <ButtonRow>
          <ButtonSmall onClick={() => closeModal()} text="Cancel" isOutline />
          {taskItems.length > 0 && <ButtonSmall onClick={() => handleUpdate()} text="Save" /> }
        </ButtonRow>
      </Modal>
    </>
  );
}
