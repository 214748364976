import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../shared/colours';
import { messageText } from '../../../shared/typography';
import { EmailRecipient } from './ShareNotesModal';

const Container = styled.div`
  cursor: pointer;
`;

const Label = styled.div`
  ${messageText};
  color: ${blue6};
`;

export interface AddRemoveProps {
  emailRecipients: EmailRecipient[],
  handleAddEmails: () => void,
  handleRemoveEmails: () => void,
}

const AddRemoveEmailsButton = ({
  emailRecipients,
  handleAddEmails,
  handleRemoveEmails,
}: AddRemoveProps) => {
  const isEmailsInputEmpty = emailRecipients.length === 0;
  if (isEmailsInputEmpty) {
    return (
      <Container onClick={handleAddEmails}>
        <Label>Add all recipients</Label>
      </Container>
    );
  }
  return (
    <Container onClick={handleRemoveEmails}>
      <Label>Remove all</Label>
    </Container>
  );
};

export default AddRemoveEmailsButton;
