import React from 'react';
import ButtonSmall from '../button-small';
import Modal from '../Modal';
import ModalBodyWrapper from '../Modal/ModalBodyWrapper';
import NewFeatureModalFeatureMol from './NewFeatureModalFeatureMol';
import { NewFeature } from '../../types/types';
import ModalFooterButtonsRightAlignedWrapper from '../Modal/ModalFooterButtonsRightAlignedWrapper';
import FireworkConfetti from '../../confetti/FireworkConfetti';
import Scrollbar from '../scrollbar';

export interface NewFeaturesModalOrgProps {
  isOpen: boolean,
  onClose: () => void;
  features: NewFeature[],
}

const NewFeaturesModalOrg = ({ isOpen, onClose, features }: NewFeaturesModalOrgProps) => {
  if (features.length === 0) return null;

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={handleOnClose} title="🥳 New Features 🥳">
        <Scrollbar maxHeight="70vh">
          <ModalBodyWrapper>
            {features.map((feature, index) => (
              <NewFeatureModalFeatureMol
                feature={feature}
                isExpandable={index !== 0}
                isLast={index === features.length - 1}
                key={feature.id}
              />
            ))}
          </ModalBodyWrapper>
        </Scrollbar>
        <ModalFooterButtonsRightAlignedWrapper>
          <ButtonSmall text="Got it" onClick={handleOnClose} />
        </ModalFooterButtonsRightAlignedWrapper>
      </Modal>
      <FireworkConfetti shouldTrigger={isOpen} />
    </>
  );
};

export default NewFeaturesModalOrg;
