import styled, { css, keyframes } from 'styled-components';
import { DEFAULT_SPACING_SIZE, SPACING_TO_LOGO_RATIO } from './constants';

// animations

export const fadeInKeyFrame = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// styling

interface ShepherdLogoContainerProps {
  width?: number;
  fadeIn: boolean;
}

export const ShepherdLogoContainer = styled.div<ShepherdLogoContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${({ width }) => (width ? (css`column-gap: ${(width * SPACING_TO_LOGO_RATIO)}px;`) : (css`column-gap: ${DEFAULT_SPACING_SIZE}px;`))};

  ${({ fadeIn }) => (fadeIn && (css`
    animation: ${fadeInKeyFrame} 1.5s ease-out forwards;
  `))};
`;
