import React from 'react';
import GitInfo from 'react-git-info/macro';
import styled from 'styled-components';
import Modal from '../../Modal/index';
import ButtonSmall from '../../button-small';
import { messageText } from '../../../typography';
import HorizontalLine from '../../../icons/horizontalLine';
import InformationItemAtom from './InformationItemAtom';
import { getNiceDate } from '../../../../utils/dateUtils/date';

const TextContainer = styled.div`
  margin: 24px;
  ${messageText};

  & > div {
    margin-bottom: 12px;
  }
`;

const LineContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

export interface FeedbackModalProps {
  isOpen: boolean,
  setModalClosed: () => void
}

function AboutModal({ isOpen, setModalClosed }: FeedbackModalProps) {
  const gitInfo = GitInfo();
  const domain = getDomain();
  const commitDateNicelyFormatted = getNiceDate(new Date(gitInfo.commit.date));
  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={setModalClosed} title="About">
        <TextContainer>
          <InformationItemAtom title="Domain" value={domain} />
          <InformationItemAtom title="Last Updated" value={commitDateNicelyFormatted} />
          <InformationItemAtom title="Branch" value={gitInfo?.branch ?? ''} />
          <InformationItemAtom title="Commit Message" value={gitInfo.commit.message} />
          <InformationItemAtom title="Commit Hash" value={gitInfo.commit.shortHash} />
        </TextContainer>
        <LineContainer>
          <HorizontalLine />
        </LineContainer>
        <ButtonRow>
          <ButtonSmall onClick={setModalClosed} text="Close" isOutline />
        </ButtonRow>
      </Modal>
    </>
  );
}

export default AboutModal;

const getDomain = () => {
  const { hostname } = window.location;
  return hostname;
};
