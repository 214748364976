/* eslint-disable no-unused-vars */
import React from 'react';
import DangerIcon from '../../../icons/DangerIcon';
import {
  Container, ModalContainer, IconContainer, TextContainer,
  ErrorTitle, ErrorDescription, ErrorAction,
} from './utils/styles';
import { ErrorModalData, ErrorModalFunctions } from './utils/types';

interface ErrorModalProps {
  isOpen: boolean;
  data: ErrorModalData;
  functions: ErrorModalFunctions;
}

const ErrorModal = ({
  isOpen,
  data,
  functions,
}:ErrorModalProps) => {
  const { error, errorDescription, action } = data;
  const { handleModalClose, handleActionClick } = functions;

  if (!isOpen) return null;

  return (
    <Container>
      <ModalContainer>
        <IconContainer>
          <DangerIcon />
        </IconContainer>
        <TextContainer>
          <ErrorTitle>{error}</ErrorTitle>
          <ErrorDescription>{errorDescription}</ErrorDescription>
          <ErrorAction onClick={handleActionClick}>{action}</ErrorAction>
        </TextContainer>
      </ModalContainer>
    </Container>
  );
};

export default ErrorModal;
