import React from 'react';
import { darkBlue2 } from '../colours';

interface Props {
  fill?: string,
}

const SpotSeparator = ({ fill }: Props) => (
  <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1" cy="1" r="1" fill={fill} />
  </svg>
);

SpotSeparator.defaultProps = {
  fill: darkBlue2,
};

export default SpotSeparator;
