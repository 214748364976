import React from 'react';
import { mapIndexToWelomeScreen } from '../../utils/functions';
import { ScreensNavigationData } from '../../utils/types';
import { Container, DotsContainer, Dot } from './utils/styles';

interface Props {
  data: ScreensNavigationData
}

const WelcomeScreensPageIndicator = ({ data }: Props) => {
  const { activeScreen, totalScreens } = data;

  const renderDots = () => (
    [...Array(totalScreens)].map((v, i) => (
      <Dot active={mapIndexToWelomeScreen(i) === activeScreen} />
    ))
  );

  return (
    <Container>
      <DotsContainer>{renderDots()}</DotsContainer>
    </Container>
  );
};

export default WelcomeScreensPageIndicator;
