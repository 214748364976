import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { header200, uiTextMedium } from '../../../../shared/typography';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import { blue6, darkBlue4 } from '../../../../shared/colours';
import { slackAuthUrl } from '../../../../utils/slack/slackUtils';
import NotionNotIntegrated from '../NotionModalComponents/NotionNotIntegrated';
import { IntegrationsTabView } from '../../../../shared/types/types';
import TrelloNotIntegrated from '../TrelloModalComponents/TrelloNotIntegrated';
import AsanaIcon from '../../../../shared/img/AsanaIcon.png';
import GoogleDocsIcon from '../../../../shared/img/Google-Docs-Logo.png';
import { SLACK_PROCESSING_VIEW } from '../../../../utils/constants';
import { JiraIcon, SlackIcon } from '../../../../shared/icons/integration-icons';

export const IntegrationsContainer = styled.div``;

export const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div:not(:last-child){
  margin-bottom: 8px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IntegrationText = styled.h2`
  ${header200};
  margin-left: 16px;
`;

export const Logo = styled.img`
  height: 28px;
  width: 28px;
`;

export const EnableButton = styled.button`
  background: none;
  border: none;
  color: ${blue6};
  ${uiTextMedium};
  cursor: pointer;
`;

export const CrossIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ComingSoon = styled.h2`
  ${uiTextMedium};
  color: ${darkBlue4};
`;

export const IntegrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface Props {
  isSlackEnabled: boolean,
  isNotionIntegrated: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
  isTrelloIntegrated: boolean,
  setIsIntegratingSlack: Dispatch<SetStateAction<boolean>>,
}

const Integrations = ({
  isSlackEnabled, isNotionIntegrated, isTrelloIntegrated, setView, setIsIntegratingSlack,
}: Props) => {
  const handleSlackButtonClick = () => {
    try {
      window.open(slackAuthUrl);
      setView(SLACK_PROCESSING_VIEW);
      setIsIntegratingSlack(true);
    } catch (error) {
      console.log(error);
    }
  };

  /* TODO Asish: if IntegrationsContainer is just an div, then use a div
   * and not a styled component
   *-------------------------reply---> IntegrationsContainer had some styling so went with a
   *                                   styled component
  */
  return (
    <IntegrationsContainer>
      {!isSlackEnabled && (
        <OuterContainer>
          <IntegrationContainer>
            <InnerContainer>
              <SlackIcon width="28" />
              <IntegrationText>Slack (Beta)</IntegrationText>
            </InnerContainer>
            <InnerContainer>
              <CrossIconContainer onClick={handleSlackButtonClick}>
                <EnableCrossIcon />
                <EnableButton>Enable</EnableButton>
              </CrossIconContainer>
            </InnerContainer>
          </IntegrationContainer>
        </OuterContainer>
      )}
      <OuterContainer>
        <NotionNotIntegrated enable={isNotionIntegrated} setView={setView} />
        <TrelloNotIntegrated enable={isTrelloIntegrated} setView={setView} />
        <IntegrationContainer>
          <InnerContainer>
            <Logo src={AsanaIcon} />
            <IntegrationText>Asana</IntegrationText>
          </InnerContainer>
          <InnerContainer>
            <ComingSoon>Coming soon</ComingSoon>
          </InnerContainer>
        </IntegrationContainer>
        <IntegrationContainer>
          <InnerContainer>
            <Logo src={GoogleDocsIcon} />
            <IntegrationText>Google docs</IntegrationText>
          </InnerContainer>
          <InnerContainer>
            <ComingSoon>Coming soon</ComingSoon>
          </InnerContainer>
        </IntegrationContainer>
        <IntegrationContainer>
          <InnerContainer>
            <JiraIcon width="28" />
            <IntegrationText>Jira</IntegrationText>
          </InnerContainer>
          <InnerContainer>
            <ComingSoon>Coming soon</ComingSoon>
          </InnerContainer>
        </IntegrationContainer>
      </OuterContainer>
    </IntegrationsContainer>
  );
};

export default Integrations;
