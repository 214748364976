import React, { useContext } from 'react';
import styled from 'styled-components';
import MeetingDataContext from './context/MeetingDataContext';
import { darkBlue4 } from '../../shared/colours';
import { small } from '../../shared/typography';
import { getDayAndMonth, getHourAndMinutes, getWeekday } from '../../utils/dateUtils/date';
import SpotSeparator from '../../shared/icons/SpotSeparator';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  ${small};
  color: ${darkBlue4};

  & > span:not(:last-of-type) {
    margin-right: 8px;
  }
`;

const SpotContainer = styled.div`
  margin-right: 8px;
`;

const StartAndEndTime = () => {
  // Example output: Friday  4 June  09:30 - 10:30
  const meetingData = useContext(MeetingDataContext);
  if (!meetingData.date.start.date) return null;
  const weekday = getWeekday(meetingData.date.start.date);
  const dayAndMonth = getDayAndMonth(meetingData.date.start.date);
  const startHourAndMinutes = getHourAndMinutes(meetingData.date.start.date);
  const endHourAndMinutes = getHourAndMinutes(meetingData.date.end.date);
  return (
    <Container>
      <span>{weekday}</span>
      <SpotContainer>
        <SpotSeparator />
      </SpotContainer>
      <span>{dayAndMonth}</span>
      <SpotContainer>
        <SpotSeparator />
      </SpotContainer>
      <span>
        {startHourAndMinutes}
        {' '}
        -
        {' '}
        {endHourAndMinutes}
      </span>
    </Container>
  );
};

export default React.memo(StartAndEndTime);
