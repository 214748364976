import * as Sentry from '@sentry/react';
import { GapiMeetingData } from '../../shared/types/types';
import { logCouldntFindMeeting } from './GoogleCalendarAPI';
import { mapGapiInsertMeetingResponseToGoogleMeetingIdsObj, RejectedGoogleMeetingIdsObj } from './GoogleCalendarUtils';

declare let gapi: any;
// gapi = Google API

export const gapiListUpcomingEvents = () => {
  gapi.client.calendar.events.list({
    calendarId: 'primary',
    timeMin: (new Date()).toISOString(),
    showDeleted: false,
    singleEvents: true,
    maxResults: 10,
    orderBy: 'startTime',
  }).then((response: any) => {
    console.log('Got list of upcoming events');
    console.log(response); // look in response.result.items for events
    return response.result.items;
  });
};

export const gapiCoreGetMyCalendarIds = async () => gapi.client.calendar.calendarList.list({})
  .then((response: any) => response.result.items.map((calendar: any) => calendar.id),
    (err: any) => { console.error('Execute error in gapiCoreGetMyCalenarIds', err); });

export const gapiCoreGetMeetingsInPeriod = async (minTime: string, maxTime: string) => {
  const calendarIds = await gapiCoreGetMyCalendarIds();
  const promises = await calendarIds
    .map(
      (calendarId: string) => gapiCoreGetMeetingsInCalendarInPeriod(
        calendarId, minTime, maxTime,
      ),
    );
  const tempResult = await Promise.all(promises).then((val) => val);
  const result = tempResult.flat();
  return result;
};

const gapiCoreGetMeetingsInCalendarInPeriod = async (
  calendarId: string,
  minTime: string,
  maxTime: string,
): Promise<string> => gapi.client.calendar.events.list({
  calendarId,
  timeMin: minTime,
  timeMax: maxTime,
  showDeleted: false,
  singleEvents: true,
  maxResults: 100,
  orderBy: 'startTime',
}).then((response: any) => response.result.items);

type ResultType = {
  result: GapiMeetingData
}

export const gapiCoreGetMeeting = async (eventId: string, calendarId: string = 'primary', number: number = 0) => gapi.client.calendar.events.get({
  calendarId,
  eventId,
})
  .then(({ result }: ResultType) => {
    console.log('Got GAPI meeting Data');
    console.log(result);
    return result;
  })
  .catch((error: any) => {
    logCouldntFindMeeting(eventId, calendarId, number);
    const scope = new Sentry.Scope();
    scope.setTag('number', number);
    Sentry.captureException(error, scope);
    throw error;
  });

export const gapiInsertMeetingCore = async (
  event: any,
) => gapi.client.calendar.events.insert({
  calendarId: 'primary',
  resource: event,
}).then((response: any) => mapGapiInsertMeetingResponseToGoogleMeetingIdsObj(response))
  .catch((error: any) => {
    console.log('Error in gapiInsertMeeting', error);
    return RejectedGoogleMeetingIdsObj;
  });
