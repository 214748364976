import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const Title = styled.h2`
  color: ${surface};
  ${uiTextMedium};
`;

interface Props {
  text: string,
}

const MeetingTitleAtom = ({ text }: Props) => (
  <Title>{text}</Title>
);

export default MeetingTitleAtom;
