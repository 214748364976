import React from 'react';
import styled from 'styled-components';
import RecurringMeetingIcon from '../../icons/RecurringMeetingIcon';
import ReactTooltip from '../tooltip/ReactTooltip';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  isRecurringMeeting: boolean;
}
const IsRecurringMeetingIconAtom = ({ isRecurringMeeting }: Props) => (
  <ReactTooltip place="top" tip="Recurring meeting" delay>
    <IconContainer>
      {isRecurringMeeting && <RecurringMeetingIcon />}
    </IconContainer>
  </ReactTooltip>

);

export default IsRecurringMeetingIconAtom;
