/* eslint-disable no-unused-vars */
import React, {
  useContext, Dispatch, SetStateAction,
  useState, useEffect,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../App';
import {
  Assignee, AssigneeV2, DatabaseTaskItem, PublicUserData, TaskItem,
} from '../../types/types';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';
import TaskModalFooterButtons from './TaskModalFooterButtons';
import TaskModalCalendar from './TaskModalCalendar';
import TaskTitleComponent from './TaskTitle';
import { getAssigneeCandidatesList } from '../../../utils/tasks/tasksUtils';
import { cfGetAssigneeDataByEmails } from '../../../database/cloudFunctionEmailAPI';
import TaskStatusDropdown from './TaskStatusDropdown';
import TaskDescription from './TaskDescription';
import CreatorComponent from './CreatorComponent';
import TaskDetailsMeetingComponent from './TaskDetailsMeetingComponent';
import PrivateTaskToggle from './PrivateTaskToggle';
import SearchableAssign from '../searchable-assign';
import ModalBodyWrapper from '../Modal/ModalBodyWrapper';
import TrelloToggle from './TrelloToggle';
import { getDbUpdateStatusHandler } from '../task-card/TaskUpdateHandlerFactory';
import { removeDuplicates } from '../../../utils/user/UserDataUtils';

export interface TaskModalContentProps {
  taskModalVersion: 'create' | 'edit',
  taskItem: TaskItem,
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  setModalClosed: () => void,
  // eslint-disable-next-line no-unused-vars
  onTitleChange: (title: string) => void,
  // eslint-disable-next-line no-unused-vars
  onDescriptionChange: (description: string) => void,
  // eslint-disable-next-line no-unused-vars
  onDueDateChange: (dueDate: any) => void,
  // eslint-disable-next-line no-unused-vars
  onAssigneeChange: (assignee: AssigneeV2) => void,
  // eslint-disable-next-line no-unused-vars
  onPrivateTaskChange: (value: any) => void
  // eslint-disable-next-line no-unused-vars
  onConfirmClick: () => void,
  // eslint-disable-next-line no-unused-vars
  openDeleteModal?: () => void,
  setTrelloToggleChange?: Dispatch<SetStateAction<boolean>>,
  loading: boolean,
  confirmButtonName: string,
  trelloChecked?: boolean,
  trelloDisabled?: boolean,
  canUpdate?: boolean
  disableAssigneeSelect?: boolean,
  disableCalendarSelect?: boolean,
}

const TaskModalContent = ({
  taskModalVersion, trelloChecked = false, trelloDisabled = false,
  setTrelloToggleChange = () => { }, taskItem, canUpdate,
  setModalClosed, onTitleChange, onDescriptionChange,
  onDueDateChange, onAssigneeChange, onPrivateTaskChange,
  onConfirmClick, loading,
  setTaskItem, confirmButtonName,
  disableAssigneeSelect, disableCalendarSelect,
  openDeleteModal,
}: TaskModalContentProps) => {
  const authData = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const userData = useContext(UserDataContext);
  const [assigneeCandidates, setAssigneeCandidates] = useState<AssigneeV2[]>([]);
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  // useEffect(() => {
  //   const candidatesEmails: string[] = removeDuplicates(
  //     [
  //       ...meetingData.data.attendees.map((attendee) => attendee.email),
  //       ...userData.friendList.map((friend: PublicUserData) => friend.email),
  //       authData.email,
  //     ],
  //   );
  //   cfGetAssigneeDataByEmails(candidatesEmails).then((result: any) => {
  //     console.log('%cFriend list', 'color: red;');
  //     console.log(result);
  //     const candidates: AssigneeV2[] = getAssigneeCandidatesList(result.data, candidatesEmails);
  //     console.log(candidates);
  //     setAssigneeCandidates(candidates);
  //   });
  // }, []); // Are we sure the meetingData and userData should not be in the change list?

  useEffect(() => {
    if (userData.friendListV2.resolvedState !== 'resolved') return;
    const candidates = removeDuplicates([...userData.friendListV2.users, userData.publicUserData]);
    setAssigneeCandidates(candidates);
  }, [userData]);

  const isButtonDisabled = taskItem.data.title.length < 1;

  const handleTitleChange = (event: any) => onTitleChange(event.target.value);
  const handleDescriptionChange = (event: any) => onDescriptionChange(event.target.value);

  return (
    <>
      <ModalBodyWrapper>
        {taskModalVersion === 'edit'
          && (
            <TaskStatusDropdown
              status={taskItem.data.status}
              updateStatus={getDbUpdateStatusHandler(
                userData,
                taskItem,
                trackEvent,
                authState,
              )}
            />
          )}
        <TaskTitleComponent
          handleTitleChange={handleTitleChange}
          title={taskItem.data.title}
          onConfirmClick={onConfirmClick}
        />
        <TaskDescription
          handleDescriptionChange={handleDescriptionChange}
          description={taskItem.data.description}
        />
        {!disableCalendarSelect && (
          <TaskModalCalendar
            date={taskItem.date.dueDate.date.date}
            setTaskItem={setTaskItem}
            onDueDateChange={onDueDateChange}
          />
        )}
        {!disableAssigneeSelect && (
          <SearchableAssign
            candidates={assigneeCandidates}
            selectedEmail={taskItem.data.assignee.email}
            onAssigneeChange={onAssigneeChange}
          />
        )}
        <TrelloToggle
          selectedEmail={taskItem.data.assignee.email}
          checked={trelloChecked}
          setChecked={setTrelloToggleChange}
          disabled={trelloDisabled}
        />
        <PrivateTaskToggle
          checked={taskItem.data.isPrivate}
          setChecked={onPrivateTaskChange}
        />
        {taskModalVersion === 'edit' && (<TaskDetailsMeetingComponent title={taskItem.meeting.name} />)}
        {taskModalVersion === 'edit'
          && (
            <CreatorComponent
              reporter={taskItem.reporter}
              createdAt={taskItem.date.created.date}
            />
          )}
      </ModalBodyWrapper>
      <TaskModalFooterButtons
        setModalClosed={setModalClosed}
        loading={loading}
        isButtonDisabled={isButtonDisabled}
        onCreateClick={onConfirmClick}
        confirmButtonName={confirmButtonName}
        inTaskDetails={taskModalVersion === 'edit'}
        canUpdate={canUpdate}
        updatedAt={taskItem.date.updated.date}
        openDeleteModal={openDeleteModal}
      />
    </>
  );
};

TaskModalContent.defaultProps = {
  confirmButtonName: 'Create',
  canUpdate: false,
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
};

export default TaskModalContent;
