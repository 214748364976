import React from 'react';
import { darkBlue1 } from '../colours';

interface Props {
  fill?: string,
}

const UpQuote = ({ fill }: Props) => (
  <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5637 23.8467L20.3679 26.1914C30.286 24.027 35.6471 15.6401 35.6471 8.60584C35.6471 3.19489 32.4304 0.309053 28.8563 0.309053C25.1035 0.309052 22.3336 3.19489 22.3336 6.62182C22.3336 9.32729 24.0313 11.3113 26.0864 12.4837C27.4267 13.2953 28.2309 14.3775 27.7841 16.3615C26.9799 19.4277 24.4781 22.1332 19.5637 23.8467ZM16.4364 8.60584C16.4364 3.19489 13.2197 0.309051 9.64561 0.309051C5.89281 0.309051 3.12289 3.19489 3.12289 6.62182C3.12289 9.32729 4.82059 11.3113 6.87569 12.4837C8.21597 13.2953 9.02014 14.3775 8.57338 16.3615C7.76921 19.4277 5.26735 22.1332 0.352974 23.8467L1.15714 26.1914C11.0752 24.027 16.4364 15.6401 16.4364 8.60584Z" fill={fill} />
  </svg>
);

UpQuote.defaultProps = {
  fill: darkBlue1,
};

export default UpQuote;
