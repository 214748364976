import React, { useRef } from 'react';
import styled from 'styled-components';
import TaskOperationButton from '../../../../shared/components/task-card/TaskOperationButton';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import DropdownMolecule from './DropdownMolecule';

const IconContainer = styled.div`
  cursor: pointer;
`;

const ThreeDotsDropdownMolecule = () => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useDetectOutsideClick(dropdownRef, false);

  const handleOpenCloseDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <IconContainer onClick={handleOpenCloseDropdown}>
        <TaskOperationButton isOpen={isDropdownOpen} />
      </IconContainer>
      <DropdownMolecule dropdownRef={dropdownRef} isOpen={isDropdownOpen} />
    </>
  );
};

export default ThreeDotsDropdownMolecule;
