import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface TableProps {
  fill?: string;
  width?: number;
  height?: number;
}

const TableIcon = ({
  fill,
  width,
  height,
}: TableProps) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 4.23047,0 C 1.69147,0 0,1.814922 0,4.419922 v 6.160156 C 0,13.187478 1.68747,15 4.23047,15 H 6.75 8.25 10.76953 C 13.31353,15 15,13.187478 15,10.580078 V 4.419922 C 15,1.812522 13.31353,0 10.76953,0 H 8.25 6.75 Z m 0,1.5 H 6.75 v 3 H 1.5 V 4.41992 C 1.5,3.44452 1.81295,2.727123 2.25195,2.265623 2.68395,1.811225 3.33347,1.5 4.23047,1.5 Z M 8.25,1.5 h 2.51953 c 0.9,0 1.55142,0.312525 1.98242,0.765625 0.437,0.4604 0.74805,1.176297 0.74805,2.154297 V 4.5 H 8.25 Z M 1.5,6 H 6.75 V 9 H 1.5 Z M 8.25,6 H 13.5 V 9 H 8.25 Z M 1.5,10.5 h 5.25 v 3 H 4.23047 c -0.9,0 -1.55142,-0.312525 -1.98242,-0.765625 C 1.81105,12.273975 1.5,11.558078 1.5,10.580078 Z m 6.75,0 h 5.25 v 0.08008 c 0,0.978 -0.31105,1.693897 -0.74805,2.154297 -0.431,0.4531 -1.08242,0.765625 -1.98242,0.765625 H 8.25 Z"
      fill={fill}
    />
  </svg>
);

TableIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 15,
};

export default TableIcon;
