import React, { Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import CalendarIcon from '../../icons/CalendarIcon';
import { TaskItem } from '../../types/types';
import CustomizedReactCalendar from '../calendar';
import TaskDetailsDateLabel from './DateLabel';

const CalendarInputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 24px 0;
`;

const CalendarContianer = styled.div`
  position: relative;
  top: 8px;
`;

const DueDateContainer = styled.div`
  margin-left: 16px;
`;

interface Props {
  date: string,
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  // eslint-disable-next-line no-unused-vars
  onDueDateChange: (dueDate: Date) => void
}

const TaskModalCalendar = ({
  date, setTaskItem, onDueDateChange,
}: Props) => {
  const CalendarRef = useRef(null);
  const [isCalendarOpen, setIsCalendarOpen] = useDetectOutsideClick(CalendarRef, false);
  const toggleIsCalendarOpen = () => setIsCalendarOpen(!isCalendarOpen);

  return (
    <CalendarInputContainer>
      <CalendarIcon />
      <DueDateContainer>
        <TaskDetailsDateLabel
          date={date}
          onDateRemove={setTaskItem}
          onClick={toggleIsCalendarOpen}
        />
        <CalendarContianer>
          <CustomizedReactCalendar
            isOpen={isCalendarOpen}
            setIsOpen={setIsCalendarOpen}
            setDate={onDueDateChange}
            calendarRef={CalendarRef}
          />
        </CalendarContianer>
      </DueDateContainer>
    </CalendarInputContainer>
  );
};

export default TaskModalCalendar;
