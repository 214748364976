import React from 'react';

interface Props {
  fill?: string,
}

const InfoCircle = ({ fill }: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33325 8.00016C1.33325 4.31869 4.31844 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31846 14.6666 8.00016C14.6666 11.6819 11.6818 14.6668 7.99992 14.6668C4.31844 14.6668 1.33325 11.6816 1.33325 8.00016ZM13.6666 8.00016C13.6666 4.87073 11.1295 2.3335 7.99992 2.3335C4.87073 2.3335 2.33325 4.87097 2.33325 8.00016C2.33325 11.1294 4.87073 13.6668 7.99992 13.6668C11.1295 13.6668 13.6666 11.1296 13.6666 8.00016ZM7.99665 4.96956C8.24978 4.96956 8.45898 5.15767 8.49209 5.40172L8.49665 5.46956V8.41556C8.49665 8.69171 8.27279 8.91556 7.99665 8.91556C7.74352 8.91556 7.53432 8.72746 7.50122 8.48341L7.49665 8.41556V5.46956C7.49665 5.19342 7.72051 4.96956 7.99665 4.96956ZM8.50332 10.5308C8.50332 10.2546 8.27946 10.0308 8.00332 10.0308L7.9288 10.0353C7.68475 10.0684 7.49665 10.2776 7.49665 10.5308C7.49665 10.8069 7.72051 11.0308 7.99665 11.0308L8.07117 11.0262C8.31522 10.9931 8.50332 10.7839 8.50332 10.5308Z" fill={fill} />
  </svg>
);

InfoCircle.defaultProps = {
  fill: 'white',
};

export default InfoCircle;
