import { TaskItem, TrelloData } from '../../shared/types/types';
import { mapTasksToTrelloTask, TrelloTaskCreate, TrelloUpdateObj } from './trelloUtils';
import { functions } from '../firebase';
import { dbTaskUpdateTrelloData } from '../../database/firebaseTasksAPI';

export const getUsersTrelloSetting = (emailId: string) => functions()
  .httpsCallable('searchUserDataByEmail')({ email: emailId });

export const getTrelloWorkspace = (accessToken: string) => functions()
  .httpsCallable('getTrelloWorkspaces')({ accessToken }).then((data) => data.data);

export const getTrelloBoards = (accessToken: string, workspaceId: string) => functions()
  .httpsCallable('getTrelloBoards')({ accessToken, workspaceId }).then((data) => data.data);

export const getTrelloBoardsList = (accessToken: string, boardId: string) => functions()
  .httpsCallable('getTrelloBoardList')({ accessToken, boardId }).then((data) => data.data);

// TODO Asish: This function should be async and return a Promise<ResolveState>
// TODO Asish: investigate if we are trying to send tasks to trello board
// if you have integrated, but not set up which board you want to send to
export const sendTaskToTrelloBoard = (
  trelloChecked: boolean,
  selectedTasks: TaskItem[],
  trelloUserData: TrelloData,
) => {
  const tasks: TrelloTaskCreate[] = mapTasksToTrelloTask(selectedTasks, trelloUserData);
  return functions().httpsCallable('sendTaskToTrelloBoard')({ accessToken: trelloUserData.accessToken, tasks }).then((data) => {
    const promiseArr = data.data.map(
      (idObj: {
        taskId: string, trelloId: string
      }) => dbTaskUpdateTrelloData(idObj.taskId, idObj.trelloId, trelloChecked),
    );
    return Promise.all(promiseArr);
  });
};

export const updateTrelloTask = (updateObj: TrelloUpdateObj) => functions().httpsCallable('updateTaskInTrelloBoard')({ ...updateObj })
  .catch((data: any) => {
    console.log(JSON.stringify(data));
  });

export const deleteTrelloTask = (taskId: string, emailId: string) => getUsersTrelloSetting(emailId)
  .then((response) => functions().httpsCallable('deleteTrelloCard')(
    { accessToken: response.data.integrations.trello.accessToken, taskId },
  )
    .then(() => {
      console.log('taskDeleted in trello', new Date());
    })
    .catch((error: any) => {
      console.log(JSON.stringify(error));
    }));
