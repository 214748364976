const PersonalOnboardingFormModel = {
  formId: 'personalOnboardingForm',
  formP1: {
    title: 'Tell us a bit about yourself',
    subtitle: 'Help us create your profile that will be displayed to other Shepherd users in your meetings.',
    firstName: {
      id: 'firstName',
      name: 'firstName',
      label: 'First name',
      placeholder: 'Enter your first name',
      requiredErrorMsg: 'Required',
    },
    lastName: {
      id: 'lastName',
      name: 'lastName',
      label: 'Last name',
      placeholder: 'Enter your last name',
      requiredErrorMsg: 'Required',
    },
    useShepherd: {
      id: 'useShepherd',
      name: 'useShepherd',
      label: 'How do you plan on using Shepherd?',
      placeholder: 'Select',
      requiredErrorMsg: 'Required',
    },
  },
  formP2: {
    title: 'We want to make your experience perfect',
    subtitle: 'Tell us a liitle bit about your job so that we can continue to cater Shepherd to your needs!',
    jobType: {
      id: 'jobType',
      name: 'jobType',
      label: 'What is your role?',
      placeholder: 'Select',
      requiredErrorMsg: 'Required',
    },
    companySize: {
      id: 'companySize',
      name: 'companySize',
      label: 'How big is your company?',
      placeholder: 'Select',
      requiredErrorMsg: 'Required',
    },
    productivityTool: {
      id: 'productivityTool',
      name: 'productivityTool',
      label: 'What productivity tool do you use the most?',
      placeholder: 'Select one or more',
      requiredErrorMsg: 'Required',
    },
    remotePolicy: {
      id: 'remotePolicy',
      name: 'remotePolicy',
      label: 'What is your remote policy?',
      placeholder: 'Select',
      requiredErrorMsg: 'Required',
    },
  },
};

export default PersonalOnboardingFormModel;
