import { GoogleMeetingIds } from '../../shared/types/types';

export const gapiInsertFirstMeetingSummary = () => {
  const lightningEmojiCode = String.fromCodePoint(0x26A1);
  const summary = `${lightningEmojiCode} Boom - You're All Set ${lightningEmojiCode}`;

  return summary;
};

export const gapiFormatTimeForFirstMeeting = () => {
  const startTime = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const endTime = new Date(startTime.getTime() + (60 * 60 * 1000)); // add 1 hour

  return { startTime: startTime.toISOString(), endTime: endTime.toISOString(), timeZone };
};

export const mapGapiInsertMeetingResponseToGoogleMeetingIdsObj = (gapiResponse: any) => {
  const GoogleMeetingIdsObj: GoogleMeetingIds = {
    eventId: gapiResponse.result?.id ?? '',
    calendarId: gapiResponse.result?.creator?.email ?? '',
    resolvedState: 'resolved',
  };

  return GoogleMeetingIdsObj;
};

export const RejectedGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'rejected',
};

export const PendingGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'pending',
};
