import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import ProfileIconsRow from '../profile-icon/ProfileIconsRow';
import ProfileIconRowSeperator from './ProfileIconRowSeperator';
import { AttendeeV2 } from '../../types/types';
import CssSpinner from '../css-spinner';
import { gray10 } from '../../colours';
import NonShepherdAttendeesList from './NonShepherdAttendeesList/NonShepherdAttendeesList';

const Container = styled.div`
  display: flex;

  & > div:nth-child(1) {
    margin-right: 8px;
  }
  & > div:nth-child(2) {
    margin-right: 8px;
  }
`;

export interface Props {
  attendees: AttendeeV2[],
  loading: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>
}

const MeetingAttendeesList = ({
  attendees,
  loading,
  setMembersInvited,
}: Props) => {
  const numberOfIconsToShow = 6;
  const nonShepherdIconsToShow = 2;
  const shepherdAttendees = attendees.filter((attendee) => attendee.userId.length);
  const nonShepherdAttendees = attendees.filter((attendee) => !attendee.userId.length);

  const shepherdAttendeesToShowProfileIcon = shepherdAttendees.slice(0, numberOfIconsToShow);
  const shepherdAttendeesExtra = shepherdAttendees.slice(numberOfIconsToShow);

  const shouldDisplaySeperator = nonShepherdAttendees.length > 0;

  if (loading) {
    return (
      <CssSpinner color={gray10} size={28} thickness={4} padding={4} />
    );
  }
  return (
    <Container>
      <ProfileIconsRow
        profileIconAttendees={shepherdAttendeesToShowProfileIcon}
        extraAttendees={shepherdAttendeesExtra}
      />
      <ProfileIconRowSeperator display={shouldDisplaySeperator} />
      <NonShepherdAttendeesList
        nonShepherdAttendees={nonShepherdAttendees}
        numberOfIconsToShow={nonShepherdIconsToShow}
        setMembersInvited={setMembersInvited}
      />
    </Container>
  );
};

export default MeetingAttendeesList;
