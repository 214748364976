import React from 'react';
import ButtonSmall from '../../button-small';
import ModalFooterButtonsRightAlignedWrapper from '../../Modal/ModalFooterButtonsRightAlignedWrapper';

interface Props {
  isButtonDisabled: boolean
  handleSave: () => void
  handleModalClose: () => void
  loading: boolean
  isSaved: boolean
  openTemplates: () => void
}

const SaveTemplateModalButtons = ({
  handleSave, handleModalClose, isButtonDisabled, loading, isSaved, openTemplates,
}: Props) => (
  isSaved
    ? (
      <ModalFooterButtonsRightAlignedWrapper>
        <ButtonSmall text="Close" isOutline onClick={handleModalClose} />
        <ButtonSmall
          type="submit"
          text="View my templates"
          onClick={openTemplates}
        />
      </ModalFooterButtonsRightAlignedWrapper>
    ) : (
      <ModalFooterButtonsRightAlignedWrapper>
        <ButtonSmall text="Cancel" isOutline onClick={handleModalClose} />
        <ButtonSmall
          type="submit"
          text="Submit"
          loading={loading}
          onClick={handleSave}
          isDisabled={isButtonDisabled}
        />
      </ModalFooterButtonsRightAlignedWrapper>
    )
);

export default SaveTemplateModalButtons;
