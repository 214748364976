import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string,
}

const ArrowRightMini = ({ fill }: Props) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.809424 11.8192C0.58588 11.6001 0.565557 11.2571 0.748458 11.0155L0.809424 10.9462L5.85506 6L0.809424 1.05377C0.58588 0.83462 0.565557 0.491687 0.748458 0.250032L0.809424 0.180798C1.03297 -0.0383511 1.38278 -0.0582738 1.62928 0.12103L1.6999 0.180798L7.19058 5.56351C7.41412 5.78266 7.43444 6.1256 7.25154 6.36725L7.19058 6.43648L1.6999 11.8192C1.454 12.0603 1.05532 12.0603 0.809424 11.8192Z" fill={fill} />
  </svg>
);

ArrowRightMini.defaultProps = {
  fill: surface,
};

export default ArrowRightMini;
