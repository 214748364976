import React, { useState } from 'react';
import styled from 'styled-components';
import { gray9 } from '../../../shared/colours';
import CalendarIcon from '../../../shared/icons/CalendarIcon';
import { MeetingNotesSection, UserCenterSection } from '../../../shared/types/types';
import dummyMeetingData from './dummyData';
import HeaderAtom from './HeaderAtom';
import MeetingCardsOrganism from './meetingCard/MeetingCardsOrganism';
import SelectMolecule from './SelectMolecule';
import TabButtonAtom from './TabButtonAtom';

const Container = styled.div`
  padding: 0px 16px;
`;

const HeaderContainer = styled.div`
  margin: 24px 0px 24px 0px;
`;

const TabNavigationContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  & > div {
    &:not(:last-child) {
        margin-right: 8px;
      }
  }
`;

interface Props {
  section: UserCenterSection,
}

const MeetingDashboard = ({ section }: Props) => {
  if (section !== 'meetings') return null;

  const [selectedTab, setSelectedTab] = useState<MeetingNotesSection>('customPeriod');
  const [checked, setChecked] = useState<boolean>(false);

  const handleAllClicked = () => {
    setSelectedTab('all');
  };

  const handleThisWeekClicked = () => {
    setSelectedTab('thisWeek');
  };

  const handleRecentClicked = () => {
    setSelectedTab('recent');
  };

  const handleCalendarClicked = () => {
    setSelectedTab('customPeriod');
  };

  const handleThisMonthClicked = () => {
    setSelectedTab('thisMonth');
  };

  const setIsChecked = () => {
    setChecked(!checked);
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderAtom text="All meeting notes" />
      </HeaderContainer>
      <TabNavigationContainer>
        <TabButtonAtom isSelected={selectedTab === 'customPeriod'} icon={<CalendarIcon fill={selectedTab === 'customPeriod' ? 'white' : gray9} />} onClick={handleCalendarClicked} />
        <TabButtonAtom isSelected={selectedTab === 'all'} text="All" onClick={handleAllClicked} />
        <TabButtonAtom isSelected={selectedTab === 'thisWeek'} text="This week" onClick={handleThisWeekClicked} />
        <TabButtonAtom isSelected={selectedTab === 'recent'} text="Recent" onClick={handleRecentClicked} />
        <TabButtonAtom isSelected={selectedTab === 'thisMonth'} text="This month" onClick={handleThisMonthClicked} />
      </TabNavigationContainer>
      <SelectMolecule isChecked={checked} setChecked={setIsChecked} />
      <MeetingCardsOrganism meetings={dummyMeetingData} isSelected={checked} />
    </Container>
  );
};

export default MeetingDashboard;
