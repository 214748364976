import React from 'react';
import { gray1, gray3, blue6 } from '../../colours';

interface Props {
  fill?: string,
}
const EllipseIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="3" fill={fill} />
    <path d="M10 14.3C10.8505 14.3 11.6818 14.0478 12.389 13.5753C13.0961 13.1028 13.6472 12.4313 13.9727 11.6455C14.2981 10.8598 14.3833 9.99523 14.2174 9.16111C14.0515 8.32699 13.6419 7.56081 13.0406 6.95944C12.4392 6.35808 11.673 5.94854 10.8389 5.78262C10.0048 5.61671 9.14018 5.70186 8.35446 6.02732C7.56874 6.35277 6.89717 6.90392 6.42468 7.61105C5.95219 8.31818 5.7 9.14954 5.7 10" stroke={gray1} strokeWidth="1.4" strokeLinecap="round" />
    <rect x="1" y="1" width="18" height="18" rx="3" stroke={gray3} strokeWidth="2" />
  </svg>
);

EllipseIcon.defaultProps = {
  fill: blue6,
};

export default EllipseIcon;
