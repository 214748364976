/* eslint-disable no-useless-escape */
/* eslint-disable no-cond-assign */
import { Fragment, Slice, Node } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';

const HTTP_LINK_REGEX = /((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/g;

const linkify = function (fragment: Fragment): Fragment {
  const linkified : Node[] = [];
  fragment.forEach((child: Node) => {
    if (child.isText) {
      const text = child.text as string;
      let pos = 0;
      let match;

      while (match = HTTP_LINK_REGEX.exec(text)) {
        const start = match.index;
        const end = start + match[0].length;
        const { link } = child.type.schema.marks;

        // simply copy across the text from before the match
        if (start > 0) {
          linkified.push(child.cut(pos, start));
        }
        const urlText = text.slice(start, end);
        linkified.push(
          child.cut(start, end).mark(link.create({ href: urlText }).addToSet(child.marks)),
        );
        pos = end;
      }

      // copy over whatever is left
      if (pos < text.length) {
        linkified.push(child.cut(pos));
      }
    } else {
      linkified.push(child.copy(linkify(child.content)));
    }
  });

  return Fragment.fromArray(linkified);
};

export default new Plugin({
  props: {
    transformPasted:
      (slice: Slice) => new Slice(linkify(slice.content), slice.openStart, slice.openEnd),
  },
});
