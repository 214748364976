import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { layoutWhite } from '../../../shared/colours';
import { MeetingSections } from '../../../shared/types/types';
import TemplatesContent from './components/TemplatesContent';
import Scrollbar from '../../../shared/components/scrollbar';

const height = 'calc(100vh - 48px - 48px - 167px - 60px)';

const TemplatesContainer = styled.div`
  width: 100%;
  height: ${height};
  background: ${layoutWhite};

  padding: 24px 16px;
`;

interface Props {
  tab: MeetingSections,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>
}

const TemplatesView = ({ tab, setIsTemplatesOpen }: Props) => (
  <Scrollbar maxHeight={height}>
    <TemplatesContainer>
      <TemplatesContent
        key="user"
        section="user"
        tab={tab}
        setIsTemplatesOpen={setIsTemplatesOpen}
      />
    </TemplatesContainer>
  </Scrollbar>
);

export default TemplatesView;
