import React from 'react';
import styled from 'styled-components';
import rockHandPath from '../../../shared/img/RockHand.png';

const Image = styled.img``;

const RockHandAtom = () => (
  <Image src={rockHandPath} />
);

export default RockHandAtom;
