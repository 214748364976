import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gray2, gray3 } from '../../colours';
import { header200, uiText } from '../../typography';

const TitleInput = styled.input`
  background: ${gray2};
  border: none;
  border-radius: 10px;
  width: 409px;
  height: 40px;
  margin: 8px 0 24px 0;
  border: 2px solid ${gray3};
  box-sizing: border-box;
  padding: 0px 0px 0px 8px;
  ${uiText};

  ::placeholder{
    margin: 10px 0px 10px 8px;
    margin-left: 8px;
  }

  :focus{
    outline: none;
  }
`;

const TaskNameSubHeader = styled.span`
  ${header200};
`;

interface Props {
  title: string,
  // eslint-disable-next-line no-unused-vars
  handleTitleChange: (event: any) => void,
  onConfirmClick: () => void,
}

const TaskTitleComponent = ({ title, handleTitleChange, onConfirmClick }: Props) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      onConfirmClick();
    }
  };

  return (
    <>
      <TaskNameSubHeader>
        Task name
      </TaskNameSubHeader>
      <TitleInput
        value={title}
        placeholder="Type task name here..."
        onKeyDown={handleKeyDown}
        onChange={handleTitleChange}
        ref={inputRef}
      />
    </>
  );
};

export default TaskTitleComponent;
