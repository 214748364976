import React from 'react';
import styled from 'styled-components';
import { uiTextMedium } from '../../../../../shared/typography';
import { surface } from '../../../../../shared/colours';

const Text = styled.span`
  ${uiTextMedium};
  color: ${surface};
`;

interface Props {
  text: string,
}

const QuestionTextAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default QuestionTextAtom;
