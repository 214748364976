import React from 'react';
import styled from 'styled-components';
import {
  darkBlue4,
  gray1, gray10, layoutWhite,
} from '../../../shared/colours';

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: fit-content;
  cursor: pointer;
  padding: ${(props: ContainerProps) => (props.isIcon ? '6px 6px' : '6px 12px')};
  border-radius: 6px;
  color: ${(props: ContainerProps) => (props.isSelected ? gray1 : 'black')} ;
  background: ${(props: ContainerProps) => (props.isSelected ? gray10 : layoutWhite)};
  &:hover {
    color: ${gray1};
    }
`;

const Text = styled.h2<ContainerProps>`
  color: ${(props: ContainerProps) => (props.isSelected ? 'white' : darkBlue4)};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
`;

interface ContainerProps {
  isSelected: boolean,
  isIcon?: boolean,
}

interface Props {
  text?: string,
  icon?: any,
  onClick: () => void,
  isSelected: boolean,
}

const TabButtonAtom = ({
  text, icon, onClick, isSelected,
}: Props) => (
  <>
    {
      text === '' ? (
        <Container isIcon isSelected={isSelected} onClick={onClick}>{icon}</Container>
      ) : (
        <Container isIcon={false} isSelected={isSelected} onClick={onClick}>
          <Text isSelected={isSelected}>
            {text}
          </Text>
        </Container>
      )
    }
  </>
);

TabButtonAtom.defaultProps = {
  text: '',
  icon: '',
};

export default TabButtonAtom;
