import React from 'react';
import { useHistory } from 'react-router';
import { onboardingFirstMeetingPath } from '../../../../../routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation-button';
import {
  BACK_DIRECTION, LIGHT_BUTTON, NEXT_DIRECTION, DARK_BUTTON, USE_SHEPHERD,
} from '../../../../../shared/components/buttons/navigation-button/utils/constants';
import { CALENDAR_SCREEN, VIDEO_SCREEN } from '../../utils/constants';
import { ScreensNavigationData, ScreensNavigateFunctions } from '../../utils/types';
import Container from './utils/styles';

interface Props {
  data: ScreensNavigationData,
  functions: ScreensNavigateFunctions
}

const WelcomeScreensNavigationButtons = ({
  data,
  functions,
}: Props) => {
  const { activeScreen } = data;
  const { navigateToPreviousScreen, navigateToNextScreen } = functions;

  const firstScreen = activeScreen === CALENDAR_SCREEN;
  const lastScreen = activeScreen === VIDEO_SCREEN;

  const history = useHistory();

  const handleUseShepherd = () => {
    history.push(onboardingFirstMeetingPath);
  };

  /* for later use
  const renderDoItLater = () => {
    if (integrationScreen) {
      return (
        <SkipOptionContainer>
          <SkipOption onClick={navigateToLastStep}>
            Do it later
          </SkipOption>
        </SkipOptionContainer>
      );
    }

    return null;
  };
  */

  if (firstScreen) {
    return (
      <Container>
        <NavigationButton
          data={{ text: NEXT_DIRECTION }}
          functions={{ onClick: navigateToNextScreen }}
          styles={{ color: DARK_BUTTON }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <NavigationButton
        data={{ text: BACK_DIRECTION }}
        functions={{ onClick: navigateToPreviousScreen }}
        styles={{ color: LIGHT_BUTTON }}
      />
      <NavigationButton
        data={{ text: (lastScreen ? USE_SHEPHERD : NEXT_DIRECTION) }}
        functions={{ onClick: (lastScreen ? handleUseShepherd : navigateToNextScreen) }}
        styles={{ color: DARK_BUTTON }}
      />
    </Container>
  );
};

export default WelcomeScreensNavigationButtons;
