// Helper for creating a schema that supports tables.
import {
  Fragment,
  Schema,
  Node,
} from 'prosemirror-model';
import {
  EditorState,
  Transaction,
} from 'prosemirror-state';

export function insertTable<S extends Schema<any, any> = any>(
  state: EditorState<S>,
  // eslint-disable-next-line no-unused-vars
  dispatch?: (tr: Transaction<S>) => void,
): boolean {
  const tr: Transaction<S> = state.tr.replaceSelectionWith(
    state.schema.nodes.table.create(
      undefined,
      Fragment.fromArray([
        state.schema.nodes.table_row.create(
          undefined,
          Fragment.fromArray([
            state.schema.nodes.table_cell.createAndFill()!,
            state.schema.nodes.table_cell.createAndFill()!,
            state.schema.nodes.table_cell.createAndFill()!,
          ]),
        ),
        state.schema.nodes.table_row.create(
          undefined,
          Fragment.fromArray([
            state.schema.nodes.table_cell.createAndFill()!,
            state.schema.nodes.table_cell.createAndFill()!,
            state.schema.nodes.table_cell.createAndFill()!,
          ]),
        ),
      ]),
    ),
  );
  if (typeof dispatch !== 'undefined') dispatch(tr);
  return true;
}

function getCellAttrs(dom: HTMLUnknownElement, extraAttrs: Record<string, any>) {
  const widthAttr = dom.getAttribute('data-colwidth');
  const widths = widthAttr && /^\d+(,\d+)*$/.test(widthAttr)
    ? widthAttr.split(',').map((s: string) => Number(s))
    : null;
  const colspan = Number(dom.getAttribute('colspan') || 1);
  return {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') || 1),
    colwidth: widths && widths.length === colspan ? widths : null,
    ...Object.entries(extraAttrs)
      .filter(([, value]) => {
        const getter = value.getFromDOM;
        return (getter && getter(dom)) != null;
      })
      .reduce((obj: Record<string, any>, item: [string, any]) => {
        const [key, value] = item;
        // eslint-disable-next-line no-param-reassign
        obj[key] = value;
        return obj;
      }, {} as Record<string, any>),
  };
}

function setCellAttrs(node: Node, extraAttrs: Record<string, any>) {
  const attrs: {
    colspan: any | undefined;
    rowspan: any | undefined;
    'data-colwidth': any | undefined;
  } = {
    colspan: undefined,
    rowspan: undefined,
    'data-colwidth': undefined,
  };
  if (node.attrs.colspan !== 1) attrs.colspan = node.attrs.colspan;
  if (node.attrs.rowspan !== 1) attrs.rowspan = node.attrs.rowspan;
  if (node.attrs.colwidth) attrs['data-colwidth'] = node.attrs.colwidth.join(',');
  Object.keys(extraAttrs).forEach((prop: string) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });
  return attrs;
}

// :: (Object) → Object
//
// This function creates a set of [node
// specs](http://prosemirror.net/docs/ref/#model.SchemaSpec.nodes) for
// `table`, `table_row`, and `table_cell` nodes types as used by this
// module. The result can then be added to the set of nodes when
// creating a a schema.
//
//   options::- The following options are understood:
//
//     tableGroup:: ?string
//     A group name (something like `"block"`) to add to the table
//     node type.
//
//     cellContent:: string
//     The content expression for table cells.
//
//     cellAttributes:: ?Object
//     Additional attributes to add to cells. Maps attribute names to
//     objects with the following properties:
//
//       default:: any
//       The attribute's default value.
//
//       getFromDOM:: ?(dom.Node) → any
//       A function to read the attribute's value from a DOM node.
//
//       setDOMAttr:: ?(value: any, attrs: Object)
//       A function to add the attribute's value to an attribute
//       object that's used to render the cell's DOM.
export function tableNodes(options: Record<string, any>) {
  const extraAttrs = options.cellAttributes || {};
  const cellAttrs = {
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
    ...Object.entries(extraAttrs)
      .reduce((obj: Record<string, any>, item: [string, any]) => {
        const [key, value] = item;
        // eslint-disable-next-line no-param-reassign
        obj[key] = { default: value.default };
        return obj;
      }, {} as Record<string, any>),
  };

  return {
    table: {
      content: 'table_row+',
      tableRole: 'table',
      isolating: true,
      group: options.tableGroup,
      parseDOM: [{ tag: 'table' }],
      toDOM() {
        return ['table', ['tbody', 0]];
      },
    },
    table_row: {
      content: '(table_cell | table_header)*',
      tableRole: 'row',
      parseDOM: [{ tag: 'tr' }],
      toDOM() {
        return ['tr', 0];
      },
    },
    table_cell: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'cell',
      isolating: true,
      parseDOM: [
        { tag: 'td', getAttrs: (dom: HTMLUnknownElement) => getCellAttrs(dom, extraAttrs) },
      ],
      toDOM(node: Node) {
        return ['td', setCellAttrs(node, extraAttrs), 0];
      },
    },
    table_header: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'header_cell',
      isolating: true,
      parseDOM: [
        { tag: 'th', getAttrs: (dom: HTMLUnknownElement) => getCellAttrs(dom, extraAttrs) },
      ],
      toDOM(node: Node) {
        return ['th', setCellAttrs(node, extraAttrs), 0];
      },
    },
  };
}
