import date from 'date-and-time';
import {
  getEndOfMonth, getEndOfWeek, getStartOfMonth, getStartOfToday, getStartOfWeek,
} from '../dateUtils/date';
import { gapiCoreGetMeeting, gapiCoreGetMeetingsInPeriod, gapiInsertMeetingCore } from './GoogleCalendarCore';
import { GapiMeetingData } from '../../shared/types/types';
import mapGapiResultToGapiMeetingData, { rejectedGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { startTrackingEvent, stopTrackingEvent } from '../analytics/eventLogger';
import { gapiInsertFirstMeetingSummary, gapiFormatTimeForFirstMeeting } from './GoogleCalendarUtils';

export const getTodaysMeetings = async () => {
  let startOfToday = getStartOfToday();
  startOfToday = date.addDays(startOfToday, 3); // Just for testing different days
  const endOfToday = date.addDays(startOfToday, 1);
  const result = await gapiCoreGetMeetingsInPeriod(
    startOfToday.toISOString(),
    endOfToday.toISOString(),
  );
  console.log('Got result in getTodaysMeetings');
  console.log(result);
  return result;
};

export const getThisWeeksUpcomingMeetings = async () => {
  const startOfWeek = getStartOfWeek();
  const endOfWeek = getEndOfWeek();
  console.log(`StartOfweek: ${startOfWeek}`); // By default a week is sunday - saturday week
  console.log(`End of week: ${endOfWeek}`);
  const result = await gapiCoreGetMeetingsInPeriod(startOfWeek, endOfWeek);
  console.log(result);
  return result;
};

export const getMontlyMeetings = async () => {
  const startOfMonth = getStartOfMonth();
  const endOfMonth = getEndOfMonth();
  console.log(`Start of month ${startOfMonth}`);
  console.log(`End of month ${endOfMonth}`);
  const result = await gapiCoreGetMeetingsInPeriod(startOfMonth, endOfMonth);
  console.log((result as GapiMeetingData[]));
  return result;
};

export const gapiGetMeeting = async (
  eventId: string,
  calendarId: string,
  userEmail: string,
) => {
  if (!validateEventIdAndCalendarId(eventId, calendarId)) {
    makeErrorMessage(eventId, calendarId);
    return rejectedGapiMeetingData;
  }
  startTrackingEvent('meetingDataLoad');
  return gapiCoreGetMeeting(eventId, calendarId, 1)
    .then((result) => {
      const gapiMeetingData = mapGapiResultToGapiMeetingData(result);
      return gapiMeetingData;
    })
    .catch(() => gapiCoreGetMeeting(eventId, userEmail, 2)
      .then((result) => {
        const gapiMeetingData = mapGapiResultToGapiMeetingData(result);
        return gapiMeetingData;
      })
      .catch(() => rejectedGapiMeetingData))
    .finally(() => stopTrackingEvent('meetingDataLoad'));
};

/* using google instaces api to get recurring events
export const gapiGetRecurringEventMeetings = (
  calendarId: string,
  eventId: string,
) => getRecurringEventData(calendarId, eventId).then((result:any) => result).catch((error:any) => {
  console.log(error);
}); */

export const logCouldntFindMeeting = (eventId: string, calendarId: string, number: number) => {
  console.log(`GAPI - Couldn't find meeting with eventId '${eventId}', and calendarId: '${calendarId}', number: ${number}`);
};

const validateEventIdAndCalendarId = (eventId: string, calendarId: string) => {
  if (eventId.length < 10 || calendarId.length < 10) {
    return false;
  }
  return true;
};

const makeErrorMessage = (eventId: string, calendarId: string) => {
  if (eventId.length < 10) {
    return console.log(`EventId is not valid: ${eventId}`);
  }
  if (calendarId.length < 10) {
    return console.log(`CalendarId is not valid: ${calendarId}`);
  }
  return '';
};

export const gapiInsertFirstMeeting = async () => {
  const summary = gapiInsertFirstMeetingSummary();
  const { startTime, endTime, timeZone } = gapiFormatTimeForFirstMeeting();

  const event = {
    summary,
    description: 'Welcome to the Shepherd Family!',
    start: {
      dateTime: startTime,
      timeZone,
    },
    end: {
      dateTime: endTime,
      timeZone,
    },
  };

  return gapiInsertMeetingCore(event);
};
