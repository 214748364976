import React from 'react';
import styled from 'styled-components';
import { gray6 } from '../../../shared/colours';
import { titleSemiboldForDashboardWelcomeScreen } from '../../../shared/typography';

const Quote = styled.span`
  width: 100%;
  text-align: center;
  ${titleSemiboldForDashboardWelcomeScreen}
  color: ${gray6};
  
  white-space: pre;
`;

interface Props {
  quote: string,
}

const QuoteAtom = ({ quote }: Props) => (
  <Quote>
    {quote}
  </Quote>
);

export default QuoteAtom;
