import {
  TaskNotificationType, TaskUpdateField, TaskItem, TaskStatus,
} from '../../shared/types/types';
import {
  ASSIGN_TASK_DESCRIPTION,
  COMPLETED_ICON_URL,
  DELETE_TASK_DESCRIPTION,
  IN_PROGRESS_ICON_URL,
  TODO_ICON_URL,
  UPDATE_TASK_DESCRIPTION,
  UPDATE_TASK_DUE_DATE_DESCRIPTION,
  UPDATE_TASK_STATUS_DESCRIPTION,
  UPDATE_TASK_TITLE_DESCRIPTION,
} from '../constants';
import {
  TASK_NOTIFICATION_ASSIGN,
  TASK_NOTIFICATION_DELETE,
  TASK_NOTIFICATION_UPDATE,
  TASK_UPDATE_FIELD_DUE_DATE,
  TASK_UPDATE_FIELD_STATUS,
  TASK_UPDATE_FIELD_TITLE,
} from '../enums';
import { DateToTemplateDateFormat } from '../dateUtils/date';
import { isEmptyHTML } from '../strings';
import { mapTaskStatusToIconURL } from '../tasks/tasksUtils';
import { EmailRecipient } from '../../pages/meeting/modal/ShareNotesModal';

export type EmailHTMLSection = {
  title: string,
  content: string,
}

export const generateTaskToHTML = (taskObj: TaskItem) => `
<ul class="task-list" style="list-style: none;padding: 0;">
<li style="text-align: inherit" class="task-item">
  <div class="task-left" style="float: left;margin-right: 24px;">
  <img src=${mapTaskStatusToIconURL(taskObj.data.status)} width="20" height="20">
  </div>
    
  <div class="task-right">
    <div class="task-title">
    ${taskObj.data.title}
    </div>
    <div class="task-detail">
      <span>${taskObj.data.assignee.name}</span>
      <span class="due-date-section">
      ${taskObj.date.dueDate.date.date ? ` | Due ${DateToTemplateDateFormat(taskObj.date.dueDate.date.date)}` : ''}
      </span>
    </div>
  </div>
</li>
</ul>
`;

export const assembleEmailHTML = (
  agendaChecked: boolean, sharedNotesChecked: boolean, privateNotesChecked: boolean,
  taskChecked: boolean, emailHead: string, agenda: string, sharedNotes: string,
  privateNotes: string, taskItems: TaskItem[],
  privateSpaceNotesCheked: boolean[], privateSpaceNotesHtml: string[],
) => {
  const sections: EmailHTMLSection[] = [];
  if (agendaChecked && !isEmptyHTML(agenda)) {
    sections.push({ title: 'Agenda', content: agenda });
  }
  if (sharedNotesChecked && !isEmptyHTML(sharedNotes)) {
    sections.push({ title: 'Shared notes', content: sharedNotes });
  }
  if (privateNotesChecked && !isEmptyHTML(privateNotes)) {
    sections.push({ title: 'Sender\'s notes', content: privateNotes });
  }

  if (privateSpaceNotesCheked?.includes(true)
    && privateSpaceNotesHtml.filter((note) => isEmptyHTML(note)).length < 1) {
    privateSpaceNotesHtml.forEach((note) => {
      sections.push({ title: 'Private Space notes', content: note });
    });
  }

  if (taskChecked && taskItems.length !== 0) {
    const taskHtml = taskItems.map((task: TaskItem) => generateTaskToHTML(task)).join('');
    sections.push({ title: 'Tasks', content: taskHtml });
  }

  let html = '<div style="padding-left: 20px;" >';
  sections.forEach((section: EmailHTMLSection) => {
    html += `<b>${section.title}:</b><p>${section.content}</p><br>`;
  });
  html += '</div>';
  html = `<div>${emailHead.split('\n').join('<br>')}</div>${html}`;
  return html;
};

// Substitute placeholder
export const populateEmailHeadTemplate = (
  template: string, meetingTitle: string, date: string,
) => template.replace(/{{\s*meeting[\s|_|-]*title\s*}}/gmi, meetingTitle)
  .replace(/{{\s*date\s*}}/gmi, date);

export const defaultEmailHeadTemplate = `Hi Team,

Thanks for attending the {{ Meeting title }} meeting on {{ Date }}.

Please find the summary below:

`;

export const getTaskOperationDescription = (
  taskNotificationType: TaskNotificationType, updateFields?: TaskUpdateField[],
) => {
  if (taskNotificationType === TASK_NOTIFICATION_ASSIGN) return ASSIGN_TASK_DESCRIPTION;
  if (taskNotificationType === TASK_NOTIFICATION_DELETE) return DELETE_TASK_DESCRIPTION;
  if (taskNotificationType === TASK_NOTIFICATION_UPDATE) {
    if (
      updateFields === undefined || updateFields.length > 1 || updateFields.length === 0
    ) { return UPDATE_TASK_DESCRIPTION; }
    if (updateFields[0] === TASK_UPDATE_FIELD_DUE_DATE) return UPDATE_TASK_DUE_DATE_DESCRIPTION;
    if (updateFields[0] === TASK_UPDATE_FIELD_TITLE) return UPDATE_TASK_TITLE_DESCRIPTION;
    if (updateFields[0] === TASK_UPDATE_FIELD_STATUS) return UPDATE_TASK_STATUS_DESCRIPTION;
  }
  throw Error('invalid notification type or update fields');
};

export const getStatusIconUrl = (status: TaskStatus): string => {
  // add overdue here for backward compatibility
  if (status === 'todo' || status === 'overdue') return TODO_ICON_URL;
  if (status === 'inProgress') return IN_PROGRESS_ICON_URL;
  if (status === 'completed') return COMPLETED_ICON_URL;
  throw Error('invalid status value');
};

export const addCallForAction = (message: string): string => {
  const callForAction = '<br/> :point_down::point_down: <br/> *Supercharge your meetings with Shepherd today by*  <a href="chrome.google.com/webstore/detail/shepherd/mfdklpflbmbhldnjohkpdfaclaklphak">*Clicking Here*</a> :shepherd-logo:';
  const newMessage = message.concat(callForAction);
  return newMessage;
};

export const isEmailAlreadyAdded = (
  recipients: EmailRecipient[], email: string,
) => (!!recipients.find((recipient) => recipient.displayValue === email));
