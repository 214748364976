import React from 'react';
import styled from 'styled-components';
import { header900 } from '../../../shared/typography';

const Title = styled.span`
  ${header900}
`;

interface Props {
  firstName: string,
}

const TitleAtom = ({ firstName }: Props) => (
  <Title>
    {`Welcome back, ${firstName}!`}
  </Title>
);

export default TitleAtom;
