/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction } from 'react';
import { functions } from '../firebase';
import { NotionCreatePageData, NotionPageData, ResolveState } from '../../shared/types/types';

export const createAndSendNotionPage = (createPageObj: NotionCreatePageData[]) => functions()
  .httpsCallable('createNotionPage')(createPageObj);

export const getNotionWorkspacePages = (searchText: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>) => {
  functions().httpsCallable('getNotionWorkspacePagesV3')({ accessToken: token, query: searchText }).then((data) => {
    if (data.data.length !== 0) {
      // no need to map cf response to NotionPageData[] as mapping is done in cf
      const workspacePages: NotionPageData[] = data.data;
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState('rejected');
    }
  }).catch(() => {
    setResolveState('rejected');
  });
};

export const getNotionSubPages = (parentPageId: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>) => {
  functions().httpsCallable('getNotionSubPages')({ accessToken: token, parentPageId }).then((data) => {
    if (data.data.length !== 0) {
      const workspacePages: NotionPageData[] = data.data.map((page: any) => (
        {
          pageId: page.id,
          parentPageId: page.parent?.page_id || '',
          title: page.child_page.title,
          workspaceToken: token,
        }
      ));
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState('rejected');
    }
  }).catch(() => {
    setResolveState('rejected');
  });
};
