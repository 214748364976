import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbUserUpdateInfo } from '../../../../database/firebaseUsersAPI';
import { header200 } from '../../../typography';
import Switch from '../../switch';

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HeadText = styled.div`
  ${header200}
`;

const SwitchGroup = styled.div`
  padding-top: 12px;
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const NotificationSettings = () => {
  const [isTaskEmailNotificationEnable, setTaskEmailNotificationEnable] = useState<boolean>(false);
  const userData = useContext(UserDataContext);
  const authData = useContext(AuthContext);
  useEffect(() => {
    setTaskEmailNotificationEnable(userData.settings.receiveTaskEmail);
  }, [userData.settings.receiveTaskEmail]);
  const handleSetTaskEmailNotificationEnable = (value: boolean) => {
    dbUserUpdateInfo(authData.userId, { 'settings.receiveTaskEmail': value });
  };
  return (
    <Container>
      <HeadText>
        Notifications
      </HeadText>
      <SwitchGroup>
        <Switch
          label="Send me emails when a task concerning me is updated"
          checked={isTaskEmailNotificationEnable}
          setChecked={handleSetTaskEmailNotificationEnable as Dispatch<SetStateAction<boolean>>}
        />
      </SwitchGroup>
    </Container>
  );
};

export default NotificationSettings;
