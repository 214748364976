/* eslint-disable no-unused-vars */

import pixabayApiKey from '../../../utils/pixabayAPIKey';
import { MediaResources, MediaResource, ResponseToMediaResources } from './types';
import {
  PixbayParameters,
  PixbayResource,
  PixbayResponse,
  PixbayFetch,
  PixbayRawFetch,
  StringifiedBoolean,
} from './types/pixbay';

/**
 * Utility
 * @param bool boolean
 * @returns StringifiedBoolean
 */
const stringifiedBoolean = (bool: boolean): StringifiedBoolean => (bool ? 'true' : 'false');

/**
 * Convert Pixbay API parameters into URLSearchParams
 * @param unprepared Possibly unprepared PixbayParameters
 * @returns URLSearchParams that can be later be used to construct the fetch URL
 */
export const pixbayURLSearchParams = (unprepared: PixbayParameters): URLSearchParams => {
  const key = unprepared.key ?? pixabayApiKey;
  const searchTerm = unprepared.q.replace(/\s+/g, '+');

  const editorsChoice = stringifiedBoolean(unprepared.editorsChoice ?? false);
  const safesearch = stringifiedBoolean(unprepared.safesearch ?? true);

  const imageType = unprepared.imageType ?? 'photo';
  const lang = unprepared.lang ?? 'en';

  const page = String(unprepared.page ?? 1);
  const perPage = String(unprepared.perPage ?? 10);

  const params = new URLSearchParams();

  params.append('key', key);
  params.append('search_term', searchTerm);

  params.append('editors_choice', editorsChoice);
  params.append('safesearch', safesearch);

  params.append('image_type', imageType);
  params.append('lang', lang);

  params.append('page', page);
  params.append('perPage', perPage);

  return params;
};

/**
 * Default fetch, no catch
 * @param prepared prepared URLSearchParams, ready to fetch results
 * @returns Promise<any>
 */
export const pixbayRawFetch: PixbayRawFetch = (prepared) => fetch(`https://pixabay.com/api/?${prepared.toString()}`, {
  method: 'GET',
});

/**
 * Type safe fetch, no catch
 * @param rawInput magic input that fires the search
 * @returns Promise<PixbayResponse>
 */
export const pixbayFetch: PixbayFetch = (rawInput) => pixbayRawFetch(pixbayURLSearchParams({
  q: rawInput,
} as PixbayParameters)).then((response) => response.json() as Promise<PixbayResponse>);

/**
 * PixbayResponse to MediaResources converter
 * @param response PixbayResponse
 * @returns MediaResources
 */
export const pixbayToMediaResources:
ResponseToMediaResources<PixbayResponse> = (response) => {
  const array: MediaResources = response.hits.map((resource: PixbayResource): MediaResource => ({
    id: resource.id,
    thumbnailURL: resource.previewURL,
    webformatURL: resource.webformatURL,
  } as MediaResource));
  return array;
};
