import React from 'react';
import ShortcutSection from './ShortcutSection';
import {
  macBasicShortcuts,
  macCommonActionShortcuts,
  macTextFormattingShortcuts,
  macTextSelectionWithCursorShortcuts,
  macTextSelectionWithKeyboardShortcuts,
} from '../data/macShortcuts';
import {
  windowsBasicShortcuts,
  windowsCommonActionShortcuts,
  windowsTextFormattingShortcuts,
  windowsTextSelectionWithCursorShortcuts,
  windowsTextSelectionWithKeyboardShortcuts,
} from '../data/windowsShortcuts';
import { OperatingSystem } from '../../../types/types';

interface ShortcutsForOSProps {
  operatingSystem: OperatingSystem
}

const ShortcutsForOS = ({ operatingSystem }: ShortcutsForOSProps) => {
  const basicShortcutsTitle = 'Basics';
  const commonShortcutsTitle = 'Common Actions';
  const textFormattingShortcutsTitle = 'Text Formatting';
  const textSelectionKeyboardShortcutsTitle = 'Text Selection with Keyboard';
  const textSelectionCursorShortcutsTitle = 'Text Selection with Cursor';

  return (
    <>
      <ShortcutSection
        title={basicShortcutsTitle}
        shortcuts={operatingSystem === 'Mac' ? macBasicShortcuts : windowsBasicShortcuts}
      />
      <ShortcutSection
        title={commonShortcutsTitle}
        shortcuts={operatingSystem === 'Mac' ? macCommonActionShortcuts : windowsCommonActionShortcuts}
      />
      <ShortcutSection
        title={textFormattingShortcutsTitle}
        shortcuts={operatingSystem === 'Mac' ? macTextFormattingShortcuts : windowsTextFormattingShortcuts}
      />
      <ShortcutSection
        title={textSelectionKeyboardShortcutsTitle}
        shortcuts={operatingSystem === 'Mac' ? macTextSelectionWithKeyboardShortcuts : windowsTextSelectionWithKeyboardShortcuts}
      />
      <ShortcutSection
        title={textSelectionCursorShortcutsTitle}
        shortcuts={operatingSystem === 'Mac' ? macTextSelectionWithCursorShortcuts : windowsTextSelectionWithCursorShortcuts}
      />
    </>
  );
};

export default ShortcutsForOS;
