/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import { blue1, layoutGrey } from '../../../../shared/colours';
import NoTooltipIcon from '../../../../shared/components/profile-icon/noTooltipIcon';
import ReactTooltip from '../../../../shared/components/tooltip/ReactTooltip';
import PlusIcon from '../../../../shared/icons/plusIcon';
import SpotSeparator from '../../../../shared/icons/SpotSeparator';
import TickIcon from '../../../../shared/icons/TickIcon';
import { SecretChat, SecretChatAttendeeV2 } from '../../../../shared/types/types';
import { mapSecretChatAttendeeV2ToAttendeeV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import ActionButton from './ActionButton';
import PrivateNoteTextAtom from './PrivateNoteTextAtom';

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -12px;
`;

const BottomContainer = styled.div<BottomContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${(props) => (props.isPlusIcon ? '22px' : '15px')};
`;

const OuterContainer = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isSelected ? 'center' : 'flex-start')};
  background-color: ${(props) => (props.isSelected ? blue1 : 'inherit')};
  padding: 8px;
  border-radius: 8px;

  :hover{
    background-color: ${layoutGrey};
  }
`;

const InnerLeftContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 16px;
  padding: 7px;
  border-radius: 8px;
  :hover{
    background-color: ${layoutGrey};
  }
`;

const InnerMidContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    :first-child {
      margin-right: 6px;
    }

    :last-child {
      margin-left: 6px;
    }
  }
`;

const MiddleContainer = styled.div`
`;

interface BottomContainerProps {
  isPlusIcon: boolean,
}

interface ContainerProps {
  isSelected: boolean,
}

interface Props {
  secretChat: SecretChat,
  isSelected: boolean,
  // eslint-disable-next-line no-unused-vars
  setChatToEdit: Dispatch<SetStateAction<SecretChat>>,
  setEditModalOpen: Dispatch<SetStateAction<boolean>>,
}

const ExtraChatMolecule = ({
  secretChat,
  isSelected,
  setChatToEdit,
  setEditModalOpen,
}: Props) => {
  const userData = useContext(UserDataContext);
  const { members } = secretChat;
  const firstMember = members[0];
  const extraMembers = members.slice(1, members.length) ?? [];
  const chatTitle = secretChat.title === '' ? 'Private note' : secretChat.title;

  const handleShareClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    console.log('share clicked');
  };

  const handleMembersTipMapping = (member: SecretChatAttendeeV2) => {
    if (userData.userId === member.userId) return `${member.data.name} (You) <br/>`;
    return `${member.data.name} <br/>`;
  };
  const membersNamesTip = secretChat.members.map(handleMembersTipMapping).join().replaceAll(',', '');

  const handleEditModalOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setEditModalOpen(true);
    setChatToEdit(secretChat);
  };

  return (
    <OuterContainer isSelected={isSelected}>
      <InnerMidContainer>
        <ReactTooltip tip={membersNamesTip} place="top">
          <InnerLeftContainer>
            <TopContainer>
              <NoTooltipIcon user={firstMember} />
            </TopContainer>
            <BottomContainer isPlusIcon={extraMembers.length > 1}>
              <PlusIcon
                attendees={extraMembers.map(mapSecretChatAttendeeV2ToAttendeeV2)}
                zIndex={30}
                noTooltip
              />
            </BottomContainer>
          </InnerLeftContainer>
        </ReactTooltip>
        <MiddleContainer>
          <PrivateNoteTextAtom text={chatTitle} />
          <ActionButtonsContainer>
            <ActionButton text="Share" onClick={(event) => handleShareClick(event)} />
            <SpotSeparator />
            <ActionButton text="Edit" onClick={(e) => handleEditModalOpen(e)} />
          </ActionButtonsContainer>
        </MiddleContainer>
      </InnerMidContainer>
      {
        isSelected && (
          <TickIcon />
        )
      }
    </OuterContainer>
  );
};

export default ExtraChatMolecule;
