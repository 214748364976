import React from 'react';
import styled from 'styled-components';
import { blue6, layoutWhite } from '../../../../../shared/colours';
import PlusIcon from '../../../../../shared/components/prosemirror/components/icons/plus';
import ButtonTextAtom from './ButtonTextAtom';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px 10px 14px;
  border-radius: 6px;

  :hover {
    background-color: ${layoutWhite};
  }
`;

interface Props {
  onClick: () => void,
}

const PrivateSpaceButtonMolecule = ({ onClick }: Props) => (
  <Container onClick={onClick}>
    <PlusIcon fill={blue6} />
    <ButtonTextAtom text="Create another Private Note" />
  </Container>
);

export default PrivateSpaceButtonMolecule;
