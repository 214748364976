import React from 'react';
import styled from 'styled-components';
import { gray1, darkBlue1 } from '../../../../../shared/colours';
import TrashBinIcon from '../../../../../shared/icons/TrashBinIcon';
import DeleteButtonAtom from './DeleteButtonAtom';
import QuestionTextAtom from './QuestionTextAtom';
import UndoButtonAtom from './UndoButtonAtom';

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px;
  box-shadow: 0px 14px 24px rgba(27, 33, 36, 0.16), 0px 8px 8px -4px rgba(27, 33, 36, 0.04);
  border-radius: 16px;
  background: ${gray1};
  z-index: 100;
  left: 50px;
  bottom: 600px;

  svg {
    margin-right: 8px;
  }
`;

const UndoCrossIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-left: 150px;
  border-left: 1px solid ${darkBlue1};

  & > span {
    margin-left: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  onDeletePrivateNote: () => void,
  refOutsideClick: React.MutableRefObject<null>,
  handleUndo: () => void,
}

const DeleteConfirmationModal = ({
  isOpen, onDeletePrivateNote, handleUndo, refOutsideClick,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Container ref={refOutsideClick}>
      <TrashBinIcon />
      <QuestionTextAtom text="Are you sure?" />
      <UndoCrossIconContainer>
        <UndoButtonAtom onClick={handleUndo} text="Cancel" />
        <DeleteButtonAtom onClick={onDeletePrivateNote} />
      </UndoCrossIconContainer>
    </Container>
  );
};

export default DeleteConfirmationModal;
