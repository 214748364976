import React, { useState } from 'react';
import styled from 'styled-components';
import {
  darkBlue1, darkBlue4, darkBlue6, gray1,
} from '../../../../shared/colours';
import PaperLockIcon from '../../../../shared/icons/PaperLockIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    color: ${darkBlue6};
    background: ${darkBlue1};      
  }
`;

const PaperLockButtonAtom = () => {
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  return (
    <Container onMouseOver={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
      <PaperLockIcon fill={darkBlue4} innerFill={isMouseOver ? darkBlue1 : gray1} />
    </Container>
  );
};

export default PaperLockButtonAtom;
