/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { dbUpdateSecretChatMembers } from '../../../../database/firebaseSecretChatAPI';
import { SecretChatData } from '../../../../shared/types/types';
import { getTimeFromNow } from '../../../../utils/dateUtils/date';
import MeetingDataContext from '../../context/MeetingDataContext';
import { returnNotifiedMembers, sortSecretChatDataChronologically } from '../../notes/secret-chat/secretChatUtils';
import PrivateSpaceInvitedPopupOrganism from './PrivateSpaceInvitedPopupOrganism';

const PrivateSpacePopupContainer = styled.div`
  position: absolute;
  left: 56px;
  top:30%;
  z-index: 100;
`;

interface Props {
  secretChat: SecretChatData[],
  isOpen: boolean,
  handleSecretChatSelected: (chatId: string) => void,
  setClosed: () => void,
}

const PrivateSpaceInvitedCtrl = ({
  secretChat,
  isOpen,
  handleSecretChatSelected,
  setClosed,
}: Props) => {
  const chronologicallySortedChats = sortSecretChatDataChronologically(secretChat);
  const latestChat = chronologicallySortedChats[0];
  if (!isOpen) return null;
  if (typeof latestChat === 'undefined') return null;

  const authState = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const currentUser = latestChat?.members?.find((member) => (member.userId === authState.userId));

  if (currentUser?.isNotified) return null;
  if (latestChat.creator.userId === authState.userId) return null;

  const handleViewChat = () => {
    handleSecretChatSelected(latestChat.chatId);
    updateMembers();
    setClosed();
  };

  const updateMembers = () => {
    const updatedMembers = returnNotifiedMembers(latestChat, authState.userId);
    dbUpdateSecretChatMembers(
      latestChat.title,
      meetingData.meetingId,
      latestChat.chatId,
      updatedMembers,
    );
  };

  const handleSetClosed = () => {
    updateMembers();
    setClosed();
  };

  const created = latestChat?.created?.date ?? new Date().toDateString();

  const createdAgo = getTimeFromNow(created);
  return (
    <PrivateSpacePopupContainer>
      <PrivateSpaceInvitedPopupOrganism
        isOpen={isOpen}
        createdAgo={createdAgo}
        creator={latestChat.creator}
        setClosed={handleSetClosed}
        viewChat={handleViewChat}
      />
    </PrivateSpacePopupContainer>
  );
};

export default PrivateSpaceInvitedCtrl;
