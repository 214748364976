import React from 'react';
import styled from 'styled-components';
import ActionButton from './ActionButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    margin-right: 12px;
  }
`;

interface Props {
  setClosed: () => void,
  viewChat: () => void,
}

const ActionButtonsMolecule = ({ setClosed, viewChat }: Props) => (
  <Container>
    <ActionButton onClick={setClosed} isDismiss text="Dismiss" />
    <ActionButton onClick={viewChat} text="View" />
  </Container>
);

export default ActionButtonsMolecule;
