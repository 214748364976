import React from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../../../shared/components/profile-icon';
import PlusIcon from '../../../../shared/icons/plusIcon';
import { SecretChat } from '../../../../shared/types/types';
import { mapSecretChatAttendeeV2ToAttendeeV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlusIconContainer = styled.div`
  margin-left: 0px;
`;

const SecondIconContainer = styled.div`
  margin-left: -5px;
`;

interface Props {
  secretChat: SecretChat,
}

const SecretChatBubbles = ({ secretChat }: Props) => {
  const attendeeProfiles = secretChat.members;
  const firstAttendee = attendeeProfiles[0];
  const secondAttendee = attendeeProfiles[1] ?? null;

  if (secretChat.members.length === 1) {
    return (
      <Container>
        <ProfileIcon user={firstAttendee} size={16} />
      </Container>
    );
  }
  if (secretChat.members.length > 2) {
    return (
      <Container>
        <ProfileIcon user={firstAttendee} size={16} />
        <PlusIconContainer>
          <PlusIcon
            attendees={attendeeProfiles.slice(1, attendeeProfiles.length).map(
              mapSecretChatAttendeeV2ToAttendeeV2,
            )}
            size={19}
            zIndex={20}
          />
        </PlusIconContainer>
      </Container>
    );
  }
  return (
    <Container>
      <ProfileIcon user={firstAttendee} size={16} />
      <SecondIconContainer>
        <ProfileIcon user={secondAttendee} size={16} />
      </SecondIconContainer>
    </Container>
  );
};

export default SecretChatBubbles;
