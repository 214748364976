import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import FirepadComponent from '../../../shared/components/firepad/component';
import { getFirepadPathV2, getFirepadSecretChatPath } from '../../../utils/firebase';
import ProseMirrorComponent from '../../../shared/components/prosemirror/index';
import MeetingDataContext from '../context/MeetingDataContext';
import PreviousMeetingDataContext from '../../../shared/components/previous-meeting-notes/context/PreviousMeetingDataContext';
import NotesLoading from './NotesLoading';
import { NoteType, Shortcut } from '../../../shared/types/types';
import { startTrackingEvent, stopTrackingEvent } from '../../../utils/analytics/eventLogger';
import { AGENDA } from '../../../utils/enums';

interface NotesProps {
  page: NoteType,
  userId: string,
  meetingId: string,
  setNotesContent: Dispatch<SetStateAction<string>>,
  setIsSynchronized: Dispatch<SetStateAction<boolean>>
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>
  isPreviousMeeting?: boolean
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  displayNotes?: boolean,
  secretChatId?: string,
}

const Notes = ({
  page,
  userId,
  meetingId,
  setNotesContent,
  setIsSynchronized,
  setProseMirrorEditorView,
  isPreviousMeeting = false,
  onShortcutTrigger,
  displayNotes = true,
  secretChatId,
}: NotesProps) => {
  // We had an issue with the notes, that when running the CE to point to localhost,
  // and creating a new meeting in calendar then we would get an Permissions Denied error.
  // This was most likely because of realtime database security rules
  // Specificially the ".validate": "newData.hasChild('history')"
  // With the new database architecture, hopefully this issue will be resolved

  const meetingData = isPreviousMeeting
    ? useContext(PreviousMeetingDataContext)
    : useContext(MeetingDataContext);

  useEffect(() => {
    if (meetingData.resolvedState === 'pending') {
      startTrackingEvent('notesLoad');
    } else {
      stopTrackingEvent('notesLoad');
    }
  }, [meetingData.resolvedState]);

  if (meetingData.resolvedState === 'pending') return <NotesLoading />;

  if (secretChatId?.length !== 0 && page === 'secret') {
    if (meetingData.version >= 3) {
      return (
        <ProseMirrorComponent
          path={getFirepadSecretChatPath(secretChatId!, 'prosemirror')}
          page={page}
          setHtml={setNotesContent}
          setIsSynchronized={setIsSynchronized}
          setProseMirrorEditorView={setProseMirrorEditorView}
          displayNotes={displayNotes}
          isPreviousMeeting={isPreviousMeeting}
        />
      );
    }
    return (
      <>
        <FirepadComponent
          path={getFirepadSecretChatPath(secretChatId!, 'firepad')}
          setIsSynchronized={setIsSynchronized}
          setHtml={setNotesContent}
          page={page}
          isPreviousMeeting={isPreviousMeeting}
          onShortcutTrigger={onShortcutTrigger}
        />
      </>
    );
  }
  if (meetingData.version >= 4 && page === AGENDA) return null;

  if (meetingData.version >= 3) {
    return (
      <ProseMirrorComponent
        path={getFirepadPathV2(page, meetingId, 'prosemirror', userId)}
        page={page}
        setHtml={setNotesContent}
        setIsSynchronized={setIsSynchronized}
        setProseMirrorEditorView={setProseMirrorEditorView}
        displayNotes={displayNotes}
        isPreviousMeeting={isPreviousMeeting}
      />
    );
  }
  return (
    <>
      <FirepadComponent
        path={getFirepadPathV2(page, meetingId, 'firepad', userId)}
        setIsSynchronized={setIsSynchronized}
        setHtml={setNotesContent}
        page={page}
        isPreviousMeeting={isPreviousMeeting}
        onShortcutTrigger={onShortcutTrigger}
      />
    </>
  );
};

Notes.defaultProps = {
  isPreviousMeeting: false,
  displayNotes: true,
  secretChatId: '',
};

export default Notes;
