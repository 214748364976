import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { dbListenToTasksForUser } from '../../database/firebaseTasksAPI';
import { rejectedMeetingData } from '../../database/utils/templateMeetingData';
import CreateNewTaskModal from '../../shared/components/task-modal/CreateNewTaskModal';
import Tasks from '../../shared/components/tasks';
import { TaskItem, UserCenterSection } from '../../shared/types/types';
import { ALL_TASKS_PAGE } from '../../utils/enums';
import WelcomeBanner from './welcomeBanner';

// const TaskTabMaxHeight = 'calc(100vh - 48px - 49px - 110px - 127px)';
const TaskTabMaxHeight = 'calc(100vh - 48px - 110px - 110px)';

interface Props {
  section: UserCenterSection,
}

const AllTasksOrganism = ({ section }: Props) => {
  // TODO Harald: Add loading state while getting tasks from database
  const authState = useContext(AuthContext);
  const [taskItems, setTaskItems] = useState<TaskItem[]>([]);
  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  const [isWelcomeBannerOpened, setWelcomeBannerClosed] = useState<boolean>(true);

  useEffect(() => {
    dbListenToTasksForUser(authState.email, setTaskItems);
  }, [authState]);

  const handleWelcomeBannerClose = () => {
    setWelcomeBannerClosed(false);
  };

  const handleCloseCreateTaskModal = () => setCreateTaskModalOpen(false);

  if (section !== 'tasks') return null;
  return (
    <div>
      <>
        <WelcomeBanner
          handleWelcomeBannerClose={handleWelcomeBannerClose}
          section={section}
          isClosed={!isWelcomeBannerOpened}
        />
        <Tasks
          taskItems={taskItems}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          height={TaskTabMaxHeight}
          page={ALL_TASKS_PAGE}
          disableAssigneeSelect
        />
        <CreateNewTaskModal
          meetingData={rejectedMeetingData}
          isModalOpen={isCreateTaskModalOpen}
          setModalClosed={handleCloseCreateTaskModal}
        />
      </>
    </div>
  );
};

export default AllTasksOrganism;
