import React, {
  ChangeEvent, Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { gray2, layoutWhite, surface } from '../../../../shared/colours';
import { messageText, header200 } from '../../../../shared/typography';
import CrossIcon from '../../../../shared/icons/cross';
import DownArrow from '../../../../shared/icons/downArrow';
import UpArrow from '../../../../shared/icons/upArrow';
import ArrowRightMini from '../../../../shared/icons/arrowRightMini';
import ArrowLeftMini from '../../../../shared/icons/arrowLeftMini';
import CssSpinner from '../../../../shared/components/css-spinner';
import { NotionData, NotionPageData, ResolveState } from '../../../../shared/types/types';
import {
  ArrowContainer, ButtonContainer, CrossContainer, InputCrossContainer,
  InputItem, InputItemContainer, InsideContainer, Menu, MultiInputContainer,
} from '../../../../shared/components/integration-multi-input';
import { getNotionSubPages, getNotionWorkspacePages } from '../../../../utils/notion/notionAPIs';
import useDebounce from '../../../../utils/hook/useDebounce';
import useSkipEffect from '../../../../utils/hook/skipUseEffectRun';
import InputPlaceholder from '../integrations/InputPlaceholder';
import Scrollbar from '../../../../shared/components/scrollbar';

const DropdownNavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 6px 12px;
  width: fit-content;
  height: 32px;
  margin-right: 4px;
  border-radius: 8px;
  border: 2px solid #E7E9EF;
  background: none;
  color: ${surface};
  ${header200};
  cursor: pointer;
`;

const InputSearchContainer = styled.input`
  background-color: ${gray2};
  outline: none;
  border: none;
  margin-left: 4px;
`;

const RightIconContainer = styled.div`
  float:right;
  padding-top:6px;
`;

const LeftIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 10px;
`;

const LoaderContainer = styled.div`
  ${messageText}
  text-align: center;
`;

const PagesContainer = styled.div`
  display: flex;
  div:last-child {
    padding: 6px 15px;
    cursor: pointer;
    :hover{
      background-color: ${layoutWhite};
    }
  }
`;

interface Props {
  workspace: NotionData[],
  isChecked: boolean,
  setPagesToReceive: Dispatch<SetStateAction<NotionPageData[]>>,
}

const NotionMultiInput = ({
  workspace, isChecked, setPagesToReceive,
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [navViewWorkspace, setNavViewWorkspace] = useState(true);
  const [navViewBack, setNavViewBack] = useState(false);
  const [pageFetchLoad, setPageFetchLoading] = useState<ResolveState>('resolved');
  const [pages, setPages] = useState<NotionPageData[]>([]);
  const [pagesToSendTo, setPagesToSendTo] = useState<NotionPageData[]>([]);
  const [subPageHistory, setSubPageHistory] = useState<NotionPageData[]>([]);
  const [searchPageText, setSearchPageText] = useState<string>('');
  const [workspaceToken, setWorkspaceToken] = useState<string>('');
  const debouncedSearchTerm = useDebounce<string>(searchPageText, 1000);
  const [workspaceName, setWorkspaceName] = useState<string>('');

  const shouldShowPlaceholder = pagesToSendTo.length === 0 && navViewWorkspace;

  useEffect(() => {
    setPagesToReceive(pagesToSendTo);
  }, [pagesToSendTo]);

  useSkipEffect(() => {
    getWorkspacePages(debouncedSearchTerm, workspaceToken);
  }, [debouncedSearchTerm], 1);

  const getWorkspacePages = (searchText: string = '', token: string = '') => {
    if (token.length === 0) return;
    setPageFetchLoading('pending');
    getNotionWorkspacePages(searchText, token, setPages, setPageFetchLoading);
  };

  const handleArrowClick = () => {
    setIsActive(!isActive);
  };

  const handleDropdownWorkspaceClicked = (item: NotionData) => {
    setWorkspaceToken(item.accessToken);
    setPages([]);
    setSearchPageText('');
    setNavViewWorkspace(false);
    setWorkspaceName(item.workspaceName);
    /*
  need help: i want to call getWorkspacePages() only after setWorkspaceToken() is set but i
  noticed that getWorkspacePages() gets called before state is updated and so getWorkspacePages()
  gets called with empty token param as a reason i had to pass the token explicitly
  */
    getWorkspacePages('', item.accessToken);
  };

  const handleDropdownPageClicked = (selectedPage: NotionPageData) => {
    setSearchPageText('');
    if (pagesToSendTo.filter(
      (page: NotionPageData) => page.pageId === selectedPage.pageId,
    ).length > 0) return;
    setPagesToSendTo([...pagesToSendTo, selectedPage]);
  };

  const handleChildPageClicked = (selectedPage: NotionPageData, isBackAction: Boolean = false) => {
    setPages([]);
    if (!isBackAction) {
      setSubPageHistory([...subPageHistory, selectedPage]);
    }
    setNavViewBack(true);
    setPageFetchLoading('pending');
    getNotionSubPages(selectedPage.pageId, workspaceToken,
      setPages, setPageFetchLoading);
  };

  const handleRemoveChannel = (
    selectedPage: NotionPageData,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const tempChannels = pagesToSendTo.filter(
      (page: NotionPageData) => page.pageId !== selectedPage.pageId,
    );
    setPagesToSendTo(tempChannels);
  };

  const handleWorkspaceButtonClick = () => {
    setPageFetchLoading('resolved');
    setNavViewWorkspace(true);
  };

  const handleBackButtonClick = () => {
    setPages([]);
    if (subPageHistory.length === 1) {
      setSubPageHistory([]);
      getWorkspacePages('', workspaceToken);
      setNavViewBack(false);
    } else {
      const modifiedHistory = subPageHistory.splice(0, subPageHistory.length - 1);
      setSubPageHistory(modifiedHistory);
      handleChildPageClicked(modifiedHistory[modifiedHistory.length - 1], true);
    }
  };

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(true);
    setSearchPageText(event.target.value);
  };

  const handleContainerClick = () => {
    setIsActive(!isActive);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  if (!isChecked) return null;

  return (
    <>
      <MultiInputContainer>
        <InsideContainer onClick={handleContainerClick}>
          <InputItemContainer>
            <InputPlaceholder shouldShow={shouldShowPlaceholder} placeholder="Select Workspace" />
            {pagesToSendTo.map((inputChannel) => (
              <InputCrossContainer key={inputChannel.pageId}>
                <InputItem>{inputChannel.title}</InputItem>
                <CrossContainer onClick={(event) => handleRemoveChannel(inputChannel, event)}>
                  <CrossIcon />
                </CrossContainer>
              </InputCrossContainer>
            ))}
            {!navViewWorkspace && isActive
              && <InputSearchContainer value={searchPageText} onClick={(event) => handleInputClick(event)} onChange={handleSearchInput} placeholder="Search Notion Page" />}
          </InputItemContainer>
          <ArrowContainer onClick={handleArrowClick}>
            {isActive ? <UpArrow /> : <DownArrow />}
          </ArrowContainer>
        </InsideContainer>
      </MultiInputContainer>
      <Scrollbar maxHeight="29vh">
        <Menu data-active={isActive}>
          {!navViewWorkspace && (
          <ButtonContainer>
            <DropdownNavButton
              onClick={navViewBack ? handleBackButtonClick : handleWorkspaceButtonClick}
            >
              <LeftIconContainer>
                <ArrowLeftMini />
              </LeftIconContainer>
              {navViewBack ? 'Back' : `${workspaceName}`}
            </DropdownNavButton>
          </ButtonContainer>
          )}
          <ul>
            {
              navViewWorkspace ? (
                workspace.map((workspaceObj: NotionData) => (
                  <li key={workspaceObj.workspaceId}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownWorkspaceClicked(workspaceObj);
                      }}
                    >
                      {workspaceObj.workspaceName}
                      <RightIconContainer>
                        <ArrowRightMini />
                      </RightIconContainer>
                    </button>
                  </li>
                ))
              ) : (
                pages.map((pageObj: NotionPageData) => (
                  <li key={pageObj.pageId}>
                    <PagesContainer>
                      <button type="button" onClick={() => handleDropdownPageClicked(pageObj)}>
                        {pageObj.title}
                      </button>
                      <RightIconContainer onClick={() => handleChildPageClicked(pageObj)}>
                        <ArrowRightMini />
                      </RightIconContainer>
                    </PagesContainer>
                  </li>
                ))
              )
            }
            {pageFetchLoad === 'pending'
              && (
                <LoaderContainer>
                  <CssSpinner color={surface} size={30} thickness={4} padding={4} />
                </LoaderContainer>
              )}
            {pageFetchLoad === 'rejected'
              && (
                <LoaderContainer>
                  <span>No notion page found</span>
                </LoaderContainer>
              )}

          </ul>
        </Menu>
      </Scrollbar>
    </>
  );
};

export default NotionMultiInput;
