import React from 'react';
import styled from 'styled-components';
import Modal from '../../shared/components/Modal';
import ModalFooterButtonsRightAlignedWrapper from '../../shared/components/Modal/ModalFooterButtonsRightAlignedWrapper';
import ModalBodyWrapper from '../../shared/components/Modal/ModalBodyWrapper';
import ModalText from '../../shared/components/Modal/ModalText';
import ButtonSmall from '../../shared/components/button-small';
import Image from '../../shared/img/permission_grant_scopes.png';

const ImageHolder = styled.img`
  width: 100%;
  object-fit: contain;
`;

interface Props {
  isModalOpen: boolean,
  onCancelClick: () => void,
  onRetryClick: () => void,
}

const RetryAuthModal = ({ isModalOpen, onCancelClick, onRetryClick }: Props) => (
  <Modal isOpen={isModalOpen} setModalClosed={onCancelClick} title="Permissions required">
    <ModalBodyWrapper>
      <ModalText>
        Please make sure you have checked all the required permissions:
      </ModalText>
      <ImageHolder src={Image} />
    </ModalBodyWrapper>
    <ModalFooterButtonsRightAlignedWrapper>
      <ButtonSmall onClick={onCancelClick} text="Cancel" isOutline />
      <ButtonSmall onClick={onRetryClick} text="Retry" />
    </ModalFooterButtonsRightAlignedWrapper>
  </Modal>
);

export default RetryAuthModal;
