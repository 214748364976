import React from 'react';
import styled from 'styled-components';
import { header600, uiText } from '../../../../shared/typography';
import ConnectedSuccess from '../../../../shared/img/open_doodles_roller_skating.png';
import { darkBlue4 } from '../../../../shared/colours';

const OuterSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const HeadText = styled.h1`
  ${header600};
  margin: 16px 37.5px 8px 37.5px;
  text-align: center;
`;

const BottomText = styled.p`
  ${uiText};
  color: ${darkBlue4};
  text-align: center;
`;

const Image = styled.img`
  width: 135px;
  height: 120px;
`;

interface Props {
 headText: string,
 bottomText: string
}

export default function IntegrationSuccessModal({ headText, bottomText }:Props) {
  return (
    <div>
      <OuterSuccessContainer>
        <SuccessContainer>
          <Image src={ConnectedSuccess} />
          <HeadText>{headText}</HeadText>
          <BottomText>
            {bottomText}
          </BottomText>
        </SuccessContainer>
      </OuterSuccessContainer>
    </div>
  );
}
