import React from 'react';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import { darkBlue4 } from '../../../colours';
import ShepherdLogo from '../../../icons/shepherd-logo';
import { uiTextMedium } from '../../../typography';

interface Props{
  text: string
}

const MeetingLoadingPage = ({ text }:Props) => (
  <Container>
    <ShepherdLogo width={267} spin fadeIn={false} />
    <Text>{text}</Text>
  </Container>
);

export default MeetingLoadingPage;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: ${SIDEBAR_WIDTH};
  height: 86vh;
`;

const Text = styled.p`
  ${uiTextMedium};
  color: ${darkBlue4};
  margin-top: 16px
`;
