/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, {
  createContext,
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { useIntercom } from 'react-use-intercom';
import { MeetingSections, Note } from '../../types/types';
import useProseMirrorFirebase from './hooks/use-prose-mirror-firebase';
import 'prosemirror-menu/style/menu.css';
import 'prosemirror-view/style/prosemirror.css';
import './styles/menu.css';
import './styles/editor.css';
import './styles/images.css';
import FileUploadModal from '../Modal/FileUploadModal';
import EmojiFloatingPanel from './components/emoji-floating-panel';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';
import useEmojiSuggester from './hooks/use-emoji-suggester';
import useMentionsSuggester from './hooks/use-mention-suggester';
import MentionsFloatingPanel from './components/mentions-floating-panel';
import useCommandsSuggester from './hooks/use-command-suggester';
import CommandsFloatingPanel from './components/commands-floating-panel';
import CustomEditorMenu from './components/menu';
import GifSearchModal from '../Modal/GifSearchModal';
import keymapAdapter from './logic/keymap/keymap-adapter';
import triggerLogEvent from './analytics';
import { SidebarStateContext } from '../../../ListenToSidebarMessageContainer';
import MeetingAnalyticsDataContext from '../../../pages/meeting/context/MeetingAnalyticsDataContext';
import { AuthContext } from '../../../App';
import { IsOnlineContext } from '../../../utils/context/DetectInternetConnectionContainer';
import RevisionModal from '../Modal/RevisionModal';
import { darkBlue1 } from '../../colours';
import { uiTextMedium } from '../../typography';

export interface EditorContextType {
  update: Object;
  setUpdate: CallableFunction;
  view: EditorView | null;
  openImageModal: CallableFunction;
  openGifModal: CallableFunction;
  openRevisionModal: CallableFunction;
}

export const EditorContext = createContext<EditorContextType | null>(null);

interface ProseMirrorProps {
  path: string;
  canEdit?: boolean;
  page: MeetingSections;
  displayNotes: boolean
  setHtml?: Dispatch<SetStateAction<string>>,
  setIsSynchronized?: Dispatch<SetStateAction<boolean>>
  setProseMirrorEditorView?: Dispatch<SetStateAction<any>>
  setMeetingNotes?: Dispatch<SetStateAction<Note>>
  setSecretNotes?: Dispatch<SetStateAction<Note[]>>
  isPreviousMeeting?: boolean
}

interface EditorContainerStyleProp {
  displayNotes: boolean
  isPreviousMeeting: boolean
  isOnline: boolean,
}

const EditorContainer = styled.div<EditorContainerStyleProp>`
  ${({ displayNotes }) => (!displayNotes) && css`
    display: none;
  `}
  ${({ isPreviousMeeting }) => (isPreviousMeeting) && css`
    height: 100%;
  `}
  // TODO Alex: We might not need this after you completely remove the text editor
  ${({ isOnline }) => (!isOnline) && css` 
    opacity: 0.5;
  `}
`;

const LostConnectionTab = styled.div`
  height: calc(100vh - 320px);
  width: 100%;
  background-color: ${darkBlue1};
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  ${uiTextMedium}
`;

interface EditorStyleProp {
  isPreviousMeeting: boolean
}

const Editor = styled.div<EditorStyleProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  // Be careful about this!
  // Will affect the horizontal scrolling
  height: ${({ isPreviousMeeting }) => (isPreviousMeeting ? '100%' : 'calc(100vh - 320px - 30px)')};
  overflow-y: auto;
  margin-bottom: 0;
  cursor: text;
`;

const ProseMirrorComponent = ({
  path,
  canEdit,
  page,
  displayNotes,
  setHtml,
  setIsSynchronized,
  setProseMirrorEditorView,
  setMeetingNotes,
  setSecretNotes,
  isPreviousMeeting = false,
}: ProseMirrorProps) => {
  const isSidebarOpen = useContext(SidebarStateContext);
  const meetingAnalyticsData = useContext(MeetingAnalyticsDataContext);
  const { meetingId } = useContext(MeetingDataContext);
  const { userId } = useContext(AuthContext);
  const { trackEvent } = useIntercom();

  const [modal, setModal] = useState(false);

  const [gifModal, setGifModal] = useState(false);

  const [revisionModal, setRevisionModal] = useState(false);

  const [update, setUpdate] = useState({});

  const isOnline = useContext(IsOnlineContext);

  const {
    emojiShow,
    emojiLeft,
    emojiTop,
    emojiContents,
    emojiSelected,
  } = useEmojiSuggester();

  const {
    mentionsShow,
    mentionsLeft,
    mentionsTop,
    mentionsContents,
    mentionsSelected,
  } = useMentionsSuggester();

  const [proseMirrorEditor,
    editorView,
    editorSchema,
    getDbRef,
    editorReadonly,
  ] = useProseMirrorFirebase({
    path,
    canEdit,
    page,
    toggleImageUpload: () => { setModal(true); },
  });

  const {
    commandsShow,
    commandsLeft,
    commandsTop,
    commandsContents,
    commandsSelected,
  } = useCommandsSuggester({
    view: editorView!,
    openGifModal: () => {
      setGifModal(true);
    },
  });

  const [showDisconnected, setShowDisconnected] = useState(false);

  const [
    disconnectTimeout,
    setDisconnectTimeout,
  ] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);

  /**
   * Since
   */
  useEffect(() => {
    if (editorReadonly) {
      setDisconnectTimeout(setTimeout(() => setShowDisconnected(true), 3000));
    } else {
      if (typeof disconnectTimeout !== 'undefined') {
        clearTimeout(disconnectTimeout);
      }
      setShowDisconnected(false);
    }
    return () => {
      if (typeof disconnectTimeout !== 'undefined') {
        clearTimeout(disconnectTimeout);
      }
    };
  }, [editorReadonly]);

  useEffect(() => {
    if (!editorView) return;

    if (setProseMirrorEditorView) setProseMirrorEditorView({ page, editorView });
    if (setMeetingNotes) setMeetingNotes(editorView.dom?.innerHTML ?? '');
    if (setSecretNotes) setSecretNotes((prevState: Note[]) => [...prevState, editorView.dom?.innerHTML ?? '']);
  }, [editorView]);

  useEffect(() => {
    if (!editorView) return;

    triggerLogEvent(
      isSidebarOpen,
      page,
      meetingAnalyticsData,
      editorView.dom?.innerHTML ?? '',
      meetingId,
      trackEvent,
      userId,
    );
  }, [editorView?.dom?.innerHTML, meetingAnalyticsData, meetingId, userId, isSidebarOpen]);

  return (
    <EditorContext.Provider
      value={{
        update,
        setUpdate,
        view: editorView,
        openImageModal: () => {
          setModal(true);
        },
        openGifModal: () => {
          setGifModal(true);
        },
        openRevisionModal: () => {
          setRevisionModal(true);
        },
      }}
    >
      <EditorContainer
        displayNotes={displayNotes}
        isPreviousMeeting={isPreviousMeeting}
        isOnline={isOnline}
      >
        <CustomEditorMenu
          hide={editorReadonly}
          schema={editorSchema!}
          view={editorView!}
          setUpdate={setUpdate}
        />
        <Editor
          id="editor"
          className={`${editorReadonly ? 'readonly-grey' : ''}`}
          style={editorReadonly ? { display: 'none' } : {}}
          ref={proseMirrorEditor}
          isPreviousMeeting={isPreviousMeeting}
          onKeyDown={(e) => {
            // On Ctrl + K, activate the link menu.
            if (e.key === 'k' && e.ctrlKey) {
              e.preventDefault();
              keymapAdapter.openLinkMenu();
              // keymapAdapter.focusLinkMenu();

              // On Ctrl + /, clear formatting.
            } else if (e.key === '/' && e.ctrlKey) {
              e.preventDefault();
              keymapAdapter.clearFormatting(editorView);

              // On Escape, close some menus.
            } else if (e.key === 'Escape') {
              keymapAdapter.closeLinkMenu();
            }
            setUpdate({});
          }}
          onClick={() => {
            if (!editorView?.hasFocus()) {
              editorView?.focus();
            }
            setUpdate({});
          }}
          onKeyUp={() => { setUpdate({}); }}
          onKeyPress={() => { setUpdate({}); }}
        />
        <FileUploadModal
          path={path}
          view={editorView!}
          schema={editorSchema!}
          isOpen={modal}
          setModalClosed={() => setModal(false)}
        />
        <GifSearchModal
          path=""
          view={editorView!}
          schema={editorSchema!}
          isOpen={gifModal}
          setModalClosed={() => setGifModal(false)}
        />
        <RevisionModal
          view={editorView!}
          schema={editorSchema!}
          isOpen={revisionModal}
          getDbRef={getDbRef}
          setModalClosed={() => setRevisionModal(false)}
        />
        <EmojiFloatingPanel
          show={emojiShow[0]}
          left={emojiLeft[0]}
          top={emojiTop[0]}
          contents={emojiContents[0]}
          selectedIndex={emojiSelected[0]}
        />
        <MentionsFloatingPanel
          show={mentionsShow[0]}
          left={mentionsLeft[0]}
          top={mentionsTop[0]}
          contents={mentionsContents[0]}
          selectedIndex={mentionsSelected[0]}
        />
        <CommandsFloatingPanel
          show={commandsShow[0]}
          left={commandsLeft[0]}
          top={commandsTop[0]}
          contents={commandsContents[0]}
          selectedIndex={commandsSelected[0]}
        />
      </EditorContainer>
      {showDisconnected
        && (
          <LostConnectionTab>
            Lost connection to the notes.
          </LostConnectionTab>
        )}
    </EditorContext.Provider>
  );
};

ProseMirrorComponent.defaultProps = {
  canEdit: true,
  setProseMirrorEditorView: null,
  setHtml: null,
  setIsSynchronized: null,
  setMeetingNotes: null,
  isPreviousMeeting: false,
  setSecretNotes: null,
};

const checkIfStateAreTheSame = (prevState: ProseMirrorProps, presentState: ProseMirrorProps) => {
  if (prevState.canEdit !== presentState.canEdit
    || prevState.path !== presentState.path
    || prevState.page !== presentState.page
    || prevState.displayNotes !== presentState.displayNotes
    || prevState.isPreviousMeeting
    || presentState.isPreviousMeeting) {
    return false;
  }
  return true;
};

export default React.memo(ProseMirrorComponent, checkIfStateAreTheSame);
