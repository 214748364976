/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import {
  Mark, MarkType, NodeType, Schema,
} from 'prosemirror-model';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { toggleMark } from 'prosemirror-commands';
import { wrapInList } from 'prosemirror-schema-list';
import { BaseEmoji, Picker } from 'emoji-mart';
import { redo, undo } from 'prosemirror-history';
import { selectionCell } from 'prosemirror-tables';
import { Selection, TextSelection } from 'prosemirror-state';
import prependHttp from 'prepend-http';
import {
  blue3, blue6, blue7, cyan3, cyan6, cyan7, darkBlue1, darkBlue4, gray1, gray2,
  gray3, gray4, gray6, gray7, gray8, green3, green6, green7, purple3, purple6,
  purple7, red3, red6, red7, surface, yellow3, yellow6, yellow7,
} from '../../../colours';
import ButtonSmall from '../../button-small';
import { uiText, uiTextMedium } from '../../../typography';
import CustomMenuItem, { MainIconContainer } from './menu-item';
import FontSizeIcon from './icons/font-size';
import FontIcon from './icons/font';
import BoldIcon from './icons/bold';
import ItalicIcon from './icons/italic';
import UnderlineIcon from './icons/underline';
import FontColorIcon from './icons/font-color';
import EnclosingBlockIcon from './icons/enclosing-block';
import UnorderedListIcon from './icons/unordered-list';
import OrderedListIcon from './icons/ordered-list';
import CheckListIcon from './icons/checklist';
import TableIcon from './icons/table';
import AtIcon from './icons/at';
import LinkIcon from './icons/link';
import EmojiIcon from './icons/emoji';
import UndoIcon from './icons/undo';
import RedoIcon from './icons/redo';
import ThreeDotsIcon from './icons/three-dots';
import markActive from '../logic/menu/helpers/mark-active';
import { EditorContext } from '..';
import table from '../logic/menu/items/table';
import 'emoji-mart/css/emoji-mart.css';
import GifIcon from './icons/gif';
import ImageIcon from './icons/image';
import FontSizeDropdown from '../../text-editor/dropdowns/FontSizeDropdown';
import StrikethroughIcon from './icons/strikethrough';
import HighlightIcon from './icons/highlight';
import ColorsDropdownOrg from '../../text-editor/dropdowns/ColorsDropdownOrg';
import LiftOutIcon from './icons/enclosing-block-out';
import { liftAny, sinkAny } from '../logic/sink-lift';
import keymapAdapter from '../logic/keymap/keymap-adapter';
import ClearFormatIcon from '../../../icons/text-editor/ClearFormatIcon';
import immediateDecorations from '../logic/decorations/immediate-decorations';
import fontSpecs from '../logic/marks/fonts';
import RevisionIcon from './icons/revision';

const MenuContainer = styled.div`
  height: 34px;
  width: 100%;
  background-color: ${gray1};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid ${darkBlue1};
  border-width: 0 0 2px 0;
  gap: 4px;
`;

const MenuSeparator = styled.div`
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  width: 0px;
  border: ${darkBlue1} solid;
  border-width: 0 2px 0 0;
`;

const FontColorPicker = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-flow: column;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 150px;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  flex-shrink: 1 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

interface ColorPickerCircleProps {
  color: string;
}

const ColorPickerCircle = styled.div<ColorPickerCircleProps>`
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
`;

const SubMenuItemsContainer = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuDropdown = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-flow: column;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: "Inter, sans-serif";
`;

const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${gray3};
  border: 2px solid ${gray4};
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 175ms;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-of-type {
    margin-bottom: 8px;
  }
`;

const Input = styled.input`
  outline: 0px solid transparent;
  outline-offset: 2px;
  border: 2px solid ${gray1};
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  flex-grow: 1;
  ${uiText}
`;

interface MenuItemProps {
  selected?: boolean;
  fontFamily?: string;
}

const DropdownItem = styled.div<MenuItemProps>`
  padding: 6px 8px;
  border-radius: 6px;
  scroll-margin: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;

  ${({ fontFamily }) => (fontFamily === 'initial' ? uiTextMedium : `font-family: ${fontFamily};`)}
  ${({ selected }) => (selected ? `background-color: ${darkBlue1};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray6 : gray2)};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

DropdownItem.defaultProps = {
  selected: false,
  fontFamily: 'initial',
};

interface CustomeEditorMenuProps {
  schema: Schema;
  view: EditorView;
  setUpdate: CallableFunction;
  hide: boolean;
}

const CustomEditorMenu = ({
  schema,
  view,
  setUpdate,
  hide,
}: CustomeEditorMenuProps) => {
  /**
   * This context holds some important values, such
   * as the prosemirror view, the update state (which
   * is used for forced updates here), and the setUpdate
   * setter, for updating every bound useEffect in the
   * application.
   */
  const ctx = useContext(EditorContext);

  /**
   * Factory which returns a callback that verifies
   * wether the text cursor is currently within
   * a specified mark. Is used for toggling the "active"
   * state of mark buttons (such as bold, italic or underline).
   * @param mark The name of the mark that is to be checked.
   * @returns A callback which will return true or false, depending
   * on the location of the cursor.
   */
  const checkMarkActive = (mark: string) => () => {
    if (!ctx?.view) return false;
    return markActive(ctx.view.state, (ctx.view.state.schema as Schema).marks[mark]);
  };

  /**
   * Factory which returns a callback that applies a mark.
   * @param mark The name of the mark to be applied.
   * @param attrs Extra attributes for the mark
   * @returns A callback which will toggle a mark over a specified
   * selection. It can also store it in a special "marks to be applied"
   * (storedMarks) if the selection length is 0.
   */
  const dispatchToggleMark = (mark: string, attrs?: Record<string, unknown>) => () => {
    if (!ctx?.view) return;
    toggleMark(ctx.view.state.schema.marks[mark], attrs)(ctx.view.state, ctx.view.dispatch);
    setUpdate({});
  };

  /**
   * Factory which returns a callback that applies list styling.
   * @param type The name of the list kind.
   * @returns A callback which will wrap the current selection in a list.
   */
  const dispatchWrapInList = (type: string) => () => {
    if (!ctx?.view) return;
    wrapInList(ctx.view.state.schema.nodes[type])(ctx.view.state, ctx.view.dispatch);
  };

  /**
   * Function which inserts text at the current cursor location.
   * @param toInsert String content to be inserted.
   * @returns Nothing.
   */
  const insert = (toInsert: string) => {
    if (!ctx?.view) return;
    const d = ctx.view.dispatch;
    const v = ctx.view;
    const s = ctx.view.state;
    const { tr } = ctx.view.state;
    tr.insertText(toInsert);
    d(tr);
  };

  /**
   * Function used for node insertion.
   * @param nodeName Node name.
   * @param attr Extra attributes for node creation.
   * @param contents Text (or Node) contents for when the node is inserted.
   * @returns Nothing.
   */
  const insertNode = (
    nodeName: string,
    attr: Record<string, any> = {},
    contents?: any,
  ) => {
    if (!ctx?.view) return;
    const ntype: NodeType = ctx.view.state.schema.nodes[nodeName];
    const { tr } = ctx.view.state;
    const d = ctx.view.dispatch;
    tr.insert(
      ctx.view.state.selection.$anchor.pos,
      ntype.create(attr, contents),
    );
    d(tr);
  };

  /**
   * These fonts will show up in the menu. Before attaching anything to
   * the menu, make sure you export the specific font from
   * src/shared/components/prosemirror/logic/marks/fonts.ts
   * and that they are properly inserted into the sechema at
   * src/shared/components/prosemirror/hooks/use-prose-mirror-firebase.ts
   */
  const fonts = useMemo(
    () => [
      {
        name: 'Inter',
        family: 'Inter',
        mark: 'inter',
        active: () => {
          let cond = false;
          Object.entries(fontSpecs).forEach(([key]) => {
            cond ||= checkMarkActive(key)();
          });
          return !cond || checkMarkActive('inter')();
        },
      },
      {
        separator: true,
        name: 'Separator',
        family: 'Separator',
        mark: '',
        active: () => false,
      },
      {
        name: 'Open Sans',
        family: 'Open Sans',
        mark: 'openSans',
        active: checkMarkActive('openSans'),
      },
      {
        name: 'Lato',
        family: 'Lato',
        mark: 'lato',
        active: checkMarkActive('lato'),
      },
      {
        name: 'Montserrat',
        family: 'Montserrat',
        mark: 'montserrat',
        active: checkMarkActive('montserrat'),
      },
      {
        name: 'Raleway',
        family: 'Raleway',
        mark: 'raleway',
        active: checkMarkActive('raleway'),
      },
    ],
    [ctx?.update],
  );

  /**
   * This useMemo() use will constantly seek the current highlight color,
   * if any is present. It will only get updated on ctx.update changes.
   * If no highlight is present it will return null.
   */
  const highlightColor = useMemo(() => {
    if (!ctx?.view) return null;

    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    if (empty) {
      const storedHighlight = (state.storedMarks ?? []).find((mark) => mark.type.spec.group === 'shepherd-custom-highlights');
      if (storedHighlight) return storedHighlight;
      const fromHighlight = ($from.marks().find((mark) => mark.type.spec.group === 'shepherd-custom-highlights'));
      if (fromHighlight) return fromHighlight;
    }

    let nodeMark = null;

    state.doc.nodesBetween(from, to, (node) => {
      const attemptMark = node.marks.find((mark) => mark.type.spec.group === 'shepherd-custom-highlights');
      if (attemptMark) nodeMark = attemptMark;
    });

    return nodeMark;
  }, [ctx?.update]);

  /**
   * This useMemo() use will constantly seek the current text color,
   * if any is present. It will only get updated on ctx.update changes.
   * If no text color is present it will return null.
   */
  const textColor = useMemo(() => {
    if (!ctx?.view) return null;

    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    if (empty) {
      const storedHighlight = (state.storedMarks ?? []).find((mark) => mark.type.spec.group === 'shepherd-custom-colors');
      if (storedHighlight) return storedHighlight;
      const fromHighlight = ($from.marks().find((mark) => mark.type.spec.group === 'shepherd-custom-colors'));
      if (fromHighlight) return fromHighlight;
    }

    let nodeMark = null;

    state.doc.nodesBetween(from, to, (node) => {
      const attemptMark = node.marks.find((mark) => mark.type.spec.group === 'shepherd-custom-colors');
      if (attemptMark) nodeMark = attemptMark;
    });

    return nodeMark;
  }, [ctx?.update]);

  type LinkInsert = {title: string, link: string};
  /**
   * State used for link inertion.
   */
  const [linkInsert, setLinkInsert] = useState<LinkInsert>({
    title: '',
    link: '',
  });

  const [fakeSelectionRange, setFakeSelectionRange] = useState<Selection<any> | null>(null);

  const insertLinkButtonLabel = useMemo(() => {
    return !fakeSelectionRange || (fakeSelectionRange && !fakeSelectionRange.empty) ? 'Make link' : 'Insert';
  }, [ctx?.update, fakeSelectionRange]);

  const fakeSelect = (highlight: string | null = '#4A90FF', text: string | null = '#FFFFFF') => {
    if (!ctx?.view) return;

    const { tr } = ctx.view.state;
    const { from, to, empty } = ctx.view.state.selection;

    if (empty) {
      setFakeSelectionRange(null);
      return;
    }

    setFakeSelectionRange(ctx.view.state.selection);

    ctx.view.dispatch(
      tr.setMeta(immediateDecorations, {
        from,
        to,
        options: {
          style: `background-color:${highlight};color:${text};`,
        },
      }),
    );
  };

  const undoFakeSelect = () => {
    if (!ctx?.view || !fakeSelectionRange) return;

    if (fakeSelectionRange.empty) {
      setFakeSelectionRange(null);
      return;
    }

    const { tr } = ctx.view.state;
    const { from, to } = fakeSelectionRange;

    ctx.view.dispatch(
      tr.setMeta(immediateDecorations, {
        from,
        to,
        options: {
          style: 'background-color:transparent;color:#000000;',
        },
        removeDecoration: true,
      }),
    );
  };

  const linkInputFocus = (e: React.MouseEvent<HTMLInputElement>) => {
    fakeSelect();
    const self = (e.target as HTMLInputElement);
    const { activeElement } = document;
    if (!(activeElement === self)) { self.focus(); }
  };

  /**
   * Link insertion command.
   * If the user has an ongoing non-empty selection, toggle the linkMark instead
   * @param details Link and title information.
   * @returns Nothing.
   */
  const insertLink = (details: LinkInsert) => {
    if (!ctx?.view) return;

    const parsedLink = prependHttp(details.link);

    const { selection } = ctx.view.state;
    if (!selection.empty) {
      undoFakeSelect();
      dispatchToggleMark('link', {
        title: details.title,
        href: parsedLink,
      })();
      return;
    }

    let { title } = details;
    if (!title) { title = parsedLink; }

    const linkMark = ctx.view.state.schema.marks.link.create({
      title,
      href: parsedLink,
    });
    const textNode = ctx.view.state.schema.text(title, [linkMark]);

    const { tr } = ctx.view.state;
    tr.insert(
      ctx.view.state.selection.$anchor.pos,
      textNode,
    );

    setLinkInsert({
      link: '',
      title: '',
    });
    ctx.view.dispatch(tr);
  };

  const linkInput = useRef<HTMLInputElement | null>(null);

  return (
    <MenuContainer
      style={hide ? { display: 'none' } : {}}
      onMouseDown={(e) => {
        // This can be any target, the cast here
        // is done because the custom behavior is
        // expected to be executed on this type.
        // For safety, we used optional chaining.
        const target = e.target as HTMLInputElement;
        if (target?.matches('#shepherd-link-insert-link')
        || target?.matches('#shepherd-link-insert-title')) {
          setUpdate({});
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        setUpdate({});
      }}
    >
      <CustomMenuItem
        tooltipText="Font"
        closeOnClickAway
        list
        listContents={(scope: any) => {
          if (!ctx?.view) return '';
          return (
            <MenuDropdown>
              {fonts.map((font) => {
                if (font.separator) {
                  return (
                    <div
                      style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: gray4,
                        maxWidth: '98px',
                        margin: 'auto',
                        marginTop: '4px',
                        marginBottom: '4px',
                      }}
                    />
                  );
                }
                return (
                  <DropdownItem
                    fontFamily={font.family}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatchToggleMark(font.mark)();
                      scope.closeContents();
                    }}
                    selected={font.active()}
                  >
                    {font.name}
                  </DropdownItem>
                );
              })}
            </MenuDropdown>
          );
        }}
        contents={({ show }) => (
          <FontIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        tooltipText="Font Size"
        closeOnClickAway
        list
        listContents={(scope: any) => {
          if (scope) {
            return (
              <FontSizeDropdown
                ctx={scope.ctx}
                onStyleChange={() => {
                  scope.closeContents();
                }}
              />
            );
          }
          return '';
        }}
        contents={({ show }) => (
          <FontSizeIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        tooltipText="Bold"
        activeCallback={checkMarkActive('strong')}
        action={dispatchToggleMark('strong')}
        contents={({ show }) => (
          <BoldIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        tooltipText="Italic"
        activeCallback={checkMarkActive('em')}
        action={dispatchToggleMark('em')}
        contents={() => (
          <ItalicIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Underline"
        activeCallback={checkMarkActive('underline')}
        action={dispatchToggleMark('underline')}
        contents={() => (
          <UnderlineIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Text colour"
        closeOnClickAway
        list
        listContents={(scope: any) => (
          <ColorsDropdownOrg
            selectedColor={textColor ? textColor.attrs.rawColor : '#1B2124'}
            setSelectedColor={(color) => {
              scope.closeContents();
              if (!ctx?.view) return false;
              const { marks }: { marks: Record<string, Mark> } = ctx.view.state.schema;
              const [seekMark] = Object.entries(marks).find(([name, mark]) => (
                (mark as any).spec.group === 'shepherd-custom-colors'
                && mark.attrs.rawColor?.default === color
              )) ?? [undefined, undefined];
              if (!seekMark) return false;
              dispatchToggleMark(seekMark)();
              return true;
            }}
            handleResetClick={() => {
              scope.closeContents();
              dispatchToggleMark('surface')();
            }}
          />
        )}
        contents={({ show }) => (
          <FontColorIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        tooltipText="Change indent"
        closeOnClickAway
        list
        listContents={(scope: any) => (
          <SubMenuItemsContainer>
            <CustomMenuItem
              tooltipText="Decrease indent"
              action={() => {
                if (!ctx?.view) return;
                liftAny(ctx.view)(ctx.view.state, ctx.view.dispatch);
              }}
              contents={() => (
                <LiftOutIcon fill={surface} />
              )}
            />
            <CustomMenuItem
              tooltipText="Increase indent"
              action={() => {
                if (!ctx?.view) return;
                sinkAny(ctx.view)(ctx.view.state, ctx.view.dispatch);
              }}
              contents={() => (
                <EnclosingBlockIcon
                  fill={surface}
                />
              )}
            />
          </SubMenuItemsContainer>
        )}
        contents={({ show }) => (
          <EnclosingBlockIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        tooltipText="Bullet list"
        action={dispatchWrapInList('bullet_list')}
        contents={() => (
          <UnorderedListIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Numbered list"
        action={dispatchWrapInList('ordered_list')}
        contents={() => (
          <OrderedListIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Checkbox"
        action={dispatchWrapInList('todo_list')}
        contents={() => (
          <CheckListIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Insert table"
        closeOnClickAway
        hideChevron
        list
        listContents={(scope: any) => (
          <MenuDropdown>
            {table.map((value) => {
              if (value.colorPicker) {
                return (
                  <CustomMenuItem
                    closeOnClickAway
                    hideChevron
                    list
                    listAlign="horizontalLeft"
                    listContents={(secondaryScope) => (
                      <ColorsDropdownOrg
                        selectedColor={(() => {
                          const color = '#FFFFFF';
                          const state = ctx?.view?.state;
                          if (!state) return color;
                          const tableCellNodePosition = selectionCell(state)?.pos;
                          if (!tableCellNodePosition) return color;
                          const tableNode = ctx?.view?.state.doc.nodeAt(tableCellNodePosition);
                          return tableNode?.attrs?.background ?? '#FFFFFF';
                        })()}
                        setSelectedColor={(color) => {
                          secondaryScope.closeContents();
                          scope.closeContents();
                          if (!ctx?.view) return false;
                          return value.callback(color, ctx.view.state, ctx.view.dispatch);
                        }}
                        handleResetClick={() => {
                          secondaryScope.closeContents();
                          scope.closeContents();
                          if (!ctx?.view) return false;
                          return value.callback(null, ctx.view.state, ctx.view.dispatch);
                        }}
                      />
                    )}
                    Container={DropdownItem}
                    contents={() => (
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                        }}
                      >
                        {(value.icon
                        && (
                          <MainIconContainer
                            style={{
                              marginRight: '8px',
                            }}
                          >
                            {value.icon}
                          </MainIconContainer>
                        )
                        )}
                        {value.label}
                      </div>
                    )}
                  />
                );
              }
              if (value.separator) {
                return (
                  <div
                    style={{
                      height: '1px',
                      width: '178px',
                      backgroundColor: gray4,
                      marginBottom: '4px',
                    }}
                  />
                );
              }
              return (
                <DropdownItem
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!ctx?.view) return;
                    value.callback(ctx.view.state, ctx.view.dispatch);
                    // data.setShow(false);
                    scope.closeContents();
                  }}
                >
                  {(value.icon
                      && (
                      <MainIconContainer
                        style={{
                          marginRight: '8px',
                        }}
                      >
                        {value.icon}
                      </MainIconContainer>
                      )
                    )}

                  {value.label}
                </DropdownItem>
              );
            })}
          </MenuDropdown>
        )}
        contents={({ show }) => (
          <TableIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        tooltipText="Mention someone"
        action={() => { insert('@'); }}
        contents={() => (
          <AtIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Insert Link"
        closeOnClickAway
        captureEvents
        list
        hideChevron
        listAlign="right"
        init={(showState) => {
          const [show, setShow] = showState;
          keymapAdapter.reactMethods = {
            openLinkMenu: () => {
              setShow(true);
            },
            closeLinkMenu: () => { setShow(false); },
            focusLinkMenu: () => {
              const linkField = (document.querySelector('#shepherd-link-insert-link') as HTMLInputElement);

              if (!linkField) return;

              if (linkField === document.activeElement) return;

              (document.querySelector('#shepherd-link-insert-link') as HTMLInputElement)
                ?.focus();
            },
            insertLink: () => { insertLink(linkInsert); },
            clearFormatting: (pmView) => {
              if (!pmView) return;
              const { selection } = pmView.state;
              if (!selection.empty) {
                const { tr } = pmView.state;
                tr.removeMark(selection.from, selection.to);
                pmView.dispatch(tr);
              }
            },
          };
        }}
        onOpen={() => {
          if (!linkInput?.current) return;
          linkInput.current.focus();
        }}
        onClose={() => {
          undoFakeSelect();
          view?.focus();
        }}
        listContents={(scope: any) => {
          if (scope) {
            return (
              <MenuDropdown
                onKeyDown={(event) => {
                  // While the menu item is open, Escape will close it.
                  if (event.key === 'Escape') {
                    setLinkInsert({
                      link: '',
                      title: '',
                    });
                    scope.setShow(false);
                    // Focus the ProseMirror editor.
                    view.focus();
                  } else if (event.key === 'Enter') {
                    insertLink(linkInsert);
                    scope.setShow(false);
                  }
                }}
              >
                <InputContainer>
                  <Input
                    id="shepherd-link-insert-title"
                    placeholder="Title (Optional)"
                    onChange={(e) => {
                      setLinkInsert({ ...linkInsert, title: e.target.value });
                    }}
                    onMouseDownCapture={linkInputFocus}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    id="shepherd-link-insert-link"
                    placeholder="Link"
                    onChange={(e) => {
                      setLinkInsert({ ...linkInsert, link: e.target.value });
                    }}
                    ref={linkInput}
                    onMouseDownCapture={linkInputFocus}
                  />
                </InputContainer>
                <ButtonContainer
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <ButtonSmall
                    text={insertLinkButtonLabel}
                    onClick={(e) => {
                      insertLink(linkInsert);
                      scope.closeContents();
                    }}
                  />
                </ButtonContainer>
              </MenuDropdown>
            );
          }
          return '';
        }}
        contents={({ show }) => (
          <LinkIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        tooltipText="Emoji"
        closeOnClickAway
        list
        hideChevron
        listAlign="right"
        listContents={(data: any) => (
          <Picker
            emojiSize={20}
            showPreview={false}
            emojisToShowFilter={(emoji) => {
              if ((emoji as any).added_in) {
                const numerical = parseFloat((emoji as any).added_in);
                return numerical < 12.0;
              }
              return false;
            }}
            color="#058fef"
            native
            title=""
            emoji=""
            autoFocus
            onSelect={(emoji: BaseEmoji) => { insert(emoji.native); data.setShow(false); }}
          />
        )}
        contents={({ show }) => (
          <EmojiIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        tooltipText="Undo"
        action={() => {
          if (!ctx?.view) return () => null;
          return undo(ctx.view.state, ctx.view.dispatch);
        }}
        contents={() => (
          <UndoIcon />
        )}
      />

      <CustomMenuItem
        tooltipText="Redo"
        action={() => {
          if (!ctx?.view) return () => null;
          return redo(ctx.view.state, ctx.view.dispatch);
        }}
        contents={() => (
          <RedoIcon />
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        tooltipText="More"
        closeOnClickAway
        list
        hideChevron
        listAlign="right"
        listContents={(data: any) => (
          <SubMenuItemsContainer>
            <CustomMenuItem
              tooltipText="Clear formatting"
              activeCallback={() => false}
              action={() => {
                if (!ctx?.view) return;
                const { selection } = ctx.view.state;
                if (!selection.empty) {
                  const { tr } = ctx.view.state;
                  tr.removeMark(selection.from, selection.to);
                  ctx.view.dispatch(tr);
                }
              }}
              contents={() => (
                <ClearFormatIcon fill={surface} />
              )}
            />
            <CustomMenuItem
              tooltipText="Strikethrough"
              activeCallback={checkMarkActive('strikethrough')}
              action={dispatchToggleMark('strikethrough')}
              contents={() => (
                <StrikethroughIcon fill={surface} />
              )}
            />
            <CustomMenuItem
              tooltipText="Highlight colour"
              list
              hideChevron
              listAlign="right"
              listContents={(scope: any) => {
                return (
                  <ColorsDropdownOrg
                    selectedColor={highlightColor ? highlightColor.attrs.rawColor : gray1}
                    setSelectedColor={(color) => {
                      if (!ctx?.view) return false;
                      const { marks }: { marks: Record<string, Mark> } = ctx.view.state.schema;
                      const [seekMark] = Object.entries(marks).find(([name, mark]) => (
                        (mark as any).spec.group === 'shepherd-custom-highlights'
                        && mark.attrs.rawColor?.default === color
                      )) ?? [undefined, undefined];
                      if (!seekMark) return false;
                      dispatchToggleMark(seekMark)();
                      return true;
                    }}
                    handleResetClick={() => {
                      dispatchToggleMark('empty-highlight')();
                    }}
                  />
                );
              }}
              contents={() => (
                <HighlightIcon fill={surface} />
              )}
            />
            <CustomMenuItem
              tooltipText="Insert image"
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openImageModal();
              }}
              contents={() => (
                <ImageIcon fill={surface} />
              )}
            />
            <CustomMenuItem
              tooltipText="Insert GIF"
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openGifModal();
              }}
              contents={() => (
                <GifIcon fill={surface} />
              )}
            />
            {
            // TODO: Uncomment this when history mode is finished.
            /* <CustomMenuItem
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openRevisionModal();
              }}
              contents={() => (
                <RevisionIcon fill={surface} />
              )}
            /> */}
          </SubMenuItemsContainer>
        )}
        contents={({ show }) => (
          <ThreeDotsIcon
            fill={show ? surface : undefined}
          />
        )}
      />
    </MenuContainer>
  );
};

export default CustomEditorMenu;
