import { EditorView } from 'prosemirror-view';

interface ReactMethods {
  openLinkMenu?: () => void;
  closeLinkMenu?: () => void;
  focusLinkMenu?: () => void;
  insertLink?: () => void;
  // eslint-disable-next-line no-unused-vars
  clearFormatting?: (view: EditorView | null) => void;
}

class KeymapAdapter {
  public openLinkMenu: CallableFunction = () => null;

  public closeLinkMenu: CallableFunction = () => null;

  public focusLinkMenu: CallableFunction = () => null;

  public insertLink: CallableFunction = () => null;

  public clearFormatting: CallableFunction = () => null;

  set reactMethods({
    openLinkMenu = () => null,
    closeLinkMenu = () => null,
    focusLinkMenu = () => null,
    insertLink = () => null,
    clearFormatting = () => null,
  }: ReactMethods) {
    this.openLinkMenu = openLinkMenu;
    this.closeLinkMenu = closeLinkMenu;
    this.focusLinkMenu = focusLinkMenu;
    this.insertLink = insertLink;
    this.clearFormatting = clearFormatting;
  }
}

export default new KeymapAdapter();
