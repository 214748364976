/* eslint-disable no-unused-vars */
import { IntercomProps } from 'react-use-intercom';
import { AuthState } from '../shared/types/types';

declare global {
    interface Window {
        Intercom:any;
    }
}

type BootType = (props?: IntercomProps | undefined) => void;

export const setupIntercom = (
  authState: AuthState,
  boot: BootType,
  shutdown: BootType,
) => {
  if (authState.userState === 'loggedIn') {
    shutdown();
    boot({
      userId: authState.userId,
      email: authState.email,
      name: `${authState.firstName} ${authState.lastName}`,
      hideDefaultLauncher: true,
    });
  } else if (authState.userState === 'loggedOut') {
    shutdown();
    boot();
  }
};

export const updateIntercomRoute = (authState: AuthState, update: any) => {
  update({
    userId: authState.userId,
    email: authState.email,
    name: `${authState.firstName} ${authState.lastName}`,
  });
};
