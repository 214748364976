import {
  SlackChannel, SlackMessageContent, SlackUser,
} from '../../shared/types/types';
import { Block } from './slackUtils';

export const mapSlackAPIChannelToSlackChannel = (channel: any): SlackChannel => {
  const data: SlackChannel = {
    id: channel?.id ?? '',
    name: channel?.name ?? '',
  };
  return data;
};

export const isValidAccessToken = (accessToken: string): boolean => accessToken.length > 10;

export const createSlackMessageContent = (channel: SlackChannel, slackBlocks: any)
  /*
    there is no type for slackBlocks as the parsing of markdown
    to slack blocks returns a array of objects and each object is
    of diffrent type based on the text type hence 'any' for slackBlocks
  */
  : SlackMessageContent => ({
  channel: channel.id,
  blocks: slackBlocks,
  mrkdwn: true,
});

export const isValidBlock = (block: Block[]) => {
  if (!block[0].type.length
    || !block[0].text.text.length
    || !block[0].text.type.length) return false;
  return true;
};

export const mapAPIUsersToTypeUsers = (response: any) => {
  const SlackUsers: SlackUser = {
    name: response?.user?.profile?.real_name,
    iconUrl: response?.user?.profile?.image_original,
  };
  return SlackUsers;
};
