/* eslint-disable no-unused-vars */
import React, {
  Dispatch,
  SetStateAction,
  useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'react-hotkeys-hook';
import { EditorView } from 'prosemirror-view';
import { AuthContext, UserDataContext } from '../../App';
import {
  darkBlue1,
  layoutWhite,
} from '../../shared/colours';
import {
  DummySetLoading,
  GapiMeetingData,
  MeetingData,
  MeetingAnalyticsData,
  TaskItem,
  Shortcut,
  UserCenterSection,
  MeetingSections,
  NoteType,
  SecretChat,
  User,
  AuthState,
} from '../../shared/types/types';
import dbListenToMeetingData from '../../database/firebaseMeetingAPI';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import updateMeetingDataFromGapiMeetingData from '../../database/utils/updateMeetingDataFromCalendarData';

import MeetingAnalyticsDataContext from './context/MeetingAnalyticsDataContext';
import { dbListenForMeetingAnalyticsData } from '../../database/firebaseAnalyticsAPI';
import INIT_MEETING_ANALYTICS_DATA from '../../utils/analytics/analyticsDataObjects';
import { dbListenToTasksForMeeting } from '../../database/firebaseTasksAPI';
import TabsMenu from './tab/TabsMenu';
import { getUnviewedTasksAssignedToMe } from '../../utils/tasks/tasksUtils';
import { leftKeyNavigation, rightKeyNavigation } from '../../shared/components/firepad/shortcutUtils';
import { cfUpdateUserFriendList } from '../../database/firebaseUsersAPI';
import MeetingDetailsHeaderOrg from '../../shared/components/MeetingDetailsHeader/MeetingDetailsHeaderOrg';
import TabBody from './tab/TabBody';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import FireworkConfetti from '../../shared/confetti/FireworkConfetti';
import { dbListenToSecretNotes } from '../../database/firebaseSecretChatAPI';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import { MEETING_PAGE, PAGE_FIELD } from '../../utils/analytics/enums';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { updateFriendListV2 } from '../../../src/external/FriendListV2/dbFriendListV2Api';
import getGapiData from '../../external/gapi/gapi';
import { AGENDA, SHARED_NOTES } from '../../utils/enums';
import MeetingPageTemplate from './MeetingPageTemplate';
import MeetingLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page/MeetingLoadingPage';

type Props = {
  match: any;
  section: UserCenterSection,
  taskItems: TaskItem[],
  setTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
  meetingTab: MeetingSections,
  setMeetingTab: Dispatch<SetStateAction<MeetingSections>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setSecretChatOpen: Dispatch<SetStateAction<boolean>>,
  secretChatId: string,
  handleSecretNotesSelected: (chatId: string) => void,
  secretNotes: SecretChat[],
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
}

const Meeting = ({
  match,
  section,
  taskItems,
  setTaskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  meetingData,
  setMeetingData,
  setProseMirrorEditorView,
  setSecretChatOpen,
  secretNotes,
  setSecretNotes,
  secretChatId,
  handleSecretNotesSelected,
}: Props) => {
  if (section !== 'currentMeeting') return null;
  const meetingId: string = match?.params?.meetingId ?? '';
  if (meetingId.length === 0) return null;

  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const [meetingAnalyticsData,
    setMeetingAnalyticsData] = useState<MeetingAnalyticsData>(INIT_MEETING_ANALYTICS_DATA);
  const [gapiMeetingData, setGapiMeetingData] = useState<GapiMeetingData>(pendingGapiMeetingData);
  // indicates if data in notes/tasks is synchronized
  const [isTabContentSynchronized, setTabContentSynchronized] = useState<boolean>(true);
  const [notesContent, setNotesContent] = useState<string>('');
  const [membersInvited, setMembersInvited] = useState<boolean>(false);

  const isSidebarOpen = useContext(SidebarStateContext);
  const meetingDataVersion = meetingData.version;

  const { update, trackEvent } = useIntercom();

  useHotkeys('ctrl+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('ctrl+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingTab, meetingDataVersion]);

  useEffect(() => {
    if (isValidMeetingId(meetingId) && authState.userState === 'loggedIn') {
      dbListenToSecretNotes(meetingId, authState.userId, authState.email, setSecretNotes);
      setGapiMeetingData(pendingGapiMeetingData);
      setMeetingData(pendingMeetingData);
      return dbListenToMeetingData(
        meetingId, authState.userId, setMeetingData, setGapiMeetingData,
      );
    }
    return () => { };
  }, [meetingId, authState]);

  useEffect(() => {
    if (isValidMeetingId(meetingId) && isValidEmail(userData.data.email)) {
      return dbListenToTasksForMeeting(meetingId, userData.data.email, setTaskItems);
    }
    return () => { };
  }, [meetingId, userData.data.email]);

  useEffect(() => {
    if (!isValidEventAndCalendarIdAndUserData(meetingData, userData)) return;
    getGapiData(meetingData, userData, setGapiMeetingData);
  }, [meetingData.googleData.ids.eventId, meetingData.googleData.ids.calendarId]);

  useEffect(() => {
    if (membersInvited) {
      setTimeout(() => {
        setMembersInvited(false);
      }, 5000);
    }
  }, [membersInvited]);

  useEffect(() => {
    updateFriendListV2(meetingData, userData);
  }, [meetingData, userData]);

  useEffect(() => {
    if (meetingDataVersion >= 4 && userData.settings.defaultUserTab === AGENDA) {
      return setMeetingTab(SHARED_NOTES);
    }

    return setMeetingTab(userData.settings.defaultUserTab);
  }, [meetingDataVersion, userData.settings.defaultUserTab]);

  useEffect(() => {
    if (meetingId.length === 0) return () => { };
    return dbListenForMeetingAnalyticsData(meetingId, setMeetingAnalyticsData);
  }, [meetingId]);

  useEffect(() => {
    if (isValidMeetingId(meetingId) && isLoggedIn(authState) && isSidebarOpen) {
      logAnalytics();
    }
    return () => { };
  }, [meetingId, authState, isSidebarOpen]);

  /* later to set user in groups

  useEffect(() => {
    if (sidebarState && meetingData.meetingId.length !== 0) {
      setMixpanelMeetingGroup(meetingData);
    }
  }, [sidebarState, meetingData.meetingId]); */

  const logAnalytics = () => {
    logPageEvent(trackEvent, MEETING_PAGE, meetingId);
  };

  const closeTemplatesView = () => {
    setIsTemplatesOpen(false);
  };

  const handleShortcutTrigger = (shortcut: Shortcut) => {
    if (shortcut === 'navigateLeft') {
      leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'navigateRight') {
      rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'openCreateTask') {
      setCreateTaskModalOpen(true);
    }
  };

  const unviewedTasksAssignedToMe = getUnviewedTasksAssignedToMe(taskItems, authState.email);
  const calendarError = gapiMeetingData.resolvedState === 'rejected';
  const attendeesLoading = meetingData.attendees.resolvedState === 'pending';

  if (meetingData.resolvedState === 'pending') {
    return <MeetingLoadingPage text="Fetching Meeting Data ...." />;
  }

  return (
    <>
      <MeetingPageTemplate
        meetingData={meetingData}
        meetingAnalyticsData={meetingAnalyticsData}
        attendeesLoading={attendeesLoading}
        calendarError={calendarError}
        setMembersInvited={setMembersInvited}
        closeTemplatesView={closeTemplatesView}
        unviewedTasksAssignedToMe={unviewedTasksAssignedToMe}
        meetingDataVersion={meetingDataVersion}
        authState={authState}
        meetingId={meetingId}
        setNotesContent={setNotesContent}
        setTabContentSynchronized={setTabContentSynchronized}
        handleShortcutTrigger={handleShortcutTrigger}
        membersInvited={membersInvited}
        taskItems={taskItems}
        meetingTab={meetingTab}
        setMeetingTab={setMeetingTab}
        isTemplatesOpen={isTemplatesOpen}
        setIsTemplatesOpen={setIsTemplatesOpen}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        isRecurringMeeting={isRecurringMeeting}
        setProseMirrorEditorView={setProseMirrorEditorView}
        setSecretChatOpen={setSecretChatOpen}
        secretNotes={secretNotes}
        setSecretNotes={setSecretNotes}
        secretChatId={secretChatId}
        handleSecretNotesSelected={handleSecretNotesSelected}
      />
    </>
  );
};

export default Meeting;

function isValidMeetingId(meetingId: string) {
  return meetingId.length > 10;
}

function isValidEmail(email: string) {
  return email.length > 4;
}

const isValidEventAndCalendarIdAndUserData = (
  meetingData: MeetingData,
  userData: User,
) => {
  if (!isValidMeetingId(meetingData.meetingId)) return false;
  if (meetingData.resolvedState !== 'resolved') return false;
  if (meetingData.googleData.ids.eventId.length === 0
    || meetingData.googleData.ids.calendarId.length === 0) return false;
  if (userData.resolvedState !== 'resolved') return false;
  return true;
};

const isLoggedIn = (authState: AuthState) => authState.userState === 'loggedIn';
