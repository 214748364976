import React from 'react';
import styled from 'styled-components';
import { header300 } from '../../../typography';
import { blue6, surface } from '../../../colours';

const Title = styled.div<StyledProps>`
  ${header300};
  color: ${({ isHoveredOver }) => (isHoveredOver ? blue6 : surface)};
`;

interface StyledProps {
  isHoveredOver: boolean,
}

interface Props {
  title: string,
  isHoveredOver: boolean,
}

const TitleAtom = ({ title, isHoveredOver }: Props) => (
  <Title isHoveredOver={isHoveredOver}>
    {title}
  </Title>
);

export default TitleAtom;
