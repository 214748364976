import React from 'react';
import PrivateNotesSwitch from '../../../shared/components/switch/PrivateNotesSwitch';
import { SecretChat } from '../../../shared/types/types';
import { isEmptyHTML } from '../../../utils/strings';

interface Props {
  secretNotes: SecretChat[],
  // eslint-disable-next-line no-unused-vars
  handlePrivateSpaceChecked: (index: number) => void,
  privateSpaceNotesChecked: boolean[],
  secretNotesHtml: string[]
}

const SecretNotesSwitchMolecule = ({
  secretNotes, privateSpaceNotesChecked, handlePrivateSpaceChecked, secretNotesHtml,
}: Props) => {
  if (typeof secretNotes === 'undefined' || secretNotes?.length === 0) return null;
  return (
    <>
      {
        secretNotes?.map((note: SecretChat, index: number) => (
          <PrivateNotesSwitch
            checked={privateSpaceNotesChecked[index]}
            setChecked={() => handlePrivateSpaceChecked(index)}
            index={index}
            label={`Private Space (${note.title})`}
            disabled={isEmptyHTML(secretNotesHtml[index])}
          />
        ))
      }
    </>
  );
};

export default SecretNotesSwitchMolecule;
