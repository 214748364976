import React from 'react';
import CustomizedBullet from '../welcome-screens-subtitle-bullets-molecule/utils/types';
import { BulletTextContainer, SubtitleBulletContainer } from './utils/styles';

interface SubtitleBulletProps {
  text: string,
  customized?: CustomizedBullet,
}

const SubtitleBullet = ({
  text,
  customized = '',
}: SubtitleBulletProps) => (
  <SubtitleBulletContainer customized={customized}>
    <BulletTextContainer>{text}</BulletTextContainer>
  </SubtitleBulletContainer>
);

SubtitleBullet.defaultProps = {
  customized: '',
};

export default SubtitleBullet;
