import React from 'react';
import styled from 'styled-components';
import { ResolveStateExtended, SlackNotifications } from '../../../../../shared/types/types';
import SlackNotificationToggleMolecule from './SlackNotificationToggleMolecule';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface Props {
  notifications: SlackNotifications,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotifications) => void,
  isResolved: ResolveStateExtended,
}

const SlackNotificationsOrganism = ({
  notifications,
  updateSlackNotifications,
  isResolved,
}: Props) => (
  <Container>
    <SlackNotificationToggleMolecule
      notifications={notifications}
      updateSlackNotifications={updateSlackNotifications}
      isResolved={isResolved}
    />
  </Container>
);

export default SlackNotificationsOrganism;
