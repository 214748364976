/*

   This file is in JS purposely, as the type of tokens and idx
   are not fully know at this point to define type for them hence
   they are written in JS.

   These are custom parsing for block type text format from MD
   to slack block formatting.

*/

export const blockHeadingOpen = (tokens, idx) => {
  const token = tokens[idx];
  return `<${token.tag === 'h6' ? 'Context' : 'Section'}><b>`;
};

export const blockHeadingClose = (tokens, idx) => {
  const token = tokens[idx];
  const close = `</b></${token.tag === 'h6' ? 'Context' : 'Section'}>`;

  if (token.tag === 'h1' || token.tag === 'h2') return `${close}<Divider />`;

  return close;
};

export const hr = () => '<Divider />';
