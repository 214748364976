import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface FontIconProps {
  fill?: string;
  width?: number;
  height?: number;
}

const FontIcon = ({
  fill,
  width,
  height,
}: FontIconProps) => (
  <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 10.5,0.75 C 10.5,1.1642 10.1642,1.5 9.75,1.5 H 6 V 9.75 C 6,10.1642 5.6642,10.5 5.25,10.5 4.8358,10.5 4.5,10.1642 4.5,9.75 V 1.5 H 0.75 C 0.3358,1.5 0,1.1642 0,0.75 0,0.3358 0.3358,0 0.75,0 H 4.5 6 9.75 c 0.4142,0 0.75,0.3358 0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

FontIcon.defaultProps = {
  fill: darkBlue4,
  width: 11,
  height: 11,
};

export default FontIcon;
