import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface PlusProps {
  fill?: string;
  width?: number;
  height?: number;
}

const PlusIcon = ({
  fill,
  width,
  height,
}: PlusProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 12.1875 5.4160156 C 11.5892 5.4160156 11.103516 5.9017 11.103516 6.5 L 11.103516 11.103516 L 6.5 11.103516 C 5.90169 11.103516 5.4160156 11.5892 5.4160156 12.1875 C 5.4160156 12.7858 5.90169 13.271484 6.5 13.271484 L 11.103516 13.271484 L 11.103516 17.875 C 11.103516 18.47331 11.5892 18.958984 12.1875 18.958984 C 12.7858 18.958984 13.271484 18.47331 13.271484 17.875 L 13.271484 13.271484 L 17.875 13.271484 C 18.4733 13.271484 18.958984 12.7858 18.958984 12.1875 C 18.958984 11.5892 18.4733 11.103516 17.875 11.103516 L 13.271484 11.103516 L 13.271484 6.5 C 13.271484 5.9017 12.7858 5.4160156 12.1875 5.4160156 z "
      fill={fill}
    />
  </svg>
);

PlusIcon.defaultProps = {
  fill: darkBlue4,
  width: 24,
  height: 24,
};

export default PlusIcon;
