/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import Modal from '.';

interface GifSearchModalProps {
  isOpen: boolean;
  setModalClosed: () => void;
  schema: Schema;
  view: EditorView;
  getDbRef: (path: string) => firebase.database.Reference;
}

const RevisionModal = ({
  isOpen, setModalClosed, view, schema, getDbRef,
}: GifSearchModalProps) => {
  const closeAndClean = () => {
    setModalClosed();
  };

  /* eslint-disable */
  useEffect(() => {
    console.log(isOpen);
    if (!isOpen) {
      return;
    }
  }, [isOpen]);
  /* eslint-enable */

  return (
    <Modal
      title="Revision"
      isOpen={isOpen}
      setModalClosed={setModalClosed}
    >
      <div>
        TBA
      </div>
    </Modal>
  );
};

export default RevisionModal;
