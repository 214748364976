/* ********* Home ********* */
export const homePath = '/';

/* ********* Onboarding ********* */
export const onboardingPath = '/onboarding';
export const onboardingPersonalPath = '/onboarding/personal';
export const onboardingFirstMeetingPath = '/onboarding/firstmeeting';
export const onboardingWorkspacePath = '/onboarding/workspace';
export const onboardingWelcomePath = '/onboarding/welcome';

/* ********* Signin ********* */
export const signinPath = '/signin';

/* ********* Logout ********* */
export const logoutPath = '/logout';

/* ********* Dashboard ********* */
export const dashboardPath = '/dashboard';

/* ********* Meeting ********* */
export const meetingPath = '/meeting';
export const meetingWithMeetingIdPath = '/meeting/:meetingId';

/* ********* User Center ********* */
export const userCenterPath = '/userCenter';

/* ********* Previous Meeting ********* */
export const previousMeetingPath = '/meeting/:meetingId?/previousMeeting/:previousMeetingId?';

/* ********* Tutorial Video ********* */
export const tutorialVideoPath = '/tutorialVideo';

/* ********* Notion Redirect Page ********* */
export const notionRedirectPath = '/notion';

/* ********* Notion Redirect Page ********* */
export const trelloRedirectPath = '/trello';

/* ********* Slack Redirect Page ********* */
export const slackRedirectPath = '/integrations/slack';

/* ********* Testing ********* */
export const testingPath = '/testing';
export const ivanTestingPath = '/ivan';
export const haraldTestingPath = '/harald';
export const shaokunTestingPath = '/shaokun';
export const matthewTestingPath = '/matthew';
export const asishTestingPath = '/asish';
export const guestTestingPath = '/guest';
