import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface UnorderedListProps {
  fill?: string;
  width?: number;
  height?: number;
}

const UnorderedListIcon = ({
  fill,
  width,
  height,
}: UnorderedListProps) => (
  <svg width={width} height={height} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 12,8.25 C 12,8.6642 11.664,9 11.25,9 H 3.75 C 3.336,9 3,8.6642 3,8.25 3,7.8358 3.336,7.5 3.75,7.5 h 7.5 C 11.664,7.5 12,7.8358 12,8.25 Z m -10.5,0 C 1.5,8.6642 1.164,9 0.75,9 0.336,9 0,8.6642 0,8.25 0,7.8358 0.336,7.5 0.75,7.5 1.164,7.5 1.5,7.8358 1.5,8.25 Z M 12,4.5 c 0,0.4142 -0.336,0.75 -0.75,0.75 H 3.75 C 3.336,5.25 3,4.9142 3,4.5 3,4.0858 3.336,3.75 3.75,3.75 h 7.5 C 11.664,3.75 12,4.0858 12,4.5 Z M 1.5,4.5 C 1.5,4.9142 1.164,5.25 0.75,5.25 0.336,5.25 0,4.9142 0,4.5 0,4.0858 0.336,3.75 0.75,3.75 1.164,3.75 1.5,4.0858 1.5,4.5 Z M 12,0.75 C 12,1.1642 11.664,1.5 11.25,1.5 H 3.767 C 3.353,1.5 3.017,1.1642 3.017,0.75 3.017,0.3358 3.353,0 3.767,0 H 11.25 C 11.664,0 12,0.3358 12,0.75 Z m -10.5,0 C 1.5,1.1642 1.164,1.5 0.75,1.5 0.336,1.5 0,1.1642 0,0.75 0,0.3358 0.336,0 0.75,0 1.164,0 1.5,0.3358 1.5,0.75 Z"
      fill={fill}
    />
  </svg>
);

UnorderedListIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 9,
};

export default UnorderedListIcon;
