import mixpanel from 'mixpanel-browser';
import {
  dashboardPath, onboardingPersonalPath, onboardingWorkspacePath,
} from '../../routes';
import {
  AuthState, IntercomTrackEvent, LoginOrSignup, MeetingSections,
  OnboardingType, TasksPage, TimeTrackingEvents,
} from '../../shared/types/types';
import { analytics } from '../firebase';
import { updateIntercomRoute } from '../intercom';
import {
  APP_EVENT,
  AUTHORIZATION_EVENT,
  CONSOLIDATED_TASKS_EVENT,
  DATABASE_EVENT,
  ONBOARDING_EVENT,
  PAGE_EVENT,
  SIDEBAR_EVENT,
  TASKS_EVENT,
  TEMPLATES_EVENT,
  VIEW_EVENT,
} from './enums';

const ROOT_DOMAIN = 'https://app.meetshepherd.com';

export const logEventPageViewDashboard = (authState: AuthState, update: any) => {
  analytics().logEvent('page_view', { page_title: 'dashboard', page_path: dashboardPath, page_location: `${ROOT_DOMAIN}${dashboardPath}` });
  updateIntercomRoute(authState, update);
  mixpanel.track('pageview', { page_title: 'dashboard', page_path: dashboardPath });
};

export const logEventPageViewPersonalOnboarding = (authState: AuthState, update: any) => {
  analytics().logEvent('page_view', { page_title: 'personal_onboarding', page_path: onboardingPersonalPath, page_location: `${ROOT_DOMAIN}${onboardingPersonalPath}` });
  updateIntercomRoute(authState, update);
  mixpanel.track('pageview', { page_title: 'personal_onboarding', page_path: onboardingPersonalPath });
};

export const logEventPageViewWorkspaceOnboarding = (authState: AuthState, update: any) => {
  analytics().logEvent('page_view', { page_title: 'workspace_onboarding', page_path: onboardingWorkspacePath, page_location: `${ROOT_DOMAIN}${onboardingWorkspacePath}` });
  updateIntercomRoute(authState, update);
  mixpanel.track('pageview', { page_title: 'workspace_onboarding', page_path: onboardingWorkspacePath });
};

export const logEventPageViewGoogleMeet = (authState: AuthState, update: any) => {
  analytics().logEvent('page_view', { page_title: 'google_meet', page_path: '/google-meet', page_location: `${ROOT_DOMAIN}/google-meet` });
  updateIntercomRoute(authState, update);
  mixpanel.track('pageview', { page_title: 'google_meet', page_path: '/google-meet' });
};

export const logEventPageViewGoogleCalendar = (authState: AuthState, update: any) => {
  analytics().logEvent('page_view', { page_title: 'google_calendar', page_path: '/google-calendar', page_location: `${ROOT_DOMAIN}/google-calendar` });
  updateIntercomRoute(authState, update);
  mixpanel.track('pageview', { page_title: 'google_calendar', page_path: '/google-calendar' });
};

export const logEventButtonClickSendEmail = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'send_email' });
  intercomTrackEvent('click-send-email');
  mixpanel.track('click', { button: 'send_email' });
};

export const logEventButtonClickSendEmailSubmit = (
  intercomTrackEvent: any, numberOfEmails: number,
) => {
  analytics().logEvent('button_click', { button: 'send_email_submit', numberOfEmails });
  intercomTrackEvent('click-email-submit', { numberOfEmails });
  mixpanel.track('click', { button: 'send_email_submit', numberOfEmails });
};

export const logEventButtonClickSendEmailCancel = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'send_email_cancel' });
  intercomTrackEvent('click-email-cancel');
  mixpanel.track('click', { button: 'send_email_cancel' });
};

export const logEventButtonClickOpenIntercom = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'open_intercom' });
  intercomTrackEvent('click-help-icon');
  mixpanel.track('click', { button: 'open_intercom' });
};

export const logEventButtonClickWelcomeBannerHelp = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'welcome_banner_help' });
  intercomTrackEvent('click-welcome-banner-help');
  mixpanel.track('click', { button: 'welcome_banner_help' });
};

export const logEventButtonClickWelcomeBannerVideo = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'welcome_banner_video' });
  intercomTrackEvent('click-welcome-banner-video');
  mixpanel.track('click', { button: 'welcome_banner_video' });
};

export const logEventButtonClickCopyText = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'copy_text' });
  intercomTrackEvent('click-copy-text');
  mixpanel.track('click', { button: 'copy_text' });
};

export const logEventButtonClickQuickSettings = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'quick_settings_open' });
  intercomTrackEvent('click-quick-settings-open');
  mixpanel.track('click', { button: 'quick_settings_open' });
};

export const logEventButtonClickInviteMembers = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'invite_members_open' });
  intercomTrackEvent('click-invite-members-open');
  mixpanel.track('click', { button: 'invite_members_open' });
};

export const logEventButtonClickSettings = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'settings_open' });
  intercomTrackEvent('click-settings-open');
  mixpanel.track('click', { button: 'settings_open' });
};

export const logEventButtonClickNoteTab = (intercomTrackEvent: any, noteTab: MeetingSections) => {
  analytics().logEvent('button_click', { button: noteTab });
  intercomTrackEvent(noteTab);
  mixpanel.track('click', { button: noteTab });
};

export const logEventButtonClickNewTask = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'new_task_button' });
  intercomTrackEvent('click-new-task-button');
  mixpanel.track('click', { button: 'new_task_button' });
};

export const logEventButtonClickEmptyTaskTab = (intercomTrackEvent: any) => {
  analytics().logEvent('button_click', { button: 'empty_task_tab' });
  intercomTrackEvent('click-empty-task-tab');
  mixpanel.track('click', { button: 'empty_task_tab' });
};

export const logEventButtonClickCEButton = (intercomTrackEvent: any, buttonName: string) => {
  analytics().logEvent('button_click', { button: `ce_${buttonName}` });
  intercomTrackEvent(`click-ce-${buttonName.replaceAll('_', '-')}`);
  mixpanel.track('click', { button: `ce_${buttonName}` });
};

export const logEventToggleSidebar = (intercomTrackEvent: any, isOpen: boolean) => {
  analytics().logEvent('toggle_sidebar', { sidebarState: isOpen });
  intercomTrackEvent('toggle-sidebar');
  mixpanel.track('toggle_sidebar', { sidebarState: isOpen });
};

export const logEventToggleSidebar2 = (intercomTrackEvent: any, isOpen: boolean) => {
  analytics().logEvent('button_click', { button: 'toggle_sidebar' });
  intercomTrackEvent('click-toggle-sidebar');
  mixpanel.track('click', { button: 'toggle_sidebar', isOpen });
};

export const logEventInviteMembers = (numberOfEmails: number, intercomTrackEvent: any) => {
  analytics().logEvent('invite_members', { numberOfEmails });
  intercomTrackEvent('invite-members', { numberOfEmails });
  mixpanel.track('invite_members', { numberOfEmails });
};

export const logEventDragAndDropTasks = (page: TasksPage, intercomTrackEvent: any) => {
  analytics().logEvent('drag_and_drop_tasks', { page });
  intercomTrackEvent('drag-and-drop-tasks', { page });
  mixpanel.track('drag_and_drop_tasks', { page });
};

// fired whenever meeting sidebar  is opened
// export const logEventOpenShepherd = (
//   meetingData: MeetingData, userId: string, intercomTrackEvent: any,
// ) => {
//   const { meetingId } = meetingData;
//   const startTime = meetingData.date.start.timestamp;
//   const endTime = meetingData.date.end.timestamp;
//   const nowTime = Date.now();

//   let timing: RelativeTiming;
//   if (nowTime < startTime) {
//     timing = 'before';
//   } else if (nowTime >= startTime && nowTime < endTime) {
//     timing = 'during';
//   } else if (nowTime >= endTime) {
//     timing = 'after';
//   }

//   analytics().logEvent('open_shepherd', { meetingId, userId, timing });
//   intercomTrackEvent('open-shepherd', { meetingId, userId, timing });
//   mixpanel.track('open_shepherd', { meetingId, userId, timing });
// };

// only triggered when a user first time open Shepherd sidebar for a meeting
// criteria: 1. sidebar is open
//           2. more than 10 characters in any tab
// triggered only once for any [user, meeting] pair
export const logEventMeetingWithShepherdUsed = (
  meetingId: string,
  userId: string,
  intercomTrackEvent: any,
) => {
  analytics().logEvent('meeting_with_shepherd_used', { meetingId, userId });
  intercomTrackEvent('meeting-with-shepherd-used', { meetingId, userId });
  mixpanel.track('meeting_with_shepherd_used', { meetingId, userId });
};

// should be fired only during the meeting time,
// and there are more than 10 characters in any notes tab
// triggered only once for any [user, meeting] pair
// TODO not used for now
// export const logEventAttendMeeting = (
//   meetingId: string, userId: string, intercomTrackEvent: any,
// ) => {
//   analytics().logEvent('attend_meeting', { meetingId, userId });
//   intercomTrackEvent('attend-meeting', { meetingId, userId });
//   mixpanel.track('attend_meeting', { meetingId, userId });
// };

// triggered only once for a meeting
// triggered when the agenda tab has more than 10 characters
export const logEventMeetingHasAgenda = (meetingId: string, intercomTrackEvent: any) => {
  analytics().logEvent('meeting_has_agenda', { meetingId });
  intercomTrackEvent('meeting-has-agenda', { meetingId });
  mixpanel.track('meeting_has_agenda', { meetingId });
};

// triggered only once for a meeting
// triggered when the sharedNotes tab has more than 10 characters
export const logEventMeetingHasSharedNotes = (meetingId: string, intercomTrackEvent: any) => {
  analytics().logEvent('meeting_has_shared_notes', { meetingId });
  intercomTrackEvent('meeting-has-shared-notes', { meetingId });
  mixpanel.track('meeting_has_shared_notes', { meetingId });
};

// triggered only once for a meeting
// triggered when the privateNotes tab has more than 10 characters
export const logEventMeetingHasPrivateNotes = (
  meetingId: string, intercomTrackEvent: any,
) => {
  analytics().logEvent('meeting_has_private_notes', { meetingId });
  intercomTrackEvent('meeting-has-private-notes', { meetingId });
  mixpanel.track('meeting_has_private_notes', { meetingId });
};

// ============ Main Analytics Function ===========

export const logEvent = (
  eventType: string, eventData: Object, intercomTrackEvent?: IntercomTrackEvent,
) => {
  if (analytics) analytics().logEvent(eventType, eventData);
  if (mixpanel) mixpanel.track(eventType, eventData);
  if (intercomTrackEvent) intercomTrackEvent(eventType, eventData);
};

// ============ Tasks Analytics ===========

export const logTasksUserAction = (intercomTrackEvent: any, action: string, field: string) => {
  logEvent(TASKS_EVENT, { action, field }, intercomTrackEvent);
};

// ============ Templates Analytics ===========

// TODO: Add meetingId to event
export const logTemplateUserAction = (intercomTrackEvent: any, action: string, field: string) => {
  logEvent(TEMPLATES_EVENT, { action, field }, intercomTrackEvent);
};

// ============ Consolidated Tasks Analytics ===========

export const logConsolidatedTasksUserAction = (
  intercomTrackEvent: any,
  action: string,
  field: string,
) => {
  logEvent(CONSOLIDATED_TASKS_EVENT, { action, field }, intercomTrackEvent);
};

// ============ Update Database Analytics ===========

export const logDatabaseEvent = (action: string, field: string) => {
  logEvent(DATABASE_EVENT, { action, field });
};

// ============ App Event Analytics (UPDATED) ===========

export const logAppEvent = (field: string, action: string) => {
  logEvent(APP_EVENT, { field, action });
};

// ============ Sidebar Event Analytics (UPDATED) ===========

export const logSidebarEvent = (field: string, action: string) => {
  logEvent(SIDEBAR_EVENT, { field, action });
};

// ============ Page Event Analytics (UPDATED) ===========

export const logPageEvent = (intercomTrackEvent: IntercomTrackEvent, page: string, meetingId: string = '') => {
  if (meetingId.length === 0) {
    logEvent(PAGE_EVENT, { page, action: VIEW_EVENT }, intercomTrackEvent);
    return;
  }

  logEvent(PAGE_EVENT, { page, meetingId, action: VIEW_EVENT }, intercomTrackEvent);
};

// ============ Signup Event Analytics ===========

export const logAuthenticationEvent = (intercomTrackEvent: IntercomTrackEvent, loginOrSignup: LoginOrSignup, type: OnboardingType = 'organic') => {
  if (loginOrSignup === 'login') {
    logEvent(AUTHORIZATION_EVENT, { action: loginOrSignup }, intercomTrackEvent);
    return;
  }

  logEvent(AUTHORIZATION_EVENT, { action: loginOrSignup, type }, intercomTrackEvent);
};

// ============ Onboarding Event Analytics ===========

export const logOnboardingEvent = (
  intercomTrackEvent: IntercomTrackEvent,
  page: string,
  action: string,
  error: string = '',
) => {
  if (error.length === 0) {
    logEvent(ONBOARDING_EVENT, { page, action }, intercomTrackEvent);
    return;
  }

  logEvent(ONBOARDING_EVENT, { page, action, error }, intercomTrackEvent);
};

// ============ Loading Analytics ===========

export const startTrackingEvent = (event: TimeTrackingEvents) => {
  console.log('log:: start', event);
  mixpanel.time_event(event);
};

export const stopTrackingEvent = (event: TimeTrackingEvents) => {
  console.log('log:: stop', event);
  mixpanel.track(event);
};
