import React from 'react';
import styled from 'styled-components';
import { ShortcutInfo } from '../../../types/types';
import SingleShortcutDetails from './SingleShortcutDetails';

const ShortcutsContainer = styled.div`
  padding: 6px 0px;
`;

interface SectionShortcutOptionsProps {
  shortcuts: ShortcutInfo[]
}

const SectionShortcutOptions = ({ shortcuts }: SectionShortcutOptionsProps) => (
  <ShortcutsContainer>
    {shortcuts.map((shortcut) => (
      <SingleShortcutDetails
        key={shortcut.name}
        shortcut={shortcut}
      />
    ))}
  </ShortcutsContainer>
);

export default SectionShortcutOptions;
