import styled, { css, keyframes } from 'styled-components';

// animations

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  23% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  73% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// styling

interface ShepherdIconContainerProps {
  spin: boolean
}

export const ShepherdIconContainer = styled.div<ShepherdIconContainerProps>`
  ${({ spin }) => (spin && (
    css`& > svg {
      transform-box: fill-box;
      transform-origin: center;
      animation: ${rotate} 2s linear infinite;
    }`
  ))};
`;
