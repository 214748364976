import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState, useRef,
} from 'react';
// import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { gray1 } from '../../../shared/colours';
import {
  MeetingSections, SecretChat, Version, MeetingData,
} from '../../../shared/types/types';
// import { logEventButtonClickNoteTab } from '../../../utils/analytics/eventLogger';
import HorizontalLoader from '../../../shared/components/css-spinner/HorizontalLoader';

import {
  AGENDA, PRIVATE_NOTES, SECRET, SHARED_NOTES, TASK,
} from '../../../utils/enums';
import MeetingDataContext from '../context/MeetingDataContext';
import DeleteConfirmationModal from '../notes/secret-chat/delete-confirmation-modal/DeleteConfirmationModal';
import PaperLockButtonAtom from '../notes/secret-chat/PaperPlusButtonAtom';
import { deleteSecretChat, emptySecretChat } from '../notes/secret-chat/secretChatUtils';
import SecretChatButton from './secret-chat-tab-buttons/SecretChatButtonMolecule';
import TabDivButton from './TabDivButton';
import AgendaTabButtonAtom from './AgendaTabButtonAtom';
import TabIndicator from './TabIndicator';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';

const TabButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${gray1};
  display: inline-flex;
  padding: 13px 16px;
  width: 100%;
`;

export interface Props {
  meetingData: MeetingData,
  meetingTab: MeetingSections,
  setMeetingTab: Dispatch<SetStateAction<MeetingSections>>,
  closeTemplatesView?: () => void
  unviewedTasks: number,
  setSecretChatOpen?: Dispatch<SetStateAction<boolean>>,
  secretNotes: SecretChat[],
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setSecretNotes: Dispatch<SetStateAction<SecretChat[]>>,
  meetingDataVersion: Version,
  secretChatId: string,
}

const TabsMenu = ({
  meetingTab,
  setMeetingTab,
  unviewedTasks,
  closeTemplatesView = () => null,
  meetingDataVersion,
  handleSecretNotesSelected,
  setSecretNotes,
  setSecretChatOpen,
  secretNotes,
  secretChatId,
}: Props) => {
  const buttonButtonsRefs = useRef(document.createElement('div'));
  const [selectedChat, setSelectedChat] = useState<number>(-1);
  const [shouldDelete, setShouldDelete] = useState<boolean>(false);
  const deletionModalRef = useRef(null);
  const [isDeleted, setIsDeleted] = useDetectOutsideClick(deletionModalRef, false);
  const [chatToDelete, setChatToDelete] = useState<SecretChat>(emptySecretChat());

  const meetingData = useContext(MeetingDataContext);

  const shouldShowPaperPlusIcon = secretNotes.length === 0;

  const handleSetNoteTab = (pressedNoteTab: MeetingSections) => () => {
    setMeetingTab(pressedNoteTab);
    closeTemplatesView();
    // logEventButtonClickNoteTab(trackEvent, pressedNoteTab);
  };

  const handleOpenSecretChatModal = () => {
    if (setSecretChatOpen) setSecretChatOpen(true);
  };

  const handleCloseDeletedModal = () => {
    setShouldDelete(true);
    setIsDeleted(false);
    setSelectedChat(-1);
    setMeetingTab('shared');
  };

  const handleUndo = () => {
    setIsDeleted(false);
  };

  useEffect(() => {
    if (secretChatId.length === 0) return;
    secretNotes.forEach((note, index) => {
      if (note.chatPathInRealtimeDb.includes(secretChatId)) setSelectedChat(index);
    });
  }, [secretChatId]);

  useEffect(() => {
    if (shouldDelete) {
      deleteSecretChat(chatToDelete, setSecretNotes, meetingData.meetingId);
      setShouldDelete(false);
      setSelectedChat(-1);
    }
  }, [shouldDelete]);

  if (meetingData.resolvedState !== 'resolved') {
    return (
      <TabButtonsContainer ref={buttonButtonsRefs}>
        <HorizontalLoader />
      </TabButtonsContainer>
    );
  }

  return (
    <>
      <TabButtonsContainer ref={buttonButtonsRefs}>
        <TabIndicator
          meetingTab={meetingTab}
          buttonsParentRef={buttonButtonsRefs}
        />
        <AgendaTabButtonAtom
          meetingDataVersion={meetingDataVersion}
          meetingTab={meetingTab}
          onClick={handleSetNoteTab(AGENDA)}
        />
        <TabDivButton
          active={meetingTab === SHARED_NOTES}
          disabled={meetingTab === SHARED_NOTES}
          onClick={handleSetNoteTab(SHARED_NOTES)}
          tooltipText="Visible to you and other attendees"
          meetingTab={SHARED_NOTES}
        >
          Shared notes
        </TabDivButton>
        <TabDivButton
          active={meetingTab === PRIVATE_NOTES}
          disabled={meetingTab === PRIVATE_NOTES}
          onClick={handleSetNoteTab(PRIVATE_NOTES)}
          tooltipText="Strictly visible to you only"
          meetingTab={PRIVATE_NOTES}
        >
          My notes
        </TabDivButton>
        <TabDivButton
          active={meetingTab === TASK}
          disabled={meetingTab === TASK}
          onClick={handleSetNoteTab(TASK)}
          unviewedTasks={unviewedTasks}
          tooltipText="Create and assign tasks for you and your team"
          meetingTab={TASK}
        >
          Tasks
        </TabDivButton>
        <TabDivButton
          active={meetingTab === SECRET}
          disabled={meetingTab === SECRET}
          onClick={() => console.log('')}
          tooltipText=""
          meetingTab={SECRET}
          padding
        >
          <SecretChatButton
            secretChats={secretNotes}
            handleSecretNotesSelected={handleSecretNotesSelected}
            setMeetingTab={setMeetingTab}
            meetingTab={meetingTab}
            setSecretNotes={setSecretNotes}
            handleOpenCreateSecretChat={handleOpenSecretChatModal}
            setSelectedChat={setSelectedChat}
            selectedChat={selectedChat}
            setDeletedModalOpen={setIsDeleted}
            setChatToDelete={setChatToDelete}
          />
        </TabDivButton>
        {
          shouldShowPaperPlusIcon && (
            <TabDivButton
              active={meetingTab === SECRET}
              disabled={meetingTab === SECRET}
              onClick={handleOpenSecretChatModal}
              tooltipText="Create new Private Space and add selected attendees"
              padding
            >
              <PaperLockButtonAtom />
            </TabDivButton>
          )
        }
        <DeleteConfirmationModal
          handleUndo={handleUndo}
          onDeletePrivateNote={handleCloseDeletedModal}
          isOpen={isDeleted}
          refOutsideClick={deletionModalRef}
        />
      </TabButtonsContainer>
    </>
  );
};

TabsMenu.defaultProps = {
  setSecretChatOpen: null,
};

const checkIfStateAreTheSame = (prevState: Props, presentState: Props) => {
  if (prevState.meetingTab !== presentState.meetingTab
    || prevState.unviewedTasks !== presentState.unviewedTasks
    || prevState.meetingDataVersion !== presentState.meetingDataVersion
    || prevState.secretNotes !== presentState.secretNotes
  ) {
    return false;
  }
  return true;
};

export default React.memo(TabsMenu, checkIfStateAreTheSame);
