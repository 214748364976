import React from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../../../shared/colours';
import { uiTextMedium } from '../../../../../shared/typography';

const UndoButton = styled.div`
  color: ${darkBlue4};
  ${uiTextMedium};
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
`;

interface Props {
  text: string,
  onClick: () => void,
}

const UndoButtonAtom = ({ text, onClick }: Props) => (
  <UndoButton onClick={onClick}>
    {text}
  </UndoButton>
);

export default UndoButtonAtom;
