import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import CreateNewTaskModal from '../task-modal/CreateNewTaskModal';
import { logEventButtonClickNewTask } from '../../../utils/analytics/eventLogger';
import ButtonMolecule from '../../../pages/all-pages/bottom-bar/ButtonMolecule';
import RectanglePlusIcon from '../../icons/RectanglePlusIcon';
import { MeetingData } from '../../types/types';

const Container = styled.div``;

interface Props {
  meetingData: MeetingData,
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
}

const CreateTask = ({
  meetingData, isOpen, setIsOpen, disableAssigneeSelect, disableCalendarSelect,
}: Props) => {
  const { trackEvent } = useIntercom();
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    logEventButtonClickNewTask(trackEvent);
    setIsOpen(true);
  };

  return (
    <Container>
      <CreateNewTaskModal
        meetingData={meetingData}
        isModalOpen={isOpen}
        setModalClosed={closeModal}
        disableAssigneeSelect={disableAssigneeSelect}
        disableCalendarSelect={disableCalendarSelect}
      />
      <ButtonMolecule onClick={openModal} text="Task" icon={<RectanglePlusIcon />} tooltipText="Create new task" />
    </Container>
  );
};

CreateTask.defaultProps = {
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
};

export default CreateTask;
