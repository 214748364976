// ======= Python QuickStart ========
// export const CLIENT_ID =
// '848917333458-ac05ab6qgu4pvt61r1ut1addv6qbjovs.apps.googleusercontent.com';
// export const API_KEY = 'AIzaSyDZDsiusjtkjdf83ryKJJPiEAMQhZmQB00';

import moment from 'moment';
import { TabDropdownItem } from '../shared/components/simple-dropdown';

// ======= Shepherd ========
export const API_KEY = 'AIzaSyCdYthlXdgBfDokonqdb0okI8xG3oLZtZc';
export const CLIENT_ID = '232311426352-6gf0bj4o857ch7rgfgk92kjpir94c6he.apps.googleusercontent.com';

// Array of API discovery doc URLs for APIs used by the quickstart
export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.acls.readonly';
// export const SCOPES = 'https://www.googleapis.com/auth/calendar';

// Database
export const DEFAULT_DB_ERROR_MESSAGE = 'Something went wrong while communicating with the database';

// Date and Time formats
export const WEEKDAY_FORMAT = 'dddd'; // Friday
export const DAY_AND_MONTH_FORMAT = 'D MMMM'; // 4 June
export const HOUR_AND_MINUTES_FORMAT = 'HH:mm'; // 09:30
export const DAY_FORMAT = 'D'; // 4
export const MONTH_FORMAT = 'MMM'; // Aug
export const YEAR_FORMAT = 'YYYY';

// Intercom
export const INTERCOM_APP_ID = 'tes7bk97';

// Slack client ID
export const CLIENT_ID_SLACK = '1490440008293.2369381747799';

// The CSS class name of React-Tooltip component
export const REACT_TOOLTIP_CLASS_NAME = 'custom-react-tooltip-wrapper';

// The CSS class name of react-beautiful-dnd droppable component
export const REACT_DND_ID_PREFIX = 'custom-react-dnd-droppable';

// The HTML ID prefix of tips of React-Tooltip
export const REACT_TOOLTIP_ID_PREFIX = 'reactTooltipID';

// User messages
export const noAccessGoogleCalendarWarning = (email: string) => `Could not fetch updated meeting data from Google Calendar. Make sure '${email}' has access to this meeting in Google Calendar.`;

// Firestore
export const TITLE_DATA_PATH = 'data.title';
export const DESCRIPTION_PATH = 'data.description';
export const START_DATE_PATH = 'date.start';
export const END_DATE_PATH = 'date.end';
export const ATTENDEES_PATH = 'data.attendees';
export const CONFERENCEID_PATH = 'googleData.ids.meetId';
export const RECURRING_EVENT_ID_PATH = 'googleData.ids.recurringEventId';
export const CONFERENCE_DATA_PATH = 'googleData.conferenceData';
export const TASKS_COLLECTION = 'tasks';

// Share notes backend credentials
export const SHARED_NOTES_TEMPLATE_ID = 'd-7b5212b8594546adbea9ca472500d389';
export const AGENDA_TEMPLATE_ID = 'd-32214207702341b0987f258659cb5fc8';
export const SEND_HEADLESS_NOTES_TEMPLATE_ID = 'd-429f4aa71f224c58b3b4cb98c107c483';

// The minimun number of characters when some note related event is triggered
export const NOTES_EVENT_TRIGGER_THRESHOLD = 10;

// Mixpanel token
export const MIXPANEL_TOKEN = 'e1daf60cf6d7f32285e5b84cf6649e3d';

export const RESET_DATE = moment().subtract(50, 'years').toDate();

export const ASSIGN_TASK_DESCRIPTION = 'has assigned a new task to you';
export const DELETE_TASK_DESCRIPTION = 'has deleted a task assigned to you';
export const UPDATE_TASK_DESCRIPTION = 'has updated a task assigned to you';
export const UPDATE_TASK_TITLE_DESCRIPTION = 'has updated the title of a task assigned to you';
export const UPDATE_TASK_DUE_DATE_DESCRIPTION = 'has updated the due date of a task assigned to you';
export const UPDATE_TASK_STATUS_DESCRIPTION = 'has updated the status of a task assigned to you';
export const UPDATE_TASK_DESCRIPTION_DESCRIPTION = 'has updated the description of a task assigned to you';

export const TODO_ICON_URL = 'http://cdn.mcauto-images-production.sendgrid.net/f18be71b57616d7c/7907672a-a22c-4871-9013-136127ab6756/20x20.png';
export const IN_PROGRESS_ICON_URL = 'http://cdn.mcauto-images-production.sendgrid.net/f18be71b57616d7c/41b43a22-6665-4d59-b85d-5754a42ccebe/20x20.png';
export const COMPLETED_ICON_URL = 'http://cdn.mcauto-images-production.sendgrid.net/f18be71b57616d7c/9ddff6d5-3bbe-45b9-aa7d-ca8a1a3df211/20x20.png';
export const OVERDUE_ICON_URL = 'http://cdn.mcauto-images-production.sendgrid.net/f18be71b57616d7c/d117682b-dd61-44f1-912a-7ff5cdf36d3e/20x20.png';

// New Features Constants
export const newFeaturesId1 = 'newTextEditor20102021';

// Trello Integration Dropdown Options
export const trelloDropdownOptions:TabDropdownItem[] = [
  {
    id: '0',
    value: 'Export selected tasks from this meeting',
  },
  {
    id: '1',
    value: 'Export only my tasks from this meeting',
  },
  {
    id: '2',
    value: 'Export all tasks from this meeting',
  },
];

// Integration Modal Views

export const OVERVIEW_VIEW = 'Overview';
export const NOTION_PROCESSING_VIEW = 'NotionProcessing';
export const SLACK_PROCESSING_VIEW = 'SlackProcessing';
export const SLACK_NOTIFICATIONS_VIEW = 'SlackNotifications';
export const SLACK_SELECT_DEFAULT_CHANNEL_VIEW = 'SlackSelectDefaultChannel';
export const TRELLO_PROCESSING_VIEW = 'TrelloProcessing';
