import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../../../shared/colours';
import { uiTextMedium } from '../../../../../shared/typography';

const Text = styled.span`
  color: ${blue6};
  ${uiTextMedium};
  margin: 0px;
  white-space: nowrap;
`;

interface Props {
  text: string,
}

const ButtonTextAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default ButtonTextAtom;
