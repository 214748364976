import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface BoldProps {
  fill?: string;
  width?: number;
  height?: number;
}

const BoldIcon = ({
  fill,
  width,
  height,
}: BoldProps) => (
  <svg width={width} height={height} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 0,0.75 C 0,0.3358 0.3358,0 0.75,0 h 4.125 c 0.6608,0 1.3069,0.194 1.8585,0.5578 0.5515,0.3639 0.984,0.8816 1.2441,1.489 0.26,0.6074 0.336,1.2778 0.2186,1.928 C 8.0788,4.625 7.7731,5.2264 7.317,5.7045 7.829,6.001 8.254,6.4269 8.5494,6.9395 8.8448,7.4521 9.0002,8.0334 9,8.625 9,9.0682 8.9127,9.5071 8.7431,9.9166 8.5735,10.326 8.3249,10.6981 8.0115,11.0115 7.6981,11.3249 7.3261,11.5735 6.9166,11.7431 6.5071,11.9127 6.0683,12 5.625,12 H 0.75 C 0.3358,12 0,11.6642 0,11.25 0,10.8358 0.3358,10.5 0.75,10.5 v -9 C 0.3358,1.5 0,1.1642 0,0.75 Z m 2.25,4.5 H 4.875 C 5.3723,5.25 5.8492,5.0525 6.2009,4.7008 6.5525,4.3492 6.75,3.8723 6.75,3.375 6.75,2.8777 6.5525,2.4008 6.2009,2.0492 5.8492,1.6975 5.3723,1.5 4.875,1.5 H 2.25 Z m 0,1.5 V 10.5 H 5.625 C 6.1223,10.5 6.5992,10.3025 6.9509,9.9508 7.3025,9.5992 7.5,9.1223 7.5,8.625 7.5,8.1277 7.3025,7.6508 6.9509,7.2992 6.5992,6.9475 6.1223,6.75 5.625,6.75 Z"
      fill={fill}
    />
  </svg>
);

BoldIcon.defaultProps = {
  fill: darkBlue4,
  width: 9,
  height: 12,
};

export default BoldIcon;
