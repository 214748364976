import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import SuccessMessage from './successMessage';
import NameField from './NameField';

interface SavedProps {
  saved: boolean
}

const SuccessMessageContainer = styled.div<SavedProps>`
  display: ${(props) => (props.saved ? 'block' : 'none')};
`;

const NameFieldContainer = styled.div<SavedProps>`
  display: ${(props) => (props.saved ? 'none' : 'block')};
`;

interface Props {
  isSaved: boolean
  title: string
  setTitle: Dispatch<SetStateAction<string>>
  handleSave: () => void
}

const HeaderComponent = ({
  isSaved, title, setTitle, handleSave,
}:Props) => (
  <>
    <SuccessMessageContainer saved={isSaved}>
      <SuccessMessage />
    </SuccessMessageContainer>
    <NameFieldContainer saved={isSaved}>
      <NameField
        title={title}
        setTitle={setTitle}
        handleSave={handleSave}
      />
    </NameFieldContainer>
  </>
);

export default HeaderComponent;
