import React, { useState } from 'react';
import ReactTooltip from '../../../../shared/components/tooltip/ReactTooltip';
import ActiveTickIcon from '../../../../shared/icons/integrations/ActiveTickIcon';
import ThreeDotsIcon from '../../../../shared/icons/integrations/ThreeDotsIcon';
import {
  ActiveText, AppContainer, IntegratedContainer, MenuActiveContainer, MenuContainer, NameText,
} from '../integrations/Integrated';
import OptionsDropdown, { OptionsDropdownItem } from '../integrations/OptionsDropdown';
import { red6, surface } from '../../../../shared/colours';
import { trelloAuthUrl } from '../../../../utils/trello/trelloUtils';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { removeTrelloIntegration } from '../../../../database/firebaseTrelloAPI';
import { TrelloData } from '../../../../shared/types/types';
import TrelloSettingModalWrapper from './TrelloSettingModalWrapper';
import { TrelloIcon } from '../../../../shared/icons/integration-icons';

interface Props {
  enable: boolean
  dropDownRef: React.MutableRefObject<null>,
  userId: string,
  trelloBoard: TrelloData
}

export default function TrelloIntegrated({
  enable, dropDownRef, userId, trelloBoard,
}:Props) {
  const [isTrelloDropdownActive,
    setIsTrelloDropdownActive] = useDetectOutsideClick(dropDownRef, false);
  const [trelloBoardSettingModal, setTrelloBoardSettingModal] = useState(false);

  const handleDisableTrello = () => {
    removeTrelloIntegration(userId);
    setIsTrelloDropdownActive(false);
  };

  const closeTrelloBoardSettingModal = () => {
    setTrelloBoardSettingModal(false);
  };

  const handleThreeDotsClick = () => {
    setIsTrelloDropdownActive(!isTrelloDropdownActive);
  };

  const trelloDropdownOptions: OptionsDropdownItem[] = [
    {
      id: 1, value: 'Edit task export settings', color: surface, onClick: () => setTrelloBoardSettingModal(!trelloBoardSettingModal),
    },
    {
      id: 2, value: 'Sign into another account', color: surface, onClick: () => window.open(trelloAuthUrl),
    },
    {
      id: 3, value: 'Disable', color: red6, onClick: () => handleDisableTrello(),
    },
  ];
  if (!enable) return <></>;
  return (
    <>
      <IntegratedContainer>
        <AppContainer>
          <TrelloIcon width="28" />
          <NameText>Trello</NameText>
        </AppContainer>
        <MenuActiveContainer>
          <ReactTooltip place="top" multiline tip={trelloBoard.board.boardId.length === 0 ? 'Setup Trello Board' : trelloBoard.board.boardName}>
            <ActiveTickIcon />
            <ActiveText>
              {trelloBoard.board.boardId.length === 0 ? 'Setup Trello Board' : 'Active'}
            </ActiveText>
          </ReactTooltip>
          <MenuContainer>
            <ThreeDotsIcon onClick={() => handleThreeDotsClick()} />
            <OptionsDropdown
              optionsRef={dropDownRef}
              isOpen={isTrelloDropdownActive}
              options={trelloDropdownOptions}
            />
          </MenuContainer>
        </MenuActiveContainer>
      </IntegratedContainer>
      <TrelloSettingModalWrapper
        isOpen={trelloBoardSettingModal}
        setModalClosed={closeTrelloBoardSettingModal}
      />
    </>
  );
}
