import React, {
  useContext, useState,
} from 'react';
import styled from 'styled-components';
import { dbUpdateSecretChatMembers } from '../../../../../database/firebaseSecretChatAPI';
import ButtonSmall from '../../../../../shared/components/button-small';
import Modal from '../../../../../shared/components/Modal';
import ModalFooterdWrapper from '../../../../../shared/components/Modal/ModalFooterWrapper';
import SearchableAssign from '../../../../../shared/components/searchable-assign';
import PaperLockIcon from '../../../../../shared/icons/PaperLockIcon';
import {
  AssigneeV2, SecretChat, SecretChatAttendeeV2,
} from '../../../../../shared/types/types';
import { mapAssigneeV2ToSecretChatAttendeeV2, mapSecretChatAttendeeV2ToAttendeeV2 } from '../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import MeetingDataContext from '../../../context/MeetingDataContext';
import { ModalLineLocal } from '../../../modal/ShareNotesModal';
import AccessAtom from '../modal/AccessAtom';
import ExplanationTextAtom from '../modal/ExplanationAtom';
import IconEmailMolecules from '../modal/IconEmailMolecules';
import { NameInputContainer } from '../modal/modal';
import DeleteChatButtonAtom from './DeleteChatButtonAtom';
import NameInputAtom from './NameInputAtom';

const Container = styled.div`
  padding: 24px 24px 8px 24px;

  span:first-child {
    margin-bottom: 12px;
  }
  span:nth-child(1) {
    margin-bottom: 16px;
  }
  span:nth-child(3) {
    margin-bottom: 8px;
  }
`;

const RightButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  secretChatId: string,
  currentAttendees: SecretChatAttendeeV2[],
  chat: SecretChat
  // eslint-disable-next-line no-unused-vars
  handleDelete: (chat: SecretChat) => void,
}

const EditSecretChatModal = ({
  isOpen, setClosed, secretChatId, currentAttendees, chat, handleDelete,
}: Props) => {
  if (!isOpen) return null;
  const [participants, setParticipants] = useState<SecretChatAttendeeV2[]>(currentAttendees);
  const [title, setTitle] = useState<string>(chat.title);
  const meetingData = useContext(MeetingDataContext);
  const attendees = meetingData.attendees.attendees.filter(
    (attendee) => attendee.userId.length !== 0,
  ) ?? [];

  const remainingAttendees = attendees.filter(
    (attendee) => currentAttendees.every(
      (currentAttendee) => currentAttendee.userId !== attendee.userId,
    ),
  );

  const onAssigneeChange = (assignee: AssigneeV2) => {
    if (assignee.data.email.length === 0) return;
    if (participants.filter((tag) => tag.data.email === assignee.data.email).length > 0) return;
    const attendee = mapAssigneeV2ToSecretChatAttendeeV2(assignee);
    setParticipants((prevState) => [...prevState, attendee]);
  };

  const handleRemove = (email: string) => {
    const tempArray = participants.filter((participant) => participant.data.email !== email);
    setParticipants(tempArray);
  };

  const handleAddAttendees = () => {
    dbUpdateSecretChatMembers(title, meetingData.meetingId, secretChatId, participants);
    setClosed();
  };

  if (remainingAttendees.length === 0) return null;
  return (
    <Modal title="Edit Private Note" isOpen={isOpen} setModalClosed={setClosed} icon={<PaperLockIcon />} isScrollbarEnabled={false}>
      <Container>
        <ExplanationTextAtom />
        <NameInputContainer>
          <AccessAtom text="Name your private space" />
          <NameInputAtom prevTitle={title} setTitle={setTitle} />
        </NameInputContainer>
        <AccessAtom text="Give access to the following" />
        <SearchableAssign
          candidates={remainingAttendees}
          selectedEmail=""
          onAssigneeChange={onAssigneeChange}
        />
        <IconEmailMolecules
          handleRemove={handleRemove}
          assignees={participants.map(mapSecretChatAttendeeV2ToAttendeeV2)}
        />
      </Container>
      <ModalLineLocal />
      <ModalFooterdWrapper>
        <DeleteChatButtonAtom onClick={() => handleDelete(chat)} text="Delete Private Note" />
        <RightButtonContainer>
          <ButtonSmall text="Cancel" onClick={setClosed} isOutline />
          <ButtonSmall text="Update" onClick={handleAddAttendees} />
        </RightButtonContainer>
      </ModalFooterdWrapper>
    </Modal>
  );
};

export default EditSecretChatModal;
