import React from 'react';
import styled, { css } from 'styled-components';
import { uiTextMedium } from '../../../shared/typography';
import {
  gray1, darkBlue4, darkBlue6, darkBlue1,
} from '../../../shared/colours';
import { MeetingSections } from '../../../shared/types/types';
import UnviewedTasksBlueBubble from './UnviewedTasksBlueBubble';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import ButtonDivStyle from './utils/index';

interface ButtonProps {
  active: boolean,
  page: MeetingSections,
  disabled: boolean,
  shouldHavePadding: boolean,
}

const Button = styled(ButtonDivStyle) <ButtonProps>`
  ${uiTextMedium};
  height: fit-content;
  margin-right: 8px;
  width: fit-content;
  border-radius: 8px;
  z-index: 1;
  background: none;
  
    padding: ${(props) => (!props.shouldHavePadding ? '6px 12px' : '0px')};

  svg {
    margin-right: 0px !important;
    padding: 4px;
  }

  ${({ active }) => active && css`
    color: ${gray1};
    &:hover {
      background: none !important;
    }
  `};
  ${({ active }) => !active && css`
    color: ${darkBlue4};
    &:hover {
      color: ${darkBlue6};
      background: ${darkBlue1};
    }
  `};
`;

export interface Props {
  active: boolean,
  disabled: boolean,
  onClick: () => void,
  meetingTab?: MeetingSections,
  unviewedTasks?: number,
  tooltipText: string,
  children: React.ReactNode;
  padding?: boolean,
}

const TabDivButton = ({
  active, disabled, onClick, meetingTab = 'agenda', unviewedTasks = 0, tooltipText, children, padding = false,
}: Props) => (
  <>
    <ReactTooltip tip={tooltipText} delay place="top">
      <Button
        active={active}
        disabled={disabled}
        onClick={onClick}
        page={meetingTab}
        shouldHavePadding={padding}
        data-meeting-tab={meetingTab}
      >
        {children}
      </Button>
    </ReactTooltip>
    <UnviewedTasksBlueBubble unviewedTasks={unviewedTasks} />
  </>
);

export default TabDivButton;
