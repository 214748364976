import React from 'react';
import SubtitleBullet from '../welcome-screens-subtitle-bullet-atom';
import SubtitleBulletsContainer from './utils/styles';
import CustomizedBullet from './utils/types';

interface SubtitleBulletsProps {
  subtitle: string[],
  customized?: CustomizedBullet
}

const SubtitleBullets = ({
  subtitle,
  customized = '',
}: SubtitleBulletsProps) => (
  <SubtitleBulletsContainer customized={customized}>
    {subtitle.map((bullet) => (
      <SubtitleBullet key={bullet} text={bullet} customized={customized} />
    ))}
  </SubtitleBulletsContainer>
);

SubtitleBullets.defaultProps = {
  customized: '',
};

export default SubtitleBullets;
