import { GapiMeetingData } from '../../shared/types/types';

const mapGapiResultToGapiMeetingData = (
  databaseData: any,
): GapiMeetingData => {
  const gapiMeetingData: GapiMeetingData = {
    resolvedState: 'resolved',
    updated: databaseData?.updated ?? '',
    summary: databaseData?.summary ?? '',
    description: databaseData?.description ?? '',
    status: databaseData?.status ?? '',
    start: {
      dateTime: databaseData?.start?.dateTime ?? '',
    },
    sequence: databaseData?.sequence ?? 0,
    reminders: {
      useDefault: databaseData?.reminders?.useDefault ?? false,
    },
    organizer: {
      email: databaseData?.organizer?.email ?? '',
      self: databaseData?.organizer?.self ?? false,
    },
    kind: databaseData?.kind ?? '',
    id: databaseData?.id ?? '',
    iCalUID: databaseData?.iCalUID ?? '',
    eventType: databaseData?.eventType ?? '',
    recurringEventId: databaseData?.recurringEventId ?? '',
    etag: databaseData?.etag ?? '',
    end: {
      dateTime: databaseData?.end?.dateTime ?? '',
    },
    creator: {
      email: databaseData?.creator?.email ?? '',
      self: databaseData?.creator?.self ?? false,
    },
    created: databaseData?.created ?? '',
    conferenceData: {
      conferenceId: databaseData?.conferenceData?.conferenceId ?? '',
      conferenceSolution: {
        iconUri: databaseData?.conferenceData?.conferenceSolution?.iconUri ?? '',
        key: {
          type: databaseData?.conferenceData?.conferenceSolution?.key?.type ?? '',
        },
        name: databaseData?.conferenceData?.conferenceSolution?.name ?? '',
      },
      entryPoints: databaseData?.conferenceData?.entryPoints ?? [],
      signature: databaseData?.conferenceData?.signature ?? '',
    },
    attendees: databaseData?.attendees ?? [],
  };
  return gapiMeetingData;
};

export default mapGapiResultToGapiMeetingData;

export const rejectedGapiMeetingData: GapiMeetingData = {
  ...mapGapiResultToGapiMeetingData({}),
  resolvedState: 'rejected',
};

export const pendingGapiMeetingData: GapiMeetingData = {
  ...mapGapiResultToGapiMeetingData({}),
  resolvedState: 'pending',
};
