import { uniqueId } from 'lodash';
import { SIDEBAR_STATE } from '../enums';
import { WindowMessage } from '../../shared/types/types';

const validOrigins: string[] = [
  'https://calendar.google.com',
  'https://meet.google.com',
];

export const checkOrigin = (origin: string): boolean => validOrigins
  .map((validOrigin) => origin.startsWith(validOrigin))
  .reduce((pre, cur) => pre || cur);

// TODO use new hook
export const listenToWindowRouteMessages = (history: any) => {
  let previousRoute = '';
  window.addEventListener('message', (event) => {
    // IMPORTANT: check the origin of the data!
    if (checkOrigin(event.origin)) {
      // if (event.origin.startsWith('http://your-first-site.com')) {
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
      const route = event.data?.redirect ?? '';
      if (!route) return;
      // TODO. Rather use useLocation()
      if (previousRoute === route) return;
      console.log(`Redirecting to ${route}`);
      history.push(route);
      previousRoute = route;
    } else {
      // The data was NOT sent from your site!
      // Be careful! Do not use it. This else branch is
      // here just for clarity, you usually shouldn't need it.
    }
  });
};

export const listenToSidebarStateMessage = (
  sidebarStateSetter: any,
) => {
  window.addEventListener('message', (event) => {
    if (checkOrigin(event.origin) && event.data.type === SIDEBAR_STATE
      && event.data.id === undefined) {
      sidebarStateSetter(event.data.isSidebarOpen);
      console.log(`sidebar message: ${event.data.isSidebarOpen}`);
    }
  });
};

export function sendWindowMessage<T>(
  data: T, type: string,
): Promise<WindowMessage> {
  return new Promise<WindowMessage>((resolve, reject) => {
    const CEWindow: Window = window.parent;
    const messageBody: WindowMessage = {
      data, id: uniqueId(), type, sequence: 0, ack: false,
    };
    try {
      CEWindow.postMessage(messageBody, '*');
      resolve(messageBody);
    } catch (error) {
      reject(error);
    }
  });
}
