import React from 'react';
import styled from 'styled-components';
import { surface } from '../colours';

interface ArrowProps {
  direction?: string
}

const ArrowContainer = styled.div<ArrowProps>`
  display: flex;
  align-items: center;

  transform: ${(props) => `rotate(${props.direction ?? 0})`};
`;

interface Props {
  fill?: string,
  direction?: string,
  width?: string,
  height?: string
}

const Arrow = ({
  fill,
  direction,
  width = '5px',
  height = '8px',
}: Props) => {
  const getDirection = () => {
    if (direction === 'right') return '180deg';
    if (direction === 'up') return '90deg';
    if (direction === 'down') return '-90deg';

    return '0deg';
  };

  const rotateValue = getDirection();

  return (
    <ArrowContainer direction={rotateValue}>
      <svg width={width} style={{ height }} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.70706 0.292068C4.51817 0.105261 4.26322 0.000488249 3.99756 0.000488237C3.7319 0.000488226 3.47695 0.105261 3.28806 0.292068L0.323059 3.23107C0.108059 3.44907 0.00105937 3.73107 0.00105936 4.01007C0.00105934 4.28907 0.108059 4.56607 0.323059 4.77907L3.27806 7.70907C3.46708 7.89563 3.72197 8.00024 3.98756 8.00024C4.25314 8.00024 4.50804 7.89563 4.69706 7.70907C4.79024 7.61724 4.86424 7.5078 4.91475 7.38711C4.96526 7.26642 4.99127 7.1369 4.99127 7.00607C4.99127 6.87524 4.96526 6.74571 4.91475 6.62503C4.86424 6.50434 4.79024 6.3949 4.69706 6.30307L2.38006 4.00507L4.70706 1.69807C4.79988 1.60603 4.87356 1.49652 4.92383 1.37586C4.97411 1.25521 5 1.12578 5 0.995069C5 0.864353 4.97411 0.734931 4.92383 0.614272C4.87356 0.493613 4.79988 0.384105 4.70706 0.292068Z" fill={fill} />
      </svg>
    </ArrowContainer>
  );
};

Arrow.defaultProps = {
  fill: surface,
  direction: 'left',
  width: '5px',
  height: '8px',
};

export default Arrow;
