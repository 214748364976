import React from 'react';

interface Props {
  fill?: string,
}

const RectanglePlusIcon = ({ fill }: Props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8588 2H8.1412C4.75586 2 2.5 4.4199 2.5 7.89317V16.1068C2.5 19.5833 4.74992 22 8.1412 22H16.8588C20.2501 22 22.5 19.5833 22.5 16.1068V7.89317C22.5 4.41665 20.2501 2 16.8588 2ZM8.1412 3.39535H16.8588C19.4563 3.39535 21.1047 5.16587 21.1047 7.89317V16.1068C21.1047 18.8341 19.4563 20.6047 16.8588 20.6047H8.1412C5.54372 20.6047 3.89535 18.8341 3.89535 16.1068V7.89317C3.89535 5.16949 5.54923 3.39535 8.1412 3.39535ZM12.5 7.88587C12.8532 7.88587 13.1451 8.14834 13.1913 8.48887L13.1977 8.58354V11.2935H15.9109C16.2962 11.2935 16.6085 11.6058 16.6085 11.9911C16.6085 12.3444 16.3461 12.6363 16.0055 12.6825L15.9109 12.6888H13.1977V15.3988C13.1977 15.7841 12.8853 16.0964 12.5 16.0964C12.1468 16.0964 11.8549 15.834 11.8087 15.4934L11.8023 15.3988V12.6888H9.08915C8.70383 12.6888 8.39147 12.3765 8.39147 11.9911C8.39147 11.6379 8.65394 11.346 8.99448 11.2998L9.08915 11.2935H11.8023V8.58354C11.8023 8.19823 12.1147 7.88587 12.5 7.88587Z" fill={fill} />
  </svg>
);

RectanglePlusIcon.defaultProps = {
  fill: 'white',
};

export default RectanglePlusIcon;
