/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import Modal from '.';
import {
  header300, header700,
} from '../../typography';
import MediaPaginator from '../MediaPaginator';
import { tenorFetch, tenorToMediaResources } from '../../logic/MediaSearchParams/tenor';
import { MediaResource } from '../../logic/MediaSearchParams/types';

const Gifzone = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  padding: 0 32px 32px 32px;
`;

const SearchzoneContent = styled.div`
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 24px;
  border: 2px #B7BECF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > * {
    box-sizing: content-box;
  }
`;

const GifzoneHeader = styled.h2`
  color: #595959;
  margin-top: 0;
  margin-bottom: 4px;
  ${header700}
`;

const GifzoneSubheader = styled.h4`
  color: #595959;
  margin-top: 4px;
  margin-bottom: 0;
  ${header300}
`;

const ButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
  gap: 8px;
`;

interface GifSearchModalProps {
  isOpen: boolean;
  setModalClosed: () => void;
  path: string,
  schema: Schema;
  view: EditorView;
}

// TODO: Find a way to incorporate overflow:scroll
//  Too many files cause the modal to go 'out of bounds'

const FileUploadModal = ({
  isOpen, setModalClosed, path, view, schema,
}: GifSearchModalProps) => {
  const closeAndClean = () => {
    setModalClosed();
  };

  const insertMediaSelection = (resource: MediaResource) => {
    const transaction = view.state.tr;
    transaction.insert(
      view.state.selection.$from.pos,
      schema.nodes.image.create({ src: resource.webformatURL }),
    );
    view.dispatch(transaction);
    closeAndClean();
  };

  return (
    <Modal
      title="Add Gif"
      isOpen={isOpen}
      setModalClosed={setModalClosed}
    >
      <Gifzone>
        <SearchzoneContent>
          <MediaPaginator
            hasPagination={false}
            // paginateNext={() => {}}
            // paginatePrevious={() => {}}
            mediaFetcher={tenorFetch}
            responseParser={tenorToMediaResources}
            mediaSelection={insertMediaSelection}
          />
        </SearchzoneContent>
      </Gifzone>
    </Modal>
  );
};

export default FileUploadModal;
