import React from 'react';
import { gray1, gray3, yellow6 } from '../../colours';

interface Props {
  fill?: string,
}
const ClockIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="3" fill={fill} />
    <path d="M14 11H9.25C9.11193 11 9 10.8881 9 10.75V7" stroke={gray1} strokeWidth="1.4" strokeLinecap="round" />
    <rect x="1" y="1" width="18" height="18" rx="3" stroke={gray3} strokeWidth="2" />
  </svg>

);

ClockIcon.defaultProps = {
  fill: yellow6,
};

export default ClockIcon;
