import React from 'react';
import styled from 'styled-components';
import imgSource from '../../../../shared/img/open-doodles-coffee.png';

const Image = styled.img``;

const ImageAtom = () => (
  <Image src={imgSource} />
);

export default ImageAtom;
