import React from 'react';
import styled from 'styled-components';
import { gray3, gray6 } from '../../../../colours';
import ClearColorIcon from '../../../../icons/text-editor/ClearColorIcon';
import { uiTextMedium } from '../../../../typography';

const ResetContainer = styled.div`
  padding: 6px 16px 6px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  :hover{
    background-color: ${gray3}
  }
  :active {
    background-color: ${gray6};
  }
  
`;

const ResetText = styled.span`
  ${uiTextMedium};
  height: 18px;
  margin-left: 8px;
`;

interface Props {
  onMouseDown: () => void;
}

const ColorDropdownResetButtonAtom = ({ onMouseDown }: Props) => (
  <ResetContainer
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onMouseDown();
    }}
  >
    <ClearColorIcon />
    <ResetText>
      Reset
    </ResetText>
  </ResetContainer>
);

export default ColorDropdownResetButtonAtom;
