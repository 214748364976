import React from 'react';
import styled from 'styled-components';
import { darkBlue3, darkBlue4 } from '../../../../shared/colours';
import { uiTextS } from '../../../../shared/typography';

const Explanation = styled.span<TextProps>`
  ${uiTextS};
  color: ${({ isCreated }) => (isCreated ? darkBlue3 : darkBlue4)};
  white-space: pre-line;
`;

interface TextProps {
  isCreated: boolean;
}

interface Props {
  explanation: string,
  isCreated?: boolean, // used to distinguish expalantion and created text
}

const ExplanationAtom = ({ explanation, isCreated = false }: Props) => (
  <Explanation isCreated={isCreated}>
    {explanation}
  </Explanation>
);

ExplanationAtom.defaultProps = {
  isCreated: false,
};

export default ExplanationAtom;
