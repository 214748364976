import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MeetingData } from '../../../types/types';
import { header400, paragraph } from '../../../typography';
import {
  gray1, gray3, gray9,
} from '../../../colours';
import { getDayAndMonth } from '../../../../utils/dateUtils/date';

const DropDownContainer = styled.div`
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background-color: ${gray1};

  width: 170px;

  margin-bottom: 8px;

  border-radius: 8px;
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);
`;

const DropDownTitle = styled.span`
  ${header400};
  color: ${gray9};

  width: calc(100% - 32px);

  padding: 12px 16px 8px 16px;

  &:hover {
    cursor: default;
  }
`;

const DropDownOptionContainer = styled.button`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: calc(100% - 32px);

  padding: 6px 16px;

  ${paragraph}

  &:hover {
    cursor: pointer;
    background-color: ${gray3};
  }

  &:last-child {
    padding-bottom: 10px;
  }
`;

interface Props {
  currentMeetingId: string,
  previousMeetingList: Array<MeetingData>,
  isDropDownOpen: boolean,
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>,
  dropDownRef: React.MutableRefObject<null>,
  setCurrentMeetingIndex: Dispatch<SetStateAction<number>>
}

const PreviousMeetingDropDownOptions = ({
  currentMeetingId,
  previousMeetingList,
  isDropDownOpen,
  setIsDropDownOpen,
  dropDownRef,
  setCurrentMeetingIndex,
}:Props) => {
  const history = useHistory();

  const handleOptionClick = (index:number, previousMeetingId:string) => {
    setIsDropDownOpen(false);
    setCurrentMeetingIndex(index);

    const newRouteURL = `/meeting/${currentMeetingId}/previousMeeting/${previousMeetingId}`;

    history.replace(newRouteURL);
  };

  if (isDropDownOpen) {
    return (
      <DropDownContainer ref={dropDownRef}>
        <DropDownTitle>Most recent</DropDownTitle>
        {previousMeetingList.map((previousMeeting, index) => (
          <DropDownOptionContainer
            onClick={() => handleOptionClick(index, previousMeeting.meetingId)}
          >
            {getDayAndMonth(previousMeeting.date.start.date)}
          </DropDownOptionContainer>
        ))}
      </DropDownContainer>
    );
  }

  return null;
};

export default PreviousMeetingDropDownOptions;
