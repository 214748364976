import styled from 'styled-components';
import { Tab } from 'react-tabs';
import {
  darkBlue4,
  gray1, gray8, surface, darkBlue1, darkBlue6,
} from '../../colours';

export const TabButtonStyles = styled.div`
  .react-tabs__tab {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 0;
    border-radius: 6px;      

    color: ${darkBlue4};

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    padding: 6px 12px;
    margin: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      background: ${darkBlue1};
      color: ${darkBlue6};
    }
    
    &--selected {
      color: ${gray1};
      background: ${surface};
        &:hover {
          background: ${gray8};
          color: ${gray1};
        }
    }
  }
`;

const TabButton = Tab;

export default TabButton;
