import React, {
  createContext, useState, Dispatch, SetStateAction, useEffect, useContext,
} from 'react';
import { DetectedOperatingSystemContext } from '../../../utils/context/OperatingSystemContext';
import { OperatingSystem, OperatingSystemState } from '../../types/types';
import Modal from '../Modal';
import ShortcutsForOS from './components/ShortcutsForOS';

export const ShortcutsContext = createContext<OperatingSystemState>({
  operatingSystem: 'Mac',
  setOperatingSystem: (() => {}) as Dispatch<SetStateAction<OperatingSystem>>,
});

interface ShortcutsProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ShortcutsModal = ({ isOpen, setIsOpen }: ShortcutsProps) => {
  const { detectedOperatingSystem } = useContext(DetectedOperatingSystemContext);
  const [operatingSystem, setOperatingSystem] = useState<OperatingSystem>('Windows');

  useEffect(() => {
    detectOperatingSystem();
  }, [isOpen, detectedOperatingSystem]);

  const detectOperatingSystem = () => {
    if (detectedOperatingSystem === 'Mac') setOperatingSystem('Mac');
    if (detectedOperatingSystem === 'Windows' || detectedOperatingSystem === 'Linux') setOperatingSystem('Windows');
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const modalTitle = 'Keyboard Shortcuts';

  return (
    <ShortcutsContext.Provider value={{ operatingSystem, setOperatingSystem }}>
      <Modal
        isOpen={isOpen}
        setModalClosed={handleModalClose}
        title={modalTitle}
        isShortcutsModal
      >
        <ShortcutsForOS operatingSystem={operatingSystem} />
      </Modal>
    </ShortcutsContext.Provider>
  );
};

export default ShortcutsModal;
