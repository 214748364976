import React, {
  useEffect,
  useState,
  createContext,
  SetStateAction,
  Dispatch,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { listenToSidebarStateMessage } from './utils/chromeExtension/chromeExtension';
import useWindowMessageReceiver from './utils/hook/windowMessageReceiver';
import { SIDEBAR_STATE } from './utils/enums';
import { logEventToggleSidebar, logEventToggleSidebar2 } from './utils/analytics/eventLogger';

interface Props {
  setHasSidebarOpened: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

export const SidebarStateContext = createContext<boolean>(false);

const ListenToSidebarMessageContainer = ({ setHasSidebarOpened, children }: Props) => {
  const { data: sidebarOpenState } = useWindowMessageReceiver<boolean>(false, SIDEBAR_STATE);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    listenToSidebarStateMessage(setSidebarOpen);
  }, []);

  useEffect(() => {
    // TODO this is for the transition between CE versions, should be cleanded up in the future
    console.log('%c[W] received sidebar state message', 'color: navy');
    console.log(sidebarOpenState);
    setSidebarOpen(sidebarOpenState);

    if (sidebarOpenState) {
      setHasSidebarOpened(true);
    }
  }, [sidebarOpenState]);

  useEffect(() => {
    if (isSidebarOpen) {
      console.log('Logging is sidebar open');
      logEventToggleSidebar(trackEvent, true);
      logEventToggleSidebar2(trackEvent, true);
    }
  }, [isSidebarOpen]);

  return (
    <SidebarStateContext.Provider value={isSidebarOpen}>
      {children}
    </SidebarStateContext.Provider>
  );
};

export default ListenToSidebarMessageContainer;
