import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../../shared/colours';
import DropdownItemAtom from './DropdownItemAtom';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 136px;
  height: 144px;
  border-radius: 10px;
  background-color: ${gray1};
  z-index: 100;
  padding: 8px;
  transform: translate(0px,97px);
`;

interface Props {
  isOpen: boolean,
  dropdownRef: MutableRefObject<null>,
}

const DropdownMolecule = ({ isOpen, dropdownRef }: Props) => {
  if (!isOpen) return null;

  const handleEditNotes = () => {
    console.log('edit notes clicked');
  };

  const handleGoToMeeting = () => {
    console.log('go to meeting');
  };

  const handleArchiveNotes = () => {
    console.log('archive notes');
  };

  const handleDelete = () => {
    console.log('delete');
  };

  return (
    <Container ref={dropdownRef}>
      <DropdownItemAtom text="Edit notes" onClick={handleEditNotes} />
      <DropdownItemAtom text="Go to meeting" onClick={handleGoToMeeting} />
      <DropdownItemAtom text="Archive notes" onClick={handleArchiveNotes} />
      <DropdownItemAtom text="Delete" onClick={handleDelete} isDelete />
    </Container>
  );
};

export default DropdownMolecule;
