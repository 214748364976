import React from 'react';

const ConnectedMark = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.6324 3.33366H6.36774C5.37118 3.33366 4.64728 3.68011 4.16769 4.18497C3.68051 4.69781 3.33341 5.4942 3.33341 6.57797V13.4227C3.33341 14.5093 3.68019 15.3053 4.16604 15.8169C4.64413 16.3204 5.36743 16.667 6.36774 16.667H13.6324C14.6327 16.667 15.356 16.3204 15.8341 15.8169C16.32 15.3053 16.6667 14.5093 16.6667 13.4227V6.57797C16.6667 5.49134 16.32 4.69537 15.8341 4.18374C15.356 3.68028 14.6327 3.33366 13.6324 3.33366ZM6.36774 1.66699H13.6324C16.4585 1.66699 18.3334 3.68087 18.3334 6.57797V13.4227C18.3334 16.3198 16.4585 18.3337 13.6324 18.3337H6.36774C3.54168 18.3337 1.66675 16.3198 1.66675 13.4227V6.57797C1.66675 3.68358 3.54663 1.66699 6.36774 1.66699Z" fill="#2FDBA1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9227 6.91107C14.2481 7.23651 14.2481 7.76414 13.9227 8.08958L9.16675 12.8455L6.07749 9.75625C5.75205 9.43081 5.75205 8.90317 6.07749 8.57774C6.40293 8.2523 6.93057 8.2523 7.256 8.57774L9.16675 10.4885L12.7442 6.91107C13.0696 6.58563 13.5972 6.58563 13.9227 6.91107Z" fill="#2FDBA1" />
  </svg>
);

export default ConnectedMark;
