import React from 'react';
import styled from 'styled-components';
import { TaskStatus } from '../../types/types';
import TaskDropdown from './TaskDropdown';
import TaskStatusDropdownOptions from './TaskStatusDropdownOptions';
import TaskStatusIcon from './TaskStatusIcon';

const Container = styled.div`
  margin-right: 16px;
`;

interface Props {
  enable?: boolean
  status: TaskStatus
  showText?: boolean
  // eslint-disable-next-line no-unused-vars
  updateStatus: (newStatus: TaskStatus) => void,
  willCloseAfterClick?: boolean,
}

const TaskStatusCheckbox = ({
  status, updateStatus, showText, willCloseAfterClick, enable,
}: Props) => {
  if (!enable) return <></>;
  return (
    <Container>
      <TaskDropdown
        willCloseAfterClick={willCloseAfterClick}
        options={TaskStatusDropdownOptions({ status, updateStatus })}
      >
        <TaskStatusIcon status={status} showText={showText} />
      </TaskDropdown>
    </Container>
  );
};

TaskStatusCheckbox.defaultProps = {
  enable: true,
  showText: false,
  willCloseAfterClick: false,
};

export default TaskStatusCheckbox;
