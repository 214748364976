import styled from 'styled-components';
import {
  surface, gray1, gray9, gray4,
} from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const ButtonDivStyle = styled.div`
  ${uiTextMedium};

  align-items: center;
  display: flex;
  background: ${surface};
  border: none;
  border-radius: 10px;
  color: ${gray1};
  cursor: pointer;
  height: 48px;
  justify-content: center;
  width: 100%;

  &:hover {
    background: ${gray9};
  }

  &:disabled {
    background: ${gray4};
    cursor: unset;
  }

  svg {
    margin-right: 32px;
    height: 24px;
  }
`;

export default ButtonDivStyle;
