import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../colours';
import Modal from './index';
import ModalLabel from './ModalLabel';
import TextBox from '../textbox';
import ButtonSmall from '../button-small';
import { messageText } from '../../typography';
import HorizontalLine from '../../icons/horizontalLine';
import { AuthContext } from '../../../App';
import FeedbackResponseModal from '../../../pages/meeting/modal/FeedbackResponseModal';
import dbSaveUserFeedback from '../../../database/firebaseFeedbackAPI';
import { getUTCString } from '../../../utils/dateUtils/date';
import { UserFeedback } from '../../types/types';
import { cfSendQuickFeedbackEmail } from '../../../database/cloudFunctionEmailAPI';

const TextContainer = styled.div`
  margin: 24px;
  ${messageText};
`;

const LineContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
`;

const LabelContainer = styled.div`
  margin: 24px 24px 8px 24px;
`;

const TextBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 409px;
  height: 160px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const Line = styled.div`
  background:  ${darkBlue1};
  height: 1px;
  width: 100%;
`;

export interface FeedbackModalProps {
  isModalOpen: boolean,
  setModalClosed: () => void
}

function FeedbackModal({ isModalOpen, setModalClosed }: FeedbackModalProps) {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const authState = useContext(AuthContext);
  const created = getUTCString(new Date().toLocaleString());
  const name = `${authState.firstName} ${authState.lastName}`;

  const userFeedback: UserFeedback = {
    created, email: authState.email, feedback, name, userId: authState.userId,
  };

  const handleSubmit = () => {
    setLoading(true);
    cfSendQuickFeedbackEmail(authState.email, feedback)
      .then(() => {
        console.log('sent successfully!');
        setLoading(false);
        handleFeedbackSent(true);
      })
      .catch((error) => {
        console.log(error);
        handleFeedbackSent(false);
      })
      .finally(() => {
        setLoading(false);
      });
    dbSaveUserFeedback(userFeedback);
  };

  const handleFeedbackSent = (response: boolean) => {
    setModalClosed();
    setIsResponseModalOpen(true);
    setIsFeedbackSent(response);
  };

  const handleCloseResponseModal = () => {
    setIsResponseModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isModalOpen} setModalClosed={setModalClosed} title="Your feedback">
        <TextContainer>
          We&apos;ll use your feedback to improve your Shepherd experience.
        </TextContainer>
        <LineContainer>
          <HorizontalLine />
        </LineContainer>
        <LabelContainer>
          <ModalLabel text="Feedback message" />
        </LabelContainer>
        <FlexContainer>
          <TextBoxContainer>
            <TextBox placeholder="Tell us what's going on..." onChange={(event: any) => setFeedback(event)} />
          </TextBoxContainer>
        </FlexContainer>
        <Line />
        <ButtonRow>
          <ButtonSmall onClick={setModalClosed} text="Cancel" isOutline />
          <ButtonSmall type="submit" onClick={handleSubmit} text="Submit feedback" loading={loading} isDisabled={feedback.length <= 1} />
        </ButtonRow>
      </Modal>
      <FeedbackResponseModal
        isResponseModalOpen={isResponseModalOpen}
        closeConfModal={handleCloseResponseModal}
        isFeedbackSent={isFeedbackSent}
      />
    </>
  );
}

export default FeedbackModal;
