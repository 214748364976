import React, {
  Dispatch, ReactNode, SetStateAction, useRef,
} from 'react';
import styled from 'styled-components';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';

interface Props {
  children: ReactNode,
  options: ReactNode,
  willCloseAfterClick?: boolean,
  setIsOpen?: Dispatch<SetStateAction<boolean>>,
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownButton = styled.span`
  cursor: pointer;
`;

const DropdownOptions = styled.div`
`;

const TaskDropdown = ({
  children, options, willCloseAfterClick, setIsOpen,
}: Props) => {
  const optionsRef = useRef(null);
  const [isOpen, setOpen] = useDetectOutsideClick(optionsRef, false);
  const onDropdownButtonClick = () => {
    setOpen(!isOpen);
    if (typeof setIsOpen !== 'undefined') setIsOpen(!isOpen);
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={onDropdownButtonClick}>
        {children}
      </DropdownButton>
      {
        isOpen
        && (
          <DropdownOptions
            ref={optionsRef}
            onClick={
              willCloseAfterClick
                ? () => setOpen(false)
                : () => { }
            }
          >
            {options}
          </DropdownOptions>
        )
      }
    </DropdownWrapper>
  );
};

TaskDropdown.defaultProps = {
  willCloseAfterClick: false,
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (_dummy: any) => {},
};

export default TaskDropdown;
