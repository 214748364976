import React, { ReactNode } from 'react';
import SimpleTabDropdown, { TabDropdownItem } from '../../../../../../shared/components/simple-dropdown';
import { SettingSubText } from '../SettingTab';

interface Props {
  label: ReactNode,
  placeholder: string,
  name: string,
  options:TabDropdownItem[],
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: TabDropdownItem) => void,
  onClick: ()=>void
}

export default function ListSettingTab({
  label, placeholder, name, options, loading, onChange, onClick,
}:Props) {
  return (
    <>
      <SettingSubText>
        {label}
      </SettingSubText>
      <SimpleTabDropdown
        onClick={onClick}
        onChange={onChange}
        loading={loading}
        options={options}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
}
