import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../shared/colours';
import { UserCenterSection } from '../../../shared/types/types';
import PageTabButtonAtom from './PageTabButtonAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${darkBlue1};
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 24px 0px 24px;
  white-space: nowrap;

  & > div:not(:last-of-type) {
    margin-right: 20px;
  }
`;

interface Props {
  section: UserCenterSection,
  setSection: Dispatch<SetStateAction<UserCenterSection>>
}

const Navbar = ({ section, setSection }: Props) => {
  // const handleAllNotesClicked = () => {
  //   console.log('all meeting notes clicked!');
  //   setSection('meetings');
  // };

  const handleAllTasksClicked = () => {
    console.log('all tasks clicked');
    setSection('tasks');
  };

  const handleCurrentMeetingClicked = () => {
    setSection('currentMeeting');
  };

  // const handleTagsClicked = () => {
  //   setSection('tags');
  // };

  return (
    <Container>
      <ButtonsContainer>
        {/* <ButtonContainer>
          <ButtonAtom
            text="All notes"
            onClick={handleAllNotesClicked}
            isSelected={section === 'meetings'}
            tooltipText="Dashboard of all your notes"
          />
        </ButtonContainer> */}
        <PageTabButtonAtom
          text="All tasks"
          onClick={handleAllTasksClicked}
          isSelected={section === 'tasks'}
          tooltipText="Dashboard of all your tasks"
        />
        <PageTabButtonAtom
          text="Current meeting"
          onClick={handleCurrentMeetingClicked}
          isSelected={section === 'currentMeeting'}
          tooltipText="Current meeting notes and tasks"
        />
        {/* <ButtonAtom
          text="Tags"
          onClick={handleTagsClicked}
          tooltipText="Don't know for now"
          isSelected={section === 'tags'}
        /> */}
      </ButtonsContainer>
    </Container>
  );
};

export default Navbar;
