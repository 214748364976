import React from 'react';
import BlackCheckedBox from '../../../../shared/icons/BlackCheckedBox';
import { EmptyIcon } from '../../../../shared/icons/tasks';

interface Props {
  isChecked: boolean,
}

const CheckIconsAtom = ({ isChecked }: Props) => (
  isChecked ? (
    <BlackCheckedBox />
  ) : (
    <EmptyIcon />
  )
);

export default CheckIconsAtom;
