import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { gray1, layoutGrey } from '../../../colours';
import { ShortcutsContext } from '../index';
import AppleLogo from '../../../icons/AppleIcon';
import WindowsLogo from '../../../icons/WindowsIcon';

interface SelectedProps {
  isAnimated: boolean;
}

const SwitchButtonContainer = styled.div<SelectedProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  background-color: ${layoutGrey};
  width: 54px;
  height: 28px;

  border-radius: 20px;

  // the circle showing the selected OS
  &::before{
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;

    background-color: ${gray1};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

    border-radius: 50%;

    margin: 2px;

    transition: 0.5s;
    ${({ isAnimated }) => isAnimated && css`
      transform: translateX(26px);
    `};
  }
`;

const LogoContainer = styled.button`
  all: unset;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 27px;
  height: 28px;

  z-index: 2;

  &:hover {
    cursor: pointer;
  }
`;

const SelectOSButton = () => {
  const ShortcutsCtx = useContext(ShortcutsContext);
  const [isAnimated, setIsAnimated] = React.useState(false);

  const handleWindowsClick = () => {
    setIsAnimated(false);
    ShortcutsCtx.setOperatingSystem('Windows');
  };

  const handleMacClick = () => {
    setIsAnimated(true);
    ShortcutsCtx.setOperatingSystem('Mac');
  };

  useEffect(() => {
    const isSelectedOperatingSystemMac = ShortcutsCtx.operatingSystem === 'Mac';
    setIsAnimated(isSelectedOperatingSystemMac);
  }, [ShortcutsCtx.operatingSystem]);

  return (
    <SwitchButtonContainer isAnimated={isAnimated}>
      <LogoContainer onClick={handleWindowsClick}>
        <WindowsLogo />
      </LogoContainer>
      <LogoContainer onClick={handleMacClick}>
        <AppleLogo />
      </LogoContainer>
    </SwitchButtonContainer>
  );
};

export default SelectOSButton;
