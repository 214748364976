import { MarkSpec } from 'prosemirror-model';

/**
 * If better cross-platform text insertion is
 * desired, a parseDOM method needs to be attached
 * here as per the description of the MarkSpec type.
 *
 * Compatibility depends on how certain applications
 * format their custom-sized text.
 */

export default {
  attrs: {
    size: {
      default: 16,
    },
  },
  toDOM(mark) {
    return [
      'span',
      { style: `font-size: ${mark.attrs.size}px;` },
    ];
  },
} as MarkSpec;
