import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const CrossIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.7703 9.66123C10.9173 9.80816 10.9999 10.0075 11 10.2153C11 10.4232 10.9175 10.6225 10.7706 10.7696C10.6237 10.9166 10.4243 10.9992 10.2165 10.9993C10.0086 10.9993 9.80927 10.9168 9.66226 10.7699L8.00026 9.10723L6.33759 10.7699C6.26498 10.8429 6.17866 10.9008 6.08358 10.9404C5.98851 10.9799 5.88656 11.0003 5.78359 11.0003C5.68062 11.0003 5.57867 10.9799 5.4836 10.9404C5.38852 10.9008 5.3022 10.8429 5.22959 10.7699C5.08258 10.6229 5 10.4235 5 10.2156C5 10.0077 5.08258 9.80826 5.22959 9.66123L6.89159 7.99923L5.22959 6.33723C5.09143 6.18856 5.0163 5.9921 5.02002 5.78917C5.02374 5.58625 5.106 5.39267 5.24952 5.24916C5.39303 5.10564 5.58661 5.02338 5.78953 5.01966C5.99246 5.01594 6.18892 5.09107 6.33759 5.22923L8.00026 6.89123L9.66226 5.22923C9.73501 5.15648 9.82138 5.09877 9.91643 5.05939C10.0115 5.02002 10.1134 4.99976 10.2163 4.99976C10.3191 4.99976 10.421 5.02002 10.5161 5.05939C10.6111 5.09877 10.6975 5.15648 10.7703 5.22923C10.843 5.30198 10.9007 5.38835 10.9401 5.48341C10.9795 5.57846 10.9997 5.68034 10.9997 5.78323C10.9997 5.88612 10.9795 5.988 10.9401 6.08305C10.9007 6.17811 10.843 6.26448 10.7703 6.33723L9.10826 7.99923L10.7703 9.66123Z" fill={fill} />
  </svg>
);

CrossIcon.defaultProps = {
  fill: surface,
  width: 16,
  height: 16,
};

export default CrossIcon;
