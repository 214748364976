import styled from 'styled-components';
import {
  blue6, blue7, darkBlue1, darkBlue4, gray1, surface,
} from '../../colours';
import { uiText } from '../../typography';

const FirepadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div > div:nth-of-type(2) {
    height: unset;
  } 

  .firepad-toolbar {
    background-color: ${gray1};
    border-bottom: none;
    padding-left: 0;
    line-height: 40px;
  }

  .firepad-toolbar-wrapper {
    margin-left: -7px;
    .firepad-btn-group:last-of-type{ // Remove img icon
      display:none;
    }
  }

  .powered-by-firepad {
    display: none;
  }
  
  .firepad-dropdown {
    display: none;
  }

  .firepad-btn {
    color: ${darkBlue4};
    border-bottom-width: 1px
  }

  .firepad-btn:hover {
    background-color: ${blue6};
    border-color: ${blue7};
    border-bottom-width: 1px
  }

  .firepad-with-toolbar .CodeMirror-lines {
    padding-top: 3px;
  }
  
  .firepad-with-toolbar .CodeMirror {
    top: 40px;
  }
  a.firepad-btn, a.firepad-btn:visited, a.firepad-btn:active {
    padding: 4px 4px 2px 4px;
    font-size: 14px;
  }

  .CodeMirror {
    ${uiText};
    color: ${surface};
    border-top: 2px solid ${darkBlue1};
    border-bottom: 2px solid ${darkBlue1};
    height: fit-content;
    min-height: 150px;
    padding: 24px 16px 24px;
  }

  .CodeMirror pre {
    z-index: unset;
  }

  .CodeMirror-lines > div > div {
    z-index: unset !important;
  }

  .CodeMirror-scroll {
    overflow-x: unset !important;
    margin-right: -40px;
  }

  /* Indent paddings */

  pre.firepad-lt-o, pre.firepad-lt-u, pre.firepad-lt-t, pre.firepad-lt-tc {
    padding-left: 20px;
  }

  pre.CodeMirror-line.firepad-li-1 {
    padding-left: 20px;
  }

  pre.CodeMirror-line.firepad-li-2 {
    padding-left: 40px;
  }

  pre.CodeMirror-line.firepad-li-3 {
    padding-left: 60px;
  }

  pre.CodeMirror-line.firepad-li-4 {
    padding-left: 80px;
  }

  pre.CodeMirror-line.firepad-li-5 {
    padding-left: 100px;
  }

  pre.CodeMirror-line.firepad-li-6 {
    padding-left: 120px;
  }

  pre.CodeMirror-line.firepad-li-7 {
    padding-left: 140px;
  }

  pre.CodeMirror-line.firepad-li-8 {
    padding-left: 160px;
  }

  pre.CodeMirror-line.firepad-li-9 {
    padding-left: 180px;
  }

  pre.CodeMirror-line.firepad-li-10 {
    padding-left: 200px;
  }

  pre.CodeMirror-line.firepad-li-11 {
    padding-left: 220px;
  }

  pre.CodeMirror-line.firepad-li-12 {
    padding-left: 240px;
  }

  pre.CodeMirror-line.firepad-li-13 {
    padding-left: 260px;
  }

  pre.CodeMirror-line.firepad-li-14 {
    padding-left: 280px;
  }

  pre.CodeMirror-line.firepad-li-15 {
    padding-left: 300px;
  }
`;

export default FirepadContainer;
