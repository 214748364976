import React, {
  useEffect, useState, useContext,
} from 'react';
import styled from 'styled-components';
import PreviousMeetingHeader from './components/HeaderBar';
import PreviousMeetingNotesView from './components/NotesView';
import { AuthContext, UserDataContext } from '../../../App';
import dbListenToMeetingData from '../../../database/firebaseMeetingAPI';
import {
  MeetingData, MeetingSections, TaskItem, AuthState, User, SecretChat,
} from '../../types/types';
import TabsMenu from '../../../pages/meeting/tab/TabsMenu';
import { pendingMeetingData } from '../../../database/utils/templateMeetingData';
import { dbListenToTasksForMeeting } from '../../../database/firebaseTasksAPI';
import { darkBlue1 } from '../../colours';
import sendPreviousMeetingWindowMessage from '../../../utils/previousMeetingInjection';
import PreviousMeetingDropDown from './components/DropDown';
import TextEditorBottomOverlay from './components/BottomOverlay';
import PreviousMeetingDataContext from './context/PreviousMeetingDataContext';
import { AGENDA, SHARED_NOTES } from '../../../utils/enums';

const Container = styled.div`
  width: 520px;
  height: calc(100vh);

  border-radius: 15px 15px 0 0;
  overflow:hidden;
`;

const HorizontalLine = styled.hr`
  margin: 0;
  border: 1px solid ${darkBlue1};
`;

type Props = {
  match: any;
}

const PreviousMeetingNotes = ({ match }: Props) => {
  const previousMeetingId: string = match?.params?.previousMeetingId ?? '';
  const currentMeetingId: string = match?.params?.meetingId ?? '';

  const authState = useContext<AuthState>(AuthContext);
  const userData = useContext<User>(UserDataContext);

  const [previousMeetingData, setPreviousMeetingData] = useState<MeetingData>(pendingMeetingData);
  const secretChatId = '';

  const [meetingTab, setMeetingTab] = useState<MeetingSections>(SHARED_NOTES);
  const [meetingTasks, setMeetingTasks] = useState<TaskItem[]>([]);

  /* eslint-disable-next-line no-unused-vars */
  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [secretNotes, setSecretNotes] = useState<SecretChat[]>([]);

  useEffect(() => {
    if (previousMeetingData.version >= 4 && userData.settings.defaultUserTab === AGENDA) {
      return setMeetingTab(SHARED_NOTES);
    }

    return setMeetingTab(userData.settings.defaultUserTab);
  }, [previousMeetingData.version, userData.settings.defaultUserTab]);

  useEffect(() => {
    if (previousMeetingId) {
      return dbListenToTasksForMeeting(
        previousMeetingId, authState.email, setMeetingTasks,
      );
    }

    return () => { };
  }, [previousMeetingId]);

  useEffect(() => {
    if (previousMeetingId) {
      return dbListenToMeetingData(
        previousMeetingId,
        authState.userId,
        setPreviousMeetingData,
        () => { },
      );
    }

    return () => { };
  }, [previousMeetingId]);

  const handleCloseButtonClick = () => {
    sendPreviousMeetingWindowMessage({ meetingId: '', previousMeetingId: '' });
  };

  if (previousMeetingId === '') {
    return (
      <>No previous meeting</>
    );
  }

  const handleSecretNotesSelected = (chatId: string) => {
    console.log(chatId);
  };

  return (
    <PreviousMeetingDataContext.Provider value={previousMeetingData}>
      <Container>
        <PreviousMeetingHeader
          handleClick={handleCloseButtonClick}
        />
        <HorizontalLine />
        <TabsMenu
          meetingData={previousMeetingData}
          meetingTab={meetingTab}
          setMeetingTab={setMeetingTab}
          unviewedTasks={0}
          secretNotes={secretNotes}
          handleSecretNotesSelected={handleSecretNotesSelected}
          setSecretNotes={setSecretNotes}
          meetingDataVersion={previousMeetingData.version}
          secretChatId={secretChatId}
        />
        <HorizontalLine />
        <PreviousMeetingNotesView
          meetingId={previousMeetingId}
          meetingTab={meetingTab}
          meetingTasks={meetingTasks}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
        />
        <TextEditorBottomOverlay />
        <PreviousMeetingDropDown
          meetingId={currentMeetingId}
          previousMeetingId={previousMeetingId}
        />
      </Container>
    </PreviousMeetingDataContext.Provider>
  );
};

export default PreviousMeetingNotes;
