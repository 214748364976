import { Block } from '@meetshepherd/martian/build/src/notion';
import React, { Dispatch, SetStateAction } from 'react';
import { ColorDropdownColors } from '../components/text-editor/dropdowns/ColorDropdown/ColorDropdownUtils';
import {
  blue6, cyan6, green6, purple6, red6,
} from '../colours';

export interface AuthState {
  userState: LoginState;
  userId: string;
  firstName: string,
  lastName: string,
  email: string,
  photoUrl: string
}

export type TimeTrackingEvents = 'appLoad' | 'attendeesLoad' | 'notesLoad' | 'meetingDataLoad'
export type LoginState = 'loggedIn' | 'loggedOut' | 'unresolved';
export type MeetingSections = NoteType | 'task';
export type NoteType = 'agenda' | 'shared' | 'private' | 'secret';
export type ConferenceType = 'googleMeet' | 'zoom' | 'undefined';

export interface MeetingData extends CoreMeetingData {
  resolvedState: ResolvedState; // Indicated if we are waiting for data to be fetched 'pending'
  // or we have successfully resolved the data 'resolved'
  // or we failed fetching the data 'rejected'
  meetingId: string;
  permissions: Permissions;
  userRole: UserPermissions;
  attendees: {
    attendees: AttendeeV2[];
    resolvedState: ResolvedState;
  };
}

// How the data is stored in the database
export interface DatabaseMeetingData extends CoreMeetingData {
  permissions: DatabasePermissions;
}

export interface Permissions {
  users: SimpleUserData[];
  userGroups: string[];
  linkPermissions: LinkPermissions;
}

// Meeting Data Fields that are common between database model and model used in the code
interface CoreMeetingData {
  version: Version;
  data: {
    agenda: any[];
    attachments: any[];
    attendees: any[];
    description: string;
    title: string;
    postMeetingTasks: any[];
    preMeetingTasks: any[];
  };
  tags: {
    tags: Tag[];
    meetingSeries: {
      name: string;
      id: string;
    };
  };
  date: {
    created: {
      date: string;
      timestamp: number;
    };
    start: {
      date: string;
      timestamp: number;
    };
    end: {
      date: string;
      timestamp: number;
    };
    lastUpdated: {
      date: string;
      timestamp: number;
    };
  };
  googleData: {
    ids: {
      eventId: string;
      recurringEventId: string;
      dataEventId: string; // Previously googleEventId
      calendarId: string;
      meetId: string;
    },
    content: {
      summary: string;
    }
    conferenceData: ConferenceData,
  };
}

export interface ConferenceData {
  type: ConferenceType,
  link: string,
}

interface Tag {
  name: string;
  id: string;
}
export interface DatabasePermissions {
  users: DatabaseUsers;
  userGroups: string[];
  linkPermissions: LinkPermissions;
}

export interface User extends CoreUser {
  resolvedState: ResolvedState;
  userId: string,
  friendListV2: FriendListV2;
  /**
  * A lot of components uses the PublicUserDataV2 type
  * and sometimes we want to pass in the auth user itself
  * For convenience, add this property here
   */
  publicUserData: PublicUserDataV2,
}

export interface DatabaseUser extends CoreUser {
  friendList2: DatabaseFriendListV2;
}

export interface CoreUser {
  data: {
    name: string;
    email: string;
    firstName: string,
    lastName: string,
    photoUrl: string
    newFeaturesViewed: string[],
    hasOnboarded: boolean,
    onboarding: OnboardingData,
    receivedWelcomeEmail: boolean
  },
  meta: {
    admin: boolean;
  },
  settings: UserSettings,
  date: {
    created: SDate,
    updated: SDate,
  },
  integrations: {
    slack: SlackData[],
    notion: NotionData[],
    trello: TrelloData,
    jira: JiraData,
  }
  friendList: PublicUserData[],
}

export interface UserSettings {
  fanOfCoding: boolean,
  fanOfPPT: boolean,
  receiveNewsletter: boolean,
  defaultUserTab: MeetingSections,
  receiveTaskEmail: boolean,
}

export interface OnboardingData {
  personalShepherdUsage: string,
  jobType: string,
  companySize: string,
  productivityTool: string,
  remotePolicy: string
}

export interface SlackData {
  version: SlackIntegrationVersion
  userAccessToken: string,
  botAccessToken: string,
  userId: string,
  defaultChannels: SlackChannel[],
  notifications: SlackNotifications,
  date: {
    created: SDate,
    updated: SDate,
  },
}

export interface SlackNotifications {
  meetingStartsSoon: boolean,
  mentionedInNotes: boolean,
  taskOverdue: boolean,
  taskCreated: boolean,
  taskUpdated: boolean,
  taskDeleted: boolean,
}

export interface NotionData {
  accessToken: string,
  botId: string,
  workspaceName: string,
  workspaceIcon: string,
  workspaceId: string,
}

export interface NotionPageData {
  pageId: string,
  parentPageId: string,
  title: string,
  workspaceToken: string
}

export interface NotionCreatePageData {
  workspaceToken: string,
  pageId: string,
  pageTitle: string,
  block: Block[]
}

export interface TrelloData {
  settings: {
    isTrelloEnabled: boolean
    isAutoSyncEnabled: boolean
    isAllowOtherToSyncEnabled: boolean,
  }
  accessToken: string
  webhookId: string
  workspaceId: string,
  workspaceName: string,
  board: TrelloBoardData
}

export interface TrelloBoardData {
  boardId: string,
  boardName: string,
  boardURL: string,
  todoList: TrelloListData,
  inProgressList: TrelloListData,
  completedList: TrelloListData,
}

export interface TrelloListData {
  listId: string
  listName: string,
}

export interface JiraData { }

export interface DatabaseUsers {
  [key: string]: SimpleUserData;
}

export interface SimpleUserData extends SimpleUserDataCore {
  access: boolean;
  role: MeetingRole;
}

export interface SimpleUserDataCore {
  userId: string;
  name: string;
  email: string;
  date: {
    added: SDate;
    created: SDate;
    lastViewed: SDate;
  };
}

export interface PublicUserData {
  name: string;
  email: string;
  userId: string;
  photoUrl: string;
  integrations: {
    // TODO: I'm pretty sure we can't expose this to the frontend
    slack: SlackData[],
    notion: NotionData[],
    trello: TrelloData,
    jira: JiraData,
  }
}

export interface FriendListV2 {
  resolvedState: ResolvedState;
  users: PublicUserDataV2[];
}

export interface DatabaseFriendListV2 {
  [userId: string]: DatabasePublicUserV2;
}

export interface DatabasePublicUserV2 {
  email: string,
}

export interface PublicUserDataV2 {
  // When updating this object, also need to update the same object in
  // functions types
  resolvedState: ResolvedState,
  userId: string,
  isShepherdUser: boolean,
  data: {
    name: string;
    email: string;
    firstName: string,
    lastName: string,
    photoUrl: string
  }
  external: {
    email: {
      receiveTaskEmail: boolean,
    },
    slack: {
      hasEnabledSlack: boolean,
      notifications: SlackNotifications,
    },
    trello: {
      isTrelloEnabled: boolean
      isAutoSyncEnabled: boolean
      isAllowOtherToSyncEnabled: boolean,
    }
  }
}

export interface UserFeedback {
  created: string
  email: string,
  feedback: string,
  name: string,
  userId: string,
}

/**
 * Shepherd Date
 * @date {string}
 * @timestamp {number} Typically used to sort or filter dates
 */
export interface SDate {
  date: string;
  timestamp: number;
}

export type LinkPermissions = 'private' | 'public_view' | 'public_edit';

export interface UserPermissions {
  canEdit: boolean;
  canEditTitle: boolean;
  canEditMeetingSeries: boolean;
  canEditLinkSettings: boolean;
  canAddUser: boolean;
  canAddAdmin: boolean;
  canAddModerator: boolean;
  canAddEditor: boolean;
  canAddCommenter: boolean;
  canAddViewer: boolean;
  canRemoveUser: boolean;
  canAddGroup: boolean;
  canDeleteMeeting: boolean;
  canComment: boolean;
  canView: boolean;
  isLastAdmin: boolean;
  role: MeetingRole;
}

export type MeetingRole =
  | 'noAccess'
  | 'public_view'
  | 'public_edit'
  | 'viewer'
  | 'commenter'
  | 'editor'
  | 'moderator'
  | 'admin';

// 3 - New Text Editor made with Prosemirror
// 4 - Agenda is deprecated
export type Version = 1 | 2 | 3 | 4;

// Setter functions
export type SetLoadingType = React.Dispatch<React.SetStateAction<boolean>>;
export const DummySetLoading = () => { };
export type SetMeetingDataType = React.Dispatch<React.SetStateAction<MeetingData>>
export type SetTaskDataType = React.Dispatch<React.SetStateAction<TaskItem>>

// Intercom
export type IntercomState = boolean;

export interface GapiMeetingData extends DatabaseGapiMeetingData {
  resolvedState: ResolvedState,
}

export type DatabaseGapiMeetingData = {
  updated: string,
  summary: string,
  description: string,
  status: string,
  start: {
    dateTime: string,
  },
  sequence: number,
  reminders: {
    useDefault: boolean
  },
  organizer: {
    email: string,
    self: boolean
  },
  kind: string,
  id: string,
  iCalUID: string,
  eventType: string,
  recurringEventId: string,
  etag: string,
  end: {
    dateTime: string
  },
  creator: {
    email: string,
    self: boolean
  },
  created: string,
  conferenceData: {
    conferenceId: string,
    conferenceSolution: {
      iconUri: string,
      key: {
        type: string
      },
      name: string
    },
    entryPoints: EntryPoint[],
    signature: string,
  },
  attendees: GoogleAttendee[],
};

export type GoogleAttendee = {
  email: string,
  responseStatus: string
}

export interface AttendeeProfile extends GoogleAttendee {
  userId: string,
  name: string,
  email: string,
  photoUrl: string
}

export type EntryPoint = {
  entryPointType: string,
  label: string,
  uri: string
}

export type Note = string;

// Tasks Types
export type TaskStatus = 'todo' | 'inProgress' | 'overdue' | 'completed';

export type DueDateType = 'date' | 'preMeetingTask' | 'noDueDate';

export type Assignee = {
  userId: string,
  name: string,
  email: string,
  photoUrl: string,
}

export type DatabaseAssignee = {
  userId: string,
  name: string,
  email: string,
  photoUrl: string,
}

export interface AttendeeV2 extends PublicUserDataV2 {
  responseStatus: string
}

export interface SecretChatAttendeeV2 extends PublicUserDataV2 {
  isNotified: boolean
}

export interface AssigneeV2 extends PublicUserDataV2 { }
export interface ReporterV2 extends PublicUserDataV2 { }

export type Reporter = Assignee;

export type TaskItemVersion = 1 | 2;

export type TaskPermissions = MeetingRole;

export interface TaskItem extends CoreTaskItem {
  taskId: string,
  assignee: AssigneeV2,
  reporter: ReporterV2,
}

export interface DatabaseTaskItem extends CoreTaskItem {
  assignee: DatabaseAssignee,
  reporter: DatabaseAssignee,
}

export type CoreTaskItem = {
  version: TaskItemVersion;
  date: {
    created: SDate,
    updated: SDate,
    dueDate: {
      type: DueDateType,
      date: SDate,
      meeting: {
        meetingId: string;
        startDate: SDate,
        name: string,
      },
    },
  },
  data: {
    status: TaskStatus;
    completed: boolean;
    assignee: Assignee;
    reporter: Reporter;
    title: string;
    description: string;
    isPrivate: boolean;
    isViewed: boolean;
  },
  integrations: {
    trello: { // TODO: Should be own type
      trelloTaskId: string,
      isTrelloSyncEnabled: boolean
    },
    slack: {
      isOverdueNotificationSent: boolean,
    }
  },
  meeting: {
    meetingId: string;
    startDate: SDate,
    tags: string[],
    name: string,
  },
  order: {
    privateIndex: number, // Index in consolidated task lits
    privatePrevTaskId: string, // Linked list, prev item
    privateNextTaskId: string, // Linked list, next item
    meetingIndex: number, // Index in meeting task list
    meetingPrevTaskId: string, // Linked list, prev item
    meetingNextTaskId: string, // Linked list, next item
  },
  // Always set to 'editor'
  permissions: TaskPermissions;
}

export type IntegrationType = 'Slack';

export type DefaultSlackChannel = {
  value: string,
};

export type SlackChannel = {
  id: string,
  name: string,
}

export type AccessToken = {
  token: string,
}
export type TaskOrderField = 'privateIndex' | 'meetingIndex';

export type TasksPage = 'meeting' | 'allTasks';

export type RelativeTiming = 'before' | 'after' | 'during' | undefined;

export type MeetingAnalyticsData = {
  hasUsedShepherd?: boolean,
  hasAgenda?: boolean,
  hasSharedNotes?: boolean,
  hasPrivateNotes?: boolean,
  users?: MeetingUserAnalyticsData[]
}

export type MeetingUserAnalyticsData = {
  userId?: string,
}

export interface TemplateData extends DatabaseTemplateData {
  templateId: string
}

// version 1 is for templates created for the firepad text editor (meetingDataVersion 2)
// version 2 is for templates created for the prosemirror text editor (meetingDataVersion 3)
export type TemplateVersion = 1 | 2;

export type TemplateShareStatus = 'private' | 'public';

export type DatabaseTemplateData = {
  version: TemplateVersion
  data: {
    title: string,
    description: string
  },
  template: string,
  date: {
    created: SDate,
    updated: SDate
  },
  meeting: {
    meetingId: string,
    startDate: SDate,
    title: string
  },
  creator: {
    userId: string,
    name: string,
    email: string
  },
  usage: {
    numberOfTimesUsed: number
  },
  share: {
    status: TemplateShareStatus
  }
};

export type WindowMessage = {
  data: any,
  id: string,
  type: string,
  sequence: number,
  ack: boolean,
};

export type CloseSidebarWindowMessage = {
  type: 'TOGGLE_SIDEBAR',
  showing: boolean
}

export type PreviousMeetingWindowMessageTypes = 'TOGGLE_PREVIOUS_MEETING_WINDOW' | 'SWITCH_PREVIOUS_MEETING_WINDOW';

export type PreviousMeetingWindowMessage = {
  type: PreviousMeetingWindowMessageTypes,
  meetingId: string,
  previousMeetingId: string
}

export type CEButtonEvent = {
  field: string,
}

export type UserCenterSection = 'tasks' | 'meetings' | 'currentMeeting' | 'tags';

export type MeetingNotesSection = 'customPeriod' | 'all' | 'thisWeek' | 'recent' | 'thisMonth';

export type TaskNotificationType = 'delete' | 'assign' | 'update';
export type TaskUpdateField = 'dueDate' | 'title' | 'status' | 'description';
export type ToastNotificationType = 'success' | 'info' | 'danger' | 'warning';

export type UseShepherdFor = 'Bring structure to meetings'
  | 'Take collaborative notes'
  | 'Keep track of tasks'
  | 'Quickly share meeting summary'
  | 'All of the above';

export type Shortcut = 'navigateLeft' | 'navigateRight' | 'openCreateTask';

export type ResolveState = 'resolved' | 'rejected' | 'pending';
export type ResolveStateExtended = 'resolved' | 'rejected' | 'pending' | 'null';

export type SlackMessageContent = {
  /*
  there is no type for slackBlocks as the parsing of markdown
  to slack blocks returns a array of objects and each object is
  of diffrent type based on the text type hence 'any' for blocks
*/
  channel: string,
  blocks: any,
  mrkdwn: boolean,
}

export type FontType = 'Open Sans' | 'Arial' | 'Lato' | 'Montserrat' | 'Raleway' | 'Roboto'

export type TextEditor = 'firepad' | 'prosemirror';

export type ResolvedState = 'resolved' | 'rejected' | 'pending';

export type ShareModalTab = 'sendNotes' | 'integrations';

export type IntegrationsTabView = 'Overview' | 'SlackNotifications' | 'SlackProcessing' | 'NotionProcessing' | 'TrelloProcessing' | 'SlackSelectDefaultChannel' | 'SlackSuccessfullyIntegrated' | '';

export type NewFeatures = {
  id: string,
  features: NewFeature[],
}

export type NewFeature = {
  id: number,
  title: string,
  explanation: string,
  media: string,
}

export type SendGridEmailRecipient = {
  email: string,
};

export type TextProps = {
  color?: ColorDropdownColors
}

export interface PublicUserSecretChatData extends PublicUserDataV2 {
  hasAccess: boolean,
}

export interface SecretChatData extends SecretChat {
  chatId: string,
}

export type SecretChat = {
  title: string,
  chatPathInRealtimeDb: string,
  members: SecretChatAttendeeV2[],
  userIds: string[],
  meetingId: string,
  created: SDate,
  updated: SDate,
  creator: PublicUserDataV2,
}
export type ShortcutInfo = {
  name: string,
  commands: string[]
}

export type Quote = {
  quote: string,
  author: string,
}

// ANALYTICS

export type LoginOrSignup = 'login' | 'signup';
export type OnboardingType = 'organic' | 'invited';

// OPERATING SYSTEM

export type OperatingSystem = 'Mac' | 'Windows' | 'Linux';

export interface OperatingSystemState {
  operatingSystem: OperatingSystem,
  setOperatingSystem: Dispatch<SetStateAction<OperatingSystem>>,
}

export type DetectedOperatingSystem = OperatingSystem | 'Pending';

export interface DetectedOperatingSystemState {
  detectedOperatingSystem: DetectedOperatingSystem,
  setDetectedOperatingSystem: Dispatch<SetStateAction<DetectedOperatingSystem>>,
}

export type SlackUser = {
  iconUrl: string,
  name: string,
}

export type SlackIntegrationVersion = 1 | 2;

// eslint-disable-next-line no-unused-vars
export type IntercomTrackEvent = (event: string, metaData?: object | undefined) => void;

export type GoogleMeetingIds = {
  eventId: string,
  calendarId: string,
  resolvedState: ResolvedState,
}

export type ShepherdMeetingId = {
  meetingId: string,
  resolvedState: ResolvedState,
}

export type WelcomeScreenColor = (typeof purple6)
  | (typeof green6)
  | (typeof cyan6)
  | (typeof red6)
  | (typeof blue6);
