import React from 'react';
import styled from 'styled-components';
import { gray3, surface } from '../../colours';
import { link } from '../../typography';

const Button = styled.button`
  ${link};
  border: none;
  background: none;
  padding: 6px 16px;
  color: ${surface};
  cursor: pointer;
  width: 100%;
  text-align: left;
  
  &:hover {
    background: ${gray3};
  }
`;

interface Props {
  value: string,
  id: string
  // eslint-disable-next-line no-unused-vars
  onClick: (value: string) => void,
}

export default function TabDropdownOption({ value, id, onClick }:Props) {
  return (
    <li>
      <Button type="button" onClick={() => onClick(id)}>{value}</Button>
    </li>
  );
}
