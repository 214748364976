import { MarkSpec } from 'prosemirror-model';

function createFontMark(family: string): MarkSpec {
  return {
    attrs: {
      family: {
        default: family,
      },
    },
    group: 'shepherd-fonts',
    excludes: 'shepherd-fonts',
    parseDOM: [
      {
        attrs: { style: `font-family: ${family};` },
      },
    ],
    toDOM(node) {
      return ['span', { style: `font-family: ${node.attrs.family};` }, 0];
    },
  };
}

/**
 * Important note:
 * The keys of these mark specs need to match the keys used within the
 * schema. Otherwise, the menu button active state selection will break,
 * since it is based directly on the keys of this export.
 *
 * So, the marks that end up in the prosemirror schema need to be named
 * exactly as they are exported here.
 */
export default {
  sfProText: createFontMark('SF Pro Text'),
  sfProDisplay: createFontMark('SF Pro Display'),
  serif: createFontMark('serif'),
  inter: createFontMark('Inter'),
  openSans: createFontMark('Open Sans'),
  lato: createFontMark('Lato'),
  montserrat: createFontMark('Montserrat'),
  raleway: createFontMark('Raleway'),
};
