import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface SplitCellsProps {
  fill?: string;
  width?: number;
  height?: number;
}

const SplitCellsIcon = ({
  fill,
  width,
  height,
}: SplitCellsProps) => (
  <svg width={width} height={height} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 6.4902343,0 c -0.4142199,0 -0.75,0.33579 -0.75,0.75 V 4.7304688 H 2.6738281 L 3.5820313,3.7089844 c 0.2751799,-0.30959 0.24709,-0.7834037 -0.0625,-1.0585937 -0.30959,-0.27519 -0.7834037,-0.24709 -1.0585938,0.0625 L 0,5.4804688 2.4609375,8.2480468 c 0.27519,0.3096 0.7490038,0.3377 1.0585938,0.0625 0.30959,-0.2752 0.33769,-0.748994 0.0625,-1.058594 L 2.6738281,6.2304688 h 3.0664062 v 3.9804692 c 0,0.4143 0.3357801,0.75 0.75,0.75 0.4142101,0 0.75,-0.3357 0.75,-0.75 V 5.4804688 0.75 c 0,-0.41421 -0.3357899,-0.75 -0.75,-0.75 z m 3.027344,0 c -0.4142,1e-7 -0.7500001,0.33579 -0.7500001,0.75 v 9.460938 c 0,0.4142 0.3358001,0.75 0.7500001,0.75 0.4142,0 0.7499997,-0.3358 0.7499997,-0.75 V 6.2304688 h 3.066406 l -0.908203,1.021484 c -0.2751,0.3096 -0.2471,0.783394 0.0625,1.058594 0.3096,0.2752 0.783394,0.2471 1.058594,-0.0625 l 2.460937,-2.767578 -2.460937,-2.7675781 c -0.2752,-0.30959 -0.748994,-0.33769 -1.058594,-0.0625 -0.3096,0.27518 -0.3376,0.7490037 -0.0625,1.0585937 l 0.908203,1.0214844 H 10.267578 V 0.75 C 10.267578,0.33579 9.9317783,0 9.5175783,0 Z"
      fill={fill}
    />
  </svg>
);

SplitCellsIcon.defaultProps = {
  fill: darkBlue4,
  width: 16,
  height: 11,
};

export default SplitCellsIcon;
