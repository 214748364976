import React from 'react';
import styled from 'styled-components';
import {
  gray2, darkBlue4, surface,
} from '../../colours';
import { uiText } from '../../typography';

const TextArea = styled.textarea`
  ${uiText};
  width: 100%;
  height: 160px;
  background-color: ${gray2};
  color: ${surface};
  border: unset;
  border-radius: 10px;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  flex-grow: 0;
  align-self: stretch;
  resize: none;

  &:focus-visible, &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${darkBlue4};
  }
`;

interface TextBoxProps {
  placeholder?: string,
  onChange?: any | undefined,
  defaultValue?: string,
}

const TextBox = ({ placeholder, onChange, defaultValue }: TextBoxProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e?.target?.value) onChange(e?.target?.value);
  };

  return (
    <>
      <TextArea placeholder={placeholder} onChange={handleChange} defaultValue={defaultValue} />
    </>
  );
};

TextBox.defaultProps = {
  placeholder: '',
  onChange: undefined,
  defaultValue: '',
};

export default TextBox;
