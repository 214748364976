import React, { useRef } from 'react';
import styled from 'styled-components';
import DownArrow from '../../icons/downArrow';
import UpArrow from '../../icons/upArrow';
import { header200 } from '../../typography';
import { surface } from '../../colours';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import TabDropdownOption from './DropdownOption';
import {
  DropdownMenu,
  DropdownTriggerArrow,
  DropdownInputPlaceholder,
} from '../dropdown/OnboardingDropdown';
import CssSpinner from '../css-spinner';
import Scrollbar from '../scrollbar';

const Container = styled.div`  
  position: relative;
  margin-bottom: 16px;
`;

const Label = styled.p`
  ${header200};
  color: ${surface};
  margin: 0 0 12px 0;
`;
const LoaderContainer = styled.div`
  text-align: center;
`;

const OptionContainer = styled.ul`
  padding: 2px 2px !important;
  border-radius: 8px 8px;
`;

export interface TabDropdownItem {
  id: string,
  value: string,
}

interface DefaultTabDropdownProps {
  label?: string,
  placeholder?: string,
  name: string,
  options:TabDropdownItem[],
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: TabDropdownItem) => void,
  onClick: ()=>void
}

const SimpleTabDropdown = ({
  label, name, placeholder, onChange, onClick,
  options, loading,
}: DefaultTabDropdownProps) => {
  const DefaultTabDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useDetectOutsideClick(DefaultTabDropdownRef, false);

  const toggleIsDropdownOpen = () => {
    onClick();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = (selectedOption: TabDropdownItem) => {
    onChange(selectedOption);
    setIsDropdownOpen(false);
  };

  return (
    <label htmlFor="dummyId">
      <Label>{label}</Label>
      <Container>
        <DropdownTriggerArrow type="button" onClick={toggleIsDropdownOpen} name={name} data-active={isDropdownOpen}>
          <DropdownInputPlaceholder>{placeholder}</DropdownInputPlaceholder>
          {isDropdownOpen ? <UpArrow /> : <DownArrow />}
        </DropdownTriggerArrow>
        <DropdownMenu
          data-active={isDropdownOpen}
          ref={DefaultTabDropdownRef}
        >
          {loading
            ? (
              <LoaderContainer>
                <CssSpinner color={surface} size={30} thickness={4} padding={4} />
              </LoaderContainer>
            ) : (
              <Scrollbar maxHeight="20vh">
                <OptionContainer>
                  {options.map((option) => (
                    <TabDropdownOption
                      value={option.value}
                      id={option.id}
                      onClick={() => handleClick(option)}
                      key={option.id}
                    />
                  ))}
                </OptionContainer>
              </Scrollbar>
            )}
        </DropdownMenu>
      </Container>
    </label>
  );
};

SimpleTabDropdown.defaultProps = {
  placeholder: 'Choices',
  label: '',
};

export default SimpleTabDropdown;
