import React from 'react';
import styled from 'styled-components';
import { header600 } from '../../../shared/typography';

const Header = styled.h1`
  ${header600}
`;

interface Props {
  text: string,
}

const HeaderAtom = ({ text }: Props) => (
  <Header>
    {text}
  </Header>
);

export default HeaderAtom;
