import { MeetingSections } from '../../../shared/types/types';
import { ensureNotUndefined } from '../../../utils/array';

export const getTabButtonStartingPosition = (
  indicator: HTMLElement, buttons: HTMLElement[], meetingTab: MeetingSections,
): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.meetingTab === meetingTab);

  // eslint-disable-next-line no-unused-vars
  const leftPadding = Number(ensureNotUndefined(
    window.getComputedStyle(buttons[0]).paddingLeft.match(/\d+/),
  )[0]);

  const parentContainer = indicator.parentElement as HTMLElement;

  const parentLeftPadding = Number(
    ensureNotUndefined(
      window.getComputedStyle(parentContainer).paddingRight.match(/\d+/),
    )[0],
  );

  // if tab not found set default to first tab
  if (buttonIndex === -1) return buttons[0].getBoundingClientRect().x - parentLeftPadding;

  return buttons[buttonIndex].getBoundingClientRect().x - parentLeftPadding;
};

export const getTabButtonWidth = (
  buttons: HTMLDivElement[], meetingTab: MeetingSections,
): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.meetingTab === meetingTab);

  // if tab not found set default to shared tab
  if (buttonIndex === -1) return buttons[0].offsetWidth;

  return buttons[buttonIndex].offsetWidth;
};
