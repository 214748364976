import {
  CALENDAR_SCREEN, INTEGRATIONS_SCREEN, MEET_SCREEN, TASKS_SCREEN, VIDEO_SCREEN,
} from './constants';
import { WelcomeScreens } from './types';

export const mapIndexToWelomeScreen = (index: number) => {
  switch (index) {
    case 0:
      return CALENDAR_SCREEN;
    case 1:
      return MEET_SCREEN;
    case 2:
      return TASKS_SCREEN;
    case 3:
      return INTEGRATIONS_SCREEN;
    case 4:
      return VIDEO_SCREEN;
    default:
      return CALENDAR_SCREEN;
  }
};

export const switchToNextWelcomeScreen = (welcomeScreen: WelcomeScreens) => {
  switch (welcomeScreen) {
    case CALENDAR_SCREEN:
      return MEET_SCREEN;
    case MEET_SCREEN:
      return TASKS_SCREEN;
    case TASKS_SCREEN:
      return INTEGRATIONS_SCREEN;
    case INTEGRATIONS_SCREEN:
      return VIDEO_SCREEN;
    default:
      return CALENDAR_SCREEN;
  }
};

export const switchToPreviousWelcomeScreen = (welcomeScreen: WelcomeScreens) => {
  switch (welcomeScreen) {
    case MEET_SCREEN:
      return CALENDAR_SCREEN;
    case TASKS_SCREEN:
      return MEET_SCREEN;
    case INTEGRATIONS_SCREEN:
      return TASKS_SCREEN;
    case VIDEO_SCREEN:
      return INTEGRATIONS_SCREEN;
    default:
      return VIDEO_SCREEN;
  }
};
