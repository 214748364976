import React from 'react';
import styled from 'styled-components';
import CrossIcon from '../../icons/cross';
import { gray7, gray9 } from '../../colours';

const Container = styled.span`
  cursor: pointer;
  position: absolute;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover > svg > path {
    fill: ${gray9};
  }
`;

interface Props {
  onClick: any
}

// eslint-disable-next-line no-unused-vars
const SearchableAssignClearButton = ({ onClick }: Props) => (
  <Container onClick={onClick}>
    <CrossIcon fill={gray7} />
  </Container>
);

export default SearchableAssignClearButton;
