import styled from 'styled-components';

// Will space out the components to each side of the modal
// Often used with a LeftContainer and a RightContainer inside
// this component
// I.e. use ModalFooterRightButtonsWrapper for the right
// container
const ModalFooterdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
`;

export default ModalFooterdWrapper;

export const ModalFooterRightButtonsWrapper = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 8px;
  }
`;

export const SingleButtonModalFooterdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 24px;
`;
