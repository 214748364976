import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface AddRowBeforeProps {
  fill?: string;
  width?: number;
  height?: number;
}

const AddRowBeforeIcon = ({
  fill,
  width,
  height,
}: AddRowBeforeProps) => (
  <svg width={width} height={height} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 7.75,8.75 C 7.33579,8.75 7,8.41421 7,8 V 6.75 H 5.75 C 5.33579,6.75 5,6.41421 5,6 5,5.58579 5.33579,5.25 5.75,5.25 H 7 V 4 C 7,3.58579 7.33579,3.25 7.75,3.25 8.16421,3.25 8.5,3.58579 8.5,4 v 1.25 h 1.25 c 0.4142,0 0.75,0.33579 0.75,0.75 0,0.41421 -0.3358,0.75 -0.75,0.75 H 8.5 V 8 C 8.5,8.41421 8.16421,8.75 7.75,8.75 Z M 2.75,6.5 C 1.23122,6.5 0,5.26878 0,3.75 v -1 C 0,1.2312 1.23122,0 2.75,0 h 10 c 1.5188,0 2.75,1.2312 2.75,2.75 v 1 c 0,1.51878 -1.2312,2.75 -2.75,2.75 h -0.5 C 11.8358,6.5 11.5,6.16421 11.5,5.75 11.5,5.33579 11.8358,5 12.25,5 h 0.5 C 13.4404,5 14,4.44036 14,3.75 v -1 C 14,2.0596 13.4404,1.5 12.75,1.5 h -10 C 2.05964,1.5 1.5,2.0596 1.5,2.75 v 1 C 1.5,4.44036 2.05964,5 2.75,5 h 0.4375 c 0.41421,0 0.75,0.33579 0.75,0.75 0,0.41421 -0.33579,0.75 -0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

AddRowBeforeIcon.defaultProps = {
  fill: darkBlue4,
  width: 16,
  height: 9,
};

export default AddRowBeforeIcon;
