import React from 'react';
import styled from 'styled-components';
import CheckIconsAtom from './meetingCard/CheckIconsAtom';
import SelectAtom from './meetingCard/SelectAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child){
      margin-right: 16px;
    }
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

interface Props {
  isChecked: boolean,
  setChecked: () => void,
}

const SelectMolecule = ({ isChecked, setChecked }: Props) => (
  <Container>
    <IconContainer onClick={setChecked}>
      <CheckIconsAtom isChecked={isChecked} />
    </IconContainer>
    <SelectAtom text="Select" />
  </Container>
);

export default SelectMolecule;
