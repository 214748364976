import React from 'react';
import styled from 'styled-components';
import logo from './sparkles.png';

const Img = styled.img`
  height: 18px;
  width: 18px;
`;

const Sparkles = () => (
  <Img src={logo} alt="Whats new" />
);

export default Sparkles;
