import { useState } from 'react';
import emojiAdapter from '../logic/suggestions/emoji-adapter';

export default function useEmojiSuggester() {
  const [show, setShow] = useState<boolean>(false);

  const [left, setLeft] = useState<number>(0);

  const [top, setTop] = useState<number>(0);

  const [contents, setContents] = useState<any[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  function rotateSelectionUp() {
    if (selectedIndex < 1) {
      setSelectedIndex(contents.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  function rotateSelectionDown() {
    setSelectedIndex((selectedIndex + 1) % contents.length);
  }

  function setSelectedAndReset(data: any[]) {
    setContents(data);
    setSelectedIndex(0);
  }

  function setViewable(value: boolean) {
    setShow(value);
  }

  function setPos(x: number, y: number) {
    setTop(y);
    setLeft(Math.min(Math.max(x, 0), 320));
  }

  function getSelectedEmoji() {
    if (selectedIndex >= contents.length) return '';
    return contents[selectedIndex].emoji;
  }

  emojiAdapter.reactMethods = {
    moveSelectionUp: rotateSelectionUp,
    moveSelectionDown: rotateSelectionDown,
    setResults: setSelectedAndReset,
    setShow: setViewable,
    setPos,
    getSelectedEmoji,
  };

  return {
    emojiShow: [show, setShow] as const,
    emojiLeft: [left, setLeft] as const,
    emojiTop: [top, setTop] as const,
    emojiContents: [contents, setContents] as const,
    emojiSelected: [selectedIndex, setSelectedIndex] as const,
  };
}
