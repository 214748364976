import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface UnderlineProps {
  fill?: string;
  width?: number;
  height?: number;
}

const UnderlineIcon = ({
  fill,
  width,
  height,
}: UnderlineProps) => (
  <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 9.75,12 c 0.414,0 0.75,-0.3358 0.75,-0.75 0,-0.4142 -0.336,-0.75 -0.75,-0.75 h -9 C 0.336,10.5 0,10.8358 0,11.25 0,11.6642 0.336,12 0.75,12 Z M 3,0.75 C 3,0.3358 2.664,0 2.25,0 1.836,0 1.5,0.3358 1.5,0.75 v 4.5 C 1.5,7.3211 3.179,9 5.25,9 7.321,9 9,7.3211 9,5.25 V 0.75 C 9,0.3358 8.664,0 8.25,0 7.836,0 7.5,0.3358 7.5,0.75 v 4.5 C 7.5,6.4926 6.493,7.5 5.25,7.5 4.007,7.5 3,6.4926 3,5.25 Z"
      fill={fill}
    />
  </svg>
);

UnderlineIcon.defaultProps = {
  fill: darkBlue4,
  width: 11,
  height: 12,
};

export default UnderlineIcon;
