import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { blue1, gray1 } from '../../colours';
import TaskCardTitle from './TaskTitle';
import TaskDueDate from './TaskDueDate';
import TaskCardAssigneeIcon from './TaskAssigneeIcon';
import { TaskItem } from '../../types/types';
import TaskStatusCheckbox from './TaskStatusCheckbox';
import TaskSettingsThreeDots from './TaskSettingsThreeDots';
import { AuthContext, UserDataContext } from '../../../App';
import { dbUpdateUnseenTask } from '../../../database/firebaseTasksAPI';
import TaskPrivateIcon from './TaskPrivateIcon';

import {
  getDbUpdateAssigneeHandler, getDbUpdateDueDateHandler,
  getDbUpdateStatusHandler, getDbUpdateTitleHandler,
} from './TaskUpdateHandlerFactory';

interface ContainerProps {
  isViewed: boolean,
}

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => (props.isViewed ? gray1 : blue1)} ;
  border-radius: 8px;

  display: flex;
  align-items: center;

  padding: 10px 16px;
  margin-bottom: 8px;

  transition: 0.1s;

  &:hover {
    box-shadow: 0px 2px 8px 0px #00000026;
    transition: box-shadow 0.1s;
    transition-timing-function: ease-in-out;
  }
`;

const TitleAndPrivateIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  margin-right: 16px;
`;

interface Props {
  taskItem: TaskItem,
  disableStatusDropdown?: boolean,
  disableGoToMeeting?: boolean,
  disableAssigneeSelect?: boolean,
  disableCalendarSelect?: boolean,
  showSettingsThreeDots?: boolean,
}

const TaskCard = ({
  taskItem, disableGoToMeeting, disableStatusDropdown,
  disableAssigneeSelect, disableCalendarSelect, showSettingsThreeDots,
}: Props) => {
  const { email } = useContext(AuthContext);
  const { trackEvent } = useIntercom();
  const userData = useContext(UserDataContext);
  const assigneeCandidates = userData.friendListV2.users;
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (!taskItem.data.isViewed
      && taskItem.data.assignee.email === email) {
      dbUpdateUnseenTask(taskItem.taskId);
    }
  }, [taskItem]);

  const isViewed = !isUnviewedAndAssignedToMe(taskItem, email);

  return (
    <Container isViewed={isViewed}>
      <TaskStatusCheckbox
        enable={disableStatusDropdown}
        status={taskItem.data.status}
        updateStatus={getDbUpdateStatusHandler(
          userData,
          taskItem,
          trackEvent,
          authState,
        )}
        willCloseAfterClick
      />
      <TitleAndPrivateIconContainer>
        <TaskCardTitle
          isPrivate={taskItem.data.isPrivate}
          text={taskItem.data.title}
          updateTitle={getDbUpdateTitleHandler(
            userData,
            taskItem,
            trackEvent,
            authState,
          )}
        />
        <TaskPrivateIcon isPrivate={taskItem.data.isPrivate} />
      </TitleAndPrivateIconContainer>
      <TaskCardAssigneeIcon
        assignee={taskItem.assignee}
        updateAssignee={getDbUpdateAssigneeHandler(
          userData,
          taskItem,
          trackEvent,
          authState,
        )}
        assigneeCandidates={assigneeCandidates}
        disableAssigneeSelect={disableAssigneeSelect}
      />
      <TaskDueDate
        taskItem={taskItem}
        updateDueDate={getDbUpdateDueDateHandler(
          userData,
          taskItem,
          trackEvent,
          authState,
        )}
        disableCalendarSelect={disableCalendarSelect}
      />
      <TaskSettingsThreeDots
        enable={showSettingsThreeDots}
        taskItem={taskItem}
        disableCalendarSelect={disableCalendarSelect}
        disableAssigneeSelect={disableAssigneeSelect}
        disableGoToMeeting={disableGoToMeeting}
      />
    </Container>
  );
};

TaskCard.defaultProps = {
  disableAssigneeSelect: false,
  disableCalendarSelect: false,
  disableGoToMeeting: false,
  disableStatusDropdown: true,
  showSettingsThreeDots: true,
};

export default TaskCard;

const isUnviewedAndAssignedToMe = (taskItem: TaskItem, email: string) => {
  if (!taskItem.data.isViewed
    && taskItem.data.assignee.email === email) {
    return true;
  }
  return false;
};
