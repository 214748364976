import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { uiText } from '../../../shared/typography';
import { darkBlue4 } from '../../../shared/colours';

const Explanation = styled.div`
  width: 100%;
  ${uiText};
  color: ${darkBlue4};
  text-align: left;
`;

interface Props {
  children: ReactNode,
}

const ExplanationAtom = ({ children }: Props) => (
  <Explanation>
    {children}
  </Explanation>
);

export default ExplanationAtom;
