import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../../../utils/globalStyle';
import {
  gray1, gray10, gray9, warning7,
} from '../../../../colours';
import { uiText, uiTextMedium } from '../../../../typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  width: ${SIDEBAR_WIDTH};
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  width: min-content;
  background-color: ${gray1};
  padding: 16px;
  border-radius: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2px 3px 4px 3px;
  margin: 0 10px 0 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  white-space: pre;

  height: 100%;
  width: 100%;

  div:first-child {
    margin-bottom: 4px;
  }
  div:nth-child(2) {
    margin-bottom: 10px;
  }
`;

export const ErrorTitle = styled.div`
  ${uiTextMedium};
  color: ${gray10};
`;

export const ErrorDescription = styled.div`
  ${uiText};
  color: ${gray9};
  text-align: left;
`;

export const ErrorAction = styled.div`
  ${uiTextMedium};
  color: ${warning7};

  &:hover {
    cursor: pointer;
  }
`;
