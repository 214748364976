// The 6-variants (i.e. darkBlue6) of the colours
// below are the ones used in our logo, which is
// also those we should try to use throughout our
// application

export const darkBlue10 = '#091639';
export const darkBlue9 = '#0B1A43';
export const darkBlue8 = '#0C1E4C';
export const darkBlue7 = '#0E2156';
export const darkBlue6 = '#0F255F';
export const darkBlue5 = '#3F517F';
export const darkBlue4 = '#6F7C9F';
export const darkBlue3 = '#8792AF';
export const darkBlue2 = '#B7BECF';
export const darkBlue1 = '#E7E9EF';

export const red10 = '#331215';
export const red9 = '#66242A';
export const red8 = '#98353E';
export const red7 = '#CB4753';
export const red6 = '#FE5968';
export const red5 = '#FE7A86';
export const red4 = '#FE9BA4';
export const red3 = '#FFACB4';
export const red2 = '#FFCDD2';
export const red1 = '#FFEEF0';

export const green10 = '#092C20';
export const green9 = '#135840';
export const green8 = '#1C8361';
export const green7 = '#26AF81';
export const green6 = '#2FDBA1';
export const green5 = '#59E2B4';
export const green4 = '#82E9C7';
export const green3 = '#97EDD0';
export const green2 = '#C1F4E3';
export const green1 = '#EAFBF6';

export const purple10 = '#1A1C3E';
export const purple9 = '#232552';
export const purple8 = '#35387B';
export const purple7 = '#464AA4';
export const purple6 = '#585DCD';
export const purple5 = '#797DD7';
export const purple4 = '#9B9EE1';
export const purple3 = '#ACAEE6';
export const purple2 = '#CDCEF0';
export const purple1 = '#EEEFFA';

export const cyan10 = '#1E434C';
export const cyan9 = '#285966';
export const cyan8 = '#3D8598';
export const cyan7 = '#51B2CB';
export const cyan6 = '#65DEFE';
export const cyan5 = '#84E5FE';
export const cyan4 = '#A3EBFE';
export const cyan3 = '#B2EFFF';
export const cyan2 = '#D1F5FF';
export const cyan1 = '#F0FCFF';

export const yellow10 = '#4D3D00';
export const yellow9 = '#665100';
export const yellow8 = '#997A00';
export const yellow7 = '#CCA200';
export const yellow6 = '#FFCB00';
export const yellow5 = '#FFD533';
export const yellow4 = '#FFE066';
export const yellow3 = '#FFE580';
export const yellow2 = '#FFEFB3';
export const yellow1 = '#FFFAE6';

export const blue10 = '#022B48';
export const blue9 = '#023960';
export const blue8 = '#03568F';
export const blue7 = '#0472BF';
export const blue6 = '#058FEF';
export const blue5 = '#37A5F2';
export const blue4 = '#69BCF5';
export const blue3 = '#82C7F7';
export const blue2 = '#B4DDFA';
export const blue1 = '#E6F4FD';

export const gray10 = '#000000';
export const gray9 = '#262626';
export const gray8 = '#595959';
export const gray7 = '#8C8C8C';
export const gray6 = '#BFBFBF';
export const gray5 = '#D9D9D9';
export const gray4 = '#E8E8E8';
export const gray3 = '#F5F5F5';
export const gray2 = '#FAFAFA';
export const gray1 = '#FFFFFF';

// Layout

export const surface = '#1B2124';
export const surfaceLight = '#2D3336';
export const layoutGrey = '#F0F2F5';
export const layoutWhite = '#F7F8F8';

// Overlays
export const overlaySurface = 'rgba(27, 33, 36, 0.4)';

// Error
export const error1 = '#FEF3F2';
export const error2 = '#FEE4E2';
export const error3 = '#FECDCA';
export const error4 = '#FDA29B';
export const error5 = '#F97066';
export const error6 = '#F04438';
export const error7 = '#D92D20';
export const error8 = '#B42318';
export const error9 = '#912018';
export const error10 = '#7A271A';

// Warning
export const warning1 = '#FFFAEB';
export const warning2 = '#FEF0C7';
export const warning3 = '#FEDF89';
export const warning4 = '#FEC84B';
export const warning5 = '#FDB022';
export const warning6 = '#F79009';
export const warning7 = '#DC6803';
export const warning8 = '#B54708';
export const warning9 = '#93370D';
export const warning10 = '#7A2E0E';
