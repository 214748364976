import React, { useRef } from 'react';
import styled from 'styled-components';
import Downshift, {
  StateChangeOptions, DownshiftState,
} from 'downshift';
import UserIcon from '../../icons/UserIcon';
import { AssigneeV2 } from '../../types/types';
import SearchableAssignLoading from './SearchableAssignLoading';
import {
  gray1, gray2, gray3, gray4, gray8,
} from '../../colours';
import SearchableAssigneOptions from './SearchableAssignOptions';
import SearchableAssignClearButton from './SearchableAssignClearButton';
import { emptyPublicUserDataV2 } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import Scrollbar from '../scrollbar';

export const AssignContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 24px 0;
`;

export const SelectContainer = styled.div`
  margin-left: 16px;
  width: 100%;
`;

export const OptionMenuContainer = styled.div`
  display: ${(props: any) => (props['data-is-open'] ? '' : 'none')};
  position: fixed;
  overflow-x:hidden;
  background: ${gray1};
  padding: 14px 12px;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 8px;
  border-radius: 10px;
  z-index: 21;
  & ul {
    padding: 0;
    margin: 0;
    & li {
      padding: 10px 20px;
      list-style: none;
      background-color: ${gray4};
      border-radius: 6px;
      cursor: pointer;
    }
  }
`;

export const Input = styled.input`
  border-radius: 10px;
  background-color: ${gray2};
  color: ${gray8};
  padding: 10px 8px;
  border: ${gray3} solid 2px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: text;
  width: 100%;
  box-sizing: border-box;
  &:focus-visible {
    outline: none;
  }
`;

export type Option = { value: string }

export const stateReducer = (state: DownshiftState<Option>,
  changes: StateChangeOptions<Option>) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.changeInput:
      return {
        ...changes,
        highlightedIndex: 0,
      };
    default:
      return changes;
  }
};

// doc for the pkg: https://github.com/downshift-js/downshift

interface Props {
  selectedEmail: string,
  // eslint-disable-next-line no-unused-vars
  onAssigneeChange: (assignee: AssigneeV2) => void, // TODO: fix type
  candidates: AssigneeV2[],
  openByDefault?: boolean,
  // isUsedForPrivateSpace?: boolean,
}

const SearchableAssign = ({
  selectedEmail, onAssigneeChange, candidates,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  const focusInput = () => inputRef.current.focus();

  const [openOptions, setOpenOptions] = useDetectOutsideClick(inputRef, false);

  const onSelectedItemChange = (selection: Option | null) => {
    if (selection === null) {
      onAssigneeChange(emptyPublicUserDataV2);
      return;
    }
    onAssigneeChange(candidates.find(
      (candidate: AssigneeV2) => candidate.data.email === selection.value,
    ) ?? emptyPublicUserDataV2);
    // if (isUsedForPrivateSpace)
  };

  const candidatesEmails = candidates.map((candidate) => ({ value: candidate.data.email }));

  return (
    <AssignContainer>
      <UserIcon />
      {candidatesEmails.length === 0
        ? (
          <SearchableAssignLoading />
        ) : (
          <SelectContainer>
            <Downshift
              onChange={onSelectedItemChange}
              itemToString={(item) => (item ? item.value : '')}
              initialSelectedItem={{ value: selectedEmail }}
              stateReducer={stateReducer}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                inputValue,
                highlightedIndex,
                getRootProps,
                clearSelection,
                isOpen,
                // selectHighlightedItem,
              }) => (
                <div>
                  <div
                    style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRootProps({} as any, { suppressRefError: true })}
                  >
                    <Input
                      onClick={() => { setOpenOptions(true); }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getInputProps()}
                      ref={inputRef}
                      placeholder="Type email here"
                    />
                    <SearchableAssignClearButton
                      onClick={() => clearSelection(focusInput)}
                    />
                  </div>
                  <OptionMenuContainer data-is-open={openOptions || isOpen}>
                    <Scrollbar maxHeight="220px">
                      <ul
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getMenuProps()}
                      >
                        {(openOptions || isOpen)
                          ? (
                            <SearchableAssigneOptions
                              candidates={candidates}
                              inputValue={inputValue}
                              getItemProps={getItemProps}
                              highlightedIndex={highlightedIndex}
                            />
                          )
                          : null}
                      </ul>
                    </Scrollbar>
                  </OptionMenuContainer>
                </div>
              )}
            </Downshift>
          </SelectContainer>
        )}
    </AssignContainer>
  );
};

SearchableAssign.defaultProps = {
  openByDefault: false,
  // isUsedForPrivateSpace: null,
};
export default SearchableAssign;
