/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  gray1, gray2, gray4, gray6,
} from '../../../colours';
import { small, uiTextMedium } from '../../../typography';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 256px;
  position: absolute;
  width: 192px;
  background-color: ${gray1};
  border-radius: 8px;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  padding: 8px;
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  padding: 6px 8px;
  border-radius: 6px;
  scroll-margin: 8px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  ${uiTextMedium}
  ${({ selected }) => (selected ? `background-color: ${gray4};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray6 : gray2)};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

MenuItem.defaultProps = {
  selected: false,
};

const MenuItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const MenuItemSubText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${small}
`;

interface CommandsFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  contents: unknown[],
  selectedIndex: number,
}

const CommandsFloatingPanel = ({
  show,
  left,
  top,
  contents,
  selectedIndex,
}: CommandsFloatingPanelProps) => {
  const selectedRef = useRef(null);

  useEffect(() => {
    if (selectedRef.current) {
      (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
      });
    }
  }, [selectedIndex, contents, show]);

  return (
    <Menu
      show={show}
      left={left}
      top={top}
    >
      {
        contents.map((value, index) => (
          <MenuItem
            selected={index === selectedIndex}
            key={(value as any).item?.name}
            ref={index === selectedIndex ? selectedRef : null}
          >
            <MenuItemText>
              { `/${(value as any).item?.name}`}
            </MenuItemText>
            <MenuItemSubText>
              Insert a GIF.
            </MenuItemSubText>
          </MenuItem>
        ))
      }
    </Menu>
  );
};

export default CommandsFloatingPanel;
